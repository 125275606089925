import { State } from 'src/models'
import { ActiveOperation } from './types'

export const list = (state: State) => state.brands.list?.data
export const brandById = (state: State, id?: string) =>
  state.brands.list?.data.find(item => item.id === id)

export const isCreating = (state: State) => state.brands.activeOperation === ActiveOperation.create
export const isRemoving = (state: State) => state.brands.activeOperation === ActiveOperation.remove
export const isFetching = (state: State) => state.brands.activeOperation === ActiveOperation.fetch
export const createError = (state: State) => state.brands.error
export const draft = (state: State) => state.brands.draft
export const signUpDraft = (state: State) => state.brands.signUpDraft
export const navigateId = (state: State) => state.brands.navigateId

export const brandsOptions = (state: State) =>
  state.brands.list?.data?.map(
    item =>
      ({
        value: item.id,
        label: item.name
      } || [])
  )
