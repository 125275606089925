import React from 'react'
import { Container, Box, Typography, Button, TextField, Paper } from '@mui/material'
import LocationAppPreview from 'src/components/AppPreview/Location'
import { PrimaryButton, SecondaryButton, Spinner } from 'src/components/index'
import { PageTitle, Text14pxPoppins } from 'src/components/Typography'
import { useDispatch, useSelector } from 'react-redux'
import providerLocations from 'src/store/providerLocations'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ProviderLocationId } from 'src/models/providerLocations'
import { State } from 'src/models'

const LocationCreationScreen = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()

  const locationId: ProviderLocationId = params?.id || (location?.state as any)?.id

  const item = useSelector((state: State) =>
    providerLocations.selectors.locationById(state, locationId)
  )

  const isUpdatingStatus = useSelector(providerLocations.selectors.isUpdatingStatus)

  const onChangeStatus = () => {
    dispatch(providerLocations.actions.updateStatus.started({ id: locationId, status: 'live' }))
  }

  const addMore = () => {
    navigate('/locations/create')
  }

  const addPrmotion = () => {
    navigate('/promotions/create')
  }

  return (
    <Container maxWidth={false}>
      <Box my={4} display='flex' flexDirection='column' alignItems='center'>
        <PageTitle marginTop={2} marginBottom={4}>
          Congratulations! You have successfully created the location
        </PageTitle>

        <LocationAppPreview item={item} />

        <Text14pxPoppins mt={5}>
          This location has been saved as a draft what would you like to do further?
        </Text14pxPoppins>
        <Container maxWidth={'sm'}>
          <Box
            gap={2}
            mt={3}
            sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 2, p: 0 }}
          >
            <SecondaryButton onClick={addPrmotion} variant='outlined' fullWidth>
              Add the promotion
            </SecondaryButton>
            {item.status !== 'live' ? (
              <PrimaryButton onClick={onChangeStatus} fullWidth>
                Publish
              </PrimaryButton>
            ) : null}
          </Box>
          <Box
            gap={2}
            mt={3}
            sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 2, p: 0 }}
          >
            <Button onClick={addMore} sx={{ alignSelf: 'center' }} variant='text'>
              Create one more
            </Button>
          </Box>
        </Container>
      </Box>
      <Spinner loading={isUpdatingStatus} />
    </Container>
  )
}

export default LocationCreationScreen
