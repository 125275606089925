import React, { ReactNode, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Container,
  Select,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { PageTitle } from '../Typography'
import CustomBreadcrumbs from '../Breadcrumbs'
import ButtonBack from '../Base/ButtonBack'
import { Spinner } from '..'

interface Props {
  loading?: boolean
  title: string
  children: ReactNode
  linkHref?: string
}

const CreateContainer = ({ loading, title, linkHref, children }: Props) => {
  return (
    <>
      <Container
        maxWidth={false}
        style={{
          padding: 50,
          display: 'flex',
          marginBottom: 100,
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Box width={'100%'} display='flex' justifyContent='space-between'>
          <div />
          <PageTitle>{title}</PageTitle>
          <ButtonBack linkHref={linkHref} />
        </Box>

        {children}
      </Container>
      <Spinner loading={loading} />
    </>
  )
}

export default CreateContainer
