import React, { useState, useEffect } from 'react'
import { Box, Button, Typography, TextField, InputAdornment, IconButton } from '@mui/material'
import Header from './Header'
import List from './List'
import Map from './Map'
import { useDispatch, useSelector } from 'react-redux'
import providerLocations from 'src/store/providerLocations'
import { Spinner } from 'src/components'

const MyLocations = () => {
  const [view, setView] = useState('grid')

  const dispatch = useDispatch()

  const list = useSelector(providerLocations.selectors.list)
  const isFetching = useSelector(providerLocations.selectors.isFetching)

  useEffect(() => {
    dispatch(providerLocations.actions.fetch.started())
  }, [])

  return (
    <Box px={6} pt={2.5} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Header view={view} setView={setView} totalCount={list.length} />
      {view === 'grid' ? <List items={list} /> : <Map items={list} />}
      <Spinner loading={isFetching && !list.length} />
    </Box>
  )
}

export default MyLocations
