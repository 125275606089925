// useGetInTouchForm.ts
import { enqueueSnackbar } from 'notistack'
import { useState, useEffect, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import usePrevious from 'src/hooks/usePrevious'
import { State } from 'src/models/index'
import { GetInTouchMessageCreate } from 'src/models/subscriptions'
import authStore from 'src/store/auth'
import subscriptionsStore from 'src/store/subscriptions'

const useGetInTouchForm = () => {
  // Selector to get initial data from the store
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentProvider = useSelector(authStore.selectors.currentProvider)
  const user = useSelector(authStore.selectors.user)
  const isCreating = useSelector(subscriptionsStore.selectors.isCreating)
  const error = useSelector(subscriptionsStore.selectors.error)

  const prevIsCreating = usePrevious(isCreating)

  const initialData: GetInTouchMessageCreate = {
    // pre-fill with default values or fetch from API
    contactName: user?.profile.fullName || '',
    contactEmail: user?.email || '',
    contactPhoneNumber: user?.phoneNumber || '',
    organizationName: currentProvider?.name || '',
    brandName: '',
    contactWebsite: '',
    message: '',
    countryCode: ''
  }

  // Define the form state
  const [formData, setFormData] = useState<GetInTouchMessageCreate>(initialData)
  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    if (!!isCreating !== !!prevIsCreating && !isCreating && !error) {
      goBack()
    }
  }, [isCreating])

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
        onExited: () => {
          dispatch(subscriptionsStore.actions.dismissError())
        }
      })
    }
  }, [error])

  // Effect to validate the form
  useEffect(() => {
    // Check if all fields are filled
    const isFilled = Object.values(formData).every(value => value.trim() !== '')
    setIsFormValid(isFilled)
  }, [formData])

  // Function to update the form state

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData(prevData => ({ ...prevData, [name]: value }))
  }

  const updateState = (name: string, value: string) => {
    setFormData(prevData => ({ ...prevData, [name]: value }))
  }

  // Function to submit the form
  const handleSubmit = () => {
    // Dispatch an action to the store or perform API call
    dispatch(subscriptionsStore.actions.getInTouchMessageCreate.started(formData))
  }

  const goBack = () => {
    navigate('/plans')
  }

  return { isCreating, goBack, formData, updateState, handleInputChange, handleSubmit, isFormValid }
}

export default useGetInTouchForm
