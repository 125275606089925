import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Spinner } from 'src/components'
import createLocation from 'src/store/createLocation'
import locationsStore from 'src/store/providerLocations'
import { useSnackbar } from 'notistack'
import usePrevious from 'src/hooks/usePrevious'
import { State } from 'src/models'
import locationAdminsStore from 'src/store/locationAdmins'

import { useTranslation } from 'react-i18next'
import { API } from 'src/data/index'
import authStore from 'src/store/auth'
import mediaUploadsStore from 'src/store/mediaUploads'
import subscriptionsStore from 'src/store/subscriptions'

import View from './View'

export default function CreateLocation() {
  const { t } = useTranslation('translation', { keyPrefix: 'create_location' })

  const user = useSelector(authStore.selectors.user)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const location = useLocation()
  const params = useParams()
  const locationId = params?.id || (location?.state as any)?.id

  const isRemoving = useSelector(locationsStore.selectors.isRemoving)
  const isCreating = useSelector(createLocation.selectors.isCreating)
  const draft = useSelector((state: any) =>
    createLocation.selectors.currentDraft(state, locationId || 'new')
  )
  const error = useSelector(locationsStore.selectors.error)
  const createError = useSelector(createLocation.selectors.error)
  const subscriptionStatus = useSelector(subscriptionsStore.selectors.subscriptionStatus)

  const providerLocationFromCache = useSelector((state: State) =>
    locationsStore.selectors.locationById(state, locationId)
  )

  const prevIsRemoving = usePrevious(isRemoving)
  const prevIsCreating = usePrevious(isCreating)

  const fetchLocationFromAPI = async () => {
    try {
      const location = await API.getLocationById(locationId)

      dispatch(createLocation.actions.edit(location))
    } catch (error) {
      console.error('failed to get location from api', error)

      enqueueSnackbar('Failed to get location from API', {
        variant: 'error',
        onExited: () => dispatch(createLocation.actions.dismissError())
      })

      navigate('/locations')
    }
  }

  useEffect(() => {
    if (locationId) {
      if (providerLocationFromCache) {
        dispatch(createLocation.actions.edit(providerLocationFromCache))
      } else {
        fetchLocationFromAPI()
      }
    }
  }, [providerLocationFromCache])

  useEffect(
    () => () => {
      dispatch(mediaUploadsStore.actions.clearDraft())
    },
    []
  )

  const save = () => {
    dispatch(createLocation.actions.createLocation.started(locationId))
  }

  const remove = () => {
    dispatch(locationsStore.actions.remove.started(locationId))
  }

  const handleBack = () => {
    dispatch(locationAdminsStore.actions.clearDraft())
    navigate(-1)
  }

  useEffect(() => {
    if (!!isRemoving !== !!prevIsRemoving && !isRemoving && !error) {
      navigate('/locations')
    }
  }, [isRemoving])

  useEffect(() => {
    if (!!isCreating !== !!prevIsCreating && !isCreating && !createError) {
      dispatch(locationAdminsStore.actions.clearDraft())
      // Navigate to locations screen after successfully updating or creating location
      navigate('/locations')
      // Previously done with
      // handleBack()
      // But it's not working correctly
    }
  }, [isCreating])

  useEffect(() => {
    if (createError || error) {
      enqueueSnackbar(createError?.message || error?.message, {
        variant: 'error',
        onExited: () => dispatch(createLocation.actions.dismissError())
      })
    }
  }, [error, createError])

  if (locationId && !draft) {
    return <Spinner loading={true} />
  }

  return <View />
}
