import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'

import horizontalLogo from 'src/assets/logo-thehubapp-horizontal.svg'
import ProfileIcon from 'src/assets/svg/Profile'

import PrimaryButton from '../Base/PrimaryButton'
import SecondaryButton from '../Base/SecondaryButton'

export default function MenuAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position='absolute'
        sx={{ backgroundColor: 'transparent', color: '#000', boxShadow: 'none', pl: 2.8, pr: 3.5 }}
      >
        <Toolbar>
          {/* Insert your logo here */}
          <img src={horizontalLogo} alt='Logo' style={{ height: '40px', marginRight: '20px' }} />

          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
            <SecondaryButton variant='outlined' href={'/login'}>
              Login
            </SecondaryButton>

            <PrimaryButton sx={{ ml: 2 }} href={'/signup'} variant='contained'>
              Sign Up
            </PrimaryButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
