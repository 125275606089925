import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { fetch, fetchAll, remove, dismissError, updateStatus, setListFilters } from './actions'
import { create } from '../createPromotion/actions'
import { logout, selectProvider } from '../auth/actions'

import keyBy from 'lodash/keyBy'
import _, { groupBy } from 'lodash'

// Types imports
import { StatePromotions } from './types'
import omitBy from 'lodash/omitBy'

const initialState: StatePromotions = {
  cache: {},
  listFilters: {}
}

export function promotions(state: StatePromotions = initialState, action: Action): StatePromotions {
  if (isType(action, create.done)) {
    const { result } = action.payload.result
    return {
      ...state,
      cache: {
        ...state.cache,
        [result.id!]: result
      }
    }
  }

  if (isType(action, updateStatus.done)) {
    const result = action.payload.result
    return {
      ...state,
      isUpdatingStatus: false,
      error: undefined,
      cache: {
        ...state.cache,
        [result.id!]: result
      }
    }
  }

  if (isType(action, updateStatus.started)) {
    return {
      ...state,
      isUpdatingStatus: true,
      error: undefined
    }
  }

  if (isType(action, fetch.started)) {
    return {
      ...state,
      error: undefined,
      isFetching: true
    }
  }

  if (isType(action, fetch.done)) {
    const { result, params } = action.payload
    const data = keyBy(result.data, 'id')
    return {
      ...state,
      cache: data,
      isFetching: false
    }
  }

  if (isType(action, fetchAll)) {
    const result = action.payload
    const locations = _.groupBy(result.data, 'brandId')
    let cache: any = {}
    Object.entries(locations).map(([k, v]) => {
      cache[k] = keyBy(v, 'id')
    })
    const data = keyBy(result.data, 'id')

    return {
      ...state,
      isFetching: false,
      cache: data
    }
  }

  if (isType(action, fetch.failed) || isType(action, updateStatus.failed)) {
    return {
      ...state,
      isUpdatingStatus: false,
      error: action.payload.error,
      isFetching: false
    }
  }

  if (isType(action, remove.started)) {
    return {
      ...state,
      isRemoving: true,
      error: undefined
    }
  }

  if (isType(action, remove.done)) {
    return {
      ...state,
      isRemoving: false,
      cache: omitBy(state.cache, ({ id }) => id === action.payload.params)
    }
  }

  if (isType(action, remove.failed)) {
    return {
      ...state,
      isRemoving: false,
      error: action.payload.error
    }
  }

  if (isType(action, dismissError)) {
    return {
      ...state,
      error: undefined
    }
  }

  if (isType(action, logout) || isType(action, selectProvider)) {
    return initialState
  }

  if (isType(action, setListFilters)) {
    return {
      ...state,
      listFilters: {
        ...state.listFilters,
        ...action.payload
      }
    }
  }

  return state
}
