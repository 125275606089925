import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import {
  create,
  clearDraft,
  remove,
  setDataAfterSignUp,
  fetch,
  dismissError,
  updateDraft
} from './actions'

// Types imports
import { StateBrands, ActiveOperation } from './types'

const initialState: StateBrands = {
  draft: {}
}

export function brands(state: StateBrands = initialState, action: Action): StateBrands {
  if (isType(action, updateDraft)) {
    return {
      ...state,
      draft: {
        ...state.draft,
        ...action.payload
      }
    }
  }

  if (isType(action, clearDraft)) {
    return {
      ...state,
      navigateId: undefined,
      draft: {}
    }
  }

  if (isType(action, setDataAfterSignUp)) {
    return {
      ...state,
      signUpDraft: action.payload
    }
  }

  if (isType(action, create.started)) {
    return {
      ...state,
      navigateId: undefined,
      activeOperation: ActiveOperation.create,
      error: undefined
    }
  }

  if (isType(action, create.done)) {
    const result = action.payload.result
    return {
      ...state,
      draft: {},
      signUpDraft: {},
      navigateId: result.countryCode === 'SA' ? result.id : undefined,
      activeOperation: undefined,
      error: undefined
    }
  }

  if (isType(action, create.failed)) {
    const result = action.payload
    return {
      ...state,
      activeOperation: undefined,
      error: action.payload.error
    }
  }

  if (isType(action, fetch.started)) {
    return {
      ...state,
      activeOperation: ActiveOperation.fetch,
      error: undefined
    }
  }

  if (isType(action, fetch.done)) {
    return {
      ...state,
      activeOperation: undefined,
      list: action.payload.result
    }
  }

  if (isType(action, fetch.failed)) {
    return {
      ...state,
      activeOperation: undefined
    }
  }

  if (isType(action, remove.started)) {
    return {
      ...state,
      activeOperation: ActiveOperation.remove,
      error: undefined
    }
  }

  if (isType(action, remove.done)) {
    const data = state.list?.data?.filter(item => item.id !== action.payload.params)! || []
    return {
      ...state,
      list: {
        ...state.list,
        data
      },
      activeOperation: undefined
    }
  }

  if (isType(action, remove.failed)) {
    return {
      ...state,

      activeOperation: undefined
    }
  }

  return state
}
