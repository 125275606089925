import React, { ChangeEvent, useState } from 'react'
import { Box, Button, Typography, Fade } from '@mui/material'
import Container from 'src/components/CreateLocation/Container'
import StepsButton from './StepsButton'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

interface Location {
  name: string
  address: string
  checked: boolean
}

const StepsCount = 5

const CreatePromotion: React.FC = () => {
  const [activeStep, setActiveStep] = useState(1)
  const navigate = useNavigate()

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 1:
        return <Step1 />
      case 2:
        return <Step2 />
      case 3:
        return <Step3 />
      case 4:
        return <Step4 />
      case 5:
        return <Step5 />
      default:
        return 'Unknown step'
    }
  }

  const handleNext = () => {
    if (StepsCount === activeStep + 1) {
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    if (activeStep === 1) {
      return navigate('/locations')
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const onSaveDraft = () => true

  return (
    <Container stepsCount={StepsCount} activeStep={activeStep} title={'Create new promotion'}>
      <Fade in={true} timeout={500}>
        <Box>
          {getStepContent(activeStep)}
          <StepsButton activeStep={activeStep} setActiveStep={setActiveStep} />
        </Box>
      </Fade>
    </Container>
  )
}

export default CreatePromotion
