import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import authStore from 'src/store/auth'
import axios from 'axios'

export default function useCountryCode() {
  const [code, setCountryCode] = useState<string | undefined>()
  const user = useSelector(authStore.selectors.user)

  useEffect(() => {
    // Try to retrieve cached data
    const cachedIP = localStorage.getItem('userIP')
    const cachedCountryCode = localStorage.getItem('countryCode')

    const fetchData = async () => {
      const response = await axios.get(
        'https://api.ipstack.com/check?access_key=9f4cbe5d92324ea73b41da93c4c10de3'
      )
      const { ip, country_code } = response.data

      // Check if the IP has changed
      if (cachedIP !== ip) {
        localStorage.setItem('userIP', ip)
        localStorage.setItem('countryCode', country_code)
        setCountryCode(country_code)
      } else {
        // Use the cached country code if the IP hasn't changed
        setCountryCode(cachedCountryCode!)
      }
    }

    if (!user && (!cachedIP || !cachedCountryCode)) {
      fetchData().catch(console.error) // It's a good practice to handle errors, at least by logging them
    }
  }, [])

  useEffect(() => {
    if (user) {
      const parsedPhoneNumber = parsePhoneNumberFromString('+' + user.phoneNumber)
      const countryCode = parsedPhoneNumber?.country
      if (countryCode) {
        localStorage.setItem('countryCode', countryCode) // Update the cached country code based on the user's phone number
        setCountryCode(countryCode)
      }
    }
  }, [user])

  // Initialize code from local storage if available
  useEffect(() => {
    const storedCountryCode = localStorage.getItem('countryCode')
    if (storedCountryCode) {
      setCountryCode(storedCountryCode)
    }
  }, [])

  return code
}
