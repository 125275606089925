import { useState, useCallback, useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export function useReCaptcha(callback?: () => void) {
  const [captchaToken, setCaptchaToken] = useState<string | undefined>()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha('resendOTP')
    setCaptchaToken(token)
  }, [executeRecaptcha])

  useEffect(() => {
    if (captchaToken) {
      callback?.()
    }
  }, [captchaToken, callback])

  return { captchaToken, handleReCaptchaVerify }
}
