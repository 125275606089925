import React from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SxProps,
  Theme,
  SelectProps
} from '@mui/material'

interface CustomDropdownProps extends SelectProps {
  label?: string
  options: Array<{ label: string; value: string | number }>
  sx?: SxProps<Theme> // allows custom styles to be passed
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  label,
  options,
  sx,
  ...selectProps // this will pass along any other props to the underlying Select component
}) => {
  // Define basic styles with a focus on the selected option
  const defaultSx: SxProps<Theme> = {
    // ... other styles
    width: '100%', // The width is now flexible, based on the parent container
    backgroundColor: '#F7F9F8', // Default background color
    // padding: '8px 16px', // Applied padding as per the new style
    borderRadius: '8px', // Applied border-radius as per the new style
    '.MuiSelect-select.MuiSelect-select': {
      '&.Mui-selected': {
        backgroundColor: 'green', // Style for selected option
        color: 'white',
        '&:hover': {
          backgroundColor: 'darkgreen' // Darken the color slightly on hover
        }
      }
    },
    '.MuiPaper-root': {
      '.MuiMenuItem-root': {
        // Normal state styles
        '&:hover': {
          backgroundColor: 'lightgrey' // Hover state
        }
      },
      '.MuiMenuItem-root.Mui-selected': {
        // Selected state styles
        backgroundColor: 'green !important', // Use !important to override inline styles
        color: 'white'
      }
    },
    // Merge parent styles
    ...sx
  }

  return (
    <FormControl variant='outlined' fullWidth sx={defaultSx}>
      <Select
        displayEmpty
        //@ts-ignore
        renderValue={value => {
          if (!value) {
            return <a>{selectProps.placeholder}</a>
          }

          return options?.find(item => item.value === value)?.label
        }}
        {...selectProps}
      >
        {options?.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={
              {
                // Add styles for each option if necessary
              }
            }
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CustomDropdown
