import React from 'react'
import { Box, TextField, InputAdornment, Grid, Typography } from '@mui/material'
import SocialNetwork from 'src/assets/svg/SocialNetwork'
import { Rany500Gray70 } from 'src/components/Typography'
import { TextInput } from 'src/components'
import useLocationStore from './useLocationsStore'
import { SocialMedia } from 'src/models/providerLocations'

const socialMediaFields = [
  { id: 'Instagram', label: 'Instagram', Icon: SocialNetwork.Instagram },
  { id: 'X', label: 'X', Icon: SocialNetwork.X },
  { id: 'Facebook', label: 'Facebook', Icon: SocialNetwork.Facebook },
  { id: 'Linkedin', label: 'LinkedIn', Icon: SocialNetwork.Linkedin },
  { id: 'TikTok', label: 'TikTok', Icon: SocialNetwork.TikTok },
  { id: 'WhatsApp', label: 'WhatsApp', Icon: SocialNetwork.WhatsApp },
  { id: 'Linktree', label: 'Linktree', Icon: SocialNetwork.Linktree },
  { id: 'GoogleMap', label: 'Google map', Icon: SocialNetwork.GoogleMap },
  { id: 'Yelp', label: 'Yelp', Icon: SocialNetwork.Yelp },
  { id: 'Foursquare', label: 'Foursquare', Icon: SocialNetwork.Foursquare }
]

const SocialMediaForm = () => {
  const { draft, updateDraftData, handleInputChange, handleSelectChange } = useLocationStore()

  const handleChange = (id: string, value: string) => {
    const data = socialMediaFields.map(item => {
      return {
        socialMediaId: item.id,
        url:
          id === item.id
            ? value
            : draft?.socialMedias?.find(draftItem => draftItem.socialMediaId === item.id)?.url || ''
      }
    })

    updateDraftData({ socialMedias: data })
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {socialMediaFields.map(({ Icon, label, id }, index) => {
        const value = draft.socialMedias?.find(item => item.socialMediaId === id)?.url
        return (
          <Grid
            key={label}
            container
            alignItems='center'
            justifyContent='space-between'
            sx={{ padding: '8px' }}
          >
            <Grid item mr={3}>
              <Grid container alignItems='center' justifyContent={'center'}>
                <Grid mt={0.4} item>
                  {
                    //@ts-ignore
                    SocialNetwork[id]?.({})
                  }
                </Grid>
                <Grid ml={2} item>
                  <Rany500Gray70>{label}</Rany500Gray70>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} display='flex' justifyContent='flex-end'>
              <TextInput
                fullWidth
                value={value}
                placeholder='Paste link'
                onChange={e => handleChange(id, e.target.value)}
              />
            </Grid>
          </Grid>
        )
      })}
    </Box>
  )
}

export default SocialMediaForm
