import React, { useEffect, ChangeEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container, List } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { PrimaryButton, Spinner } from 'src/components'
import authStore from 'src/store/auth'
import adminsStore from 'src/store/admins'

import { PageTitle, PageSubtitle } from 'src/components/Typography'
import UserItem from './UserItem'

export default function MyProfile() {
  const dispatch = useDispatch()

  const admins = useSelector(adminsStore.selectors.list)

  useEffect(() => {
    dispatch(adminsStore.actions.fetch.started())
  }, [])

  return (
    <Container maxWidth={false}>
      <PageTitle mt={4}>Profile settings</PageTitle>
      <PageSubtitle mt={5}>Users management</PageSubtitle>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            marginTop: 1,
            minWidth: '35%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <List>
            {admins.map((item, index) => (
              <UserItem key={item.phoneNumber} item={item} />
            ))}
          </List>
          <Box
            //@ts-ignore
            component={Link}
            to={'/admin/create'}
            display={'flex'}
            style={{ textDecoration: 'none' }}
            justifyContent={'flex-end'}
            mt={3}
          >
            <PrimaryButton variant='contained' color='primary'>
              + New user
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
