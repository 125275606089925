import React from 'react'
import { Container, Box, Typography, Button, TextField, Paper } from '@mui/material'
import LocationAppPreview from 'src/components/AppPreview/Location'
import { PrimaryButton, SecondaryButton } from 'src/components/index'
import {
  PageTitle,
  Text14pxPoppins,
  Text14pxRany,
  Text14pxRanyBold,
  Text16pxRany500
} from 'src/components/Typography'
import { useSelector } from 'react-redux'
import providerLocations from 'src/store/providerLocations'
import FoodicsImage from 'src/assets/visual.png'
import AddLinkIcon from '@mui/icons-material/AddLink'
import { foodicsConnectUrl } from 'src/config/index'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const LocationCreationScreen = () => {
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()
  const brandId: string = params?.id || (location?.state as any)?.id

  return (
    <Container maxWidth={false}>
      <Box my={4} display='flex' flexDirection='column' alignItems='center'>
        <PageTitle marginTop={4} marginBottom={4}>
          Congratulations! You have successfully created the brand
        </PageTitle>

        <Text16pxRany500 marginBottom={2} sx={{ width: 800, textAlign: 'center' }}>
          Activate Promo Codes with Foodics
        </Text16pxRany500>

        <Text14pxPoppins marginBottom={4} sx={{ width: 800, textAlign: 'center' }}>
          Enhance your sales strategy by enabling promo codes and discount systems in Foodics.
          Streamline offers, attract more customers, and boost revenue efficiently. Link now for a
          competitive edge.
        </Text14pxPoppins>

        <Container maxWidth={'sm'}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box component='img' src={FoodicsImage} />

            <Box
              gap={2}
              marginTop={10}
              sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 2, p: 0 }}
            >
              <SecondaryButton
                onClick={() => {
                  navigate('/brands')
                }}
                variant='outlined'
                fullWidth
              >
                Skip
              </SecondaryButton>
              <PrimaryButton
                startIcon={<AddLinkIcon color={'inherit'} />}
                fullWidth
                href={`${foodicsConnectUrl}&state=b${brandId}`}
                //@ts-ignore
                target='_blank'
                //@ts-ignore
                rel='noreferrer'
                disableElevation
              >
                Enable Foodics
              </PrimaryButton>
            </Box>
          </Box>
        </Container>
      </Box>
    </Container>
  )
}

export default LocationCreationScreen
