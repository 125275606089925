import { call, put, select, takeLatest, retry } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { parsePhoneNumber } from 'libphonenumber-js'

import { Action } from 'typescript-fsa'
import { State } from 'src/models'
import {
  sendPhoneVerification,
  signIn,
  authChange,
  sessionRefresh,
  saveProfile,
  logout,
  getCountriesOTPRequestMethodsList,
  updateCurrentProvider,
  selectProvider,
  createProvider,
  signUp,
  allowedOTPMethodsChange
} from './actions'
import {
  SendPhoneVerificationParams,
  SignInData,
  EditProfileData,
  LogoutPayload,
  UpdateCurrentProviderData,
  SelectProviderPayload,
  CreateProviderData,
  SignUpData
} from './types'
import {
  PhoneVerification,
  SessionWithUser,
  UserWithSession,
  AuthData,
  User,
  CountriesOTPRequestMethodsListResult,
  ProviderAdmin,
  Provider,
  ProviderRole,
  CountryOTPRequestMethodsList
} from 'src/models/auth'

import { API, mainAPI } from 'src/data'
import { convertNumberToEuropean } from 'src/utils/convertNumberUtils'
import { fetchFoodicsAuthCallback } from 'src/store/foodics/actions'
import getAllowedOTPMethods from 'src/utils/getAllowedOTPMethods'
import brands from '../brands'
import { enqueueSnackbar } from 'notistack'

export default function createRootSaga() {
  function* fetchCountriesOTPRequestMethodsListSaga(): SagaIterator {
    try {
      const result: CountriesOTPRequestMethodsListResult = yield call([
        mainAPI,
        mainAPI.getCountryOTPRequestMethods
      ])

      yield put(getCountriesOTPRequestMethodsList.done({ result }))
    } catch (error) {
      if (error instanceof Error) yield put(getCountriesOTPRequestMethodsList.failed({ error }))
    }
  }

  function* sendPhoneVerificationSaga({
    payload
  }: Action<SendPhoneVerificationParams>): SagaIterator {
    try {
      const signInData: SignInData = yield select((state: State) => state.auth.signInData)
      const signUpData: SignUpData = yield select((state: State) => state.auth.signUpData)

      const phoneNumber =
        payload.operation === 'signUp' ? signUpData?.phoneNumber : signInData?.phoneNumber

      const { messenger, recaptcha } = { ...signInData, ...payload }

      const result: PhoneVerification = yield call(
        [mainAPI, mainAPI.sendPhoneVerification],
        convertNumberToEuropean(phoneNumber!),
        undefined,
        undefined,
        messenger,
        undefined,
        recaptcha
      )

      yield put(
        sendPhoneVerification.done({
          params: payload,
          result
        })
      )

      const countriesOTPRequestMethodsList: CountryOTPRequestMethodsList[] = yield select(
        (state: State) => state.auth.countriesOTPRequestMethodsList
      )
      const methods = getAllowedOTPMethods(countriesOTPRequestMethodsList, phoneNumber!)

      yield put(allowedOTPMethodsChange(methods))
    } catch (error) {
      yield put(
        sendPhoneVerification.failed({
          params: payload,
          error: new Error(error instanceof Error ? error.toString() : 'Failed to request OTP')
        })
      )
    }
  }

  function* signInSaga(): SagaIterator {
    const signInData: SignInData = yield select((state: State) => state.auth.signInData)

    try {
      //@ts-ignore
      const response: SessionWithUser = yield call([mainAPI, mainAPI.logIn], {
        id: signInData.phoneVerificationId!,
        otp: convertNumberToEuropean(signInData.otp!)
      })

      yield put(
        signIn.done({
          result: {
            session: Object.assign({}, response, { user: undefined }),
            user: response.user,
            providerAdmins: response.user?.profile?.providerAdmins || []
          }
        })
      )
    } catch (error) {
      if (error instanceof Error) yield put(signIn.failed({ error }))
    }
  }

  function* signUpSaga(): SagaIterator {
    const signUpData: SignUpData = yield select((state: State) => state.auth.signUpData)

    try {
      const response: UserWithSession = yield call(
        [mainAPI, mainAPI.register],
        {
          id: signUpData.phoneVerificationId!,
          otp: convertNumberToEuropean(signUpData.otp!)
        },
        {
          email: signUpData.email,
          fullName: signUpData.fullName || '',
          isBusiness: true
        },
        signUpData.referralCode || undefined
      )

      yield put(
        signIn.done({
          result: {
            session: response.session,
            user: Object.assign({}, response, { session: undefined }),
            providerAdmins: response?.profile?.providerAdmins || []
          }
        })
      )

      yield put(
        createProvider.started({
          provider: {
            name: signUpData?.providerName!,
            posSystemType: signUpData.posSystemType,
            posSystemOther: signUpData.posSystemOther
          }
        })
      )

      const { brandName, countryCode, city, contactEmail, websiteUrl, contactPhoneNumber } =
        signUpData
      yield put(
        brands.actions.setDataAfterSignUp({
          name: brandName,
          countryCode,
          city,
          contactPhoneNumber,
          contactEmail,
          websiteUrl
        })
      )
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar?.(error.message, {
          variant: 'error'
        })
        yield put(signIn.failed({ error }))
      }
    }
  }

  function* signInSuccessSaga(result: AuthData): SagaIterator {
    const { session, user, providerAdmins, currentProvider } = result
    let selectedProvider: Provider | undefined

    // Select first provider as current
    if (!currentProvider && providerAdmins?.length && providerAdmins[0].provider) {
      selectedProvider = providerAdmins[0].provider
    }

    const authData = {
      session,
      user,
      providerAdmins: providerAdmins || [],
      currentProvider: currentProvider || selectedProvider || null
    }

    yield put(authChange(authData))

    API.setAuthToken({ token: result.session.token }, result.user.id, authData.currentProvider?.id)
    mainAPI.setAuthToken(
      { token: result.session.token },
      result.user.id,
      authData.currentProvider?.id
    )

    yield call([localStorage, 'setItem'], 'user', JSON.stringify(result.user))
    yield call([localStorage, 'setItem'], 'session', JSON.stringify(result.session))
    yield call([localStorage, 'setItem'], 'providerAdmins', JSON.stringify(result.providerAdmins))

    if (authData.currentProvider) {
      yield call(
        [localStorage, 'setItem'],
        'currentProvider',
        JSON.stringify(authData.currentProvider)
      )
    }
  }

  function* loadAuthDataSaga() {
    try {
      const sessionJson: string = yield call([localStorage, localStorage.getItem], 'session')
      const userJson: string = yield call([localStorage, localStorage.getItem], 'user')
      const providerAdminsJson: string = yield call(
        [localStorage, localStorage.getItem],
        'providerAdmins'
      )
      const currentProviderJson: string = yield call(
        [localStorage, localStorage.getItem],
        'currentProvider'
      )

      const session = JSON.parse(sessionJson)
      const user = JSON.parse(userJson)
      const providerAdmins = JSON.parse(providerAdminsJson)
      let currentProvider: Provider | null = JSON.parse(currentProviderJson)
      const currentProviderFromProviderAdmins = providerAdmins?.find(
        (providerAdmin: ProviderAdmin) => providerAdmin.providerId === currentProvider?.id
      )

      // If current provider doesn't exist in providerAdmins array -> erase it
      if (!currentProviderFromProviderAdmins) {
        currentProvider = null
      }

      // Select first provider as current
      if (!currentProvider && providerAdmins?.length && providerAdmins[0].provider) {
        currentProvider = providerAdmins[0].provider
      }

      yield put(
        authChange({
          session,
          user,
          providerAdmins,
          currentProvider,
          isRestored: true
        })
      )

      if (session) {
        API.setAuthToken(
          { token: (session as SessionWithUser).token },
          user.id,
          currentProvider?.id
        )
        mainAPI.setAuthToken(
          { token: (session as SessionWithUser).token },
          user.id,
          currentProvider?.id
        )

        yield call(refreshSessionSaga, session.id)
      }
      yield call(fetchCountriesOTPRequestMethodsListSaga)
    } catch (error) {
      yield put(
        authChange({
          session: null,
          user: null,
          currentProvider: null,
          providerAdmins: null,
          isRestored: true
        })
      )
    }
  }

  function* refreshSessionSaga(sessionId: string): SagaIterator {
    try {
      let session: SessionWithUser | null = null

      yield retry(5, 5000, async () => {
        session = await mainAPI.refreshSession(sessionId)
      })

      if (session) {
        session as SessionWithUser

        const providerAdmins = (session as SessionWithUser)!.user?.profile?.providerAdmins || []
        const currentProviderJson: string = yield call(
          [localStorage, localStorage.getItem],
          'currentProvider'
        )
        let currentProvider: Provider | null = JSON.parse(currentProviderJson)
        const currentProviderAdminFromProviderAdmins = providerAdmins.find(
          (providerAdmin: ProviderAdmin) => providerAdmin.providerId === currentProvider?.id
        )

        if (currentProviderAdminFromProviderAdmins) {
          currentProvider = {
            ...currentProviderAdminFromProviderAdmins.provider,
            role: currentProviderAdminFromProviderAdmins.role as ProviderRole
          }
        }

        const authData = {
          session: { ...(session as SessionWithUser), user: undefined },
          user: (session as SessionWithUser)!.user,
          providerAdmins: (session as SessionWithUser)!.user?.profile?.providerAdmins || [],
          currentProvider: currentProvider ? currentProvider : undefined
        }

        yield put(sessionRefresh(authData))
        yield call([localStorage, 'setItem'], 'user', JSON.stringify(authData.user))
        yield call([localStorage, 'setItem'], 'session', JSON.stringify(authData.session))
        yield call(
          [localStorage, 'setItem'],
          'providerAdmins',
          JSON.stringify(authData.providerAdmins)
        )
        yield call([localStorage, 'setItem'], 'currentProvider', JSON.stringify(currentProvider))

        API.setAuthToken({ token: (session as SessionWithUser).token }, authData.user.id)
        mainAPI.setAuthToken({ token: (session as SessionWithUser).token }, authData.user.id)
      } else {
        API.setAuthToken(undefined, undefined, undefined)
        mainAPI.setAuthToken(undefined, undefined, undefined)
      }
    } catch (error) {
      console.log('Failed to refresh session', error)
    }
  }

  function* saveProfileSaga(): SagaIterator {
    const userId: string = yield select((state: State) => state.auth.user!.id)
    const editProfileData: EditProfileData = yield select(
      (state: State) => state.auth.editProfileData
    )
    const { lastName, firstName, countryCode, phoneNumber, otp } = editProfileData

    try {
      if ((countryCode || phoneNumber) && !otp) {
        throw new Error('Please enter verification code')
      }

      if (!firstName || firstName.length <= 0) {
        throw new Error('First name should not be empty')
      }

      if (!lastName || lastName.length <= 0) {
        throw new Error('Last name should not be empty')
      }

      const result: User = yield call([mainAPI, mainAPI.updateUser], userId, {
        profile: {
          fullName: `${firstName} ${lastName}`,
          username: editProfileData.username,
          description: editProfileData.bio,
          referralCode: editProfileData.referralCode
        },
        email:
          editProfileData.email && editProfileData.email.length
            ? editProfileData.email.trim()
            : null,
        phoneVerification:
          editProfileData.phoneVerificationId && otp
            ? {
                id: editProfileData.phoneVerificationId,
                otp
              }
            : undefined
      })

      yield put(
        saveProfile.done({
          result
        })
      )
      yield call([localStorage, 'setItem'], 'user', JSON.stringify(result))
    } catch (error) {
      if (error instanceof Error) yield put(saveProfile.failed({ error }))
    }
  }

  function* updateCurrentProviderSaga(action: Action<UpdateCurrentProviderData>) {
    // Params field is required by typescript-fsa, but we don't have params in saga, so we just use empty object
    const params = { provider: {} as Provider }

    try {
      const { provider } = action.payload
      const providerAdmins: ProviderAdmin[] = yield select(
        (state: State) => state.auth.providerAdmins
      )

      if (!provider?.id) {
        throw new Error('ID is required to update provider')
      }

      if (!provider?.name) {
        throw new Error('Please enter provider name')
      }

      const result: Provider = yield call([API, API.updateProvider], provider.id!, {
        name: provider.name,
        subscriptionCountry: provider.subscriptionCountry
      })

      yield call([localStorage, 'setItem'], 'currentProvider', JSON.stringify(result))

      const providerAdminsWithUpdatedProvider = providerAdmins.map(providerAdmin => {
        if (providerAdmin.providerId === provider.id) {
          return {
            ...providerAdmin,
            provider: result
          }
        }

        return providerAdmin
      })

      yield put(
        updateCurrentProvider.done({
          params,
          result: { currentProvider: result, providerAdmins: providerAdminsWithUpdatedProvider }
        })
      )
    } catch (error) {
      if (error instanceof Error) yield put(updateCurrentProvider.failed({ params, error }))
    }
  }

  function* createProviderSaga(action: Action<CreateProviderData>) {
    // Params field is required by typescript-fsa, but we don't have params in saga, so we just use empty object
    const params = { provider: {} as Provider }

    try {
      const { provider } = action.payload

      if (!provider?.name) {
        throw new Error('Please enter provider name')
      }

      const result: Provider = yield call([API, API.createProvider], {
        name: provider?.name,
        subscriptionCountry: provider.subscriptionCountry
      })

      yield call([localStorage, 'setItem'], 'currentProvider', JSON.stringify(result))

      yield put(
        createProvider.done({
          params,
          result: { currentProvider: result }
        })
      )

      // Refresh session and provider admins array
      const sessionJson: string = yield call([localStorage, localStorage.getItem], 'session')
      const session = JSON.parse(sessionJson)

      yield call(refreshSessionSaga, session.id)
    } catch (error) {
      if (error instanceof Error) yield put(createProvider.failed({ params, error }))
    }
  }

  function* selectCurrentProviderSaga(action: Action<SelectProviderPayload>) {
    const { provider } = action.payload

    yield call([localStorage, 'setItem'], 'currentProvider', JSON.stringify(provider))
  }

  function* deleteUserSaga() {
    try {
      const userId: string = yield select((state: State) => state.auth.user!.id)
      yield call([mainAPI, mainAPI.deleteUser], userId)
    } catch (error) {
      // yield put(deleteUser.failed({ error }))
    }
  }

  function* logoutSaga(payload: Action<LogoutPayload>) {
    // Reset API and mainAPI axios client auth headers
    API.setAuthToken(undefined, undefined)
    mainAPI.setAuthToken(undefined, undefined)
    // yield call(deleteUserSaga)
    yield put(
      authChange({
        session: null,
        user: null,
        currentProvider: null,
        signInData: null,
        providerAdmins: null
      })
    )
    yield call([localStorage, 'clear'])
  }

  return [
    call(loadAuthDataSaga),
    takeLatest(getCountriesOTPRequestMethodsList.started, fetchCountriesOTPRequestMethodsListSaga),
    takeLatest(sendPhoneVerification.started, sendPhoneVerificationSaga),
    takeLatest(signIn.started, signInSaga),
    takeLatest(signUp.started, signUpSaga),

    takeLatest(signIn.done, action => signInSuccessSaga(action.payload.result)),
    takeLatest(saveProfile.started, saveProfileSaga),
    // @ts-ignore
    takeLatest(updateCurrentProvider.started, updateCurrentProviderSaga),
    takeLatest(createProvider.started, createProviderSaga),
    takeLatest(selectProvider, selectCurrentProviderSaga),
    takeLatest(logout, logoutSaga),
    takeLatest(fetchFoodicsAuthCallback.done, loadAuthDataSaga)
  ]
}
