import React from 'react'
import { Paper, styled } from '@mui/material'

interface BottomSheetComponentProps {
  imgWidth: string
  maxHeight?: string // Optional prop to set the maximum height of the BottomSheet
  children?: React.ReactNode // Allow children in BottomSheetComponent
}

// Updated styled component with maxHeight property
const BottomSheet = styled(Paper)<{ imgWidth: string; maxHeight?: string }>(
  ({ theme, imgWidth, maxHeight }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: +theme.shape.borderRadius * 2,
    borderTopRightRadius: +theme.shape.borderRadius * 2,
    overflowY: 'auto', // Enable vertical scrolling
    maxHeight: maxHeight || '70%' // Use maxHeight prop or default to 50%
  })
)

const BottomSheetComponent: React.FC<BottomSheetComponentProps> = ({
  imgWidth,
  children,
  maxHeight
}) => {
  return (
    <BottomSheet elevation={3} imgWidth={imgWidth} maxHeight={maxHeight}>
      {children}
    </BottomSheet>
  )
}

export default BottomSheetComponent
