import React from 'react'
import { styled } from '@mui/material/styles'
import LocationPreview from 'src/assets/LocationPreview.png'

interface Props {
  imgWidth: any
}

const aspectRatio = (844 / 390) * 100 // Height divided by width times 100

const StyledBackgroundImageContainer = styled('div')<Props>(({ theme, imgWidth }) => ({
  position: 'relative', // To position the BottomSheet absolutely within
  paddingTop: `${aspectRatio}%`, // This maintains the aspect ratio
  backgroundImage: `url(${LocationPreview})`,
  width: imgWidth, // Set a specific width or '100%' for full width
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}))
const BackgroundImageContainer: React.FC<Props> = ({ imgWidth, children }) => {
  return (
    <StyledBackgroundImageContainer imgWidth={imgWidth}>{children}</StyledBackgroundImageContainer>
  )
}

export default BackgroundImageContainer
