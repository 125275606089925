import { PrimaryButton } from '..'

interface ButtonProps {
  isDisabled: boolean
  text: string
  onClick: () => void
}
const Button = (props: ButtonProps) => {
  return (
    <PrimaryButton
      variant='outlined'
      fullWidth
      sx={{
        ...(props.isDisabled && {
          backgroundColor: '#8DE4C4'
        })
      }}
      color='primary'
      onClick={props.onClick}
    >
      {props.text}
    </PrimaryButton>
  )
}

export default Button
