import React, { useState, useCallback } from 'react'
import { arrayMoveImmutable } from 'array-move'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import Item from './Item'

import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import mediaUploadsStore from 'src/store/mediaUploads'

import { MediaUpload, MediaUploadList } from 'src/store/mediaUploads/types'
import { MediaFile } from 'src/models/uploads'

type ItemType = MediaFile | MediaUpload
const DisplayOrderer = () => {
  const mediaList = useSelector(mediaUploadsStore.selectors.mediaUploads)
  const dispatch = useDispatch()

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const newArray = arrayMoveImmutable(mediaList, oldIndex, newIndex).map((img, indx) => ({
      ...img,
      displayOrder: indx + 1
    }))
    dispatch(mediaUploadsStore.actions.changeImagesOrder(newArray))
  }

  const onDelete = (item: ItemType) => {
    dispatch(mediaUploadsStore.actions.deleteImage(item))
  }

  return mediaList.length ? (
    <SortableList
      distance={2}
      items={mediaList}
      onSortEnd={onSortEnd}
      axis={'xy'}
      onDelete={onDelete}
    />
  ) : null
}

const SortableList = SortableContainer(
  ({ items, onDelete }: { items: MediaUploadList; onDelete: (item: ItemType) => void }) => {
    return (
      <div className={`grid-container`}>
        {items.map((item, index) => (
          <SortableItem key={index} index={index} item={item} onDelete={onDelete} />
        ))}
      </div>
    )
  }
)

const SortableItem = SortableElement(
  (props: {
    item: ItemType
    isOdd?: boolean
    index: number
    onDelete: (item: ItemType) => void
  }) => {
    return <Item {...props} />
  }
)

export default DisplayOrderer
