import { Action } from 'typescript-fsa'
import { SagaIterator } from 'redux-saga'

import { put, call, takeLatest } from 'redux-saga/effects'
import { ProviderAdminCreate, Admin, AdminProfileUpsert } from 'src/models/admins'
import { upsert, fetch, remove } from './actions'
import { API } from 'src/data'
import { enqueueSnackbar } from 'notistack'

export default function createRootSaga() {
  function* createAdminSaga({ payload }: Action<AdminProfileUpsert>): SagaIterator {
    try {
      const result = yield call([API, API.upsertProfileAdmin], payload.phoneNumber!, payload)
      yield put(upsert.done({ params: payload, result }))
    } catch (error) {
      if (error instanceof Error) {
        yield put(upsert.failed({ params: payload, error }))
        enqueueSnackbar?.(error.message, {
          variant: 'error'
        })
      }
    }
  }

  function* fetchProfileAdminsSaga(): SagaIterator {
    try {
      const result = yield call([API, API.readProfileAdmins])
      yield put(fetch.done({ result: result.data }))
    } catch (error) {
      if (error instanceof Error) {
        yield put(fetch.failed({ error }))
        enqueueSnackbar?.(error.message, {
          variant: 'error'
        })
      }
    }
  }

  function* removeSaga({ payload }: Action<Admin>): SagaIterator {
    try {
      const result = yield call([API, API.deleteAdmin], payload.id!)
      yield put(remove.done({ params: payload, result }))
    } catch (error) {
      if (error instanceof Error) {
        yield put(remove.failed({ params: payload, error }))
        enqueueSnackbar?.(error.message, {
          variant: 'error'
        })
      }
    }
  }

  return [
    takeLatest(fetch.started, fetchProfileAdminsSaga),
    takeLatest(upsert.started, createAdminSaga),
    takeLatest(remove.started, removeSaga)
  ]
}
