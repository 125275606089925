import { resizePx } from 'src/theme'

const Icon = () => (
  <svg
    width={resizePx(256)}
    height={resizePx(160)}
    viewBox='0 0 206 160'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_389_19001)'>
      <path
        d='M91.5053 36.382C83.0262 36.3886 74.6059 34.9704 66.5938 32.1863C55.8525 28.4601 47.0531 23.3204 40.4399 16.9101C27.9305 4.78421 17.6746 2.95678 11.2751 3.55851C4.34775 4.21018 0.315478 7.76939 0.275577 7.80528L0 7.49704C0.0407055 7.46034 4.15611 3.82035 11.2098 3.14876C15.3463 2.75519 19.672 3.44093 24.0671 5.18759C29.548 7.36571 35.1531 11.2095 40.7267 16.6122C47.2963 22.9804 56.0446 28.0887 66.7286 31.7949C86.342 38.5987 108.447 36.9514 127.376 27.2752C133.643 24.0713 139.837 21.8843 145.786 20.7752C153.288 19.3762 160.403 19.701 166.934 21.7397C181.285 26.2196 191.088 25.982 196.784 24.9943C202.93 23.9284 205.719 21.7953 205.746 21.7739L206 22.1007C205.885 22.1908 203.102 24.319 196.855 25.4023C191.113 26.3981 181.24 26.6393 166.811 22.135C152.201 17.5749 137.404 22.6137 127.563 27.644C118.207 32.4521 107.991 35.3438 97.5106 36.1505C95.5016 36.3048 93.4999 36.382 91.5053 36.382Z'
        fill='#3F3D56'
      />
      <path
        d='M90.6706 41.5197C92.9805 41.5197 94.8529 39.6409 94.8529 37.3233C94.8529 35.0057 92.9805 33.127 90.6706 33.127C88.3608 33.127 86.4883 35.0057 86.4883 37.3233C86.4883 39.6409 88.3608 41.5197 90.6706 41.5197Z'
        fill='#17D48F'
      />
      <path
        d='M155.467 24.7501C157.777 24.7501 159.65 22.8714 159.65 20.5538C159.65 18.2362 157.777 16.3574 155.467 16.3574C153.158 16.3574 151.285 18.2362 151.285 20.5538C151.285 22.8714 153.158 24.7501 155.467 24.7501Z'
        fill='#17D48F'
      />
      <path
        d='M20.7175 8.39272C23.0273 8.39272 24.8998 6.51395 24.8998 4.19636C24.8998 1.87877 23.0273 0 20.7175 0C18.4077 0 16.5352 1.87877 16.5352 4.19636C16.5352 6.51395 18.4077 8.39272 20.7175 8.39272Z'
        fill='#17D48F'
      />
      <path
        d='M120.664 100.311V140.856H127.018V103.881H152.433V140.856H158.787V59.7656H152.433C150.327 59.7656 147.096 70.7678 147.096 81.8233C147.096 90.1564 148.066 97.2979 149.445 100.311H120.664ZM152.433 100.311H152.371C152.392 100.266 152.412 100.218 152.433 100.171V100.311Z'
        fill='#3F3D56'
      />
      <path
        d='M144.209 39.6973C138.994 39.6973 134.766 44.405 134.766 50.2124V57.5907H137.027L138.337 54.857L138.009 57.5907H152.546L153.737 55.1055L153.439 57.5907H155.076V51.7974C155.076 45.1147 150.21 39.6973 144.209 39.6973Z'
        fill='#2F2E41'
      />
      <path
        d='M133.388 87.9023L127.59 90.3748L107.441 106.809C106.7 107.723 106.246 108.836 106.137 110.009C105.992 111.9 99.9038 121.79 102.078 123.098C104.252 124.407 112.08 128.334 112.515 125.716C112.95 123.098 115.269 111.9 115.269 111.9L134.692 94.4471L133.388 87.9023Z'
        fill='#2F2E41'
      />
      <path
        d='M110.632 123.535L108.168 130.807C108.168 130.807 110.777 133.57 109.472 135.897V143.896H107.443L106.863 139.679C106.863 139.679 104.552 143.843 103.384 143.896C100.937 144.008 96.0904 144.881 95.9454 143.572C95.8005 142.264 99.6155 140.987 99.6155 140.987L103.674 129.789L105.414 122.953L110.632 123.535Z'
        fill='#2F2E41'
      />
      <path
        d='M148.465 86.0123C148.465 86.0123 149.769 98.8109 146.001 101.138C142.232 103.465 125.562 110.882 125.562 110.882C125.562 110.882 132.665 117.572 131.215 119.172C129.766 120.772 123.243 125.135 122.373 124.263C121.504 123.39 111.792 112.918 112.951 109.573C114.111 106.228 130.201 95.3204 130.201 95.3204L132.085 88.4848L132.665 82.9581L138.753 79.0312L148.465 86.0123Z'
        fill='#2F2E41'
      />
      <path
        d='M141.824 56.2141C145.161 56.2141 147.867 53.4995 147.867 50.151C147.867 46.8024 145.161 44.0879 141.824 44.0879C138.487 44.0879 135.781 46.8024 135.781 50.151C135.781 53.4995 138.487 56.2141 141.824 56.2141Z'
        fill='#FFB9B9'
      />
      <path
        d='M146.508 52.6328C146.508 52.6328 146.219 58.1595 150.712 59.9047C155.206 61.65 139.551 72.9942 139.551 72.9942V60.341C139.551 60.341 141.58 58.1595 139.551 54.8144L146.508 52.6328Z'
        fill='#FFB9B9'
      />
      <path
        d='M156.148 78.5938L154.988 84.2659L151.509 103.755C151.509 103.755 154.408 112.045 150.495 111.899C146.581 111.754 148.465 102.882 148.465 102.882L150.495 86.7383V80.4845L156.148 78.5938Z'
        fill='#FFB9B9'
      />
      <path
        d='M131.217 75.832L129.912 80.9224L123.969 95.3208C123.969 95.3208 119.91 102.156 123.244 102.011C126.578 101.866 126.578 95.3208 126.578 95.3208L133.391 83.6857V77.141L131.217 75.832Z'
        fill='#FFB9B9'
      />
      <path
        d='M130.201 118.299L134.984 122.807C134.984 122.807 138.318 122.08 139.623 126.007C140.927 129.934 141.797 132.406 141.797 132.406L140.058 133.424L137.883 130.37C137.883 130.37 136.579 135.897 135.274 136.333C133.97 136.77 127.447 139.678 128.027 136.333L131.216 131.679C131.216 131.679 131.651 126.443 130.346 126.007C129.041 125.571 125.562 121.208 125.562 121.208L130.201 118.299Z'
        fill='#2F2E41'
      />
      <path
        d='M148.693 90.5204C148.693 90.5204 135.068 85.5755 134.198 86.0118C133.551 86.2988 132.861 86.4783 132.156 86.5435L132.169 84.5574C132.169 84.5574 136.662 82.9576 139.126 83.9757C141.591 84.9937 149.128 89.5023 149.128 89.5023L148.693 90.5204Z'
        fill='#3F3D56'
      />
      <path
        d='M140.418 70.0872C140.418 70.0872 144.332 65.5786 145.491 62.8152C146.225 61.14 147.572 59.8114 149.253 59.1035C149.253 59.1035 157.957 61.07 157.812 64.5605C157.667 68.051 152.304 73.4323 152.304 73.4323C152.304 73.4323 153.029 92.7756 149.115 92.4847C148.271 91.4739 147.675 90.2783 147.376 88.9942C146.941 86.9581 133.46 84.7765 133.46 84.7765C133.46 84.7765 132.301 86.8126 132.156 85.94C132.011 85.0673 133.315 63.6879 133.315 63.6879L140.228 59.1035L140.418 70.0872Z'
        fill='#D0CDE1'
      />
      <path
        d='M156.699 63.1551L156.796 63.2098C157.106 63.3842 157.363 63.6383 157.543 63.9459C157.722 64.2535 157.817 64.6035 157.817 64.9599L157.815 75.7533C157.817 76.0467 157.881 76.3363 158.005 76.6021C158.129 76.8679 158.309 77.1035 158.532 77.2925C159.091 77.7691 159.609 78.4557 158.54 78.8131C156.801 79.3949 149.988 82.3036 149.843 81.2856C149.698 80.2675 149.408 79.104 149.843 79.104C150.183 79.104 152.212 68.6749 153.842 64.2158C153.941 63.9428 154.099 63.6947 154.303 63.4888C154.508 63.2829 154.754 63.124 155.026 63.0231C155.298 62.9223 155.588 62.8818 155.877 62.9046C156.166 62.9274 156.446 63.0129 156.699 63.1551Z'
        fill='#D0CDE1'
      />
      <path
        d='M135.274 63.1777L133.245 63.7595L130.925 73.7948C130.925 73.7948 128.171 74.3765 129.041 75.3946C129.911 76.4126 133.534 79.0305 133.534 79.0305L135.274 63.1777Z'
        fill='#D0CDE1'
      />
      <path
        d='M146.894 42.5926C146.439 41.9804 145.85 41.4814 145.173 41.1344C144.495 40.7874 143.747 40.6017 142.986 40.5918H142.801C139.226 40.5918 136.328 43.8373 136.328 47.8408L137.526 47.8408L137.719 46.3635L138.003 47.8408H145.107L145.702 46.591L145.553 47.8408H146.951C147.603 51.0904 146.666 54.3399 144.14 57.5895H146.52L147.711 55.0898L147.413 57.5895H151.951L152.844 51.8402C152.844 47.5346 150.351 43.8835 146.894 42.5926Z'
        fill='#2F2E41'
      />
      <path
        d='M25.4929 109.679C27.0178 106.348 28.0903 98.4517 28.0903 89.238C28.0903 77.0144 24.5171 64.8496 22.1892 64.8496H15.1641V154.509H22.1892V113.626H50.2896V154.509H57.3148V109.679H25.4929ZM22.1892 109.524C22.2119 109.576 22.2346 109.629 22.2575 109.679H22.1892V109.524Z'
        fill='#3F3D56'
      />
      <path
        d='M54.775 93.3223C54.775 93.3223 63.2355 96.8421 60.5529 98.9125C57.8703 100.983 52.2988 96.428 52.5051 95.8068C52.7115 95.1857 51.6797 93.7364 51.6797 93.7364L54.775 93.3223Z'
        fill='#9F616A'
      />
      <path
        d='M47.3443 65.5781L50.0269 68.0627L50.6459 75.3093L52.7095 82.97C52.7095 82.97 56.2175 90.2167 55.5985 91.666C54.9794 93.1153 56.2175 93.5294 56.2175 93.5294L51.4714 95.3928C51.4714 95.3928 46.3125 89.5955 46.3125 88.3532C46.3125 87.111 47.3443 65.5781 47.3443 65.5781Z'
        fill='#575A88'
      />
      <path
        opacity='0.1'
        d='M47.3443 65.5781L50.0269 68.0627L50.6459 75.3093L52.7095 82.97C52.7095 82.97 56.2175 90.2167 55.5985 91.666C54.9794 93.1153 56.2175 93.5294 56.2175 93.5294L51.4714 95.3928C51.4714 95.3928 46.3125 89.5955 46.3125 88.3532C46.3125 87.111 47.3443 65.5781 47.3443 65.5781Z'
        fill='black'
      />
      <path
        d='M72.7261 140.113C72.7261 140.113 71.6943 144.875 71.488 145.29C71.2816 145.704 75.4087 147.153 75.4087 147.153L80.3612 143.633C80.3612 143.633 78.2977 139.078 78.2977 138.25L72.7261 140.113Z'
        fill='#575A88'
      />
      <path
        d='M45.6922 93.7363L47.1367 95.3927C47.1367 95.3927 77.0581 99.1195 77.6772 104.917C78.2962 110.714 82.6297 138.044 80.1534 138.872C77.6772 139.701 71.2802 142.806 71.2802 141.15C71.2802 139.494 67.1531 116.718 67.9785 112.578C67.9785 112.578 32.2792 108.023 31.4538 102.846C30.6284 97.6702 32.2792 95.3927 32.2792 95.3927L45.6922 93.7363Z'
        fill='#2F2E41'
      />
      <path
        opacity='0.1'
        d='M45.6922 93.7363L47.1367 95.3927C47.1367 95.3927 77.0581 99.1195 77.6772 104.917C78.2962 110.714 82.6297 138.044 80.1534 138.872C77.6772 139.701 71.2802 142.806 71.2802 141.15C71.2802 139.494 67.1531 116.718 67.9785 112.578C67.9785 112.578 32.2792 108.023 31.4538 102.846C30.6284 97.6702 32.2792 95.3927 32.2792 95.3927L45.6922 93.7363Z'
        fill='black'
      />
      <path
        d='M72.3137 144.875C72.3137 144.875 71.4883 143.012 71.0756 143.633C70.6628 144.254 69.2184 151.501 70.6628 151.501C72.1073 151.501 85.314 154.814 87.1712 154.4C89.0284 153.986 92.5364 154.607 92.1237 152.536C91.711 150.466 89.6475 150.259 89.6475 150.259C89.6475 150.259 82.0123 145.704 81.5996 143.426C81.1869 141.149 78.917 142.391 78.917 142.391V143.84C78.917 143.84 74.5836 147.153 72.3137 144.875Z'
        fill='#2F2E41'
      />
      <path
        d='M33.1073 49.8427C33.1073 49.8427 34.5518 56.8823 31.4565 57.5034C28.3612 58.1246 35.3772 62.2655 35.3772 62.2655L39.917 64.75L43.2187 61.4373C43.2187 61.4373 40.2266 55.3294 42.4965 50.7744C44.7664 46.2194 33.1073 49.8427 33.1073 49.8427Z'
        fill='#9F616A'
      />
      <path
        d='M43.2187 61.4373L39.917 64.75L35.3772 62.2655C35.3772 62.2655 28.3612 58.1246 31.4565 57.5034C33.4478 57.1038 33.5593 54.0478 33.388 51.9194C33.3321 51.2225 33.2384 50.5293 33.1073 49.8427C33.1073 49.8427 44.7664 46.2194 42.4965 50.7744C42.1516 51.4778 41.9124 52.2285 41.7866 53.0022C41.0912 57.0955 43.2187 61.4373 43.2187 61.4373Z'
        fill='#9F616A'
      />
      <path
        d='M54.7734 146.119V150.881H62.6149L63.234 149.018L62.4086 144.049L54.7734 146.119Z'
        fill='#575A88'
      />
      <path
        d='M22.375 97.8771C22.375 97.8771 22.375 110.093 27.3275 110.507C32.28 110.921 50.0265 113.199 50.0265 113.199C50.0265 113.199 51.0583 133.489 52.9155 140.115C54.7727 146.74 53.1218 147.154 54.7727 147.568C56.4235 147.982 63.4395 146.533 63.2332 145.084C63.0268 143.634 62.2014 106.987 62.2014 106.987C62.2014 106.987 64.0586 99.1194 39.2961 95.3926L22.375 97.8771Z'
        fill='#2F2E41'
      />
      <path
        d='M55.3923 149.432C55.3923 149.432 54.1542 146.74 53.9479 147.569C53.7415 148.397 51.2653 155.643 53.7415 156.265C56.2178 156.886 64.0592 160.199 69.2181 159.991C74.377 159.784 74.996 159.577 74.996 159.577C74.996 159.577 76.4405 157.507 74.5833 156.265C72.7261 155.022 65.091 147.776 64.6783 146.948C64.2656 146.119 61.1703 145.498 61.1703 146.948C61.1703 148.397 56.8368 151.296 55.3923 149.432Z'
        fill='#2F2E41'
      />
      <path
        opacity='0.1'
        d='M42.4946 50.7744C42.1497 51.4778 41.9105 52.2285 41.7848 53.0022C40.7571 53.6254 40.0699 53.466 38.7802 53.466C36.8673 53.466 36.0955 51.0125 34.8595 49.7392C34.7625 48.5569 33.1055 49.8427 33.1055 49.8427C33.1055 49.8427 44.7645 46.2194 42.4946 50.7744Z'
        fill='black'
      />
      <path
        d='M38.2667 53.3623C42.0276 53.3623 45.0764 50.3033 45.0764 46.5298C45.0764 42.7563 42.0276 39.6973 38.2667 39.6973C34.5058 39.6973 31.457 42.7563 31.457 46.5298C31.457 50.3033 34.5058 53.3623 38.2667 53.3623Z'
        fill='#9F616A'
      />
      <path
        d='M50.0265 68.0627C50.0265 68.0627 48.9947 75.5163 48.3756 78.208C47.7566 80.8996 46.9312 93.3223 46.5184 94.9787C46.1057 96.6351 43.6295 97.0492 38.677 96.428C38.3661 96.3888 38.0534 96.3653 37.7401 96.3576C34.6902 96.2603 31.2008 97.4053 27.9713 98.3039C26.1141 98.8215 24.3415 99.2563 22.7877 99.3267C20.4352 99.4344 19.746 96.8173 19.8265 93.9849C19.9029 91.3492 20.6478 88.523 21.3432 87.525C22.5958 85.7279 21.3638 69.5927 21.8261 64.2717V64.2696C21.8962 63.458 22.0056 62.8989 22.1686 62.6795C23.4067 61.0231 31.4546 56.8822 32.3563 57.0499C33.256 57.2176 40.9469 62.0583 40.9469 62.0583C42.8041 62.0583 42.7442 60.0375 42.7442 60.0375C42.7442 60.0375 44.0422 62.4724 46.5184 63.0936C48.9947 63.7147 50.0265 68.0627 50.0265 68.0627Z'
        fill='#575A88'
      />
      <path
        d='M36.2311 94.9043C36.2311 94.9043 49.5237 95.329 47.1951 96.9135C44.8665 98.4981 34.5488 99.9474 34.3424 97.8769C34.1361 95.8065 36.2311 94.9043 36.2311 94.9043Z'
        fill='#9F616A'
      />
      <path
        opacity='0.1'
        d='M37.7418 96.3578C37.7851 97.1528 35.6535 98.9438 34.9642 100.155C34.5825 100.826 31.6275 99.9417 27.9729 98.304C25.1685 97.041 22.448 95.5984 19.8281 93.985C19.9045 91.3493 20.6494 88.5231 21.3448 87.5252C22.5974 85.728 21.3655 69.5929 21.8277 64.2718V64.2697C21.9379 64.2136 22.0525 64.1665 22.1702 64.1289H24.6465C33.107 68.0628 23.4084 85.0406 23.4084 85.0406C23.4084 85.0406 36.2024 95.6 37.4405 96.0141C37.5217 96.0295 37.5954 96.0717 37.65 96.134C37.7046 96.1962 37.7369 96.275 37.7418 96.3578Z'
        fill='black'
      />
      <path
        d='M24.6467 62.8867H22.1704C22.1704 62.8867 19.9005 63.5079 18.0434 67.6488C16.1862 71.7897 9.37647 84.6266 15.3608 89.5957C21.345 94.5648 34.139 100.362 34.9644 98.9128C35.7898 97.4635 38.6788 95.186 37.4407 94.7719C36.2026 94.3578 23.4086 83.7984 23.4086 83.7984C23.4086 83.7984 33.1072 66.8206 24.6467 62.8867Z'
        fill='#575A88'
      />
      <path
        d='M37.2193 43.3495C37.2193 43.3495 37.5181 39.1987 41.2048 40.8911C44.8915 42.5836 44.6509 37.9186 43.5368 37.0943C42.4227 36.2699 42.458 36.0659 38.3833 36.6175C34.3087 37.169 26.3979 38.1035 28.9558 46.3239C31.5137 54.5443 33.8303 52.4296 33.8303 52.4296C33.8303 52.4296 31.7971 45.0323 33.8572 46.4416L35.9174 47.8509L37.0045 47.6199L37.2193 43.3495Z'
        fill='#2F2E41'
      />
      <path
        d='M189.337 103.118C189.337 99.3811 161.659 96.3516 127.516 96.3516C93.3735 96.3516 65.6953 99.3811 65.6953 103.118C65.6953 104.567 69.8552 105.909 76.9355 107.009V153.305H80.3076V107.487C91.6476 108.953 108.587 109.885 127.516 109.885C146.602 109.885 163.666 108.938 175.006 107.45V153.305H178.378V106.965C185.287 105.873 189.337 104.547 189.337 103.118Z'
        fill='#3F3D56'
      />
      <path
        d='M135.259 103.26C142.211 103.26 147.847 101.87 147.847 100.155C147.847 98.4393 142.211 97.0488 135.259 97.0488C128.308 97.0488 122.672 98.4393 122.672 100.155C122.672 101.87 128.308 103.26 135.259 103.26Z'
        fill='#F2F2F2'
      />
      <path
        d='M135.263 102.701C140.961 102.701 145.581 101.561 145.581 100.155C145.581 98.7491 140.961 97.6094 135.263 97.6094C129.565 97.6094 124.945 98.7491 124.945 100.155C124.945 101.561 129.565 102.701 135.263 102.701Z'
        fill='#D0CDE1'
      />
      <path
        d='M88.4197 105.745C95.3716 105.745 101.007 104.354 101.007 102.639C101.007 100.924 95.3716 99.5332 88.4197 99.5332C81.4677 99.5332 75.832 100.924 75.832 102.639C75.832 104.354 81.4677 105.745 88.4197 105.745Z'
        fill='#F2F2F2'
      />
      <path
        d='M88.4193 105.185C94.1176 105.185 98.737 104.045 98.737 102.639C98.737 101.233 94.1176 100.094 88.4193 100.094C82.721 100.094 78.1016 101.233 78.1016 102.639C78.1016 104.045 82.721 105.185 88.4193 105.185Z'
        fill='#D0CDE1'
      />
      <path
        d='M134.97 99.9346C138.284 99.9346 140.97 99.5089 140.97 98.9839C140.97 98.4588 138.284 98.0332 134.97 98.0332C131.655 98.0332 128.969 98.4588 128.969 98.9839C128.969 99.5089 131.655 99.9346 134.97 99.9346Z'
        fill='#9F616A'
      />
      <path
        d='M131.77 98.6282C132.817 98.6282 133.665 98.1671 133.665 97.5983C133.665 97.0295 132.817 96.5684 131.77 96.5684C130.723 96.5684 129.875 97.0295 129.875 97.5983C129.875 98.1671 130.723 98.6282 131.77 98.6282Z'
        fill='#17D48F'
      />
      <path
        d='M131.059 98.6282C132.106 98.6282 132.954 98.1671 132.954 97.5983C132.954 97.0295 132.106 96.5684 131.059 96.5684C130.012 96.5684 129.164 97.0295 129.164 97.5983C129.164 98.1671 130.012 98.6282 131.059 98.6282Z'
        fill='#17D48F'
      />
      <path
        d='M139.352 98.8645C140.399 98.8645 141.247 98.4034 141.247 97.8346C141.247 97.2658 140.399 96.8047 139.352 96.8047C138.305 96.8047 137.457 97.2658 137.457 97.8346C137.457 98.4034 138.305 98.8645 139.352 98.8645Z'
        fill='#17D48F'
      />
      <path
        d='M135.008 98.6282C136.055 98.6282 136.903 98.1671 136.903 97.5983C136.903 97.0295 136.055 96.5684 135.008 96.5684C133.962 96.5684 133.113 97.0295 133.113 97.5983C133.113 98.1671 133.962 98.6282 135.008 98.6282Z'
        fill='#17D48F'
      />
      <path
        d='M138.247 98.6282C139.293 98.6282 140.142 98.1671 140.142 97.5983C140.142 97.0295 139.293 96.5684 138.247 96.5684C137.2 96.5684 136.352 97.0295 136.352 97.5983C136.352 98.1671 137.2 98.6282 138.247 98.6282Z'
        fill='#17D48F'
      />
      <path
        d='M140.692 96.9645C137.267 98.1306 133.425 98.1306 129.164 96.9645V96.804C129.163 95.5917 129.627 94.4254 130.459 93.5466C131.292 92.6678 132.429 92.1436 133.636 92.0824C134.651 92.0311 135.669 92.0325 136.684 92.0863C137.77 92.1498 138.79 92.6279 139.536 93.4226C140.281 94.2172 140.695 95.268 140.692 96.3591V96.9645Z'
        fill='#3F3D56'
      />
      <path
        d='M139.014 101.329C136.483 101.586 133.933 101.586 131.402 101.329C130.801 101.27 130.244 100.989 129.839 100.541C129.433 100.093 129.208 99.5096 129.207 98.9043H141.209C141.208 99.5096 140.983 100.093 140.577 100.541C140.172 100.989 139.614 101.27 139.014 101.329Z'
        fill='#3F3D56'
      />
      <path
        d='M133.35 93.5573C133.075 93.6724 132.78 93.7317 132.482 93.7317C132.184 93.7317 131.888 93.6724 131.613 93.5573C131.888 93.4421 132.184 93.3828 132.482 93.3828C132.78 93.3828 133.075 93.4421 133.35 93.5573Z'
        fill='#D0CDE1'
      />
      <path
        d='M135.089 94.2702C134.813 94.3853 134.518 94.4446 134.22 94.4446C133.922 94.4446 133.627 94.3853 133.352 94.2702C133.627 94.155 133.922 94.0957 134.22 94.0957C134.518 94.0957 134.813 94.155 135.089 94.2702Z'
        fill='#D0CDE1'
      />
      <path
        d='M135.245 93.0026C134.97 93.1178 134.674 93.1771 134.376 93.1771C134.078 93.1771 133.783 93.1178 133.508 93.0026C133.783 92.8874 134.078 92.8281 134.376 92.8281C134.674 92.8281 134.97 92.8874 135.245 93.0026Z'
        fill='#D0CDE1'
      />
      <path
        d='M138.483 93.0026C138.208 93.1178 137.913 93.1771 137.615 93.1771C137.317 93.1771 137.021 93.1178 136.746 93.0026C137.021 92.8874 137.317 92.8281 137.615 92.8281C137.913 92.8281 138.208 92.8874 138.483 93.0026Z'
        fill='#D0CDE1'
      />
      <path
        d='M137.612 93.7155C137.337 93.8306 137.042 93.8899 136.744 93.8899C136.445 93.8899 136.15 93.8306 135.875 93.7155C136.15 93.6003 136.445 93.541 136.744 93.541C137.042 93.541 137.337 93.6003 137.612 93.7155Z'
        fill='#D0CDE1'
      />
      <path
        d='M88.3329 102.419C91.6471 102.419 94.3338 101.993 94.3338 101.468C94.3338 100.943 91.6471 100.518 88.3329 100.518C85.0187 100.518 82.332 100.943 82.332 101.468C82.332 101.993 85.0187 102.419 88.3329 102.419Z'
        fill='#9F616A'
      />
      <path
        d='M85.1333 101.113C86.1799 101.113 87.0283 100.651 87.0283 100.083C87.0283 99.5138 86.1799 99.0527 85.1333 99.0527C84.0867 99.0527 83.2383 99.5138 83.2383 100.083C83.2383 100.651 84.0867 101.113 85.1333 101.113Z'
        fill='#17D48F'
      />
      <path
        d='M84.4224 101.113C85.4689 101.113 86.3174 100.651 86.3174 100.083C86.3174 99.5138 85.4689 99.0527 84.4224 99.0527C83.3758 99.0527 82.5273 99.5138 82.5273 100.083C82.5273 100.651 83.3758 101.113 84.4224 101.113Z'
        fill='#17D48F'
      />
      <path
        d='M92.7153 101.351C93.7619 101.351 94.6103 100.89 94.6103 100.321C94.6103 99.7521 93.7619 99.291 92.7153 99.291C91.6687 99.291 90.8203 99.7521 90.8203 100.321C90.8203 100.89 91.6687 101.351 92.7153 101.351Z'
        fill='#17D48F'
      />
      <path
        d='M88.3716 101.113C89.4182 101.113 90.2666 100.651 90.2666 100.083C90.2666 99.5138 89.4182 99.0527 88.3716 99.0527C87.325 99.0527 86.4766 99.5138 86.4766 100.083C86.4766 100.651 87.325 101.113 88.3716 101.113Z'
        fill='#17D48F'
      />
      <path
        d='M91.6099 101.113C92.6564 101.113 93.5049 100.651 93.5049 100.083C93.5049 99.5138 92.6564 99.0527 91.6099 99.0527C90.5633 99.0527 89.7148 99.5138 89.7148 100.083C89.7148 100.651 90.5633 101.113 91.6099 101.113Z'
        fill='#17D48F'
      />
      <path
        d='M94.0553 99.4488C90.6305 100.615 86.7878 100.615 82.5273 99.4488V99.2884C82.5266 98.076 82.9904 96.9098 83.8227 96.031C84.655 95.1521 85.7922 94.6279 86.9989 94.5668C88.0146 94.5155 89.0322 94.5168 90.0477 94.5707C91.1334 94.6342 92.1536 95.1123 92.8989 95.9069C93.6442 96.7016 94.058 97.7523 94.0553 98.8435V99.4488Z'
        fill='#3F3D56'
      />
      <path
        d='M92.3774 103.813C89.8464 104.07 87.296 104.07 84.765 103.813C84.1646 103.754 83.6075 103.474 83.2019 103.025C82.7964 102.577 82.5713 101.994 82.5703 101.389H94.5721C94.5711 101.994 94.346 102.577 93.9404 103.025C93.5349 103.474 92.9778 103.754 92.3774 103.813Z'
        fill='#3F3D56'
      />
      <path
        d='M86.7136 96.0417C86.4384 96.1568 86.1432 96.2161 85.8451 96.2161C85.547 96.2161 85.2518 96.1568 84.9766 96.0417C85.2518 95.9265 85.547 95.8672 85.8451 95.8672C86.1432 95.8672 86.4384 95.9265 86.7136 96.0417Z'
        fill='#D0CDE1'
      />
      <path
        d='M88.4519 96.7545C88.1767 96.8697 87.8815 96.929 87.5834 96.929C87.2853 96.929 86.9901 96.8697 86.7148 96.7545C86.9901 96.6394 87.2853 96.5801 87.5834 96.5801C87.8815 96.5801 88.1767 96.6394 88.4519 96.7545Z'
        fill='#D0CDE1'
      />
      <path
        d='M88.6082 95.487C88.333 95.6021 88.0378 95.6614 87.7396 95.6614C87.4415 95.6614 87.1463 95.6021 86.8711 95.487C87.1463 95.3718 87.4415 95.3125 87.7396 95.3125C88.0378 95.3125 88.333 95.3718 88.6082 95.487Z'
        fill='#D0CDE1'
      />
      <path
        d='M91.8465 95.487C91.5713 95.6021 91.2761 95.6614 90.9779 95.6614C90.6798 95.6614 90.3846 95.6021 90.1094 95.487C90.3846 95.3718 90.6798 95.3125 90.9779 95.3125C91.2761 95.3125 91.5713 95.3718 91.8465 95.487Z'
        fill='#D0CDE1'
      />
      <path
        d='M90.9754 96.1999C90.7002 96.315 90.405 96.3743 90.1068 96.3743C89.8087 96.3743 89.5135 96.315 89.2383 96.1999C89.5135 96.0847 89.8087 96.0254 90.1068 96.0254C90.405 96.0254 90.7002 96.0847 90.9754 96.1999Z'
        fill='#D0CDE1'
      />
      <path
        d='M146.09 99.3125L146.119 99.9538L146.156 100.786C146.156 100.786 142.349 101.002 141.582 101.815C141.564 101.832 141.548 101.851 141.533 101.871L140.008 101.536L140.004 100.782C140.004 100.782 140.025 100.771 140.066 100.749C140.075 100.744 140.087 100.737 140.099 100.731C141.965 99.8143 144.012 99.3295 146.09 99.3125Z'
        fill='#17D48F'
      />
      <path
        opacity='0.1'
        d='M146.091 99.3125L146.12 99.9538C144.432 100.006 141.236 101.016 141.236 101.016L140.066 100.749C141.941 99.8195 144 99.3286 146.091 99.3125Z'
        fill='black'
      />
      <path
        opacity='0.1'
        d='M140.099 100.73L141.236 101.015L141.582 101.814C141.564 101.832 141.548 101.85 141.533 101.87L140.008 101.536L140.004 100.782C140.004 100.782 140.025 100.77 140.066 100.748C140.075 100.743 140.087 100.737 140.099 100.73Z'
        fill='white'
      />
      <path
        d='M100.071 102.004L100.1 102.645L100.137 103.477C100.137 103.477 96.3293 103.693 95.5622 104.506C95.5445 104.524 95.5281 104.543 95.5133 104.563L93.9882 104.228L93.9844 103.474C93.9844 103.474 94.0054 103.462 94.0463 103.44C94.0557 103.435 94.0674 103.429 94.0791 103.423C95.9453 102.506 97.9929 102.021 100.071 102.004Z'
        fill='#17D48F'
      />
      <path
        opacity='0.1'
        d='M100.071 102.004L100.1 102.645C98.412 102.698 95.2166 103.707 95.2166 103.707L94.0469 103.44C95.9212 102.511 97.981 102.02 100.071 102.004Z'
        fill='black'
      />
      <path
        opacity='0.1'
        d='M94.079 103.424L95.216 103.709L95.5622 104.507C95.5445 104.525 95.5281 104.544 95.5133 104.564L93.9882 104.229L93.9844 103.475C93.9844 103.475 94.0054 103.463 94.0463 103.441C94.0557 103.436 94.0673 103.43 94.079 103.424Z'
        fill='white'
      />
      <path
        d='M169.022 80.1038C168.697 79.7782 168.316 79.5142 167.898 79.3245V71.2795C167.898 71.1852 167.86 71.0947 167.794 71.028C167.728 70.9613 167.637 70.9238 167.543 70.9238H165.558C165.464 70.9238 165.374 70.9613 165.308 71.028C165.241 71.0947 165.204 71.1852 165.204 71.2795V79.1702C164.486 79.409 163.862 79.8685 163.419 80.4834C162.977 81.0983 162.739 81.8375 162.738 82.596V99.8537C162.738 100.061 162.779 100.267 162.858 100.459C162.937 100.651 163.054 100.825 163.2 100.972C163.346 101.119 163.52 101.235 163.711 101.315C163.903 101.394 164.107 101.435 164.314 101.435H168.566C168.982 101.435 169.381 101.269 169.676 100.974C169.97 100.679 170.135 100.279 170.135 99.8611V82.7994C170.136 82.2987 170.038 81.8028 169.847 81.3402C169.656 80.8775 169.375 80.4574 169.022 80.1038Z'
        fill='#3F3D56'
      />
      <path d='M170.135 85.125H162.738V94.929H170.135V85.125Z' fill='#17D48F' />
      <path
        d='M168.057 71.4736H167.897V71.2795C167.897 71.1852 167.859 71.0947 167.793 71.028C167.726 70.9613 167.636 70.9238 167.542 70.9238H165.557C165.463 70.9238 165.373 70.9613 165.307 71.028C165.24 71.0947 165.203 71.1852 165.203 71.2795V71.4736H165.043V72.2982H165.203V76.513H167.897V72.2982H168.057V71.4736Z'
        fill='#17D48F'
      />
      <path
        d='M109.795 87.0853C109.599 86.6661 109.445 86.3696 109.391 86.2676C106.07 87.0897 103.855 86.395 103.506 86.2721C103.335 86.5958 102.268 88.6649 101.837 90.6487C101.448 92.4381 101.674 93.6376 102.509 94.2141L102.534 94.2318L102.539 94.2354C103.461 94.845 104.341 95.3848 105.153 95.8398C105.262 95.9009 105.366 95.9577 105.463 96.0101L105.473 96.0158L105.48 96.0251C105.592 96.1661 105.696 96.313 105.791 96.4653C105.971 96.7171 106.08 97.0126 106.107 97.321V106.208L106.051 106.209C103.933 106.253 102.622 106.613 102.622 106.922C102.622 107.261 104.194 107.638 106.449 107.638C108.705 107.638 110.277 107.261 110.277 106.922C110.277 106.618 108.988 106.259 106.906 106.211L106.85 106.21L106.902 97.989C106.904 97.4324 107.033 96.8835 107.279 96.385C107.321 96.3012 107.367 96.2197 107.414 96.1429C107.451 96.0811 107.491 96.0208 107.533 95.9639L107.54 95.9534L107.551 95.9472C107.656 95.8901 107.766 95.8282 107.884 95.7615L107.897 95.7539C108.666 95.3183 109.495 94.8075 110.36 94.2353C111.132 93.7243 111.401 92.6916 111.159 91.166C110.928 89.7113 110.29 88.144 109.795 87.0853Z'
        fill='#D0CDE1'
      />
      <path
        d='M109.993 94.0501C109.086 94.65 108.337 95.0999 107.765 95.424H107.764C106.929 95.3668 106.09 95.3905 105.259 95.4946C104.671 95.1659 103.879 94.6943 102.905 94.0501C102.896 94.0439 102.887 94.0378 102.877 94.0311V94.0305C101.984 93.4137 101.962 92.1073 102.252 90.7715C102.992 90.689 104.171 90.7251 105.756 91.293C107.632 91.9655 109.596 91.5852 110.734 91.2394C110.921 92.418 110.807 93.5116 109.993 94.0501Z'
        fill='#17D48F'
      />
      <path
        d='M104.601 90.2868C104.742 90.2868 104.857 90.1715 104.857 90.0292C104.857 89.8869 104.742 89.7715 104.601 89.7715C104.459 89.7715 104.344 89.8869 104.344 90.0292C104.344 90.1715 104.459 90.2868 104.601 90.2868Z'
        fill='#17D48F'
      />
      <path
        d='M103.882 92.6579C104.024 92.6579 104.139 92.5426 104.139 92.4003C104.139 92.2579 104.024 92.1426 103.882 92.1426C103.74 92.1426 103.625 92.2579 103.625 92.4003C103.625 92.5426 103.74 92.6579 103.882 92.6579Z'
        fill='white'
      />
      <path
        d='M105.217 90.8037C105.302 90.8037 105.371 90.7344 105.371 90.6489C105.371 90.5634 105.302 90.4941 105.217 90.4941C105.131 90.4941 105.062 90.5634 105.062 90.6489C105.062 90.7344 105.131 90.8037 105.217 90.8037Z'
        fill='#17D48F'
      />
      <path
        d='M105.834 93.7412C105.919 93.7412 105.988 93.6719 105.988 93.5864C105.988 93.5009 105.919 93.4316 105.834 93.4316C105.749 93.4316 105.68 93.5009 105.68 93.5864C105.68 93.6719 105.749 93.7412 105.834 93.7412Z'
        fill='white'
      />
      <path
        d='M104.756 93.8955C104.841 93.8955 104.91 93.8262 104.91 93.7407C104.91 93.6552 104.841 93.5859 104.756 93.5859C104.671 93.5859 104.602 93.6552 104.602 93.7407C104.602 93.8262 104.671 93.8955 104.756 93.8955Z'
        fill='white'
      />
      <path
        d='M107.888 88.7412C107.974 88.7412 108.043 88.6719 108.043 88.5864C108.043 88.5009 107.974 88.4316 107.888 88.4316C107.803 88.4316 107.734 88.5009 107.734 88.5864C107.734 88.6719 107.803 88.7412 107.888 88.7412Z'
        fill='#17D48F'
      />
      <path
        d='M121.842 78.7084C121.646 78.2892 121.492 77.9927 121.438 77.8906C118.117 78.7127 115.902 78.018 115.553 77.8952C115.382 78.2189 114.315 80.288 113.884 82.2718C113.495 84.0611 113.721 85.2607 114.556 85.8372L114.581 85.8549L114.586 85.8585C115.508 86.468 116.388 87.0078 117.2 87.4628C117.309 87.5239 117.413 87.5807 117.509 87.6332L117.52 87.6388L117.527 87.6481C117.639 87.7891 117.742 87.936 117.838 88.0883C118.018 88.3401 118.127 88.6357 118.153 88.9441V97.8313L118.098 97.8325C115.98 97.8758 114.669 98.2361 114.669 98.545C114.669 98.8838 116.241 99.2614 118.496 99.2614C120.752 99.2614 122.324 98.8838 122.324 98.545C122.324 98.2407 121.035 97.8824 118.953 97.8342L118.897 97.8328L118.949 89.612C118.951 89.0554 119.079 88.5066 119.326 88.0081C119.368 87.9243 119.413 87.8428 119.46 87.766C119.498 87.7042 119.538 87.6439 119.579 87.587L119.587 87.5765L119.598 87.5703C119.703 87.5131 119.813 87.4512 119.931 87.3845L119.944 87.377C120.713 86.9413 121.541 86.4305 122.407 85.8583C123.179 85.3474 123.448 84.3147 123.206 82.789C122.975 81.3343 122.337 79.767 121.842 78.7084Z'
        fill='#D0CDE1'
      />
      <path
        d='M122.04 85.6712C121.133 86.2711 120.384 86.721 119.811 87.0451H119.811C118.975 86.9879 118.136 87.0115 117.305 87.1157C116.718 86.7869 115.926 86.3154 114.952 85.6712C114.943 85.665 114.933 85.6588 114.924 85.6521V85.6516C114.031 85.0348 114.008 83.7283 114.299 82.3926C115.039 82.3101 116.218 82.3462 117.803 82.9141C119.679 83.5866 121.643 83.2063 122.781 82.8605C122.968 84.0391 122.854 85.1327 122.04 85.6712Z'
        fill='#17D48F'
      />
      <path
        d='M116.647 81.9099C116.789 81.9099 116.904 81.7945 116.904 81.6522C116.904 81.5099 116.789 81.3945 116.647 81.3945C116.506 81.3945 116.391 81.5099 116.391 81.6522C116.391 81.7945 116.506 81.9099 116.647 81.9099Z'
        fill='#17D48F'
      />
      <path
        d='M115.929 84.279C116.071 84.279 116.185 84.1637 116.185 84.0213C116.185 83.879 116.071 83.7637 115.929 83.7637C115.787 83.7637 115.672 83.879 115.672 84.0213C115.672 84.1637 115.787 84.279 115.929 84.279Z'
        fill='white'
      />
      <path
        d='M117.263 82.4248C117.349 82.4248 117.418 82.3555 117.418 82.27C117.418 82.1845 117.349 82.1152 117.263 82.1152C117.178 82.1152 117.109 82.1845 117.109 82.27C117.109 82.3555 117.178 82.4248 117.263 82.4248Z'
        fill='#17D48F'
      />
      <path
        d='M117.881 85.3623C117.966 85.3623 118.035 85.293 118.035 85.2075C118.035 85.122 117.966 85.0527 117.881 85.0527C117.796 85.0527 117.727 85.122 117.727 85.2075C117.727 85.293 117.796 85.3623 117.881 85.3623Z'
        fill='white'
      />
      <path
        d='M116.803 85.5165C116.888 85.5165 116.957 85.4473 116.957 85.3618C116.957 85.2763 116.888 85.207 116.803 85.207C116.717 85.207 116.648 85.2763 116.648 85.3618C116.648 85.4473 116.717 85.5165 116.803 85.5165Z'
        fill='white'
      />
      <path
        d='M119.935 80.3642C120.02 80.3642 120.089 80.2949 120.089 80.2094C120.089 80.124 120.02 80.0547 119.935 80.0547C119.85 80.0547 119.781 80.124 119.781 80.2094C119.781 80.2949 119.85 80.3642 119.935 80.3642Z'
        fill='#17D48F'
      />
    </g>
    <defs>
      <clipPath id='clip0_389_19001'>
        <rect width='206' height='160' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Icon
