import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { SecondaryButton, PrimaryButton } from 'src/components' // Adjust the import path according to your project structure
import ActionsButtons from './ActionsButtons' // Adjust the import path according to your project structure
import DeletePopup from '../Dialogs/DeletePopup' // Adjust the import path according to your project structure
import { Text14pxRany, Text16pxRany500 } from '../Typography'
import LeavePagePopup from '../Dialogs/LeavePage' // Adjust the import path according to your project structure

import { useNavigate } from 'react-router-dom'

interface Props {
  primaryText?: string
  secondaryText?: string
  btnDeleteTxt?: string
  modalTitle?: string
  modalText?: string
  backHref: string
  onPrimaryClick: () => void
  onSecondClick?: () => void
  onDelete: () => void
}
// Props for customizing text and onClick actions for both buttons
const ActionsButtonsEdit = ({
  modalText,
  modalTitle,
  btnDeleteTxt,
  primaryText,
  secondaryText,
  onDelete,
  onSecondClick,
  onPrimaryClick,
  backHref
}: Props) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [modalVisible, setConfirmModalVisible] = useState(false)

  const navigate = useNavigate()

  const handleBack = () => {
    setConfirmModalVisible(false)
    backHref ? navigate(backHref) : onSecondClick?.()
  }
  const onBtnBackClick = () => {
    setConfirmModalVisible(true)
  }
  return (
    <>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Box
          gap={2}
          mt={3}
          sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
        >
          <SecondaryButton variant='outlined' fullWidth onClick={onBtnBackClick}>
            {secondaryText || 'Back'}
          </SecondaryButton>
          <PrimaryButton variant='contained' fullWidth color='primary' onClick={onPrimaryClick}>
            {primaryText || 'Update'}
          </PrimaryButton>
        </Box>
        <Text16pxRany500
          mt={2}
          color='error'
          style={{ color: 'red' }}
          onClick={() => setDeleteModalVisible(true)}
        >
          {btnDeleteTxt || 'Delete'}
        </Text16pxRany500>
      </Box>
      <LeavePagePopup
        open={modalVisible}
        onClose={() => setConfirmModalVisible(false)}
        onConfirm={handleBack}
      />

      <DeletePopup
        text={modalText}
        title={modalTitle}
        open={deleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
        onConfirm={() => {
          onDelete()
          setDeleteModalVisible(false)
        }}
      />
    </>
  )
}

export default ActionsButtonsEdit
