import React, { useState } from 'react'
import { Box, Button, Typography, Fade } from '@mui/material'
import Container from 'src/components/CreateLocation/Container'
import NavigationButtons from 'src/components/CreateLocation/NavigationButtons'
import usePromotionStore from './usePromotionStore'
import { PromotionType } from 'src/models/promotions'
import isEmpty from 'lodash/isEmpty'

interface Props {
  activeStep: number
  setActiveStep: (index: number) => void
}

const CreatePromotion = ({ activeStep, setActiveStep }: Props) => {
  const { validate, activeStepIsCompleted, save } = usePromotionStore()

  const handleNext = () => {
    const errors = validate(activeStep)
    if (!isEmpty(errors)) {
      return
    }
    if (activeStep === 5) {
      save()
      return
    }
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const onSaveDraft = () => {}

  return (
    <Box mt={5}>
      <NavigationButtons
        activeStep={activeStep}
        backHref={'/promotions'}
        disabled={!activeStepIsCompleted(activeStep)}
        onContinue={handleNext}
        onBack={handleBack}
        // onSaveDraft={onSaveDraft}
      />
    </Box>
  )
}

export default CreatePromotion
