import { resizePx } from 'src/theme'

const Icon = () => (
  <svg
    width={resizePx(227)}
    height={resizePx(188)}
    viewBox='0 0 227 188'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_389_20357)'>
      <path
        d='M201.616 73.8579H201.545L160.565 91.4622L104.495 115.549C104.276 115.641 104.041 115.69 103.803 115.691C103.565 115.692 103.33 115.646 103.11 115.556L45.2623 91.4119L3.27503 73.8866L3.2109 73.8579H3.13945C2.47695 73.8586 1.84179 74.1237 1.37333 74.595C0.904871 75.0663 0.641359 75.7053 0.640625 76.3718V185.546C0.641359 186.212 0.904871 186.851 1.37333 187.322C1.84179 187.794 2.47695 188.059 3.13945 188.059H201.616C202.279 188.059 202.914 187.794 203.383 187.322C203.851 186.851 204.114 186.212 204.115 185.546V76.3718C204.114 75.7053 203.851 75.0663 203.383 74.595C202.914 74.1237 202.279 73.8586 201.616 73.8579Z'
        fill='white'
      />
      <path
        d='M201.797 74.5763C201.724 74.5763 201.653 74.5538 201.593 74.5119L102.869 5.34328C102.568 5.13377 102.211 5.0218 101.846 5.02243C101.48 5.02305 101.124 5.13624 100.824 5.34679L2.81097 74.5112C2.73343 74.5655 2.63766 74.5867 2.54465 74.5701C2.45163 74.5535 2.36895 74.5004 2.31471 74.4226C2.26047 74.3448 2.23911 74.2486 2.25527 74.1549C2.27142 74.0613 2.32378 73.9779 2.40092 73.9231L100.414 4.75868C100.834 4.46396 101.333 4.30554 101.845 4.30469C102.356 4.30384 102.856 4.46062 103.277 4.75394L202.001 73.9224C202.063 73.9661 202.11 74.0287 202.135 74.1011C202.159 74.1735 202.16 74.2518 202.137 74.3248C202.115 74.3977 202.069 74.4614 202.008 74.5067C201.947 74.5519 201.873 74.5763 201.797 74.5763Z'
        fill='#3F3D56'
      />
      <path
        d='M8.41016 77.0279L101.941 7.29199L196.182 81.9897L106.76 135.319L58.212 124.187L8.41016 77.0279Z'
        fill='#E6E6E6'
      />
      <path
        d='M62.3101 168.26H16.1033C15.5308 168.26 14.9814 168.032 14.5759 167.626C14.1705 167.219 13.9423 166.667 13.9414 166.091C13.9406 165.515 14.1672 164.962 14.5714 164.554C14.9757 164.146 15.5244 163.917 16.097 163.916H62.3101C62.8826 163.915 63.432 164.143 63.8375 164.55C64.2429 164.957 64.4711 165.509 64.472 166.085C64.4728 166.661 64.2462 167.213 63.842 167.621C63.4377 168.029 62.889 168.259 62.3164 168.26H62.3101Z'
        fill='#04BC79'
      />
      <path
        d='M32.6798 158.922H16.1018C15.5296 158.922 14.9805 158.694 14.5754 158.287C14.1703 157.881 13.9423 157.329 13.9414 156.753C13.9406 156.177 14.167 155.625 14.5709 155.217C14.9748 154.809 15.5232 154.579 16.0955 154.578H32.6798C32.9634 154.578 33.2444 154.633 33.5066 154.742C33.7688 154.85 34.0072 155.01 34.2081 155.211C34.409 155.413 34.5686 155.652 34.6775 155.915C34.7865 156.179 34.8428 156.461 34.8432 156.747C34.8436 157.032 34.7882 157.315 34.68 157.578C34.5718 157.842 34.413 158.082 34.2126 158.284C34.0123 158.486 33.7744 158.646 33.5125 158.756C33.2506 158.865 32.9698 158.921 32.6862 158.922H32.6798Z'
        fill='#04BC79'
      />
      <path
        d='M103.255 115.87C102.903 115.87 102.556 115.802 102.23 115.668L44.5469 91.5893V16.2185C44.5476 15.552 44.8112 14.913 45.2796 14.4417C45.7481 13.9705 46.3832 13.7054 47.0457 13.7046H157.707C158.37 13.7054 159.005 13.9705 159.473 14.4417C159.942 14.913 160.205 15.552 160.206 16.2185V91.6419L160.098 91.6886L104.31 115.656C103.976 115.798 103.617 115.87 103.255 115.87Z'
        fill='white'
      />
      <path
        d='M103.253 116.049C102.879 116.05 102.508 115.977 102.161 115.834L44.3672 91.7092V16.2183C44.368 15.5042 44.6503 14.8196 45.1522 14.3147C45.6541 13.8097 46.3347 13.5257 47.0445 13.5249H157.706C158.416 13.5257 159.096 13.8097 159.598 14.3147C160.1 14.8196 160.383 15.5042 160.383 16.2183V91.7604L104.379 115.821C104.023 115.972 103.64 116.05 103.253 116.049ZM45.0811 91.2294L102.434 115.17C102.968 115.389 103.568 115.385 104.1 115.16L159.669 91.2862V16.2183C159.669 15.6947 159.462 15.1926 159.094 14.8224C158.726 14.4521 158.227 14.2438 157.706 14.2432H47.0445C46.524 14.2438 46.025 14.4521 45.6569 14.8224C45.2888 15.1926 45.0818 15.6947 45.0811 16.2183L45.0811 91.2294Z'
        fill='#3F3D56'
      />
      <path
        d='M201.081 73.8579H201.01L160.029 91.4622L103.96 115.549C103.741 115.641 103.505 115.69 103.268 115.691C103.03 115.692 102.795 115.646 102.574 115.556L44.7271 91.4119L2.73991 73.8866L2.67574 73.8579H2.60426C1.94176 73.8586 1.30663 74.1237 0.838173 74.595C0.369714 75.0663 0.106203 75.7053 0.105469 76.3718V185.546C0.106203 186.212 0.369714 186.851 0.838173 187.322C1.30663 187.794 1.94176 188.059 2.60426 188.059H201.081C201.744 188.059 202.379 187.794 202.847 187.322C203.316 186.851 203.579 186.212 203.58 185.546V76.3718C203.579 75.7053 203.316 75.0663 202.847 74.595C202.379 74.1237 201.744 73.8586 201.081 73.8579ZM202.866 185.546C202.866 186.022 202.678 186.478 202.343 186.815C202.008 187.152 201.555 187.341 201.081 187.341H2.60426C2.13095 187.341 1.67706 187.152 1.34238 186.815C1.00769 186.478 0.81961 186.022 0.81942 185.546V76.3718C0.820068 75.9074 0.999076 75.4613 1.31907 75.1267C1.63907 74.792 2.07526 74.5948 2.53646 74.5762L44.7271 92.1876L102.3 116.22C102.923 116.475 103.621 116.471 104.242 116.209L160.029 92.2415L201.153 74.5762C201.613 74.5962 202.048 74.794 202.367 75.1284C202.686 75.4628 202.865 75.9082 202.866 76.3718V185.546Z'
        fill='#3F3D56'
      />
      <path
        d='M94.275 41.8783H55.008C54.2513 41.8772 53.526 41.5741 52.9913 41.0354C52.4566 40.4967 52.1562 39.7666 52.1562 39.0053C52.1562 38.2441 52.4566 37.5139 52.9913 36.9752C53.526 36.4366 54.2513 36.1334 55.008 36.1323H94.275C95.0317 36.1334 95.7571 36.4366 96.2918 36.9752C96.8265 37.5139 97.1268 38.2441 97.1268 39.0053C97.1268 39.7666 96.8265 40.4967 96.2918 41.0354C95.7571 41.5741 95.0317 41.8772 94.275 41.8783Z'
        fill='#04BC79'
      />
      <path
        d='M73.9277 29.6679H55.0081C54.2507 29.6679 53.5243 29.3652 52.9888 28.8264C52.4532 28.2876 52.1523 27.5568 52.1523 26.7949C52.1523 26.0329 52.4532 25.3021 52.9888 24.7633C53.5243 24.2246 54.2507 23.9219 55.0081 23.9219H73.9277C74.6851 23.9219 75.4115 24.2246 75.9471 24.7633C76.4826 25.3021 76.7835 26.0329 76.7835 26.7949C76.7835 27.5568 76.4826 28.2876 75.9471 28.8264C75.4115 29.3652 74.6851 29.6679 73.9277 29.6679Z'
        fill='#04BC79'
      />
      <path
        d='M131.745 70.6083H71.7736C71.0169 70.6072 70.2916 70.3041 69.7569 69.7654C69.2222 69.2267 68.9219 68.4966 68.9219 67.7353C68.9219 66.974 69.2222 66.2439 69.7569 65.7052C70.2916 65.1666 71.0169 64.8634 71.7736 64.8623H131.745C132.502 64.8634 133.227 65.1666 133.762 65.7052C134.296 66.2439 134.597 66.974 134.597 67.7353C134.597 68.4966 134.296 69.2267 133.762 69.7654C133.227 70.3041 132.502 70.6072 131.745 70.6083Z'
        fill='#CCCCCC'
      />
      <path
        d='M131.745 83.8959H71.7737C71.0163 83.8959 70.29 83.5932 69.7544 83.0544C69.2188 82.5156 68.918 81.7849 68.918 81.0229C68.918 80.2609 69.2188 79.5302 69.7544 78.9914C70.29 78.4526 71.0163 78.1499 71.7737 78.1499H131.745C132.503 78.1499 133.229 78.4526 133.765 78.9914C134.3 79.5302 134.601 80.2609 134.601 81.0229C134.601 81.7849 134.3 82.5156 133.765 83.0544C133.229 83.5932 132.503 83.8959 131.745 83.8959Z'
        fill='#CCCCCC'
      />
      <path
        d='M199.975 102.217C214.972 102.217 227.13 89.9866 227.13 74.899C227.13 59.8115 214.972 47.5806 199.975 47.5806C184.978 47.5806 172.82 59.8115 172.82 74.899C172.82 89.9866 184.978 102.217 199.975 102.217Z'
        fill='#04BC79'
      />
      <path
        d='M197.385 86.431L189.258 75.9174L193.984 72.2191L197.832 77.1971L210.833 63.3906L215.18 67.5341L197.385 86.431Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_389_20357'>
        <rect width='227' height='188' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Icon
