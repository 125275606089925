import actionCreatorFactory from 'typescript-fsa'
import {
  UpdateSignInDataParams,
  SendPhoneVerificationParams,
  AuthChangePayload,
  EditProfileData,
  LogoutPayload,
  AllowedOTPMethodsChangePayload,
  UpdateCurrentProviderPayload,
  SelectProviderPayload,
  UpdateCurrentProviderData,
  CreateProviderData,
  CreateProviderPayload,
  SignUpData,
  SignUpValidationErrors
} from './types'
import {
  SendPhoneVerificationResult,
  User,
  AuthData,
  CountriesOTPRequestMethodsListResult
} from 'src/models/auth'

// Actions
const actionCreator = actionCreatorFactory('auth')

export const updateSignInData = actionCreator<UpdateSignInDataParams>('UPDATE_SIGN_IN_DATA')
// prettier-ignore
export const getCountriesOTPRequestMethodsList = actionCreator.async<void, CountriesOTPRequestMethodsListResult, Error>('GET_COUNTRIES_OTP_REQUEST_METHODS_LIST')
export const allowedOTPMethodsChange = actionCreator<AllowedOTPMethodsChangePayload>(
  'ALLOWED_OTP_METHODS_CHANGE'
)
// prettier-ignore
export const sendPhoneVerification = actionCreator.async<SendPhoneVerificationParams, SendPhoneVerificationResult, Error>('SEND_PHONE_VERIFICATION')
export const signIn = actionCreator.async<void, AuthData, Error>('SIGN_IN')
export const authChange = actionCreator<AuthChangePayload>('AUTH_CHANGE')
export const sessionRefresh = actionCreator<AuthData>('SESSION_REFRESH')
export const saveProfile = actionCreator.async<void, User, Error>('SAVE_PROFILE')
export const updateEditProfileData = actionCreator<EditProfileData>('UPDATE_EDIT_PROFILE_DATA')
export const dismissError = actionCreator<void>('DISMISS_ERROR')
export const logout = actionCreator<LogoutPayload>('LOGOUT')
export const resetLimits = actionCreator<void>('RESET_LIMITS')
export const setRedirectURL = actionCreator<string>('SET_REDIRECT_URL')

export const updateCurrentProvider = actionCreator.async<
  UpdateCurrentProviderData,
  UpdateCurrentProviderPayload,
  Error
>('UPDATE_CURRENT_PROVIDER')
export const selectProvider = actionCreator<SelectProviderPayload>('SELECT_PROVIDER')
export const createProvider = actionCreator.async<CreateProviderData, CreateProviderPayload, Error>(
  'CREATE_PROVIDER'
)

//Sign Up

export const updateSignUpData = actionCreator<SignUpData>('UPDATE_SIGN_UP_DATA')
export const setValidationsErrors = actionCreator<SignUpValidationErrors>('SET_SIGN_UP_ERROS')
export const signUp = actionCreator.async<void, AuthData, Error>('SIGN_UP')
