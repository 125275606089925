import React from 'react'
import Box from '@mui/material/Box'
import { SecondaryButton } from 'src/components/index'
import { LocationCount, Text14pxRany, Text14pxRanyBold } from 'src/components/Typography'
import { Card, CardContent, CardMedia } from '@mui/material'
import { ProfileCard } from 'src/components/Base/Cards'
import { useNavigate } from 'react-router-dom'
import { Brand } from 'src/models/brands'
import EditIcon from '@mui/icons-material/Edit' // Import the edit icon

interface BrandProps {
  brand: Brand
}

const BrandItem: React.FC<BrandProps> = ({ brand }) => {
  const navigate = useNavigate()

  const handleEditClick = () => {
    navigate(`edit/${brand.id}`) //
  }

  return (
    <ProfileCard sx={{ marginTop: 2 }}>
      <CardContent
        sx={{
          display: 'flex',
          padding: 0,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CardMedia
          sx={{
            borderRadius: 20,
            objectFit: 'cover',
            height: 60,
            width: 60,
            backgroundSize: 'cover',
            mt: 2,
            mb: 2
            // paddingTop is a percentage of the width, which maintains the aspect ratio
          }}
          image={brand.image?.url}
        />

        <Box ml={4}>
          <Text14pxRanyBold gutterBottom>{brand.name}</Text14pxRanyBold>
          <Box display={'flex'} mt={2} alignItems={'center'} flexDirection={'row'}>
            <LocationCount label={`Locations: ${brand.locations?.length || 0}`} />
          </Box>
        </Box>
      </CardContent>

      <Box sx={{ ml: 2 }}>
        <SecondaryButton
          startIcon={<EditIcon />} // Add the edit icon as the start icon
          onClick={handleEditClick}
          variant='outlined'
        >
          Edit
        </SecondaryButton>
      </Box>
    </ProfileCard>
  )
}

export default BrandItem
