import React, { useState } from 'react'
import { Typography, Box, TextField } from '@mui/material'
import Section from './Section'
import SelectedDays from './SelectedDays'
import SelectedTime from './SelectedTime'

import usePromotionStore from '../usePromotionStore'

const DayTimeComponent: React.FC = () => {
  const { draft, updateDraft } = usePromotionStore()

  const [sections, setSections] = useState({
    permanentPromotion: false,
    sameTimeForSelectedDays: false,
    differentTimeForSelectedDays: false
  })

  const handleToggle = (section: keyof typeof sections) => () => {
    setSections(prevSections => ({
      permanentPromotion: false,
      sameTimeForSelectedDays: false,
      differentTimeForSelectedDays: false,
      [section]: !prevSections[section]
    }))
  }

  const selectAnyTime = (e: any) => updateDraft('discountAvailableAtAnyTime', e.target.checked)

  return (
    <Box>
      <Section
        label='Permanent promotion'
        checked={!!draft?.discountAvailableAtAnyTime}
        onToggle={selectAnyTime}
      />
      <Section
        label='Same time for selected days'
        checked={sections.sameTimeForSelectedDays}
        onToggle={handleToggle('sameTimeForSelectedDays')}
        hasArrow
      >
        <SelectedDays />
      </Section>
      <Section
        label='Different time for selected days'
        checked={sections.differentTimeForSelectedDays}
        onToggle={handleToggle('differentTimeForSelectedDays')}
        hasArrow
      >
        <SelectedTime />
      </Section>
    </Box>
  )
}

export default DayTimeComponent
