import React from 'react'
import LoginHeader from 'src/components/Header/WelcomeHeader'
// App.tsx
import { Typography, Box, Container, Card, CardContent, Grid } from '@mui/material'
import { PrimaryButton } from 'src/components'
import Welcome1Icon from 'src/assets/svg/Welcome1'
import Welcome2Icon from 'src/assets/svg/Welcome2'
import Welcome3Icon from 'src/assets/svg/Welcome3'

import { calculateResponsiveFontSize } from 'src/theme/index'

const HeroSection: React.FC = () => {
  return (
    <Box textAlign='center' my={16}>
      <Typography
        gutterBottom
        variant='h1'
        sx={{
          fontFamily: 'Rany',
          fontSize: '52px',
          fontWeight: 700,
          lineHeight: '62px',
          letterSpacing: '0em',
          textAlign: 'center'
        }}
      >
        Elevate Your Business & Attract More Customers
      </Typography>

      <Typography
        mt={4}
        sx={{
          fontFamily: 'Rany',
          fontSize: '20px',
          fontWeight: 700,
          color: '#292B2A',
          lineHeight: '22px',
          letterSpacing: '0px', // MUI expects a string value for letterSpacing
          textAlign: 'center'
        }}
      >
        Unlock the potential to attract more customers and stand out by making a unique and
        compelling offers!
      </Typography>
      <PrimaryButton href={'/signup'} sx={{ mt: 4, width: 250 }} variant='contained'>
        Get started
      </PrimaryButton>
    </Box>
  )
}

interface CustomCardComponentProps {
  title: string
  text: string
  svgIcon: any // This assumes you're passing a component that is a typeof SvgIcon
}

function CustomCardComponent({ title, text, svgIcon: SvgIconComponent }: CustomCardComponentProps) {
  return (
    <Card
      sx={{
        width: '324px',
        borderRadius: '16px',
        overflow: 'visible',
        padding: '32px',
        backgroundColor: '#F7F9F8'
      }}
    >
      <Box sx={{ position: 'relative', top: -80, display: 'flex', justifyContent: 'center' }}>
        <SvgIconComponent />
      </Box>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography
          mt={-8}
          variant='h6'
          sx={{
            fontFamily: 'Rany',
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: `${calculateResponsiveFontSize(1.5)}rem`,
            letterSpacing: '0px',
            textAlign: 'center'
          }}
        >
          {title}
        </Typography>
        <Typography
          mt={3}
          variant='subtitle2'
          sx={{
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: '#000000',
            lineHeight: `${calculateResponsiveFontSize(1.3)}rem`,
            letterSpacing: '0em',
            textAlign: 'left'
          }}
        >
          {text}
        </Typography>
      </CardContent>
    </Card>
  )
}

const FeaturesSection: React.FC = () => {
  return (
    <Grid container gap={5} justifyContent='center' sx={{ flexGrow: 1 }}>
      <CustomCardComponent
        title='Get More Customers'
        text="With strategic promotion and visibility on our platform, you'll attract and engage a broader audience, ensuring your business gets the attention it deserves"
        svgIcon={Welcome1Icon}
      />
      <CustomCardComponent
        title='Make a Unique Offer'
        text="Whether it's a special discount, a limited-time promotion, or an exclusive bundle, capture the attention of your audience and create a buzz"
        svgIcon={Welcome2Icon}
      />
      <CustomCardComponent
        title='Show Off Your Business'
        text='Highlight your strengths, showcase your products or services, and let your unique brand personality take center stage.'
        svgIcon={Welcome3Icon}
      />
    </Grid>
  )
}

const App: React.FC = () => {
  return (
    <Box px={6} pt={2.5} sx={{ display: 'flex', flexDirection: 'column' }}>
      <LoginHeader />
      <HeroSection />
      <FeaturesSection />
      {/* Footer or other components */}
    </Box>
  )
}

export default App
