import React from 'react'
import InputWithLabel, { InputLabelWrapper } from 'src/components/Base/InputWithLabel'
import { Dropdown } from 'src/components/index'
import usePromotionStore from './usePromotionStore'
import { PromotionDiscountType } from 'src/models/promotions'

const PromotionForm = () => {
  const { draft, errors, handleInputChange, handleSelectChange } = usePromotionStore()

  const typeOptions: Array<{
    value: PromotionDiscountType
    label: string
  }> = [
    {
      value: 'percentage_discount',
      label: 'Discount %'
    },
    {
      value: 'fixed_discount',
      label: 'Discount amount'
    },
    {
      value: 'custom_offer',
      label: 'Offer'
    }
  ]

  return (
    <form noValidate autoComplete='off'>
      <InputWithLabel
        name={'title'}
        onChange={handleInputChange}
        fullWidth
        error={!!errors.title}
        helperText={errors.title}
        value={draft?.title}
        label='Promotion title'
        placeholder='Enter your promotion title'
      />

      <InputLabelWrapper label={'Promotion type'} error={!!errors.type} helperText={errors.type}>
        <Dropdown
          labelId='brand-label'
          id='brand'
          name={'type'}
          fullWidth
          placeholder='Select your promotion type'
          value={draft?.type}
          options={typeOptions}
          //@ts-ignore
          onChange={handleSelectChange}
        />
      </InputLabelWrapper>

      <InputWithLabel
        multiline
        rows={4}
        name={'text'}
        fullWidth
        sx={{ lineHeight: 0 }}
        value={draft.text}
        error={!!errors.text}
        helperText={errors.text}
        onChange={handleInputChange}
        label='Promotion description'
        placeholder='Enter your promotion description'
      />
    </form>
  )
}

export default PromotionForm
