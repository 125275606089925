import { auth } from './auth/reducer'
import { createLocation } from './createLocation/reducer'
import { providerLocations } from './providerLocations/reducer'
import { createPromotion } from './createPromotion/reducer'
import { promotions } from './promotions/reducer'
import { admins } from './admins/reducer'
import { locationAdmins } from './locationAdmins/reducer'
import { remoteConfig } from './remoteConfig/reducer'
import { mediaUploads } from './mediaUploads/reducer'
import { foodics } from './foodics/reducer'
import { subscriptions } from './subscriptions/reducer'
import { adminPreview } from './adminPreview/reducer'
import { brands } from './brands/reducer'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistPromotionsConfig = {
  key: 'promotions_v10',
  blacklist: ['error', 'isUpdatingStatus'],
  storage
}

const persistLocationConfig = {
  key: 'providerLocations_v2',
  blacklist: ['error', 'isUpdatingStatus'],
  storage
}

const persistCreateLocationConfig = {
  key: 'createProviderLocation_v5',
  storage,
  blacklist: ['error', 'drafts', 'isCreating']
}

const persistCreatePromotionConfig = {
  key: 'createPromotion_v6',
  blacklist: ['error', 'drafts', 'isCreating'],
  storage
}

const persistAdminsConfig = {
  key: 'admins',
  blacklist: ['error', 'isCreating'],
  storage
}

const persistLocationAdminsConfig = {
  key: 'locationAdmins-v1',
  blacklist: ['error', 'isCreating'],
  storage
}

const persistSubscriptionsConfig = {
  key: 'subscriptions-v4',
  blacklist: [],
  storage
}

const persistBrandsConfig = {
  key: 'brands-v4',
  blacklist: ['error', 'isCreating'],
  storage
}

const persistAuthConfig = {
  key: 'auth-v412',
  blacklist: [
    'session',
    'user',
    'providerAdmins',
    'currentProvider',
    'signInData',
    'editProfileData',
    'activeOperation',
    'redirectURL',
    'validationErrors'
  ],
  storage
}

export default Object.assign({
  remoteConfig,
  auth: persistReducer(persistAuthConfig, auth),
  createLocation: persistReducer(persistCreateLocationConfig, createLocation),
  providerLocations: persistReducer(persistLocationConfig, providerLocations),
  createPromotion: persistReducer(persistCreatePromotionConfig, createPromotion),
  promotions: persistReducer(persistPromotionsConfig, promotions),
  admins: persistReducer(persistAdminsConfig, admins),
  locationAdmins: persistReducer(persistLocationAdminsConfig, locationAdmins),
  mediaUploads,
  foodics,
  subscriptions: persistReducer(persistSubscriptionsConfig, subscriptions),
  adminPreview,
  brands: persistReducer(persistBrandsConfig, brands)
})
