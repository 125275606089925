import { resizePx } from 'src/theme'

const Icon = () => (
  <svg
    width={resizePx(178)}
    height={resizePx(208)}
    viewBox='0 0 178 208'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_389_20116)'>
      <path
        d='M172.247 76.6633H5.60064C4.11579 76.6615 2.69223 76.0698 1.64228 75.0179C0.592328 73.966 0.00171453 72.5399 0 71.0523V5.611C0.00171453 4.12339 0.592328 2.69721 1.64228 1.64532C2.69223 0.593424 4.11579 0.0017177 5.60064 0H172.247C173.732 0.0017177 175.155 0.593424 176.205 1.64532C177.255 2.69721 177.846 4.12339 177.848 5.611V71.0523C177.846 72.5399 177.255 73.966 176.205 75.0179C175.155 76.0698 173.732 76.6615 172.247 76.6633Z'
        fill='#F2F2F2'
      />
      <path
        d='M133.383 73.0852H32.0545C24.5037 73.0767 17.2646 70.0678 11.9253 64.7187C6.58606 59.3696 3.58274 52.117 3.57422 44.5522V32.1152C3.58273 24.5504 6.58605 17.2979 11.9253 11.9487C17.2645 6.59954 24.5037 3.59061 32.0545 3.58203H145.797C153.347 3.59061 160.587 6.59954 165.926 11.9487C171.265 17.2979 174.268 24.5504 174.277 32.1152C174.265 42.9774 169.952 53.3911 162.286 61.0718C154.619 68.7525 144.225 73.0729 133.383 73.0852Z'
        fill='white'
      />
      <path
        d='M107.244 22.316H40.2647C39.8816 22.316 39.5142 22.1636 39.2434 21.8922C38.9725 21.6208 38.8203 21.2527 38.8203 20.869C38.8203 20.4852 38.9725 20.1171 39.2434 19.8457C39.5142 19.5743 39.8816 19.4219 40.2647 19.4219H107.244C107.627 19.4219 107.994 19.5743 108.265 19.8457C108.536 20.1171 108.688 20.4852 108.688 20.869C108.688 21.2527 108.536 21.6208 108.265 21.8922C107.994 22.1636 107.627 22.316 107.244 22.316Z'
        fill='#E6E6E6'
      />
      <path
        d='M141.15 37.7555H40.2647C39.8816 37.7555 39.5142 37.603 39.2434 37.3316C38.9725 37.0603 38.8203 36.6922 38.8203 36.3084C38.8203 35.9246 38.9725 35.5565 39.2434 35.2852C39.5142 35.0138 39.8816 34.8613 40.2647 34.8613H141.15C141.533 34.8613 141.9 35.0138 142.171 35.2852C142.442 35.5565 142.594 35.9246 142.594 36.3084C142.594 36.6922 142.442 37.0603 142.171 37.3316C141.9 37.603 141.533 37.7555 141.15 37.7555Z'
        fill='#E6E6E6'
      />
      <path
        d='M141.15 53.193H40.2647C39.8816 53.193 39.5142 53.0405 39.2434 52.7691C38.9725 52.4978 38.8203 52.1297 38.8203 51.7459C38.8203 51.3621 38.9725 50.994 39.2434 50.7227C39.5142 50.4513 39.8816 50.2988 40.2647 50.2988H141.15C141.533 50.2988 141.9 50.4513 142.171 50.7227C142.442 50.994 142.594 51.3621 142.594 51.7459C142.594 52.1297 142.442 52.4978 142.171 52.7691C141.9 53.0405 141.533 53.193 141.15 53.193Z'
        fill='#E6E6E6'
      />
      <path
        d='M141.15 53.193H40.2647C39.8816 53.193 39.5142 53.0405 39.2434 52.7691C38.9725 52.4978 38.8203 52.1297 38.8203 51.7459C38.8203 51.3621 38.9725 50.994 39.2434 50.7227C39.5142 50.4513 39.8816 50.2988 40.2647 50.2988H141.15C141.533 50.2988 141.9 50.4513 142.171 50.7227C142.442 50.994 142.594 51.3621 142.594 51.7459C142.594 52.1297 142.442 52.4978 142.171 52.7691C141.9 53.0405 141.533 53.193 141.15 53.193Z'
        fill='#E6E6E6'
      />
      <path
        d='M100.175 39.5263C100.191 39.9622 100.302 40.3892 100.501 40.7774C100.699 41.1655 100.98 41.5053 101.324 41.7728C101.668 42.0403 102.066 42.2291 102.491 42.3257C102.915 42.4223 103.356 42.4245 103.782 42.3321L109.29 51.3854L111.634 46.4014L106.044 38.5683C105.842 37.8625 105.388 37.2555 104.769 36.8624C104.15 36.4693 103.408 36.3174 102.685 36.4355C101.961 36.5536 101.306 36.9334 100.843 37.5031C100.381 38.0727 100.143 38.7926 100.175 39.5263Z'
        fill='#FFB6B6'
      />
      <path
        d='M141.513 65.4719L122.53 55.1561L116.543 49.5281C116.543 49.5281 113.94 48.8298 114.898 47.9819C115.856 47.134 113.126 46.3162 113.126 46.3162L109.911 43.2949L105.344 46.5327L106.666 48.5856C106.666 48.5856 106.575 51.984 108.433 51.3302C110.291 50.6764 109.827 53.4949 109.827 53.4949L120.919 70.7204L141.513 65.4719Z'
        fill='#3F3D56'
      />
      <path
        d='M85.4755 55.7539C95.7159 55.7539 104.017 47.4371 104.017 37.1777C104.017 26.9184 95.7159 18.6016 85.4755 18.6016C75.2351 18.6016 66.9336 26.9184 66.9336 37.1777C66.9336 47.4371 75.2351 55.7539 85.4755 55.7539Z'
        fill='#04BC79'
      />
      <path d='M141.569 200.777H137.714L135.879 185.879H141.569V200.777Z' fill='#FFB6B6' />
      <path
        d='M143.605 207.47H140.84L140.347 204.855L139.083 207.47H131.75C131.399 207.47 131.056 207.357 130.773 207.148C130.49 206.94 130.281 206.646 130.176 206.31C130.072 205.973 130.077 205.613 130.192 205.28C130.306 204.947 130.524 204.659 130.813 204.459L136.669 200.408V197.764L142.829 198.132L143.605 207.47Z'
        fill='#2F2E41'
      />
      <path d='M110.737 200.777H106.882L105.047 185.879H110.737V200.777Z' fill='#FFB6B6' />
      <path
        d='M112.769 207.47H110.005L109.511 204.855L108.247 207.47H100.914C100.563 207.47 100.22 207.357 99.9372 207.148C99.654 206.94 99.4449 206.646 99.3403 206.31C99.2357 205.973 99.2411 205.613 99.3557 205.28C99.4704 204.947 99.6882 204.659 99.9774 204.459L105.833 200.408V197.764L111.993 198.132L112.769 207.47Z'
        fill='#2F2E41'
      />
      <path d='M137.479 88.1055H118.02V110.3H137.479V88.1055Z' fill='#FFB6B6' />
      <path
        d='M118.469 99.0527L115.176 102.952L109.488 114.949L95.1561 149.113L92.125 156.338L94.5728 169.111C94.5728 169.111 94.969 178.109 94.969 179.582C94.969 182.581 97.4729 184.243 97.4729 184.243L99.3098 193.828L117.122 193.079C117.122 193.079 116.501 182.513 112.77 179.848C109.039 177.183 107.324 163.293 107.324 163.293L105.297 157.13L125.056 133.666L125.183 141.868L125.245 145.838C125.245 145.838 122.351 171.659 125.724 176.67C129.097 181.681 125.793 181.092 125.793 181.092L125.954 191.429L147.059 191.579L142.403 161.818C142.403 161.818 140.039 159.948 141.304 158.068C142.569 156.188 140.078 153.886 140.078 153.886L141.195 130.652C141.195 130.652 139.958 126.639 141.413 126.118C142.868 125.596 141.604 122.145 141.604 122.145L141.82 117.648L137.479 103.702L118.469 99.0527Z'
        fill='#2F2E41'
      />
      <path
        d='M137.78 88.1042L139.876 81.8058L141.373 79.7063L141.971 73.7079C141.971 57.512 138.366 60.209 138.366 60.209L136.283 54.5128L126.704 54.2129L120.567 60.0614L115.477 61.8609L113.484 72.2353L117.124 83.3054L118.022 89.3039C116.309 92.4725 116.454 90.9737 117.872 93.3528L134.787 98.0016C140.774 100.101 140.774 94.7025 139.573 93.3528C138.373 92.0032 137.78 88.1042 137.78 88.1042Z'
        fill='#3F3D56'
      />
      <path
        d='M130.256 52.2008C135.161 52.2008 139.137 48.2173 139.137 43.3035C139.137 38.3897 135.161 34.4062 130.256 34.4062C125.351 34.4062 121.375 38.3897 121.375 43.3035C121.375 48.2173 125.351 52.2008 130.256 52.2008Z'
        fill='#FFB6B6'
      />
      <path
        d='M142.111 55.1783C142.194 55.3198 142.245 55.4783 142.259 55.6421C142.273 55.8059 142.25 55.9708 142.192 56.1244C142.133 56.2781 142.041 56.4166 141.922 56.5295C141.803 56.6425 141.659 56.7271 141.503 56.7769C140.312 57.1728 139.578 58.3275 138.722 59.2513C137.869 60.172 136.495 60.9368 135.405 60.31C134.318 59.6862 134.243 58.0936 133.333 57.2298C132.447 56.39 130.974 56.492 129.932 57.1308L129.901 57.15C129.722 57.2675 129.511 57.3295 129.297 57.3286C129.082 57.3276 128.872 57.2636 128.693 57.1445C128.515 57.0254 128.375 56.8564 128.291 56.6585C128.207 56.4605 128.183 56.2423 128.221 56.0307C128.586 54.0858 128.951 52.1411 129.316 50.1966C128.358 52.5091 126.966 54.6165 125.217 56.405C124.736 56.962 124.125 57.3924 123.439 57.6587C122.816 57.8566 122.212 57.2118 121.607 57.3288C123.11 56.0121 128.163 47.5722 127.259 41.9307C126.196 42.0507 125.134 42.1707 124.071 42.2906C123.956 41.7867 123.71 41.3222 123.358 40.944C123.401 41.4344 123.339 41.9283 123.176 42.3926C122.733 42.4436 122.287 42.4916 121.844 42.5426C121.227 42.6115 120.556 42.6715 120.026 42.3476C119.143 41.8078 119.116 40.5301 119.368 39.5223C120.104 36.5921 122.337 34.1417 125.071 32.858C127.804 31.5774 128.612 34.4296 131.492 33.5179C137.18 31.7184 141.539 34.7956 143.075 40.5061C144.365 45.2959 143.428 50.3795 142.111 55.1783Z'
        fill='#2F2E41'
      />
      <path
        d='M166.697 207.663H155.823L155.776 207.578C155.637 207.325 155.503 207.057 155.377 206.782C154.257 204.38 153.784 201.632 153.366 199.208L153.052 197.381C153.013 197.159 153.042 196.932 153.134 196.727C153.226 196.522 153.377 196.349 153.567 196.231C153.757 196.112 153.979 196.053 154.203 196.061C154.427 196.07 154.643 196.145 154.824 196.277C156.477 197.482 158.13 198.685 159.784 199.888C160.41 200.344 161.126 200.872 161.81 201.443C161.877 201.122 161.946 200.799 162.015 200.481C162.062 200.262 162.174 200.061 162.336 199.906C162.498 199.75 162.703 199.647 162.924 199.609C163.145 199.57 163.372 199.599 163.577 199.691C163.782 199.783 163.954 199.934 164.073 200.125L165.345 202.173C166.273 203.668 167.092 205.142 166.925 206.731C166.926 206.751 166.925 206.77 166.921 206.789C166.888 207.05 166.826 207.307 166.736 207.554L166.697 207.663Z'
        fill='#F0F0F0'
      />
      <path
        d='M177.61 207.899L74.2749 208C74.1718 207.999 74.073 207.958 74.0003 207.885C73.9276 207.811 73.8867 207.712 73.8867 207.609C73.8867 207.506 73.9276 207.406 74.0003 207.333C74.073 207.26 74.1718 207.219 74.2749 207.218L177.61 207.117C177.713 207.118 177.812 207.159 177.885 207.232C177.957 207.306 177.998 207.405 177.998 207.508C177.998 207.611 177.957 207.711 177.885 207.784C177.812 207.857 177.713 207.898 177.61 207.899Z'
        fill='#CACACA'
      />
      <path
        d='M83.7889 62.18L72.4225 52.5633C72.034 52.2339 71.7917 51.7635 71.749 51.2553C71.7062 50.7472 71.8664 50.2428 72.1944 49.8528L74.2864 47.371C74.6152 46.9818 75.0848 46.7391 75.592 46.6963C76.0992 46.6534 76.6026 46.8139 76.9919 47.1425L88.3583 56.7591C88.7468 57.0886 88.989 57.559 89.0318 58.0671C89.0746 58.5753 88.9144 59.0796 88.5864 59.4696L86.4944 61.9514C86.1655 62.3407 85.696 62.5833 85.1888 62.6262C84.6816 62.669 84.1781 62.5085 83.7889 62.18Z'
        fill='#3F3D56'
      />
      <path
        d='M73.021 50.0522C72.8443 50.2623 72.758 50.5341 72.781 50.808C72.8041 51.0818 72.9346 51.3353 73.1439 51.5128L84.5103 61.1295C84.7201 61.3065 84.9914 61.393 85.2647 61.3699C85.5381 61.3468 85.7911 61.2161 85.9683 61.0063L88.0603 58.5245C88.237 58.3144 88.3233 58.0425 88.3003 57.7687C88.2773 57.4949 88.1467 57.2414 87.9374 57.0639L76.571 47.4472C76.3612 47.2702 76.0899 47.1837 75.8166 47.2068C75.5433 47.2299 75.2902 47.3606 75.113 47.5704L73.021 50.0522Z'
        fill='white'
      />
      <path
        d='M79.6917 53.7903C79.6523 53.8456 79.6005 53.891 79.5406 53.9227C79.4806 53.9545 79.4141 53.9719 79.3462 53.9734L77.5603 54.0145C77.5029 54.0158 77.4459 54.0058 77.3924 53.985C77.3389 53.9642 77.29 53.9331 77.2485 53.8934C77.207 53.8537 77.1737 53.8062 77.1505 53.7536C77.1273 53.701 77.1147 53.6443 77.1134 53.5868C77.1121 53.5294 77.1221 53.4722 77.1428 53.4186C77.1636 53.365 77.1946 53.316 77.2343 53.2744C77.2739 53.2328 77.3214 53.1995 77.3739 53.1763C77.4264 53.153 77.4829 53.1404 77.5403 53.1391L78.7086 53.1122L77.558 50.0705C77.5173 49.962 77.5211 49.8417 77.5688 49.7361C77.6164 49.6304 77.704 49.548 77.8122 49.5069C77.9204 49.4658 78.0405 49.4694 78.146 49.5169C78.2516 49.5644 78.3341 49.6519 78.3753 49.7602L79.7449 53.3806C79.7696 53.446 79.7784 53.5165 79.7703 53.5861C79.7623 53.6556 79.7377 53.7223 79.6987 53.7804L79.6917 53.7903Z'
        fill='#04BC79'
      />
      <path
        d='M79.9212 54.5562C80.3519 54.6196 80.7636 54.7768 81.1271 55.0168C81.4907 55.2568 81.7972 55.5738 82.0253 55.9454C82.2533 56.3169 82.3973 56.734 82.447 57.1674C82.4967 57.6007 82.451 58.0397 82.3131 58.4534L90.6971 64.9237L85.4969 66.7165L78.3325 60.2974C77.6541 60.0192 77.1012 59.5013 76.7785 58.8419C76.4557 58.1826 76.3857 57.4275 76.5815 56.7199C76.7773 56.0122 77.2255 55.4011 77.841 55.0023C78.4565 54.6035 79.1966 54.4448 79.9212 54.5562Z'
        fill='#FFB6B6'
      />
      <path
        d='M123.573 57.7539L102.107 70.9764C102.107 70.9764 93.4526 67.7682 95.1441 66.283C96.8355 64.7978 91.4509 63.7937 91.4509 63.7937L85.5025 59.7844L82.0508 63.8034C82.0508 63.8034 82.6797 70.3313 83.7498 69.201C84.82 68.0707 86.4195 71.1584 86.4195 71.1584C86.4195 71.1584 86.8922 74.6062 89.9385 73.7386C92.9848 72.8709 101.159 81.9655 101.159 81.9655L127.577 72.0943L123.573 57.7539Z'
        fill='#3F3D56'
      />
      <path
        d='M84.8233 44.6297C84.5745 44.6297 84.329 44.5716 84.1065 44.4601C83.8839 44.3486 83.6903 44.1866 83.541 43.9872L79.6096 38.7355C79.4833 38.5667 79.3914 38.3748 79.3392 38.1705C79.287 37.9662 79.2754 37.7536 79.3052 37.5448C79.335 37.336 79.4055 37.1352 79.5127 36.9537C79.62 36.7722 79.7619 36.6137 79.9303 36.4872C80.2704 36.2317 80.6979 36.1219 81.1187 36.1822C81.3271 36.212 81.5276 36.2827 81.7087 36.3901C81.8899 36.4976 82.0481 36.6397 82.1744 36.8084L84.7465 40.244L91.3525 30.3167C91.5883 29.9623 91.955 29.7163 92.3719 29.6328C92.7888 29.5492 93.2217 29.635 93.5755 29.8713C93.9292 30.1076 94.1748 30.4749 94.2582 30.8926C94.3416 31.3102 94.2559 31.744 94.0201 32.0984L86.1572 43.9145C86.015 44.1282 85.8237 44.3046 85.5994 44.429C85.375 44.5533 85.1242 44.622 84.8679 44.6292C84.853 44.6294 84.8382 44.6297 84.8233 44.6297Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_389_20116'>
        <rect width='178' height='208' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Icon
