import { PromotionId, Promotion } from 'src/models/promotions'

import { State } from 'src/models'
import { createSelector } from 'reselect'

export const locations = (state: State) => state.providerLocations.cache
const promotionsCache = (state: State) => state.promotions.cache

export const list = (state: State) => Object.values(state.promotions.cache)

export const isUpdatingStatus = (state: State) => state.promotions.isUpdatingStatus

export const isFetching = (state: State) => state.promotions.isFetching
export const isRemoving = (state: State) => state.promotions.isRemoving
export const error = (state: State) => state.promotions.error
export const promotionById = (state: State, id: PromotionId) => state.promotions.cache[id]
export const listFilters = (state: State) => state.promotions.listFilters
