import actionCreatorFactory from 'typescript-fsa'

import {
  PromotionId,
  PromotionsResponse,
  Promotion,
  PromotionQueryParams
} from 'src/models/promotions'
import { ProviderLocationId } from 'src/models/providerLocations'
import { UpdateStatusPayload } from './types'

// Actions
const actionCreator = actionCreatorFactory('promotions')

export const fetch = actionCreator.async<void, PromotionsResponse, Error>('FETCH')
export const fetchAll = actionCreator<PromotionsResponse>('FETCH_ALL')
export const setListFilters = actionCreator<PromotionQueryParams>('SET_LIST_FITERS')

export const selectLocation = actionCreator<ProviderLocationId>('SELECT_LOCATION')
export const remove = actionCreator.async<PromotionId, void, Error>('REMOVE')
export const dismissError = actionCreator<void>('DISMISS_ERROR')
export const updateStatus = actionCreator.async<UpdateStatusPayload, Promotion, Error>(
  'UPDATE_STATUS'
)
