import React from 'react'
import { Box } from '@mui/material'
import PhotoPicker from 'src/components/FilesDropzone/PhotoPicker'
import InputWithLabel, { InputLabelWrapper } from 'src/components/Base/InputWithLabel'
import CountryPicker from 'src/components/Pickers/CountryPicker'
import PhoneNumberInput from 'src/components/PhoneNumberInput'
import POSForm from './POSForm'

import useSignUpStore from './useSignUpStore'
import CityPicker from 'src/components/Pickers/CityPicker'

export const FormComponent = () => {
  const { draft, errors, updateDraft, handleInputChange } = useSignUpStore()

  const onChangeCountryCode = (value: string) => updateDraft('countryCode', value)
  const handlePhone = (value: string) => updateDraft('contactPhoneNumber', value)
  const onChangeCity = (value: string, googlePlaceId?: string) => {
    updateDraft('city', value)
    updateDraft('googlePlaceId', googlePlaceId)
  }

  return (
    <Box sx={{ mt: 1 }}>
      <InputLabelWrapper required id='providerName' name='providerName' label='Brand Logo'>
        <PhotoPicker uploadType='brand-image' />
      </InputLabelWrapper>
      <InputWithLabel
        required
        id='providerName'
        name='providerName'
        label='Organisation name'
        placeholder='Enter your organisation name'
        fullWidth
        error={!!errors.providerName}
        helperText={errors.providerName || 'Enter the official name of your organization'}
        onChange={handleInputChange}
        value={draft.providerName}
      />

      <InputWithLabel
        required
        id='brandName'
        name='brandName'
        label='Brand name'
        placeholder='Enter your Brand Name'
        fullWidth
        error={!!errors.brandName}
        helperText={errors.brandName || 'Enter the name associated with your brand or product'}
        onChange={handleInputChange}
        value={draft.brandName}
      />

      <InputLabelWrapper label='Country'>
        <CountryPicker country={draft.countryCode} onChange={onChangeCountryCode} />
      </InputLabelWrapper>
      <POSForm />

      {draft.countryCode ? (
        <InputLabelWrapper label='City'>
          <CityPicker countryCode={draft.countryCode!} onChange={onChangeCity} />
        </InputLabelWrapper>
      ) : null}

      <InputWithLabel
        label='Contact Email'
        required
        id='email'
        name='contactEmail'
        placeholder='Enter your contact email'
        fullWidth
        error={!!errors.contactEmail}
        helperText={errors.contactEmail || 'This will be shown in promotion of the app.'}
        onChange={handleInputChange}
        value={draft.contactEmail}
      />

      <InputWithLabel
        helperText={errors.websiteUrl}
        error={!!errors.websiteUrl}
        name='websiteUrl'
        id='phone-number'
        placeholder='Enter website'
        label='Web Site'
        onChange={handleInputChange}
        value={draft.websiteUrl}
        fullWidth
      />

      <InputLabelWrapper
        helperText={errors.contactPhoneNumber || 'This will be shown in promotion of the app.'}
        error={!!errors.contactPhoneNumber}
        id='phone-number'
        label='Contact Phone number'
      >
        <PhoneNumberInput defaultValue={draft.contactPhoneNumber} onChangePhone={handlePhone} />
      </InputLabelWrapper>
    </Box>
  )
}

export default FormComponent
