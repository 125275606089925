// Container.tsx
import React, { ReactNode } from 'react'
import { Box, Grid } from '@mui/material'

interface ContainerProps {
  children: ReactNode
}

const Container: React.FC<ContainerProps> = ({ children }) => {
  return (
    <Box sx={{ height: '85vh', width: '100%' }}>
      <Grid container style={{ height: '100%' }}>
        {children}
      </Grid>
    </Box>
  )
}

export default Container
