import React, { useEffect } from 'react'

import InputWithLabel, { InputLabelWrapper } from 'src/components/Base/InputWithLabel'
import CurrencyPicker from 'src/components/Pickers/CurrencyPicker'
import usePromotionStore from './usePromotionStore'
import { PromotionType } from 'src/models/promotions'
import InputAdornment from '@mui/material/InputAdornment'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import authStore from 'src/store/auth'
import countryInfo from 'src/assets/json/countryInfo.json'

const LocationAddressForm = () => {
  const { errors, promotionId, draft, handleInputChange, updateDraft } = usePromotionStore()

  // TO DO REFACTOR
  const userPhoneNumber = useSelector(authStore.selectors.user)?.phoneNumber

  const userCurrency = countryInfo.find(item =>
    `+${userPhoneNumber}`.startsWith(item.dial_code)
  )?.currency

  useEffect(() => {
    !promotionId && !draft?.currency && updateDraft('currency', userCurrency)
    promotionId && !draft?.currency && updateDraft('currency', userCurrency)
  }, [])

  const handlePercentageDiscount = (e: any) => {
    const percent = e.target.value
    if ((percent + '')?.split('.')?.[1]?.length > 2) {
      return
    }
    let value = percent
    if (+percent > 100) {
      value = '100'
    } else if (+percent < 0) {
      value = '0'
    }

    updateDraft('discountPercentage', value.toString())
  }

  const updateCurrency = (value: string) => updateDraft('currency', value)

  const renderCurrency = () => {
    return (
      <InputLabelWrapper label={'Currency'} error={!!errors.currency} helperText={errors.currency}>
        <CurrencyPicker currency={draft.currency || userCurrency} onChange={updateCurrency} />
      </InputLabelWrapper>
    )
  }

  switch (draft?.type) {
    case PromotionType.fixedDiscount:
      return (
        <>
          <InputWithLabel
            label='Discount amount'
            placeholder='Enter your discount amount'
            key={PromotionType.fixedDiscount}
            fullWidth
            name='fixedDiscount'
            type='number'
            error={!!errors.fixedDiscount}
            helperText={errors.fixedDiscount}
            defaultValue={draft?.fixedDiscount}
            //@ts-ignore
            endAdornment={
              <InputAdornment position='end'>
                <Typography>huy</Typography>
              </InputAdornment>
            }
            onChange={handleInputChange}
          />
          {renderCurrency()}
        </>
      )
    case PromotionType.percentageDiscount:
      return (
        <>
          <InputWithLabel
            name='discountPercentage'
            label='Discount percentage'
            placeholder='Enter your discount percentage'
            key={PromotionType.percentageDiscount}
            fullWidth
            type='number'
            value={draft?.discountPercentage}
            error={!!errors.discountPercentage}
            helperText={errors.discountPercentage}
            onChange={handlePercentageDiscount}
            InputProps={{
              inputProps: { min: 0, max: 100 },
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography>%</Typography>
                </InputAdornment>
              )
            }}
            onWheel={(e: any) => e.target.blur()}
          />
          {renderCurrency()}
          <InputWithLabel
            name='maxDiscountAmount'
            label='Maximum discount'
            placeholder='Enter maximum discount amount'
            fullWidth
            error={!!errors.maxDiscountAmount}
            helperText={errors.maxDiscountAmount}
            value={draft?.maxDiscountAmount}
            type='number'
            InputProps={{
              inputProps: { min: 0, max: 100 },
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography>{draft.currency}</Typography>
                </InputAdornment>
              )
            }}
            onChange={handleInputChange}
          />
        </>
      )
    case PromotionType.customOffer:
      return (
        <InputWithLabel
          name={'customOfferText'}
          label='Offer'
          placeholder='Enter the text of your offer'
          fullWidth
          error={!!errors.customOfferText}
          helperText={errors.customOfferText}
          value={draft?.customOfferText}
          onChange={handleInputChange}
        />
      )
    default:
      return <div />
  }
}

export default LocationAddressForm
