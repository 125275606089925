import React from 'react'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import { ProviderLocationStatus } from 'src/models/providerLocations'
import { PromotionStatus } from 'src/models/promotions'
import { useTranslation } from 'react-i18next'
import { calculateResponsiveFontSize } from 'src/theme'
import { SubscriptionStatus } from 'src/models/subscriptions'
type StatusChipProps = {
  status: string
}

const StyledChip = styled(Chip)<{ status: string }>(({ theme, status }) => {
  const getStatusColor = (status: string): string => {
    switch (status) {
      case 'active':
        return '#5ABA1A'
      case 'past_due':
        return 'rgb(156, 39, 176)'
      case 'unpaid':
        return '#E42121'
      case 'canceled':
        return 'rgb(0, 0, 0)'
      case 'incomplete':
        return 'rgb(255, 152, 0)'
      case 'incomplete_expired':
        return 'rgb(255, 152, 0)'
      case 'trialing':
        return 'rgb(33, 150, 243)'
      case 'paused':
        return 'rgb(255,255,0)'
      default:
        return 'rgb(0, 0, 0)' // Default color as RGB
    }
  }

  const backgroundColor = getStatusColor(status)

  return {
    backgroundColor,
    color: theme.palette.common.white, // Assuming you want the text color to be white
    borderRadius: '4px', // border-radius set to 4px
    fontSize: `${calculateResponsiveFontSize(0.75)}rem`, // font-size set to 12px
    fontWeight: 400, // font-weight set to 400
    lineHeight: `1.5rem`, // line-height set to 18px
    //letterSpacing: '0em', // letter-spacing set to 0em
    textAlign: 'left', // text-align set to left
    minWidth: '54px', // width set to 54px
    height: `${calculateResponsiveFontSize(1.5)}rem`, // height set to 22px
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    '& .MuiChip-label': {
      padding: 0, // Remove padding from the label if necessary
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
})

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
  const getStatusLabel = (status: string): string => {
    // Modify the labels as per your requirements
    switch (status) {
      case 'active':
        return 'Active'
      case 'past_due':
        return 'Past Due'
      case 'unpaid':
        return 'Unpaid'
      case 'canceled':
        return 'Canceled'
      case 'incomplete':
        return 'Incomplete'
      case 'incomplete_expired':
        return 'Incomplete Expired'
      case 'trialing':
        return 'Trialing'
      case 'paused':
        return 'Paused'
      default:
        return ''
    }
  }

  const label = getStatusLabel(status)

  return (
    <div>
      <StyledChip status={status} label={label} />
    </div>
  )
}

export default StatusChip
