import React from 'react'
import { calculateResponsiveFontSize } from 'src/theme/index'
import { Box, Paper, Avatar, Typography, Button, Grid, Chip, styled, Link } from '@mui/material'
import BackgroundImageContainer from './BackgroundImageContainer'
import BottomSheetComponent from './BottomSheet'

interface Props {
  children: React.ReactNode
}
const Container: React.FC<Props> = ({ children }) => {
  const imgWidth = '30vh' // This value can be dynamically calculated if needed

  return (
    <Box>
      <BackgroundImageContainer imgWidth={imgWidth}>
        <BottomSheetComponent imgWidth={imgWidth}>{children}</BottomSheetComponent>
      </BackgroundImageContainer>
    </Box>
  )
}

export default Container
