import React from 'react'
import { Typography, Box, Avatar } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import Tags from './components/Tags'

import { CustomCard, Label, Category, Subtitle } from 'src/components/List/Components'
import LocationLabel from 'src/components/List/LocationLabel'
import Container from './components/Container'
import ActionButtons from './components/ActionButtons'

import { ProviderLocation } from 'src/models/providerLocations'
import { calculateResponsiveFontSize } from 'src/theme/index'
import PhotosComponent from './components/Photos'
import Contacts from './components/Contacts'

import { Text14pxRanyBold } from '../Typography'

interface Props {
  item: ProviderLocation
}
const LocationComponent = ({ item }: Props) => {
  const imgWidth = '30vh' // This value can be dynamically calculated if needed
  const imgHeight = '51vh'
  const ratio = 516 / 238

  return (
    <Container>
      <Box sx={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '24px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={item?.image?.url}
            alt='Logo'
            sx={{
              width: `${calculateResponsiveFontSize(3)}rem`,
              height: `${calculateResponsiveFontSize(3)}rem`,
              marginRight: 2
            }}
          />
          <Box>
            <Label variant='subtitle1'>{item?.name}</Label>
            <Subtitle variant={'subtitle2'} mt={1}>
              {item?.brand?.name}
            </Subtitle>
            <Category label={'Food'} sx={{ mt: 1, padding: 0 }} />
          </Box>
        </Box>

        <LocationLabel address={item.address!} fontSize={14} />
      </Box>

      <ActionButtons />

      <Box sx={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: '32px' }}>
        <Text14pxRanyBold mt={1} mb={0}>
          Contacts
        </Text14pxRanyBold>
        <Contacts data={item} />
        <PhotosComponent images={item?.images} />
        <Tags tags={item.tags} />
      </Box>
    </Container>
  )
}

export default LocationComponent
