import { resizePx } from 'src/theme'

const ProfileIcon = (props: { width?: number; height?: number; isActive?: boolean }) => (
  <svg
    width={resizePx(props.width || 150)}
    height={resizePx(props.height || 115)}
    viewBox='0 0 150 115'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_389_21188)'>
      <path
        d='M126.257 113.557L29.8114 113.59C24.9662 113.592 20.6261 111.121 18.202 106.981C16.9898 104.911 16.3836 102.64 16.3828 100.368C16.382 98.0972 16.9869 95.8256 18.1974 93.7548L66.3896 7.181C68.8111 3.03918 73.1495 0.565741 77.9947 0.564088C82.8399 0.562434 87.1801 3.03291 89.6044 7.17308L137.864 93.7247C139.073 95.7894 139.677 98.0577 139.677 100.328C139.677 102.598 139.073 104.869 137.862 106.94C135.441 111.082 131.102 113.555 126.257 113.557ZM17.5951 100.367C17.595 102.434 28.2375 97.9792 29.34 99.8624C31.5454 103.629 25.4029 112.398 29.811 112.396C29.811 112.396 134.612 110.111 136.814 106.344C137.915 104.46 138.466 102.393 138.465 100.327C138.464 98.2604 137.913 96.1943 136.81 94.3112L88.5506 7.75951C86.3514 4.00396 82.4032 1.7563 77.9951 1.75781C73.5891 1.75931 17.5953 98.299 17.5951 100.367Z'
        fill='#3F3D56'
      />
      <path
        d='M106.138 94.4644C130.523 94.4644 150.291 74.9572 150.291 50.8937C150.291 26.8303 130.523 7.32306 106.138 7.32306C81.7524 7.32306 61.9844 26.8303 61.9844 50.8937C61.9844 74.9572 81.7524 94.4644 106.138 94.4644Z'
        fill='#04BC79'
      />
      <path
        d='M106.148 77.6037C108.387 77.6037 110.202 75.8126 110.202 73.6032C110.202 71.3938 108.387 69.6027 106.148 69.6027C103.909 69.6027 102.094 71.3938 102.094 73.6032C102.094 75.8126 103.909 77.6037 106.148 77.6037Z'
        fill='white'
      />
      <path
        d='M106.13 24.1847C105.757 26.6266 106.424 27.1376 106.428 37.6648C106.432 48.1921 108.645 62.3066 106.143 62.3074C103.641 62.3083 102.725 33.1791 94.036 30.601C83.8198 27.5699 107.335 16.2799 106.13 24.1847Z'
        fill='white'
      />
      <path
        d='M46.8759 73.139L42.8868 81.4564C47.7093 78.0439 55.2419 75.1022 61.2361 73.5267C55.651 70.8645 48.8169 66.5722 44.734 62.3225L47.0284 71.1109C20.1289 65.7042 0.81187 45.3405 0.803692 22.0137L-0.167969 21.6838C-0.159428 46.0493 18.8903 67.6188 46.8759 73.139Z'
        fill='#3F3D56'
      />
    </g>
    <defs>
      <clipPath id='clip0_389_21188'>
        <rect width='150' height='114' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>
)
export default ProfileIcon
