import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container } from '@mui/material'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import subscriptionsStore from 'src/store/subscriptions'
import adminsStore from 'src/store/admins'

import Form from './Form'

import ContainerPageWithBack from 'src/components/Layouts/ContainerPageWithBack'
import { AdminProfileUpsert } from 'src/models/admins'
import { draft } from 'src/store/brands/selectors'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { PhoneNumber } from 'libphonenumber-js'
import usePrevious from 'src/hooks/usePrevious'

const App: React.FC = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions' })
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [adminProfile, setAdminProfile] = useState<AdminProfileUpsert>({})

  const updateProfile = (
    key: keyof AdminProfileUpsert,
    value: AdminProfileUpsert[keyof AdminProfileUpsert]
  ) => {
    setAdminProfile(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  const onSave = () => {
    dispatch(adminsStore.actions.upsert.started(adminProfile))
  }

  const isCreating = useSelector(adminsStore.selectors.isCreating)
  const error = useSelector(adminsStore.selectors.createError)
  const prevIsCreating = usePrevious(isCreating)

  useEffect(() => {
    if (!!isCreating !== !!prevIsCreating && !isCreating && !error) {
      navigate(`/users`)
    }
  }, [isCreating])

  return (
    <ContainerPageWithBack linkHref='/users' loading={isCreating} title={'Add new admin'}>
      <Box mt={2}></Box>
      <Container maxWidth='sm'>
        <Form draft={adminProfile} updateProfile={updateProfile} onSave={onSave} />
      </Container>
    </ContainerPageWithBack>
  )
}

export default App
