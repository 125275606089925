import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Promotion } from 'src/models/promotions'
import { useTranslation } from 'react-i18next'
import Dialog from './SelectStatusDialog'
import promotions from 'src/store/promotions'
import { StatusInline, StatusGroup } from './StatusView'
import { PromotionDraftUpdate } from 'src/store/createPromotion/types'

type Type = 'list' | 'form'

interface Props {
  promotion: Promotion | PromotionDraftUpdate
  type?: Type
}

export default function PromotionStatus({ promotion, type }: Props) {
  const [modalOpen, setModalOpen] = useState(false)
  const { id, status } = promotion

  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'statuses' })

  const onClick = () => setModalOpen(true)
  const onClose = () => setModalOpen(false)
  const onConfirm = (value: any) => {
    dispatch(promotions.actions.updateStatus.started({ id: id!, status: value }))
    setModalOpen(false)
  }

  return (
    <>
      <Dialog currentStatus={status!} open={modalOpen} onConfirm={onConfirm} onClose={onClose} />
      {type === 'list' && status ? (
        <StatusInline status={status!} onClick={onClick} />
      ) : (
        <StatusGroup
          status={status!}
          onClick={onClick}
          description={
            status === 'live' || status === 'declined'
              ? t('warning_for_live_or_declined_statuses_for_promotion')
              : ''
          }
        />
      )}
    </>
  )
}
