import rtl from 'jss-rtl'
import { create } from 'jss'
import i18next from 'i18next'
import * as React from 'react'
import { useEffect } from 'react'
import createCache from '@emotion/cache'
import rtlPlugin from 'stylis-plugin-rtl'
import { useSelector } from 'react-redux'
import { CacheProvider } from '@emotion/react'
import { StylesProvider, jssPreset } from '@mui/styles'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'src/theme'

import { IsLogged } from 'src/components/RequireAuth'
import remoteConfigStore from 'src/store/remoteConfig'
import { GoogleRecaptchaWrapper } from 'src/components/GoogleRecaptchaWrapper'

// Components
import AppLayout from 'src/components/Layouts/AppLayout'
import ProfileLayout from 'src/components/Layouts/ProfileLayout'

// Pages
import Welcome from './pages/Welcome'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import OneTimePassword from './pages/OneTimePassword'
import CreateProviderLocation from './pages/CreateProviderLocation'
import EditProviderLocation from './pages/CreateProviderLocation/Edit'
import LocationSuccessful from './pages/CreateProviderLocation/LocationSuccessful'

import CreatePromotion from './pages/CreatePromotion'
import PromotionSuccessful from './pages/CreatePromotion/PromotionSuccessful'

//import EditPromotion from './pages/CreatePromotion/Edit'

import Locations from './pages/Locations'
import Promotions from './pages/Promotions'
import CreateProvider from './pages/CreateProvider'
import ProviderSettings from './pages/ProviderSettings'
import AddAdmin from './pages/AddAdmin'
import PromotionPreview from './pages/PromotionPreview'
import AddLocationAdmin from './pages/AddLocationAdmin'
import { FoodicsSuccess } from './pages/Foodics/FoodicsSuccess'

import SubscriptionPlans from './pages/SubscriptionPlans'
import CreateCustomPlan from './pages/CreateCustomPlan'
import Subscriptions from './pages/Subscriptions'

import Brands from './pages/Brands'
import BrandCreate from './pages/CreateBrand/Create'
import BrandEdit from './pages/CreateBrand/Edit'
import FoodicsLink from './pages/CreateBrand/FoodicsLink'

import Users from './pages/Users'
import UserCreate from './pages/User/Create'
import UserEdit from './pages/User/Edit'

// Admin sections

const cacheLtr = createCache({
  key: 'muiltr'
})

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin]
})

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

function RTL(props: { children: any }) {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>
}

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default function App() {
  const isReady = useSelector(remoteConfigStore.selectors.isReady)
  const [isRtl, setIsRtl] = React.useState(false)

  React.useEffect(() => {
    i18next.on('languageChanged', onLanguageChange)
    return () => i18next.off('languageChanged', onLanguageChange)
  }, [isReady])

  const onLanguageChange = (newLang: any) => {
    if (isReady) {
      const rtl = i18next.dir(newLang)
      setIsRtl(rtl === 'rtl')
    }
  }

  React.useLayoutEffect(() => {
    document.body.setAttribute('dir', isRtl ? 'rtl' : 'ltr')
  }, [isRtl])

  let appTheme = isRtl ? theme : theme

  const UnkownRouteHandler = ({ to }: { to: string }) => {
    const prevRoute = useLocation()
    return <Navigate to={to} state={{ prevRoute }} replace />
  }

  return isReady ? (
    <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
      <ThemeProvider theme={appTheme}>
        <RTL>
          <ScrollToTop />
          <Routes>
            <Route
              path='/welcome'
              element={
                <IsLogged>
                  <Welcome />
                </IsLogged>
              }
            />

            <Route
              path='signup'
              element={
                <IsLogged>
                  <GoogleRecaptchaWrapper>
                    <SignUp />
                  </GoogleRecaptchaWrapper>
                </IsLogged>
              }
            />
            <Route
              path='login'
              element={
                <IsLogged>
                  <GoogleRecaptchaWrapper>
                    <Login />
                  </GoogleRecaptchaWrapper>
                </IsLogged>
              }
            />
            <Route
              path='password'
              element={
                <IsLogged>
                  <GoogleRecaptchaWrapper>
                    <OneTimePassword />
                  </GoogleRecaptchaWrapper>
                </IsLogged>
              }
            />
            <Route path='' element={<AppLayout />}>
              <Route path='locations'>
                <Route path='create' element={<CreateProviderLocation />} />
                <Route path='complete/:id' element={<LocationSuccessful />} />
                <Route path='edit/:id' element={<EditProviderLocation />} />
                <Route path='admins/create' element={<AddLocationAdmin />} />
                <Route index element={<Locations />} />
              </Route>
              <Route path='plans' element={<SubscriptionPlans />} />
              <Route path='admin/create' element={<UserCreate />} />
              <Route path='admin/edit/:id' element={<UserEdit />} />
              <Route path='custom-plan' element={<CreateCustomPlan />} />

              <Route path='brand/create' element={<BrandCreate />} />
              <Route path='brands/edit/:id' element={<BrandEdit />} />
              <Route path='brands/foodics/:id' element={<FoodicsLink />} />

              <Route path='promotions'>
                <Route path='preview/:id' element={<PromotionPreview />} />
                <Route path='preview' element={<PromotionPreview />} />
                <Route path='create' element={<CreatePromotion />} />
                <Route path='edit/:id' element={<CreatePromotion />} />
                <Route path='complete/:id' element={<PromotionSuccessful />} />

                <Route index element={<Promotions />} />
              </Route>

              <Route element={<ProfileLayout />}>
                <Route path='/brands' element={<Brands />} />
                <Route path='/users' element={<Users />} />
                <Route path='/subscriptions' element={<Subscriptions />} />
                {/* Define the logout route or handle the logout functionality differently */}
              </Route>

              <Route path='providers'>
                <Route path='create' element={<CreateProvider />} />
                <Route path=':id' element={<ProviderSettings />} />
              </Route>

              <Route path='create_admin' element={<AddAdmin />} />

              <Route path='home/foodics-success' element={<FoodicsSuccess />} />
              <Route path='foodics-success' element={<FoodicsSuccess />} />
              <Route index element={<Navigate to='/locations' replace />} />
            </Route>
            <Route
              path='subscriptions/subscriptionCancelled'
              element={<UnkownRouteHandler to='/locations' />}
            />
            <Route
              path='subscriptions/subscriptionCreated'
              element={<UnkownRouteHandler to='/locations' />}
            />
          </Routes>
        </RTL>
      </ThemeProvider>
    </CacheProvider>
  ) : null
}
