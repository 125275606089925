import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import promotionsStore from 'src/store/promotions'
import providerLocationsStore from 'src/store/providerLocations'
import { ProviderLocationId } from 'src/models/providerLocations'
import { Promotion } from 'src/models/promotions'
import { useTranslation } from 'react-i18next'
import Header from './Header'
import Map from './Map'
import List from './List'
import { Spinner } from 'src/components/index'

const PromotionsPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'promotions_page' })

  const [view, setView] = useState('grid')

  const list = useSelector(promotionsStore.selectors.list)

  const isFetching = useSelector(promotionsStore.selectors.isFetching)

  const providerLocations = useSelector(providerLocationsStore.selectors.list)

  useEffect(() => {
    dispatch(promotionsStore.actions.fetch.started())
  }, [])

  return (
    <Box px={6} pt={2.5} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Header view={view} setView={setView} totalCount={list.length} />
      {view === 'grid' ? <List items={list} /> : <Map items={list} />}
      <Spinner loading={isFetching && !list.length} />
    </Box>
  )
}

export default PromotionsPage
