import React from 'react'
import { Button, Box } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import FilesDropzone from 'src/components/FilesDropzone'
import { InputLabelWrapper } from 'src/components/Base/InputWithLabel'

const PhotoUploadComponent = () => {
  return (
    <InputLabelWrapper label='Upload your photos to promotion gallery (optional)'>
      <FilesDropzone uploadType={'promotion-image'} />
    </InputLabelWrapper>
  )
}

export default PhotoUploadComponent
