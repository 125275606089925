import React from 'react'

import useSignUpStore from './useSignUpStore'
import { Rany18px700 } from 'src/components/Typography'
import Step1 from './Step1'
import Step2 from './Step2'

export const FormComponent = () => {
  const { draft, errors, updateDraft, handleInputChange } = useSignUpStore()

  return (
    <>
      <Rany18px700 mt={2} style={{ textAlign: 'center' }}>
        Is all the information correct?
      </Rany18px700>
      <Step1 />
      <Step2 />
    </>
  )
}

export default FormComponent
