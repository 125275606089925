import { Promotion } from 'src/models/promotions'
import actionCreatorFactory from 'typescript-fsa'
import { PromotionDraftUpdate, CreatePromotionResult, PromotionsValidationErrors } from './types'
import { UploadFileResult } from 'src/models/uploads'

// Actions
const actionCreator = actionCreatorFactory('createPromotion')

export const updateDraft = actionCreator<PromotionDraftUpdate>('UPDATE_DRAFT')
// prettier-ignore
export const create = actionCreator.async<string | void, CreatePromotionResult, Error>('CREATE')
export const uploadImage = actionCreator.async<File, UploadFileResult, Error>('UPLOAD_IMAGE')
export const dismissError = actionCreator<void>('DISMISS_ERROR')
export const edit = actionCreator<Promotion>('EDIT')
export const setValidationsErrors = actionCreator<PromotionsValidationErrors>('SET_FORM_ERROS')
