import React, { useState } from 'react'
import { Box } from '@mui/material'
import PhoneNumberInput from 'src/components/PhoneNumberInput'
import useSignUpStore from './useSignUpStore'

import InputWithLabel, { InputLabelWrapper } from 'src/components/Base/InputWithLabel'
interface Props {}
const ContactForm: React.FC<Props> = () => {
  const { draft, errors, updateDraft, handleInputChange } = useSignUpStore()

  const handlePhone = (value: string) => updateDraft('phoneNumber', value)

  return (
    <Box component='form' noValidate autoComplete='off'>
      <InputWithLabel
        required
        id='fullName'
        name='fullName'
        label='Full name'
        // label='Full Name'
        placeholder='Enter your full name'
        fullWidth
        error={!!errors.fullName}
        helperText={errors.fullName || ' '}
        onChange={handleInputChange}
        value={draft.fullName}
      />

      <InputWithLabel
        label='Email'
        required
        id='email'
        name='email'
        //  label='Email'
        placeholder='Enter your email'
        fullWidth
        error={!!errors.email}
        helperText={errors.email || ' '}
        onChange={handleInputChange}
        value={draft.email}
      />

      <InputLabelWrapper
        helperText={errors.phoneNumber || ' '}
        error={!!errors.phoneNumber}
        id='phone-number'
        label='Phone number'
      >
        <PhoneNumberInput defaultValue={draft.phoneNumber} onChangePhone={handlePhone} />
      </InputLabelWrapper>
    </Box>
  )
}

export default ContactForm
