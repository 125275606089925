import actionCreatorFactory from 'typescript-fsa'
import { LocationDraftUpdate, LocationValidationErrors } from './types'
import { ProviderLocation } from 'src/models/providerLocations'
import { UploadFileResult } from 'src/models/uploads'

// Actions
const actionCreator = actionCreatorFactory('createLocation')

export const updateDraft = actionCreator<LocationDraftUpdate>('UPDATE_DRAFT')
export const edit = actionCreator<ProviderLocation>('EDIT')

// prettier-ignore
export const createLocation = actionCreator.async<string | void, ProviderLocation, Error>('CREATE')
export const uploadImage = actionCreator.async<File, UploadFileResult, Error>('UPLOAD_IMAGE')
export const dismissError = actionCreator<void>('DISMISS_ERROR')
export const setValidationsErrors = actionCreator<LocationValidationErrors>('SET_FORM_ERROS')
