import React, { useState } from 'react'
import { IconButton } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useTheme } from '@mui/material/styles'

const Marker = ({
  id,
  text,
  status,
  selected,
  setSelected
}: {
  id: string
  text?: string
  status: string
  selected: boolean
  lat: number
  lng: number
  setSelected: (id: string) => void
}) => {
  const handleClick = () => {
    setSelected(id) // Pass the id to the setSelected function
  }

  let backgroundColor
  let color
  switch (status) {
    case 'declined':
      backgroundColor = 'rgba(228, 33, 33, 0.2)' // #E42121
      color = 'rgba(228, 33, 33, 1)'
      break
    case 'draft':
      backgroundColor = 'rgba(255, 198, 0, 0.2)'
      color = 'rgba(255, 198, 0, 1)' // #FFC600
      break
    case 'live':
      backgroundColor = 'rgba(90, 186, 26,  0.2)'
      color = 'rgba(90, 186, 26, 1)' // #5ABA1A
      break
    default: // #5ABA1A
      backgroundColor = 'rgba(90, 186, 26,  0.2)'
      color = 'rgba(90, 186, 26, 1)' // #5ABA1A
  }

  return (
    <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}>
      <IconButton
        onClick={handleClick}
        style={{
          backgroundColor: selected ? backgroundColor : 'transparent',
          color: color
        }}
      >
        <LocationOnIcon style={{ fontSize: selected ? '42px' : '32px' }} />
      </IconButton>
      {text && <span>{text}</span>}
    </div>
  )
}

export default Marker
