import React from 'react'
import { Box, List, ListItem, ListItemText } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import authStore from 'src/store/auth'

// Function to dynamically generate the style for a NavLink based on its active state
const activeStyle = ({ isActive }: { isActive: boolean }) => ({
  fontFamily: 'Rany, sans-serif',
  fontWeight: isActive ? 'bold' : '400', // Active item is bold
  color: isActive ? '#292B2A' : '#95A19C', // Active item is black, inactive is grey
  textDecoration: 'none',
  fontSize: '40px',
  padding: '8px 16px', // Consistent padding for all items
  display: 'block' // Make the link fill the list item for better click area
})

const logoutStyle = {
  color: '#E42121', // Red color for the logout link
  textDecoration: 'none',
  padding: '8px 16px', // Consistent padding for all items
  display: 'block' // Make the link fill the list item for better click area
}

const Sidebar = () => {
  const dispatch = useDispatch()

  const logout = () => dispatch(authStore.actions.logout({}))

  return (
    <Box
      sx={{
        width: '240px',
        flexShrink: 0,
        p: 2,
        height: '100vh',
        //borderRight: '1px solid #eee',
        '& .MuiListItem-root': {
          padding: 0 // Remove padding from list items to control via NavLink
        },
        '& .MuiListItemText-primary': {
          fontSize: '1.2rem'
        }
      }}
    >
      <List>
        <ListItem disablePadding>
          <NavLink to='/brands' style={activeStyle}>
            <ListItemText primary='Brand information' />
          </NavLink>
        </ListItem>
        <ListItem disablePadding>
          <NavLink to='/users' style={activeStyle}>
            <ListItemText primary='Users management' />
          </NavLink>
        </ListItem>
        <ListItem disablePadding>
          <NavLink to='/subscriptions' style={activeStyle}>
            <ListItemText primary='Subscriptions' />
          </NavLink>
        </ListItem>
        <ListItem onClick={logout} disablePadding>
          <ListItemText style={logoutStyle} primary='Log out' />
        </ListItem>
      </List>
    </Box>
  )
}

export default Sidebar
