import { PromotionsResponse, Promotion, PromotionId } from 'src/models/promotions'
import { ProviderLocationId } from 'src/models/providerLocations'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { Action } from 'typescript-fsa'
import { UpdateStatusPayload } from './types'
import { fetch, fetchAll, remove, updateStatus } from './actions'
import { API } from 'src/data'
import { selectProvider } from '../auth/actions'
import { listFilters } from './selectors'

export default function createRootSaga() {
  function* fetchPromotionsSaga({ payload }: Action<void>): SagaIterator {
    try {
      const params = yield select(listFilters)
      const result: PromotionsResponse = yield call([API, API.readAllPromotions], params)
      yield put(fetchAll(result))
    } catch (error) {
      if (error instanceof Error) yield put(fetch.failed({ params: payload, error }))
    }
  }

  function* removeLocationSaga({ payload }: Action<ProviderLocationId>): SagaIterator {
    try {
      // const locationId = yield select(selectedLocationId)
      yield call([API, API.deletePromotion], payload)
      yield put(remove.done({ params: payload }))
    } catch (error) {
      if (error instanceof Error) yield put(remove.failed({ error, params: payload }))
    }
  }

  function* updateStatusSaga({ payload }: Action<UpdateStatusPayload>): SagaIterator {
    try {
      const { id, status } = payload

      const result: Promotion = yield call(
        //@ts-ignore
        [API, API.updatePromotion],
        {
          status
        },
        id
      )
      yield put(updateStatus.done({ params: payload, result }))
    } catch (error) {
      if (error instanceof Error) yield put(updateStatus.failed({ error, params: payload }))
    }
  }

  return [
    takeLatest(fetch.started, fetchPromotionsSaga),
    takeLatest(remove.started, removeLocationSaga),
    takeLatest(updateStatus.started, updateStatusSaga)
  ]
}
