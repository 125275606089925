import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Provider } from 'src/models/auth'
import Box from '@mui/material/Box'
import { foodicsConnectUrl } from 'src/config/index'
import { PrimaryButton } from '..'
import { InputLabel, Text14pxPoppins, Text16pxRany500 } from '../Typography'

interface FoodicsNotConnectedProps {
  currentProvider: Provider | null
  brandId?: string
}

export const FoodicsNotConnected: FC<FoodicsNotConnectedProps> = ({ brandId, currentProvider }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'foodics_settings' })

  const stateValue = brandId ? `b${brandId}` : `p${currentProvider?.id}`

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Text14pxPoppins>{t('not_connected')}</Text14pxPoppins>
      <PrimaryButton
        sx={{
          ml: 2
        }}
        variant='contained'
        // TODO: Use client id from env
        href={`${foodicsConnectUrl}&state=${stateValue}`}
        //@ts-ignore
        target='_blank'
        //@ts-ignore
        rel='noreferrer'
        disableElevation
      >
        {t('connect_foodics')}
      </PrimaryButton>
    </Box>
  )
}
