import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography, Grid, SxProps, Theme, Container, styled, Link } from '@mui/material'
import { ReactNode } from 'react'
import { calculateResponsiveFontSize } from 'src/theme/index'

interface ReusableComponentProps {
  icon: ReactNode // Define the type for the icon
  title: string // Title text
  subtitle: string // Subtitle text
  children: ReactNode // Children components for the right side
}

const containerSx: SxProps<Theme> = {
  backgroundColor: 'grey.200',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const boxSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  alignItems: 'center',
  height: '100%'
}

const titleSx: SxProps<Theme> = {
  fontFamily: 'Rany',
  color: '#292B2A',
  fontSize: '1.125rem', // Adjust based on your calculateResponsiveFontSize or similar function
  fontWeight: 700,
  lineHeight: '1.5rem', // Same as above
  letterSpacing: '0px',
  mt: 8
}

const subtitleSx: SxProps<Theme> = {
  fontFamily: 'Poppins',
  fontWeight: 400,
  color: '#78827E',
  textAlign: 'center',
  lineHeight: '1.3rem', // Adjust with your responsive font size calculation
  fontSize: '0.875rem', // Same as above
  letterSpacing: '0em',
  mt: 2
}

const AuthLayout: React.FC<ReusableComponentProps> = ({ icon, title, subtitle, children }) => {
  const [imageWidth, setImageWidth] = useState(0)
  const imageRef = useRef(null)

  useEffect(() => {
    if (imageRef.current) {
      //@ts-ignore
      setImageWidth(imageRef.current.offsetWidth)
    }
  }, [])

  return (
    <Grid container sx={{ minHeight: '100vh' }}>
      {/* Left Section with specific styles */}
      <Grid item xs={12} md={6} sx={containerSx}>
        <Box sx={boxSx}>
          <Box ref={imageRef}>{icon}</Box>
          <Typography variant='h5' sx={titleSx}>
            {title}
          </Typography>
          <Typography
            variant='subtitle1'
            sx={{
              ...subtitleSx, // Your existing styles
              width: imageWidth, // Set the width to match the image
              maxWidth: '100%' // Ensure it doesn't exceed the parent container's width
            }}
          >
            {subtitle}
          </Typography>
        </Box>
      </Grid>

      {/* Right Section - Dynamic Content */}
      <Grid item xs={12} pb={8} pt={8} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Container maxWidth='sm'>{children}</Container>
      </Grid>
    </Grid>
  )
}

export default AuthLayout

export const AuthButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
  justifyContent: 'space-between', // Change this to 'center' to center the buttons
  alignItems: 'center',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(2)
}))

export const AuthTextWithLink = ({
  text,
  linkHref,
  linkText
}: {
  text: string
  linkHref: string
  linkText: string
}) => {
  return (
    <Typography
      sx={{
        alignSelf: 'center',
        fontFamily: 'Rany',
        color: '#292B2A',
        mt: 2,
        fontSize: `${calculateResponsiveFontSize(0.875)}rem`,
        fontWeight: 400,
        lineHeight: `${calculateResponsiveFontSize(1.375)}rem`,
        letterSpacing: '0px',
        textAlign: 'center'
      }}
      variant='body2'
    >
      {text}{' '}
      <Link
        href={linkHref}
        underline='hover'
        sx={{
          color: '#292B2A',
          fontWeight: 700
        }}
      >
        {linkText}
      </Link>
    </Typography>
  )
}
