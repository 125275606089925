import React from 'react'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { TextField, InputAdornment, Typography, Box, styled } from '@mui/material'

import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { calculateResponsiveFontSize } from 'src/theme/index'

interface CounterProps {
  isAnnual: boolean
  value: number
  onChange: (value: number) => void
  min?: number
  max?: number
  step?: number
  name?: string
  label?: string
  disabled?: boolean
  error?: boolean
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}

const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max)

const Counter: React.FC<CounterProps> = ({
  value,
  isAnnual,
  onChange,
  min = 1,
  max = 10000,
  step = 1,
  name,
  label,
  disabled,
  error,
  onBlur
}) => {
  const { t } = useTranslation('translation')

  const setValue = (newValue: number) => {
    if (onChange) {
      onChange(newValue !== undefined ? clamp(newValue, min, max) : newValue)
    }
  }
  const onIncrement = () => setValue(value !== undefined ? value + step : min)
  const onDecrement = () => setValue(value !== undefined ? value - step : min)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) {
      return
    }
    const value = e.target.value ? parseInt(e.target.value, 10) : undefined
    onChange(value!)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Typography
        style={{
          fontFamily: 'Poppins, Arial',
          fontWeight: 400,
          fontSize: `${calculateResponsiveFontSize(0.7)}rem`,
          color: isAnnual ? 'white' : 'black'
        }}
      >
        {t('subscription_plan.number_of_locations')}
      </Typography>
      <TextField
        name={name}
        sx={{
          borderRadius: 1,
          backgroundColor: 'white'
        }}
        inputProps={{
          style: { textAlign: 'center' },
          min,
          max
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <div>
                <IconButton
                  size='small'
                  style={{ color: 'black', padding: 0 }}
                  onClick={onDecrement}
                  disabled={disabled}
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <div>
                <IconButton
                  size='small'
                  style={{ color: 'black', padding: 0 }}
                  onClick={onIncrement}
                  disabled={disabled}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </InputAdornment>
          )
        }}
        value={value !== undefined ? value : ''}
        onChange={handleChange}
        label={label}
        type='number'
        disabled={disabled}
        error={error}
        onBlur={onBlur}
      />
    </Box>
  )
}

export default React.memo(Counter)
