import React, { useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import Item from './Item'
import { ProviderLocation } from 'src/models/providerLocations'
import EmptyPlaceholder from 'src/components/List/EmptyPlaceholder'
import EmptyLocations from 'src/assets/svg/EmptyLocations'
import EmptySubscriptions from 'src/assets/svg/EmptySubscriptions'

import subscriptions from 'src/store/subscriptions'

import { useSelector } from 'react-redux'

type Props = {
  items: ProviderLocation[]
}

const LocationstList = ({ items }: Props) => {
  const subscriptionStatus = useSelector(subscriptions.selectors.subscriptionStatus)

  if (!subscriptionStatus?.locationCreationAllowed && !items.length) {
    return (
      <EmptyPlaceholder
        icon={<EmptySubscriptions />}
        titleText={'You don’t have an available subscription'}
        subtitleText={
          'To add new location you should buy a subscription first by pressing the button below'
        }
        buttonText={'Buy a subscription'}
        hrefLink={'/plans'}
      />
    )
  }
  if (!items.length) {
    return (
      <EmptyPlaceholder
        icon={<EmptyLocations />}
        titleText={'You don’t have any locations'}
        subtitleText={'You can add your first location by pressing the button below'}
        buttonText={'Add first location'}
        hrefLink={'create'}
      />
    )
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {items.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Item item={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default LocationstList
