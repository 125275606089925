import * as React from 'react'

import { InputLabelWrapper } from 'src/components/Base/InputWithLabel'
import providerLocationsStore from 'src/store/providerLocations'
import brandsStore from 'src/store/brands'
import adminsStore from 'src/store/admins'

import { useSelector } from 'react-redux'
import { List, Box } from '@mui/material'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'

import Typography from '@mui/material/Typography'
import BrandListItem from './BrandListItem'
import LocationListItem from './LocationListItem'
import LocationList from 'src/pages/Users/LocationList'

interface LocationListProps {
  checkedBrands: string[]
  checkedLocations: string[]
  setCheckedBrands: React.Dispatch<React.SetStateAction<string[]>>
  setCheckedLocations: React.Dispatch<React.SetStateAction<string[]>>
}

const BrandsComponent: React.FC<LocationListProps> = ({
  checkedLocations,
  checkedBrands,
  setCheckedBrands,
  setCheckedLocations
}) => {
  const brandList = useSelector(brandsStore.selectors.list)
  const list = useSelector(providerLocationsStore.selectors.list)
  const [expandedPanel, setExpandedPanel] = React.useState<string | null>(null)

  const handleExpandClick = (panel: string | null) => {
    setExpandedPanel(expandedPanel !== panel ? panel : null)
  }

  const preventDefault = (event: any) => {
    event.stopPropagation()
  }

  const handleToggle = (id: string) => {
    const currentIndex = checkedBrands.indexOf(id)
    const newChecked = [...checkedBrands]

    if (currentIndex === -1) {
      newChecked.push(id) // Add to the list if not already added
    } else {
      newChecked.splice(currentIndex, 1) // Remove from the list if already added
    }

    setCheckedBrands(newChecked)
  }

  const handleLocationToggle = (id: string) => {
    const currentIndex = checkedLocations.indexOf(id)
    const newChecked = [...checkedLocations]

    if (currentIndex === -1) {
      newChecked.push(id) // Add to the list if not already added
    } else {
      newChecked.splice(currentIndex, 1) // Remove from the list if already added
    }

    setCheckedLocations(newChecked)
  }

  const filterLocations = list?.filter(item => !checkedBrands.includes(item.brandId!))

  return (
    <div>
      <InputLabelWrapper label='Brands'>
        {brandList?.map(brand => (
          <Accordion
            key={brand.id}
            expanded={expandedPanel === `panel${brand.id}`}
            onChange={preventDefault} // Normally toggles expansion, but we prevent default
            sx={{
              boxShadow: 'none', // Remove shadow
              '&:before': {
                // Remove default before element (used for borders)
                display: 'none'
              },
              '&.Mui-expanded': {
                margin: '0 !important' // Remove margin applied when expanded
              },
              '&:not(:last-child)': {
                borderBottom: 0 // Remove border between accordion items
              }
            }}
          >
            <BrandListItem
              brand={brand}
              onToggle={handleToggle}
              onExpandClick={handleExpandClick}
              isChecked={checkedBrands.indexOf(brand.id) !== -1}
              isExpanded={expandedPanel === `panel${brand.id}`}
            />
            <AccordionDetails
              sx={{
                padding: '0 24px 24px' // Adjust padding as needed
              }}
            >
              <LocationList locations={brand.locations! || []} />
            </AccordionDetails>
          </Accordion>
        ))}
      </InputLabelWrapper>

      {filterLocations.length ? (
        <InputLabelWrapper label='Locations'>
          <Box sx={{ width: '100%', maxHeight: 'calc(50vh - 100px)', overflow: 'auto', ml: 1 }}>
            <List>
              {filterLocations.map((location, index) => (
                <LocationListItem
                  key={location.id}
                  location={location}
                  onToggle={handleLocationToggle}
                  isChecked={checkedLocations?.indexOf(location?.id!) !== -1}
                />
              ))}
            </List>
          </Box>
        </InputLabelWrapper>
      ) : null}
    </div>
  )
}

export default BrandsComponent
