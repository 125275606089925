import React from 'react'
import TextField, { TextFieldProps, StandardTextFieldProps } from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

// Extends the TextFieldProps to include a success prop
interface CustomInputProps extends StandardTextFieldProps {
  success?: boolean
}

// Use the styled API to override styles conditionally based on props
const StyledTextField = styled(TextField)<CustomInputProps>(({ theme, error, success }) => ({
  '& .MuiOutlinedInput-root': {
    //fontSize: 18,
    //  lineHeight: 19,
    backgroundColor: '#F7F9F8', // Default background color
    width: '100%', // The width is now flexible, based on the parent container
    '& fieldset': {
      borderColor: success ? 'green' : 'default',
      borderWidth: error ? '2px' : 'default',
      borderRadius: '8px'
    },
    '&:hover fieldset': {
      borderColor: success ? 'green' : theme.palette.primary.main
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
      animation: 'pulse 1s infinite'
    }
  },
  '@keyframes pulse': {
    '0%': {
      boxShadow: `0 0 0 0 ${theme.palette.primary.main}`
    },
    '100%': {
      boxShadow: `0 0 0 0 rgba(0, 0, 0, 0)`
    }
  }
}))

// The CustomInput component can now accept all the props from TextFieldProps
const CustomInput: React.FC<CustomInputProps> = ({ success, ...props }) => {
  // You can now pass a 'success' prop for success state styling
  return (
    <StyledTextField
      {...props} // Spread all other props to the TextField component
      InputProps={{
        ...props.InputProps, // Spread any custom InputProps from the parent
        readOnly: props.disabled
      }}
      // Apply conditional styles for success state
      sx={{
        ...props.sx, // Allow custom styles to be passed from the parent
        ...(props.error && {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#E42121'
            }
          }
        }),
        ...(success && {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'green'
            }
          }
        })
      }}
    />
  )
}

export default CustomInput
