import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import AccountCircle from '@mui/icons-material/AccountCircle'
import horizontalLogo from 'src/assets/logo-thehubapp-horizontal.svg'
import ProfileIcon from 'src/assets/svg/Profile'

import { styled, useTheme } from '@mui/material/styles'
import { NavLink, useLocation } from 'react-router-dom'
import { calculateResponsiveFontSize, resizePx } from 'src/theme'

const StyledNavLink = styled(NavLink)`
  font-family: 'Rany', sans-serif;
  font-size: ${calculateResponsiveFontSize(0.875)}rem;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none; // Remove underline from links
  color: inherit; // Use color from parent or explicitly set
  cursor: pointer;
  color: ${props => (props.theme.palette.text as any).gray60};
  transition: color 0.3s ease, font-weight 0.3s ease;

  &.active {
    font-weight: 700;
    color: ${props => props.theme.palette.primary.main};
  }

  &:hover {
    color: ${props => props.theme.palette.primary.dark};
  }
`

export default function MenuAppBar() {
  const location = useLocation()

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position='static'
        sx={{ backgroundColor: '#fff', color: '#000', boxShadow: 'none', pl: 2.8, pr: 3.5 }}
      >
        <Toolbar>
          {/* Insert your logo here */}
          <img src={horizontalLogo} alt='Logo' style={{ height: '40px', marginRight: '20px' }} />

          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Typography variant='subtitle1' component='div' ml={2}>
              <StyledNavLink to={'/locations'}>My locations</StyledNavLink>
            </Typography>

            <Typography variant='subtitle1' component='div' ml={3}>
              <StyledNavLink to={'/promotions'}>My promotions</StyledNavLink>
            </Typography>
            <Typography variant='subtitle1' component='div' ml={3}>
              <StyledNavLink to={'/plans'}>Subscriptions</StyledNavLink>
            </Typography>
          </Box>

          <NavLink key={'brands'} to={'/brands'}>
            {({ isActive }) => <ProfileIcon isActive={isActive} />}
          </NavLink>
          <Typography component='div' ml={1}>
            <StyledNavLink to={'/brands'}>My profile</StyledNavLink>
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
