import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  IconButton,
  FormGroup,
  FormControlLabel,
  TextField,
  Box,
  Button
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { TextInput } from 'src/components/index'
import { DayOfTheWeek } from 'src/models/promotions'
import usePromotionStore from '../usePromotionStore'

import {
  TimePeriod,
  dayAbbreviations,
  daysOfWeek,
  reverseTransformData,
  transformScheduleData
} from './utils'

const initialData = [{ id: Date.now(), startsAt: '07:30', endsAt: '17:30', selectedDays: [] }]
const ScheduleComponent: React.FC = () => {
  const { draft, updateDraft } = usePromotionStore()

  const init = draft?.discountSchedule ? reverseTransformData(draft?.discountSchedule) : initialData
  const [timePeriods, setTimePeriods] = useState<TimePeriod[]>(init)

  useEffect(() => {
    updateDraft('discountSchedule', transformScheduleData(timePeriods))
  }, [timePeriods])

  const handleDayChange = (day: DayOfTheWeek, periodId: number) => {
    setTimePeriods(current =>
      current.map(period => {
        if (period.id === periodId) {
          if (period.selectedDays.includes(day)) {
            return { ...period, selectedDays: period.selectedDays.filter(d => d !== day) }
          } else {
            return { ...period, selectedDays: [...period.selectedDays, day] }
          }
        }
        return period
      })
    )
  }

  const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>, periodId: number) => {
    const newStartTime = event.target.value
    setTimePeriods(current =>
      current.map(period =>
        period.id === periodId ? { ...period, startsAt: newStartTime } : period
      )
    )
  }

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>, periodId: number) => {
    const newEndTime = event.target.value
    setTimePeriods(current =>
      current.map(period => (period.id === periodId ? { ...period, endsAt: newEndTime } : period))
    )
  }

  const isDaySelected = (day: DayOfTheWeek, periodId: number) => {
    const period = timePeriods.find(period => period.id === periodId)
    return period?.selectedDays.includes(day) ?? false
  }

  const addTimePeriod = () => {
    setTimePeriods(current => [
      ...current,
      { id: Date.now(), startsAt: '08:00', endsAt: '19:00', selectedDays: [] }
    ])
  }

  const removeTimePeriod = (periodId: number) => {
    setTimePeriods(current => current.filter(period => period.id !== periodId))
  }

  return (
    <div>
      {timePeriods.map((period, index) => (
        <Box key={period.id} sx={{ marginBottom: 2 }}>
          <FormGroup row>
            <TextInput
              placeholder='Starts at'
              type='time'
              sx={{ width: 220 }}
              value={period.startsAt}
              onChange={(e: any) => handleStartTimeChange(e, period.id)}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 300 // 5 min
              }}
            />
            <TextInput
              placeholder='Ends at'
              type='time'
              sx={{ width: 220, ml: 4 }}
              value={period.endsAt}
              onChange={(e: any) => handleEndTimeChange(e, period.id)}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 300 // 5 min
              }}
            />
            {index > 0 && (
              <IconButton onClick={() => removeTimePeriod(period.id)} color='error'>
                <DeleteIcon />
              </IconButton>
            )}
          </FormGroup>
          <FormGroup sx={{ marginTop: 2 }} row>
            {daysOfWeek.map(day => (
              <FormControlLabel
                key={day}
                control={
                  <Checkbox
                    checked={isDaySelected(day, period.id)}
                    onChange={() => handleDayChange(day, period.id)}
                    name={day}
                    color='primary'
                  />
                }
                label={dayAbbreviations[day]}
              />
            ))}
          </FormGroup>
        </Box>
      ))}
      <Button variant='outlined' startIcon={<AddIcon />} onClick={addTimePeriod}>
        Add period
      </Button>
    </div>
  )
}

export default ScheduleComponent
