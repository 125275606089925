import { Box, Chip, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { SubscriptionPlan } from 'src/models/subscriptions'
import { calculateResponsiveFontSize } from 'src/theme/index'

// Styled Chip for the original price
const OriginalPriceChip = styled(Chip)({
  fontFamily: 'Rany, Arial', // Fallback to Arial if Rany isn't available
  textDecoration: 'line-through',
  fontWeight: '500',
  padding: '2px 12px',
  color: '#292B2A',
  fontSize: `${calculateResponsiveFontSize(0.85)}rem`
})

// Container for the price display
const PriceContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start'
})

type Props = {
  data: SubscriptionPlan
  isAnnual: boolean
}

const PriceDisplay = ({ data, isAnnual }: Props) => {
  const { intervalCount, interval } = data.recurring
  const { t } = useTranslation('translation')

  return (
    <PriceContainer>
      {data.discountPrice ? (
        <OriginalPriceChip
          style={{
            marginBottom: 2,
            backgroundColor: isAnnual ? '#FFFFFF' : '#E8ECEA'
          }}
          label={`$${data.price}`}
        />
      ) : null}
      <Typography
        variant='h4'
        component='div'
        style={{
          fontFamily: 'Rany, Arial', // Fallback to Arial if Rany isn't available
          fontSize: `${calculateResponsiveFontSize(2.4)}rem`,
          color: isAnnual ? 'white' : 'black',
          fontWeight: 700,
          letterSpacing: '0em',
          textAlign: 'left'
        }}
      >
        <span style={{ fontWeight: 'bold' }}> ${data.discountPrice || data.price}</span>
        <span
          style={{
            fontFamily: 'Poppins, Arial',
            fontWeight: 400,
            fontSize: `${calculateResponsiveFontSize(0.8)}rem`,
            marginLeft: '4px'
          }}
        >
          {'/ ' + t(`subscription_plan.price_${interval}`, { intervalCount })}
        </span>
      </Typography>
    </PriceContainer>
  )
}

export default PriceDisplay
