import actionCreatorFactory from 'typescript-fsa'
import { ProviderAdminCreate, Admin } from 'src/models/admins'
import { Brand, BrandCreate, BrandId, BrandsListResponse } from 'src/models/brands'

// Actions
const actionCreator = actionCreatorFactory('brands')

export const updateDraft = actionCreator<BrandCreate>('UPDATE_DRAFT')
export const clearDraft = actionCreator<void>('CLEAR_DRAFT')

export const setDataAfterSignUp = actionCreator<BrandCreate>('CREATE_AFTER_SIGN_UP')

export const fetch = actionCreator.async<void, BrandsListResponse, Error>('FETCH')

export const create = actionCreator.async<void, Brand, Error>('ADD')
export const edit = actionCreator<Brand>('EDIT')

export const remove = actionCreator.async<BrandId, void, Error>('REMOVE')
export const dismissError = actionCreator<void>('DISMISS_ERROR')
