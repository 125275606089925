import React from 'react'
import { Stepper, Step, StepLabel, StepConnector, styled } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

// Custom connector

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '&.MuiStepConnector-root': {
    marginLeft: theme.spacing(2),
    //  width: theme.spacing(8), // set your desired max-width here
    marginRight: theme.spacing(2)
  },
  '&.Mui-active': {
    '& .MuiStepConnector-line': {
      borderColor: '#04BC79'
    }
  },
  '&.Mui-completed': {
    '& .MuiStepConnector-line': {
      borderColor: '#04BC79'
    }
  },
  '& .MuiStepConnector-line': {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#E0E5E3',
    borderWidth: 3
  }
}))

// Custom step icon using styled component
const CustomStepIconRoot = styled('div')<{
  ownerState: { active?: boolean; completed?: boolean }
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#E0E5E3',
  zIndex: 1,
  color: 'black',
  width: theme.spacing(4),
  height: theme.spacing(4),
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#04BC79',
    color: 'white'
  }),
  ...(ownerState.completed && {
    backgroundColor: '#04BC79',
    color: 'white'
  })
}))

function CustomStepIcon(props: { active: boolean; completed: boolean; icon: React.ReactNode }) {
  const { active, completed, icon } = props

  //{completed ? <CheckCircleIcon /> : icon}

  return <CustomStepIconRoot ownerState={{ completed, active }}>{icon}</CustomStepIconRoot>
}

interface Props {
  stepsCount: number
  activeStep: number
}
export default function CustomStepper({ stepsCount, activeStep }: Props) {
  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<CustomConnector />}>
      {[...Array(stepsCount)]
        .map((_, i) => i)
        .map((item, index) => (
          <Step key={item + index}>
            <StepLabel key={item + index} StepIconComponent={CustomStepIcon}>
              {''}
            </StepLabel>
          </Step>
        ))}
    </Stepper>
  )
}
