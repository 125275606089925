import { Brand, BrandCreate, BrandsListResponse } from 'src/models/brands'

export enum ActiveOperation {
  create = 1,
  update = 2,
  remove = 3,
  fetch = 4
}

export interface StateBrands {
  list?: BrandsListResponse
  draft: BrandCreate
  signUpDraft?: BrandCreate
  activeOperation?: ActiveOperation
  navigateId?: string
  error?: Error
}
