import { resizePx } from 'src/theme'

const Icon = () => (
  <svg
    width={resizePx(306)}
    height={resizePx(190)}
    viewBox='0 0 306 190'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_389_20027)'>
      <path
        d='M0.5 189.373C0.5 189.666 0.735768 189.901 1.02936 189.901H304.971C305.264 189.901 305.5 189.666 305.5 189.373C305.5 189.081 305.264 188.846 304.971 188.846H1.02936C0.735768 188.846 0.5 189.081 0.5 189.373Z'
        fill='#3F3D56'
      />
      <path
        d='M126.604 189.767H31.5993C27.8053 189.767 24.7188 186.691 24.7188 182.909V6.85835C24.7188 3.07661 27.8053 0 31.5993 0H126.604C130.398 0 133.485 3.07661 133.485 6.85835V182.909C133.485 186.691 130.398 189.767 126.604 189.767ZM31.5993 0.886764C28.296 0.886764 25.6084 3.56567 25.6084 6.85835V182.909C25.6084 186.202 28.296 188.881 31.5993 188.881H126.604C129.907 188.881 132.595 186.202 132.595 182.909V6.85835C132.595 3.56567 129.907 0.886764 126.604 0.886764H31.5993Z'
        fill='#3F3D56'
      />
      <path
        d='M296.986 89.521C296.61 88.8898 294.986 89.5656 294.687 88.9928C294.389 88.422 295.899 87.5523 296.761 85.5655C296.917 85.2072 297.897 82.9494 297.171 82.2528C295.796 80.9322 289.503 86.3818 287.301 84.5984C286.817 84.2071 286.48 83.4257 285.017 82.1572C284.435 81.6526 284.083 81.4247 283.742 81.5095C283.259 81.6298 283.221 82.2745 282.703 83.542C281.925 85.4429 281.466 85.3281 281.013 86.8447C280.677 87.9706 280.754 88.6251 280.358 88.7526C279.799 88.9331 279.283 87.7389 278.574 87.8459C277.85 87.955 277.413 89.3454 277.274 90.3864C277.015 92.34 277.735 93.6291 278.134 94.9846C278.567 96.4577 278.825 98.6979 277.823 101.869L267.914 129.699C270.102 123.988 276.436 108.334 279.098 102.517C279.866 100.838 280.715 99.1343 282.457 98.4621C284.134 97.8149 286.437 98.2013 289.374 97.4961C289.717 97.4138 290.668 97.1724 290.788 96.6145C290.887 96.1532 290.327 95.8957 290.413 95.4637C290.529 94.8842 291.63 94.8771 292.982 94.2085C293.936 93.737 294.531 93.1764 295.042 92.6942C295.196 92.5488 297.486 90.3613 296.986 89.521Z'
        fill='#F2F2F2'
      />
      <path
        d='M258.001 104.171C257.582 104.173 257.446 105.162 257.078 105.143C256.711 105.124 256.718 104.135 255.989 103.14C255.857 102.96 255.028 101.829 254.476 101.983C253.429 102.275 254.297 106.926 252.783 107.489C252.451 107.612 251.969 107.552 250.922 107.902C250.506 108.041 250.292 108.147 250.235 108.338C250.155 108.61 250.462 108.814 250.936 109.434C251.648 110.362 251.459 110.554 252.075 111.213C252.532 111.702 252.877 111.854 252.825 112.084C252.753 112.41 252.016 112.317 251.864 112.695C251.709 113.081 252.267 113.696 252.74 114.064C253.627 114.754 254.47 114.774 255.252 114.97C256.103 115.183 257.28 115.704 258.553 117.109L269.397 129.986C267.216 127.268 261.336 119.653 259.239 116.672C258.634 115.811 258.04 114.905 258.212 113.859C258.377 112.851 259.232 111.836 259.732 110.196C259.791 110.005 259.946 109.469 259.706 109.25C259.508 109.068 259.219 109.268 259.031 109.101C258.779 108.877 259.093 108.337 259.154 107.482C259.198 106.879 259.093 106.427 259.003 106.038C258.976 105.92 258.559 104.169 258.001 104.171Z'
        fill='#F2F2F2'
      />
      <path
        d='M266.366 106.044L266.113 103.797L266.229 103.626C266.763 102.838 267.035 102.069 267.038 101.339C267.039 101.223 267.033 101.107 267.028 100.988C267.006 100.52 266.979 99.9371 267.283 99.2578C267.454 98.8791 267.934 98.0024 268.656 98.1109C268.85 98.1381 268.997 98.227 269.109 98.3312C269.125 98.3051 269.142 98.2791 269.16 98.2509C269.385 97.9091 269.563 97.7626 269.734 97.6216C269.866 97.5131 270.002 97.4013 270.216 97.1365C270.309 97.0205 270.382 96.9185 270.444 96.8327C270.63 96.5734 270.874 96.2642 271.304 96.2696C271.763 96.2913 272.004 96.67 272.164 96.9206C272.448 97.3677 272.577 97.6899 272.663 97.9037C272.694 97.9818 272.729 98.0697 272.747 98.0968C272.895 98.3138 274.099 98.1109 274.552 98.0371C275.57 97.8679 276.451 97.7214 276.814 98.3616C277.075 98.8194 276.881 99.4249 276.223 100.209C276.018 100.454 275.799 100.639 275.606 100.784C275.766 100.88 275.91 101.026 275.968 101.256C276.105 101.801 275.636 102.347 274.573 102.881C274.309 103.014 273.953 103.192 273.455 103.278C273.221 103.318 273.009 103.328 272.829 103.333C272.825 103.437 272.8 103.55 272.737 103.666C272.55 104.008 272.161 104.171 271.576 104.132C270.931 104.098 270.401 103.976 269.933 103.868C269.524 103.775 269.172 103.696 268.895 103.714C268.382 103.755 267.987 104.164 267.524 104.695L266.366 106.044Z'
        fill='#F2F2F2'
      />
      <path
        d='M267.537 92.6632L265.42 93.4767L265.226 93.4086C264.326 93.0936 263.51 93.0263 262.801 93.2084C262.688 93.2374 262.577 93.2721 262.463 93.3073C262.014 93.4472 261.455 93.6212 260.719 93.4996C260.308 93.4309 259.336 93.1904 259.258 92.467C259.235 92.2728 259.284 92.1086 259.357 91.9746C259.327 91.9654 259.298 91.9558 259.266 91.9456C258.877 91.8156 258.69 91.6811 258.509 91.5516C258.371 91.4521 258.228 91.3493 257.917 91.2107C257.78 91.1499 257.663 91.1054 257.564 91.0679C257.265 90.9537 256.903 90.797 256.799 90.381C256.704 89.9326 257.01 89.6046 257.213 89.3873C257.575 88.9994 257.855 88.7933 258.04 88.6567C258.108 88.607 258.185 88.5506 258.206 88.5264C258.379 88.3286 257.877 87.2197 257.691 86.8013C257.268 85.8624 256.902 85.0499 257.432 84.5375C257.81 84.1705 258.447 84.2033 259.375 84.6393C259.664 84.7752 259.899 84.9386 260.09 85.0885C260.141 84.9095 260.247 84.7338 260.456 84.6193C260.949 84.3489 261.598 84.6633 262.386 85.5528C262.582 85.7735 262.845 86.071 263.055 86.5294C263.153 86.7454 263.216 86.9466 263.267 87.1194C263.369 87.0967 263.485 87.0917 263.614 87.1236C263.993 87.2169 264.249 87.5503 264.36 88.1249C264.491 88.7548 264.507 89.2975 264.521 89.7761C264.534 90.1939 264.547 90.5535 264.635 90.8154C264.805 91.2993 265.302 91.5765 265.934 91.8891L267.537 92.6632Z'
        fill='#F2F2F2'
      />
      <path
        d='M266.366 86.0437L266.113 83.7966L266.229 83.6262C266.763 82.8385 267.035 82.0692 267.038 81.3389C267.039 81.2228 267.033 81.1067 267.028 80.9885C267.006 80.5197 266.979 79.9371 267.283 79.2578C267.454 78.8791 267.934 78.0024 268.656 78.1109C268.85 78.1381 268.997 78.227 269.109 78.3312C269.125 78.3052 269.142 78.2791 269.16 78.2509C269.385 77.9091 269.563 77.7626 269.734 77.6216C269.866 77.5131 270.002 77.4013 270.216 77.1366C270.309 77.0205 270.382 76.9185 270.444 76.8327C270.63 76.5734 270.874 76.2642 271.304 76.2696C271.763 76.2913 272.004 76.67 272.164 76.9206C272.448 77.3677 272.577 77.6899 272.663 77.9037C272.694 77.9818 272.729 78.0697 272.747 78.0968C272.895 78.3138 274.099 78.1109 274.552 78.0371C275.57 77.8679 276.451 77.7214 276.814 78.3616C277.075 78.8195 276.881 79.4249 276.223 80.2094C276.018 80.4535 275.799 80.6391 275.606 80.7845C275.766 80.88 275.91 81.0264 275.968 81.2565C276.105 81.8012 275.636 82.347 274.573 82.8808C274.309 83.0143 273.953 83.1922 273.455 83.2779C273.221 83.3181 273.009 83.3279 272.829 83.3333C272.825 83.4374 272.8 83.5503 272.737 83.6664C272.55 84.0082 272.161 84.1709 271.576 84.1319C270.931 84.0982 270.401 83.9756 269.933 83.8682C269.524 83.7749 269.172 83.6957 268.895 83.7141C268.382 83.7554 267.987 84.1644 267.524 84.695L266.366 86.0437Z'
        fill='#F2F2F2'
      />
      <path
        d='M268.671 129.662L268.199 129.367L267.648 129.429C267.639 129.378 267.609 129.261 267.564 129.078C267.318 128.073 266.566 125.015 265.939 120.2C265.501 116.839 265.254 113.383 265.203 109.927C265.151 106.466 265.316 103.845 265.447 101.739C265.547 100.151 265.668 98.638 265.787 97.1645C266.102 93.2279 266.4 89.5095 266.178 85.3928C266.129 84.4738 266.027 82.5609 264.905 80.5253C264.255 79.3448 263.359 78.2966 262.242 77.4112L262.937 76.541C264.169 77.5197 265.161 78.6807 265.883 79.9915C267.126 82.2484 267.238 84.3328 267.292 85.3343C267.517 89.5236 267.216 93.2779 266.897 97.2524C266.78 98.7205 266.659 100.227 266.56 101.809C266.429 103.895 266.267 106.493 266.317 109.909C266.367 113.324 266.612 116.738 267.044 120.057C267.663 124.812 268.404 127.824 268.647 128.813C268.777 129.342 268.804 129.452 268.671 129.662Z'
        fill='#F2F2F2'
      />
      <path
        d='M260.196 79.1772C260.151 79.1772 260.104 79.1762 260.058 79.1729C259.108 79.1219 258.229 78.5555 257.444 77.49C257.076 76.9887 256.888 76.4169 256.513 75.2765C256.454 75.1007 256.171 74.2088 255.999 72.9816C255.887 72.1798 255.901 71.8445 256.06 71.5483C256.237 71.2184 256.523 70.9884 256.846 70.8332C256.832 70.7269 256.843 70.6173 256.889 70.5055C257.079 70.0422 257.584 70.103 257.858 70.1323C257.996 70.1496 258.168 70.1724 258.354 70.1627C258.647 70.1486 258.805 70.0617 259.043 69.9315C259.271 69.8068 259.555 69.6516 259.975 69.5659C260.805 69.3934 261.5 69.6277 261.729 69.7048C262.935 70.1051 263.529 71.0394 264.217 72.1212C264.354 72.3382 264.825 73.1281 265.136 74.211C265.36 74.9922 265.328 75.3394 265.261 75.5976C265.124 76.1282 264.8 76.432 263.977 77.1026C263.119 77.8046 262.688 78.1562 262.317 78.3699C261.456 78.8658 260.915 79.1772 260.196 79.1772Z'
        fill='#F2F2F2'
      />
      <path d='M133.749 78.2598H24.4531V106.556H133.749V78.2598Z' fill='#17D48F' />
      <path d='M94.0057 0.443359H65.6172V189.324H94.0057V0.443359Z' fill='#17D48F' />
      <path
        opacity='0.2'
        d='M95.0554 104.425C95.2564 103.646 95.3762 102.836 95.4054 102.001L130.911 69.7698L100.393 54.9141L86.9435 90.6648C86.0241 90.4254 85.0647 90.285 84.0701 90.285C83.542 90.285 83.0265 90.3334 82.5176 90.4029L69.1661 54.9141L38.6484 69.7698L72.7587 100.734C72.7367 101.022 72.7147 101.31 72.7147 101.604C72.7147 102.58 72.8517 103.522 73.0848 104.425C52.1761 110.939 43.6164 130.608 43.6164 130.608L69.8758 151.83C68.3097 136.219 77.874 120.237 83.0446 112.871C83.3829 112.901 83.7239 112.922 84.0701 112.922C84.4163 112.922 84.7573 112.901 85.0956 112.871C90.2662 120.237 99.8305 136.219 98.2644 151.83L124.524 130.608C124.524 130.608 115.964 110.939 95.0554 104.425Z'
        fill='black'
      />
      <path
        d='M98.9097 144.183C98.6035 144.183 98.2948 144.117 98.0033 143.981C97.2662 143.638 96.8005 142.923 96.7877 142.115C96.5725 128.429 88.7434 114.997 84.0956 108.208C83.7852 107.755 83.2491 107.484 82.6614 107.484H82.6588C82.0495 107.484 81.5135 107.755 81.2031 108.208C76.5555 114.997 68.7264 128.429 68.511 142.115C68.4982 142.923 68.0325 143.638 67.2953 143.981C66.5508 144.328 65.692 144.223 65.0545 143.708L43.2283 126.068C42.4493 125.438 42.2205 124.335 42.6847 123.444C44.891 119.209 53.3849 104.935 70.0546 99.0658C70.7985 98.8039 71.2503 98.0656 71.153 97.2702C71.0989 96.826 71.0715 96.38 71.0715 95.9444C71.0726 95.4248 70.8734 94.9529 70.5251 94.6368L39.1465 66.1521C38.6261 65.6797 38.3751 64.983 38.4745 64.2885C38.5742 63.594 39.0114 62.9952 39.6439 62.6873L65.7695 49.97C66.3053 49.7089 66.9307 49.6857 67.4856 49.9052C68.0401 50.1252 68.4782 50.571 68.6878 51.1278L80.8013 83.3262C81.0513 83.9909 81.6925 84.4247 82.4203 84.4065C82.9692 84.394 83.5258 84.4256 84.0808 84.4975C84.864 84.6018 85.6031 84.1615 85.8772 83.4314L98.0305 51.1278C98.2401 50.571 98.6782 50.1252 99.2327 49.9052C99.7872 49.6859 100.413 49.7091 100.949 49.97L127.074 62.6873C127.707 62.9952 128.144 63.594 128.244 64.2889C128.343 64.983 128.092 65.6797 127.572 66.1521L94.697 95.9951C94.3905 96.2731 94.1998 96.6528 94.1598 97.0637C94.1511 97.1525 94.1418 97.2412 94.1312 97.3296C94.041 98.0769 94.5069 98.8065 95.2389 99.0641C111.912 104.932 120.407 119.209 122.614 123.444C123.078 124.335 122.85 125.438 122.071 126.068L100.244 143.708C99.8563 144.022 99.386 144.183 98.9097 144.183Z'
        fill='#17D48F'
      />
      <path
        d='M283.253 189.94H191.592C187.798 189.94 184.711 186.863 184.711 183.082V136.595C184.711 132.813 187.798 129.736 191.592 129.736H283.253C287.047 129.736 290.133 132.813 290.133 136.595V183.082C290.133 186.863 287.047 189.94 283.253 189.94ZM191.592 130.623C188.288 130.623 185.601 133.302 185.601 136.595V183.082C185.601 186.374 188.288 189.053 191.592 189.053H283.253C286.556 189.053 289.244 186.374 289.244 183.082V136.595C289.244 133.302 286.556 130.623 283.253 130.623H191.592Z'
        fill='#3F3D56'
      />
      <path d='M246.621 129.791H230.961V189.888H246.621V129.791Z' fill='#17D48F' />
      <path d='M289.689 152.424H185.156V168.034H289.689V152.424Z' fill='#17D48F' />
      <path
        opacity='0.2'
        d='M234.364 169.942C234.796 170.052 235.244 170.118 235.706 170.134L253.544 189.657L261.766 172.877L241.98 165.481C242.112 164.976 242.19 164.448 242.19 163.901C242.19 163.611 242.163 163.327 242.125 163.048L261.766 155.706L253.544 138.926L236.407 157.682C236.248 157.67 236.088 157.657 235.926 157.657C235.386 157.657 234.865 157.733 234.364 157.861C230.76 146.364 219.874 141.657 219.874 141.657L208.129 156.096C216.769 155.235 225.614 160.494 229.69 163.337C229.674 163.523 229.662 163.711 229.662 163.901C229.662 164.092 229.674 164.279 229.69 164.465C225.614 167.308 216.769 172.567 208.129 171.706L219.874 186.145C219.874 186.145 230.76 181.439 234.364 169.942Z'
        fill='black'
      />
      <path
        d='M254.186 186.973C253.831 186.973 253.49 186.825 253.248 186.559L236.731 168.481C236.595 168.332 236.396 168.238 236.172 168.215L236.101 168.208C235.698 168.164 235.33 168.381 235.203 168.738C231.943 177.951 224.006 182.64 221.651 183.858C221.121 184.133 220.462 183.994 220.084 183.529L210.32 171.526C210.011 171.146 209.948 170.633 210.157 170.188C210.363 169.75 210.79 169.473 211.273 169.466C218.821 169.349 226.238 165.055 229.987 162.506C230.212 162.352 230.347 162.087 230.346 161.797C230.347 161.494 230.212 161.229 229.987 161.075C226.238 158.526 218.823 154.232 211.273 154.116C210.79 154.108 210.362 153.831 210.157 153.392C209.948 152.947 210.011 152.435 210.32 152.055L220.084 140.052C220.462 139.587 221.121 139.449 221.651 139.723C224.006 140.941 231.941 145.629 235.202 154.839C235.328 155.196 235.689 155.418 236.052 155.375C236.448 155.325 236.842 155.314 237.223 155.342L253.248 137.802C253.531 137.492 253.95 137.342 254.367 137.402C254.784 137.461 255.143 137.722 255.327 138.099L262.367 152.466C262.523 152.786 262.537 153.159 262.405 153.49C262.273 153.82 262.006 154.081 261.671 154.206L243.851 160.867C243.523 160.99 243.309 161.309 243.318 161.662L243.319 161.79C243.319 162.067 243.3 162.348 243.262 162.626C243.213 162.983 243.437 163.339 243.794 163.473L261.672 170.155C262.006 170.28 262.273 170.541 262.405 170.872C262.537 171.203 262.523 171.576 262.367 171.896L255.327 186.262C255.143 186.64 254.784 186.9 254.367 186.96C254.306 186.968 254.246 186.973 254.186 186.973Z'
        fill='#17D48F'
      />
      <path
        d='M171.175 76.1243H156.84V71.4195C156.84 63.5334 163.263 57.1309 171.175 57.1309L171.175 76.1243Z'
        fill='#2F2E41'
      />
      <path
        d='M167.998 67.3418L170.462 78.3918L156.02 76.0014L161.905 65.5508L167.998 67.3418Z'
        fill='#FFB6B6'
      />
      <path
        opacity='0.1'
        d='M167.998 67.3418L170.462 78.3918L156.02 76.0014L161.905 65.5508L167.998 67.3418Z'
        fill='black'
      />
      <path d='M167.995 90.9473H154.148V111.375H167.995V90.9473Z' fill='#FFB6B6' />
      <path
        d='M176.999 128.074L173.953 144.361C173.953 144.361 175.891 155.679 170.076 167.273L166.199 179.143L160.383 178.314C160.383 178.314 163.983 169.481 163.983 165.616C163.983 161.752 164.814 146.845 164.814 146.845L164.398 129.836L176.999 128.074Z'
        fill='#FFB6B6'
      />
      <path
        d='M159.549 178.039C159.549 178.039 166.195 175.554 166.749 179.143L166.472 180.799C166.472 180.799 168.965 188.252 165.364 188.805C161.764 189.357 158.441 190.185 158.164 188.528C157.887 186.872 157.891 184.805 158.581 183.354C159.272 181.903 159.549 178.039 159.549 178.039Z'
        fill='#2F2E41'
      />
      <path
        d='M156.031 125.549L154.413 142.037C154.413 142.037 149.393 152.371 151.744 165.12L152.157 177.597L157.974 178.413C157.974 178.413 156.98 168.93 158.059 165.218C159.137 161.506 162.498 146.957 162.498 146.957L167.643 130.734L156.031 125.549Z'
        fill='#FFB6B6'
      />
      <path
        d='M158.851 178.378C158.851 178.378 153.16 174.149 151.627 177.443L151.431 179.11C151.431 179.11 147.036 187.353 150.34 188.882C153.644 190.41 156.527 190.352 157.255 188.838C157.983 187.324 158.555 185.337 158.297 183.752C158.039 182.167 158.851 178.378 158.851 178.378Z'
        fill='#2F2E41'
      />
      <path
        d='M173.397 101.85L185.306 126.97C185.306 126.97 161.351 138.978 147.227 138.978L154.239 101.85L173.397 101.85Z'
        fill='#2F2E41'
      />
      <path
        d='M162.735 72.4525L169.659 73.5567L169.105 90.9475L176.859 106.406L166.889 111.375C166.889 111.375 159.135 96.7445 157.75 87.635C156.366 78.5255 157.196 71.9004 157.196 71.9004L162.735 72.4525Z'
        fill='#E6E6E6'
      />
      <path
        d='M157.473 73.0033L150.549 74.1074L151.103 91.4983L149.441 107.509L153.319 111.926C153.319 111.926 161.073 97.2952 162.458 88.1857C163.842 79.0763 163.011 72.4512 163.011 72.4512L157.473 73.0033Z'
        fill='#E6E6E6'
      />
      <path
        d='M168.136 73.9693L169.66 73.5552C169.66 73.5552 175.06 72.8651 175.891 75.0735C176.722 77.2818 176.445 90.256 176.445 90.256C176.445 90.256 177.874 106.18 172.313 106.361C166.752 106.543 167.859 93.2924 167.859 93.2924L168.136 73.9693Z'
        fill='#E6E6E6'
      />
      <path
        d='M165.582 70.3746C169.54 70.3746 172.749 67.176 172.749 63.2303C172.749 59.2846 169.54 56.0859 165.582 56.0859C161.623 56.0859 158.414 59.2846 158.414 63.2303C158.414 67.176 161.623 70.3746 165.582 70.3746Z'
        fill='#FFB6B6'
      />
      <path
        d='M163.341 73.063C164.878 72.6742 166.622 74.3111 167.235 76.7202C167.493 77.6792 167.517 78.6859 167.305 79.656L167.966 82.3945L168.014 82.392L173.226 94.6343C174.438 97.4807 174.31 100.749 172.764 103.43C171.845 105.025 170.638 106.097 169.221 105.002C166.869 103.186 165.333 92.7874 164.632 86.8141L163.011 80.7422C162.36 79.9901 161.899 79.0935 161.667 78.1275C161.054 75.7193 161.803 73.4519 163.341 73.063Z'
        fill='#FFB6B6'
      />
      <path
        d='M156.645 73.9693L155.122 73.5552C155.122 73.5552 149.721 72.8651 148.89 75.0735C148.06 77.2818 148.337 90.256 148.337 90.256C148.337 90.256 146.908 106.18 152.469 106.361C158.029 106.543 156.922 93.2924 156.922 93.2924L156.645 73.9693Z'
        fill='#E6E6E6'
      />
      <path
        d='M161.442 73.063C159.905 72.6742 158.161 74.3111 157.548 76.7202C157.29 77.6792 157.266 78.6859 157.478 79.656L156.817 82.3945L156.769 82.392L151.557 94.6343C150.345 97.4807 150.473 100.749 152.019 103.43C152.938 105.025 154.145 106.097 155.562 105.002C157.914 103.186 159.45 92.7874 160.151 86.8141L161.773 80.7422C162.423 79.9901 162.884 79.0935 163.116 78.1275C163.729 75.7193 162.98 73.4519 161.442 73.063Z'
        fill='#FFB6B6'
      />
      <path
        d='M151.857 69.0095C152.222 67.9045 152.941 66.9372 153.263 65.8191C153.691 64.3347 154.601 67.6279 154.69 66.086C154.859 63.1553 159.346 55.6166 161.908 54.1686C164.47 52.7205 165.941 53.1571 168.551 54.5171C168.551 54.5171 169.818 54.5974 171.395 55.3735C172.972 56.1497 174.392 57.3928 175.006 59.036C175.492 60.3384 175.438 61.7688 175.6 63.149C176.053 66.9943 178.216 70.6514 177.751 74.4953C177.607 75.6946 177.149 76.9347 176.166 77.6401C175.182 78.3455 173.593 78.2688 172.945 77.2478C172.724 76.8993 172.631 76.486 172.441 76.1195C172.252 75.7531 171.91 75.4115 171.496 75.4229C170.875 75.44 170.559 76.271 170.804 76.8405C171.049 77.4099 171.624 77.7537 172.165 78.0595C170.878 78.6368 169.28 78.457 168.154 77.608C167.029 76.759 166.421 75.2759 166.628 73.8839C166.92 71.9223 169.787 72.3283 170.124 70.374C170.37 68.9513 171.234 66.5709 170.998 65.1464C170.763 63.722 169.634 65.4467 170.824 64.6237C169.515 65.2527 166.715 60.9496 165.344 60.4676C163.974 59.9856 162.812 58.942 162.189 57.6334C160.735 59.7336 159.457 59.1632 158.936 61.6614C158.416 64.1595 158.897 69.835 161.04 71.2297C160.182 71.8131 159.499 72.6474 159.096 73.6016C158.868 74.1421 158.72 74.7336 158.342 75.1825C157.619 76.04 156.284 76.0921 155.221 75.7316C153.902 75.2846 152.774 74.3027 152.152 73.06C151.53 71.8173 151.422 70.3287 151.857 69.0095Z'
        fill='#2F2E41'
      />
    </g>
    <defs>
      <clipPath id='clip0_389_20027'>
        <rect width='305' height='190' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
)

export default Icon
