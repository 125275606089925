import { Box, Typography } from '@mui/material'
import { Text14pxPoppins, Text16pxRany500 } from '../Typography'
import authStore from 'src/store/auth'
import { useDispatch, useSelector } from 'react-redux'
import { ResendOTPLink, ResendOTP } from './ResendOTPLink'
import { OTPMethods } from 'src/models/auth'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  handleResendOTP: ResendOTP
  activeMessenger?: OTPMethods
}
const ResendButtons: React.FC<Props> = ({ activeMessenger, handleResendOTP }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'otp' })

  const isSendingPhoneVerification = useSelector(authStore.selectors.isSendingPhoneVerification)
  const isSendLimitReached = useSelector(authStore.selectors.isSendLimitReached)
  const allowedOTPMethods = useSelector(authStore.selectors.allowedOTPMethods)

  useEffect(() => {
    if (!allowedOTPMethods?.length) {
      dispatch(authStore.actions.getCountriesOTPRequestMethodsList.started())
    }
  }, [])
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Text14pxPoppins style={{ textAlign: 'center' }} mt={2} mb={2} gutterBottom>
        Didn't get code? Resend a code via
      </Text14pxPoppins>

      {(allowedOTPMethods?.includes('sms') || allowedOTPMethods?.includes('sms_with_captcha')) && (
        <ResendOTPLink
          resendOTPHandler={handleResendOTP}
          isSendingPhoneVerification={isSendingPhoneVerification}
          activeMessenger={activeMessenger}
          isSendLimitReached={isSendLimitReached}
          linkText={'SMS'}
          messenger={allowedOTPMethods?.includes('sms') ? 'sms' : 'sms_with_captcha'}
        />
      )}
      {allowedOTPMethods?.includes('whatsapp') && (
        <ResendOTPLink
          resendOTPHandler={handleResendOTP}
          isSendingPhoneVerification={isSendingPhoneVerification}
          activeMessenger={activeMessenger}
          linkText={'Whatsapp'}
          messenger={'whatsapp'}
        />
      )}
    </Box>
  )
}

export default ResendButtons
