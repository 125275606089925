import React from 'react'
import Box from '@mui/material/Box'
import { SecondaryButton, PrimaryButton } from 'src/components' // Adjust the import path according to your project structure
import { useNavigate } from 'react-router-dom'

interface Props {
  primaryText?: string
  secondaryText?: string
  hrefLink?: string
  primaryOnCLick: () => void
  secondOnCLick?: () => void
}
// Props for customizing text and onClick actions for both buttons
const ActionButtons = ({
  primaryText,
  hrefLink,
  secondaryText,
  primaryOnCLick,
  secondOnCLick
}: Props) => {
  const navigate = useNavigate()

  const hanleClick = () => {
    hrefLink ? navigate(hrefLink) : secondOnCLick?.()
  }

  return (
    <Box gap={2} mt={3} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <SecondaryButton variant='outlined' fullWidth onClick={hanleClick}>
        {secondaryText || 'Back'}
      </SecondaryButton>
      <PrimaryButton variant='contained' fullWidth color='primary' onClick={primaryOnCLick}>
        {primaryText || 'Continue'}
      </PrimaryButton>
    </Box>
  )
}

export default ActionButtons
