import { resizePx } from 'src/theme'

const Icon = () => (
  <svg
    width={resizePx(198)}
    height={resizePx(160)}
    viewBox='0 0 198 160'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g path='url(#clip0_389_18900)'>
      <path
        d='M158.067 29.1446C157.038 40.4357 154.108 52.3416 149.513 63.8291C143.83 78.0351 136.065 90.5476 127.058 100.014L126.559 99.5797C135.522 90.1589 143.251 77.7016 148.91 63.5545C154.025 50.7699 157.067 37.4657 157.712 25.0859L158.35 25.1925C158.282 26.4999 158.188 27.8173 158.067 29.1446Z'
        fill='#F2F2F2'
      />
      <path
        d='M162.165 94.7213C149.514 114.773 134.166 129.101 118.948 135.064L118.641 134.501C133.753 128.58 149.011 114.326 161.604 94.3652L162.165 94.7213Z'
        fill='#F2F2F2'
      />
      <path
        d='M157.762 43.2032C156.734 54.4943 153.803 66.4002 149.208 77.8877C143.525 92.0937 135.76 104.606 126.754 114.073L126.254 113.638C135.217 104.218 142.946 91.7602 148.605 77.613C153.72 64.8285 156.762 51.5243 157.407 39.1445L158.046 39.2511C157.978 40.5585 157.883 41.8759 157.762 43.2032Z'
        fill='#F2F2F2'
      />
      <path
        d='M15.7439 72.1899H7.4437C7.26636 72.1903 7.09125 72.1507 6.93148 72.074C6.77172 71.9973 6.63145 71.8856 6.52121 71.7472C6.4123 71.6123 6.33546 71.4546 6.29646 71.286C6.25746 71.1173 6.25732 70.942 6.29603 70.7732C7.08654 67.298 7.03688 63.6853 6.15114 60.2329C6.09511 60.0055 6.10866 59.7667 6.19003 59.5471C6.27141 59.3276 6.41688 59.1373 6.60773 59.0008C10.8757 55.9424 14.2369 56.0312 16.598 59.265C16.7044 59.4124 16.7751 59.5824 16.8045 59.7616C17.4354 63.5402 17.4703 67.3938 16.908 71.1832C16.8689 71.4629 16.7295 71.719 16.5154 71.9041C16.3014 72.0892 16.0273 72.1907 15.7439 72.1899Z'
        fill='#E6E6E6'
      />
      <path
        d='M13.1694 63.9089H10.3141C10.1562 63.9089 10.0048 63.8464 9.89311 63.7352C9.78146 63.624 9.71875 63.4731 9.71875 63.3158C9.71875 63.1585 9.78146 63.0076 9.89311 62.8964C10.0048 62.7851 10.1562 62.7227 10.3141 62.7227H13.1694C13.327 62.723 13.4781 62.7856 13.5895 62.8968C13.7009 63.008 13.7635 63.1587 13.7635 63.3158C13.7635 63.4729 13.7009 63.6236 13.5895 63.7348C13.4781 63.846 13.327 63.9086 13.1694 63.9089Z'
        fill='white'
      />
      <path
        d='M13.1694 65.989H10.3141C10.1562 65.989 10.0048 65.9265 9.89311 65.8153C9.78146 65.7041 9.71875 65.5532 9.71875 65.3959C9.71875 65.2386 9.78146 65.0877 9.89311 64.9765C10.0048 64.8652 10.1562 64.8027 10.3141 64.8027H13.1694C13.327 64.8031 13.4781 64.8657 13.5895 64.9769C13.7009 65.0881 13.7635 65.2388 13.7635 65.3959C13.7635 65.553 13.7009 65.7037 13.5895 65.8149C13.4781 65.9261 13.327 65.9887 13.1694 65.989Z'
        fill='white'
      />
      <path
        d='M13.1694 68.0691H10.3141C10.1562 68.0691 10.0048 68.0066 9.89311 67.8954C9.78146 67.7841 9.71875 67.6333 9.71875 67.476C9.71875 67.3186 9.78146 67.1678 9.89311 67.0565C10.0048 66.9453 10.1562 66.8828 10.3141 66.8828H13.1694C13.327 66.8831 13.4781 66.9458 13.5895 67.057C13.7009 67.1682 13.7635 67.3189 13.7635 67.476C13.7635 67.6331 13.7009 67.7837 13.5895 67.8949C13.4781 68.0061 13.327 68.0688 13.1694 68.0691Z'
        fill='white'
      />
      <path
        d='M166.592 109.114H158.291C158.114 109.114 157.939 109.074 157.779 108.998C157.619 108.921 157.479 108.809 157.369 108.671C157.26 108.536 157.183 108.378 157.144 108.21C157.105 108.041 157.105 107.866 157.144 107.697C157.934 104.222 157.885 100.609 156.999 97.1567C156.943 96.9294 156.956 96.6905 157.038 96.471C157.119 96.2514 157.265 96.0611 157.455 95.9246C161.723 92.8663 165.085 92.955 167.446 96.1888C167.552 96.3362 167.623 96.5062 167.652 96.6854C168.283 100.464 168.318 104.318 167.756 108.107C167.717 108.387 167.577 108.643 167.363 108.828C167.149 109.013 166.875 109.115 166.592 109.114Z'
        fill='#E6E6E6'
      />
      <path
        d='M164.02 100.833H161.164C161.007 100.832 160.856 100.77 160.744 100.659C160.633 100.547 160.57 100.397 160.57 100.24C160.57 100.083 160.633 99.9318 160.744 99.8206C160.856 99.7094 161.007 99.6468 161.164 99.6465H164.02C164.178 99.6465 164.329 99.709 164.441 99.8202C164.552 99.9315 164.615 100.082 164.615 100.24C164.615 100.397 164.552 100.548 164.441 100.659C164.329 100.77 164.178 100.833 164.02 100.833Z'
        fill='white'
      />
      <path
        d='M164.02 102.913H161.164C161.007 102.913 160.856 102.85 160.744 102.739C160.633 102.627 160.57 102.477 160.57 102.32C160.57 102.163 160.633 102.012 160.744 101.901C160.856 101.79 161.007 101.727 161.164 101.727H164.02C164.178 101.727 164.329 101.789 164.441 101.9C164.552 102.012 164.615 102.162 164.615 102.32C164.615 102.477 164.552 102.628 164.441 102.739C164.329 102.85 164.178 102.913 164.02 102.913Z'
        fill='white'
      />
      <path
        d='M164.02 104.993H161.164C161.007 104.993 160.856 104.93 160.744 104.819C160.633 104.708 160.57 104.557 160.57 104.4C160.57 104.243 160.633 104.092 160.744 103.981C160.856 103.87 161.007 103.807 161.164 103.807H164.02C164.178 103.807 164.329 103.869 164.441 103.98C164.552 104.092 164.615 104.242 164.615 104.4C164.615 104.557 164.552 104.708 164.441 104.819C164.329 104.93 164.178 104.993 164.02 104.993Z'
        fill='white'
      />
      <path
        d='M198 159.69C198 159.731 197.992 159.771 197.977 159.809C197.961 159.846 197.938 159.881 197.909 159.909C197.88 159.938 197.846 159.961 197.808 159.976C197.771 159.992 197.73 160 197.689 160H0.31057C0.228202 160 0.14921 159.967 0.0909673 159.909C0.0327242 159.851 0 159.772 0 159.69C0 159.608 0.0327242 159.53 0.0909673 159.471C0.14921 159.413 0.228202 159.381 0.31057 159.381H197.689C197.73 159.381 197.771 159.389 197.808 159.404C197.846 159.42 197.88 159.442 197.909 159.471C197.938 159.5 197.961 159.534 197.977 159.572C197.992 159.609 198 159.65 198 159.69Z'
        fill='#CCCCCC'
      />
      <path
        d='M52.0658 140.746L51.5482 133.572C48.1571 131.883 44.4035 131.044 40.6133 131.129C45.9456 135.235 45.5185 143.443 49.2114 149.06C50.3767 150.803 51.9207 152.263 53.729 153.33C55.5374 154.398 57.5638 155.047 59.6579 155.228L64.0957 157.772C64.6034 154.429 64.3776 151.016 63.4339 147.769C62.4903 144.521 60.8512 141.516 58.6296 138.96C57.3691 137.539 55.9338 136.281 54.3579 135.217C53.3879 137.992 52.0658 140.746 52.0658 140.746Z'
        fill='#F2F2F2'
      />
      <path
        d='M145.931 141.785L146.448 134.611C149.84 132.922 153.593 132.083 157.383 132.168C152.051 136.274 152.478 144.482 148.785 150.099C147.62 151.842 146.076 153.302 144.268 154.369C142.459 155.437 140.433 156.086 138.339 156.267L133.901 158.811C133.393 155.468 133.619 152.055 134.563 148.808C135.506 145.56 137.145 142.555 139.367 139.999C140.628 138.578 142.063 137.32 143.639 136.256C144.609 139.031 145.931 141.785 145.931 141.785Z'
        fill='#F2F2F2'
      />
      <path
        d='M96.6501 60.8302C99.3887 60.8302 101.609 58.6182 101.609 55.8897C101.609 53.1611 99.3887 50.9492 96.6501 50.9492C93.9115 50.9492 91.6914 53.1611 91.6914 55.8897C91.6914 58.6182 93.9115 60.8302 96.6501 60.8302Z'
        fill='#04BC79'
      />
      <path
        d='M99.1964 53.8312C99.1218 53.7603 99.0227 53.7207 98.9196 53.7207C98.8165 53.7207 98.7174 53.7603 98.6428 53.8312C97.5906 54.6672 96.6456 55.629 95.8289 56.6948C95.6284 56.3083 95.4279 55.9218 95.2275 55.5352C94.996 55.0891 94.3202 55.4832 94.5514 55.929C94.8446 56.4943 95.1379 57.0596 95.4311 57.6249C95.4665 57.6827 95.5162 57.7303 95.5754 57.7634C95.6346 57.7965 95.7013 57.8139 95.7692 57.8139C95.837 57.8139 95.9038 57.7965 95.963 57.7634C96.0221 57.7303 96.0718 57.6827 96.1072 57.6249C96.9775 56.4038 98.0176 55.3121 99.1964 54.3828C99.2693 54.3093 99.3101 54.2102 99.3101 54.107C99.3101 54.0037 99.2693 53.9046 99.1964 53.8312Z'
        fill='white'
      />
      <path
        d='M74.4619 69.9003L66.9844 58.2003L75.6432 53.5801L77.9159 62.5066L93.9286 71.1277C94.5607 70.901 95.2455 70.8625 95.8992 71.0169C96.553 71.1712 97.1476 71.5117 97.6105 71.9969C98.0734 72.4821 98.3846 73.0911 98.5061 73.7494C98.6277 74.4078 98.5543 75.0872 98.295 75.7047C98.0357 76.3223 97.6017 76.8513 97.0459 77.2274C96.49 77.6034 95.8363 77.8102 95.1646 77.8225C94.4928 77.8348 93.832 77.652 93.2626 77.2966C92.6933 76.9412 92.24 76.4284 91.9581 75.8207L74.4619 69.9003Z'
        fill='#A0616A'
      />
      <path
        d='M61.5223 44.1658C59.1728 47.6027 67.5223 60.0956 67.5223 60.0956C67.929 59.2221 71.8173 69.0317 72.4685 68.4522C74.3194 66.8053 77.2168 62.2177 78.8274 62.0132C79.7484 61.8963 76.6913 55.3882 76.6913 55.3882C76.6913 55.3882 75.2941 51.5452 73.3981 46.6361C72.888 45.2384 72.0311 43.9919 70.9074 43.0128C69.7837 42.0337 68.4296 41.3538 66.9712 41.0364C66.9712 41.0364 63.8718 40.7289 61.5223 44.1658Z'
        fill='#2F3734'
      />
      <path
        d='M61.6375 156.142L65.1887 156.142L66.878 142.494L61.6367 142.494L61.6375 156.142Z'
        fill='#A0616A'
      />
      <path
        d='M60.7305 154.988L61.73 154.987L65.6325 153.406L67.7242 154.987H67.7244C68.9065 154.987 70.0401 155.455 70.8759 156.288C71.7117 157.121 72.1813 158.25 72.1814 159.428V159.572L60.7307 159.572L60.7305 154.988Z'
        fill='#2F3734'
      />
      <path
        d='M78.1372 156.142L81.6884 156.142L83.378 142.494L78.1367 142.494L78.1372 156.142Z'
        fill='#A0616A'
      />
      <path
        d='M77.2305 154.988L78.23 154.987L82.1325 153.406L84.2242 154.987H84.2244C85.4065 154.987 86.5401 155.455 87.3759 156.288C88.2117 157.121 88.6813 158.25 88.6814 159.428V159.572L77.2307 159.572L77.2305 154.988Z'
        fill='#2F3734'
      />
      <path
        d='M79.674 81.5312L56.5751 82.1066C52.1881 84.4806 52.1215 89.6189 53.5589 95.7195C53.5589 95.7195 53.0737 99.3455 54.0442 100.312C55.0147 101.279 55.4999 101.279 55.0147 102.972C54.5294 104.664 57.0932 107.427 56.7818 107.738C56.4704 108.048 55.7426 109.74 55.7426 109.74L58.1688 123.519C58.1688 123.519 59.8672 147.45 60.3524 147.934C60.8377 148.417 61.3229 147.934 60.8377 149.143C60.3524 150.351 59.8672 149.868 60.3524 150.351C60.7822 150.813 61.1872 151.297 61.5655 151.802H67.4315C67.4315 151.802 67.8738 149.384 67.8738 149.143C67.8738 148.901 68.359 147.45 68.359 147.209C68.359 146.967 67.9301 146.54 67.9301 146.54C67.7819 146.048 67.6818 145.544 67.6311 145.033C67.6311 144.55 66.1754 125.936 66.1754 125.936L69.0869 101.763L76.123 125.453C76.123 125.453 76.123 146.242 76.6082 146.725C77.0935 147.209 77.0935 146.967 76.8508 147.934C76.6082 148.901 75.6377 148.659 76.3656 149.384C77.0935 150.11 77.3361 148.901 77.0935 150.11L76.8509 151.318L84.1296 151.422C84.1296 151.422 85.1001 149.384 84.6148 148.659C84.1296 147.934 84.1587 148.132 84.7507 146.945C85.3427 145.758 85.5853 145.517 85.3427 145.275C85.1001 145.033 85.1001 143.747 85.1001 143.747L83.887 120.618C83.887 120.618 83.6443 96.203 83.6443 95.4778C83.6261 95.1046 83.6951 94.7323 83.8459 94.3903V93.4059L82.9165 89.9179L79.674 81.5312Z'
        fill='#2F3734'
      />
      <path
        d='M65.7981 33.6762C69.7618 33.6762 72.975 30.4748 72.975 26.5256C72.975 22.5764 69.7618 19.375 65.7981 19.375C61.8343 19.375 58.6211 22.5764 58.6211 26.5256C58.6211 30.4748 61.8343 33.6762 65.7981 33.6762Z'
        fill='#A0616A'
      />
      <path
        d='M69.3873 38.4031C70.9335 39.319 71.8584 41.0017 72.3893 42.714C73.3723 45.8842 73.964 49.1618 74.1513 52.4745L74.7121 62.3922L81.6576 83.5098C75.6382 88.5844 53.0122 84.6632 53.0122 84.6632C53.0122 84.6632 52.3177 84.4325 53.0122 83.7405C53.7068 83.0485 54.3829 83.6616 53.6884 82.9696C52.9939 82.2776 53.4726 83.0485 53.7042 82.3565C53.9357 81.6645 53.7042 82.1259 53.4726 81.8952C53.2411 81.6645 55.2649 79.5885 55.2649 79.5885L53.4128 67.4668L51.0977 43.0164C53.8758 39.5564 59.4631 37.5168 59.4631 37.5168L60.9068 34.9277L68.1252 35.5031L69.3873 38.4031Z'
        fill='#2F3734'
      />
      <path
        d='M71.7143 22.728C72.2721 23.1425 72.6854 23.7209 72.8959 24.3817C72.9768 23.3018 72.9819 22.2176 72.9113 21.1369C72.8267 20.3776 72.2901 20.2068 71.6688 20.0088C71.1077 19.83 70.4731 19.628 70.3024 18.8909C68.0464 16.7042 64.1191 16.8145 61.4314 17.5772C59.9524 17.9969 59.1014 18.5362 59.0253 18.8521L59.0095 18.9171L58.9495 18.947C57.8095 19.515 57.5876 21.3083 57.6022 22.7127C57.63 25.3586 58.547 28.3638 59.0635 28.6079C59.0909 28.6209 59.0978 28.6158 59.1096 28.606C59.8874 27.9866 60.8364 28.653 61.2534 29.0153C61.4994 27.3708 62.2914 25.8558 63.5029 24.7124C64.7144 23.5689 66.2756 22.863 67.9371 22.7072C68.4797 22.655 69.0179 22.565 69.548 22.4381C69.8168 22.3688 70.0932 22.3327 70.3709 22.3305C70.8489 22.3233 71.3177 22.462 71.7143 22.728Z'
        fill='#2F3734'
      />
      <path
        d='M116.267 87.2289L130.072 73.2608L133.71 62.0893L127.856 59.7305C124.957 62.6446 121.972 71.5655 121.972 71.5655L112.549 84.1658C112.474 84.1937 112.399 84.2232 112.325 84.258C111.891 84.4621 111.517 84.7742 111.24 85.1642C110.963 85.5542 110.791 86.0089 110.741 86.4844C110.692 86.9598 110.766 87.4399 110.958 87.8783C111.149 88.3167 111.45 88.6986 111.833 88.9869C112.216 89.2753 112.666 89.4605 113.142 89.5246C113.617 89.5886 114.101 89.5294 114.547 89.3527C114.993 89.1759 115.385 88.8876 115.686 88.5156C115.988 88.1435 116.187 87.7004 116.267 87.2289Z'
        fill='#FFB8B8'
      />
      <path
        d='M126.262 61.0093L133.664 63.1428L133.692 63.1375C136.413 62.6111 138.565 58.1059 139.891 54.4192C140.328 53.2032 140.284 51.8675 139.77 50.6821C139.256 49.4967 138.309 48.5501 137.121 48.0336C136.242 47.6585 135.275 47.5358 134.33 47.6794C133.385 47.8231 132.499 48.2272 131.772 48.8463L128.081 52.0127L126.262 61.0093Z'
        fill='#04BC79'
      />
      <path
        d='M119.926 155.727L115.944 155.726L114.051 140.428L119.926 140.428L119.926 155.727Z'
        fill='#FFB8B8'
      />
      <path
        d='M120.942 154.433L119.821 154.433L115.447 152.66L113.102 154.432H113.102C111.777 154.432 110.506 154.957 109.569 155.89C108.632 156.824 108.106 158.09 108.105 159.41V159.572L120.942 159.572L120.942 154.433Z'
        fill='#2F3734'
      />
      <path
        d='M137.59 155.727L133.609 155.726L131.715 140.428L137.59 140.428L137.59 155.727Z'
        fill='#FFB8B8'
      />
      <path
        d='M138.606 154.433L137.485 154.433L133.111 152.66L130.766 154.432H130.766C129.441 154.433 128.17 154.957 127.233 155.89C126.296 156.824 125.77 158.09 125.77 159.41V159.572L138.606 159.572L138.606 154.433Z'
        fill='#2F3734'
      />
      <path
        d='M143.66 89.83C143.66 89.83 144.125 95.3876 142.963 100.714C141.801 106.04 141.801 121.04 141.801 121.04L139.244 150.27L131.574 149.575L129.483 100.714L122.045 117.155L122.742 119.471L121.813 120.05C121.813 120.05 122.51 124.565 122.045 124.565C121.58 124.565 120.418 125.26 120.418 125.26L121.348 149.112L113.445 148.88L110.656 115.303L113.678 96.0824L113.91 92.3772L114.956 92.4201L115.489 90.872L116.467 88.209L143.66 89.83Z'
        fill='#2F3734'
      />
      <path
        d='M144.062 90.997L144.103 90.9391C146.316 87.8532 142.988 80.4378 142.487 79.3628L144.6 78.6794L144.124 74.6483L143.899 72.8512L145.514 71.2416L145.519 71.2015L146.449 63.0965L147.8 57.7133C148.31 55.6783 148.266 53.5448 147.675 51.532C147.083 49.5192 145.964 47.6995 144.433 46.2598L141.309 43.3246L137.512 37.7266H131.388L129.288 41.6779C127.253 42.1467 125.447 43.3121 124.185 44.9711C122.923 46.6302 122.285 48.6777 122.383 50.7571L122.859 61.1204L120.996 74.0236L120.995 76.3588L118.898 78.6801L119.356 80.9606L117.76 81.6419L117.056 84.9173C116.764 85.3007 114.84 87.8572 114.84 88.5561C114.84 88.661 114.918 88.7624 115.084 88.875C116.396 89.7597 123.645 91.5636 126.853 90.2845C130.27 88.9231 143.855 90.9654 143.991 90.9862L144.062 90.997Z'
        fill='#04BC79'
      />
      <path
        d='M133.661 35.92C138.14 35.92 141.771 32.3024 141.771 27.8399C141.771 23.3774 138.14 19.7598 133.661 19.7598C129.182 19.7598 125.551 23.3774 125.551 27.8399C125.551 32.3024 129.182 35.92 133.661 35.92Z'
        fill='#FFB8B8'
      />
      <path
        d='M132.882 36.0504C134.137 36.0377 135.39 35.918 136.626 35.6928C137.849 35.412 140.547 33.3329 141.283 32.1046C141.829 31.192 142.157 29.7804 142.336 28.757C142.578 27.4026 142.531 26.0127 142.2 24.6774C141.868 23.3421 141.258 22.091 140.411 21.0053C140.084 20.5505 139.64 20.1921 139.125 19.9681C139.08 19.9517 139.033 19.9374 138.986 19.9253C138.24 19.7501 137.564 19.3569 137.044 18.7961C136.943 18.6826 136.834 18.5764 136.718 18.4785C136.134 18.0133 135.449 17.6922 134.718 17.5415C133.536 17.2587 131.845 17.6131 129.693 18.5957C128.611 19.0893 127.411 18.9516 126.486 18.7765C126.434 18.7679 126.381 18.7729 126.332 18.7912C126.283 18.8094 126.239 18.8402 126.205 18.8805C125.823 19.2566 125.313 19.4748 124.776 19.4912C124.448 19.514 124.114 19.9765 123.713 20.5787C123.622 20.7154 123.515 20.8749 123.438 20.9723L123.423 20.7895L123.242 20.9985C122.958 21.3271 122.762 21.722 122.672 22.1463C122.583 22.5706 122.602 23.0107 122.729 23.4255C122.856 23.8402 123.087 24.2162 123.399 24.5184C123.711 24.8207 124.095 25.0393 124.515 25.1539C124.848 25.2331 125.188 25.2781 125.53 25.2884C125.738 25.3017 125.952 25.3154 126.158 25.3455C126.82 25.463 127.44 25.7494 127.958 26.1769C128.476 26.6044 128.873 27.1583 129.112 27.7846C129.168 27.9293 129.257 28.0592 129.372 28.1643C129.487 28.2693 129.624 28.3467 129.774 28.3904C129.923 28.4341 130.081 28.443 130.235 28.4164C130.388 28.3897 130.533 28.3283 130.659 28.2369C130.869 28.0656 131.117 27.9473 131.382 27.892C131.647 27.8368 131.922 27.8464 132.183 27.9198C132.409 28.0379 132.589 28.2301 132.69 28.4642C132.813 28.7247 133.01 28.9429 133.258 29.0909C134.102 29.4975 134.209 31.3922 133.951 32.9807C133.702 34.5118 133.16 35.6287 132.633 35.6967C132.228 35.7491 132.182 35.7791 132.155 35.8514L132.13 35.9162L132.177 35.975C132.404 36.0503 132.644 36.0761 132.882 36.0504Z'
        fill='#2F3734'
      />
      <path
        d='M126.153 61.8726L122.255 56.9238C118.284 58.0132 111.238 64.2702 111.238 64.2702L96.7691 70.5263C96.2862 70.4454 95.7904 70.4927 95.3316 70.6635C94.8729 70.8342 94.4674 71.1224 94.1559 71.4989C93.8445 71.8754 93.6381 72.327 93.5575 72.8083C93.4769 73.2895 93.5251 73.7834 93.697 74.2403C93.8689 74.6971 94.1586 75.1008 94.5369 75.4107C94.9151 75.7205 95.3687 75.9256 95.8518 76.0053C96.3349 76.085 96.8306 76.0365 97.2889 75.8646C97.7473 75.6928 98.1521 75.4037 98.4626 75.0264L117.417 69.7579L126.153 61.8726Z'
        fill='#FFB8B8'
      />
      <path
        d='M125.22 63.0045L131.371 56.173L131.712 51.3324C131.776 50.3818 131.571 49.4325 131.122 48.5917C130.672 47.7509 129.996 47.0521 129.168 46.5744C128.043 45.9342 126.715 45.7438 125.454 46.0417C124.192 46.3396 123.092 47.1035 122.374 48.179C120.201 51.4425 117.863 55.8548 119.066 58.3425L119.078 58.3678L125.22 63.0045Z'
        fill='#04BC79'
      />
      <path
        d='M68.5278 88.6817L52.5415 80.5244C51.4044 79.9442 51.0277 78.4052 51.7018 77.0937L62.1253 56.8161C62.7994 55.5046 64.273 54.9097 65.4101 55.4899L81.3965 63.6472C82.5336 64.2274 82.9102 65.7664 82.2361 67.0779L71.8127 87.3556C71.1385 88.6671 69.6649 89.262 68.5278 88.6817Z'
        fill='#E6E6E6'
      />
      <path
        d='M65.7971 84.9306L54.9358 79.3884C53.8708 78.845 53.518 77.4037 54.1493 76.1754L62.809 59.3291C63.4403 58.1008 64.8204 57.5437 65.8854 58.0871L79.0037 64.781C80.0687 65.3244 80.4214 66.7657 79.79 67.9939L75.0856 77.1458C72.2734 82.6168 70.5409 87.3512 65.7971 84.9306Z'
        fill='white'
      />
      <path
        d='M71.1696 75.7309L61.5494 70.8221C61.4318 70.7386 61.3482 70.6157 61.314 70.4759C61.2798 70.3361 61.2972 70.1887 61.363 70.0606C61.4289 69.9325 61.5387 69.8323 61.6726 69.7782C61.8065 69.7242 61.9554 69.72 62.0921 69.7662L71.7123 74.6751C71.83 74.7586 71.9135 74.8815 71.9477 75.0213C71.982 75.1611 71.9646 75.3085 71.8987 75.4366C71.8329 75.5647 71.723 75.6649 71.5891 75.7189C71.4553 75.773 71.3064 75.7772 71.1696 75.7309Z'
        fill='#04BC79'
      />
      <path
        d='M62.6706 74.0283L60.4752 72.908C60.3576 72.8245 60.274 72.7017 60.2398 72.5618C60.2056 72.422 60.223 72.2746 60.2888 72.1465C60.3547 72.0185 60.4645 71.9182 60.5984 71.8642C60.7323 71.8101 60.8812 71.8059 61.0179 71.8522L63.2134 72.9724C63.331 73.0559 63.4145 73.1788 63.4488 73.3186C63.483 73.4584 63.4656 73.6059 63.3997 73.7339C63.3339 73.862 63.224 73.9622 63.0901 74.0163C62.9563 74.0703 62.8074 74.0746 62.6706 74.0283Z'
        fill='#04BC79'
      />
      <path
        d='M65.4848 78.0983L59.401 74.994C59.2833 74.9105 59.1998 74.7876 59.1656 74.6478C59.1314 74.508 59.1487 74.3606 59.2146 74.2325C59.2804 74.1044 59.3903 74.0042 59.5242 73.9501C59.658 73.8961 59.807 73.8918 59.9437 73.9381L66.0275 77.0425C66.1451 77.126 66.2287 77.2489 66.2629 77.3887C66.2971 77.5285 66.2797 77.6759 66.2139 77.804C66.148 77.9321 66.0382 78.0323 65.9043 78.0863C65.7704 78.1404 65.6215 78.1446 65.4848 78.0983Z'
        fill='#04BC79'
      />
      <path
        d='M74.9743 68.3247L65.3541 63.4158C65.2365 63.3323 65.1529 63.2094 65.1187 63.0696C65.0845 62.9298 65.1019 62.7824 65.1677 62.6543C65.2335 62.5262 65.3434 62.426 65.4773 62.372C65.6112 62.318 65.7601 62.3137 65.8968 62.36L75.517 67.2689C75.6347 67.3524 75.7182 67.4752 75.7524 67.615C75.7867 67.7549 75.7692 67.9023 75.7034 68.0303C75.6376 68.1584 75.5277 68.2586 75.3938 68.3127C75.26 68.3667 75.1111 68.371 74.9743 68.3247Z'
        fill='#04BC79'
      />
      <path
        d='M66.4753 66.622L64.2799 65.5018C64.1622 65.4183 64.0787 65.2954 64.0445 65.1556C64.0103 65.0158 64.0277 64.8684 64.0935 64.7403C64.1593 64.6122 64.2692 64.512 64.4031 64.4579C64.537 64.4039 64.6859 64.3996 64.8226 64.4459L67.0181 65.5662C67.1357 65.6497 67.2192 65.7726 67.2534 65.9124C67.2877 66.0522 67.2703 66.1996 67.2044 66.3277C67.1386 66.4558 67.0287 66.556 66.8948 66.61C66.761 66.6641 66.6121 66.6683 66.4753 66.622Z'
        fill='#04BC79'
      />
      <path
        d='M69.2933 70.6941L63.2096 67.5897C63.0919 67.5062 63.0084 67.3833 62.9742 67.2435C62.9399 67.1037 62.9573 66.9563 63.0232 66.8282C63.089 66.7001 63.1989 66.5999 63.3328 66.5458C63.4666 66.4918 63.6155 66.4875 63.7523 66.5338L69.8361 69.6382C69.9537 69.7217 70.0373 69.8446 70.0715 69.9844C70.1057 70.1242 70.0883 70.2716 70.0225 70.3997C69.9566 70.5278 69.8468 70.628 69.7129 70.682C69.579 70.7361 69.4301 70.7403 69.2933 70.6941Z'
        fill='#04BC79'
      />
      <path
        d='M55.2795 71.0285L51.668 57.6327L61.3189 55.8125L60.8 65.0072L73.4806 78.0161C74.1519 77.9888 74.8167 78.1567 75.3939 78.4993C75.9711 78.8418 76.4358 79.3444 76.7313 79.9455C77.0269 80.5467 77.1404 81.2206 77.0582 81.885C76.976 82.5493 76.7015 83.1755 76.2683 83.6872C75.8351 84.1988 75.2617 84.5738 74.6183 84.7665C73.9748 84.9591 73.289 84.961 72.6445 84.772C71.9999 84.583 71.4245 84.2113 70.9884 83.7021C70.5522 83.1929 70.2742 82.5683 70.1882 81.9044L55.2795 71.0285Z'
        fill='#A0616A'
      />
      <path
        d='M50.6824 42.6124C47.4065 45.189 51.6112 59.6017 51.6112 59.6017C52.2621 58.8899 53.0186 69.4098 53.8143 69.0516C56.0758 68.0335 60.2207 64.523 61.8187 64.8091C62.7325 64.9728 61.7751 57.8507 61.7751 57.8507C61.7751 57.8507 61.599 53.7671 61.268 48.5175C61.2021 47.0317 60.7599 45.5866 59.9826 44.3167C59.2053 43.0469 58.1182 41.9937 56.8225 41.2551C56.8225 41.2551 53.9583 40.0357 50.6824 42.6124Z'
        fill='#2F3734'
      />
      <path
        d='M105.19 100.761L96.4659 96.6565C96.2793 96.5692 96.115 96.4409 95.9853 96.2814C95.8556 96.1218 95.7638 95.935 95.7169 95.735C95.6696 95.5394 95.6674 95.3357 95.7104 95.1391C95.7534 94.9425 95.8406 94.7582 95.9654 94.6C98.4923 91.3823 100.228 87.6194 101.032 83.613C101.093 83.3063 101.258 83.03 101.501 82.8313C101.743 82.6326 102.047 82.5238 102.361 82.5234C106.81 82.5385 110.135 84.1571 112.244 87.3337C112.355 87.4989 112.429 87.6863 112.46 87.8828C112.491 88.0793 112.478 88.2802 112.423 88.4715C111.202 92.6641 109.345 96.6458 106.914 100.278C106.734 100.553 106.46 100.753 106.143 100.842C105.826 100.93 105.487 100.902 105.19 100.761Z'
        fill='#E6E6E6'
      />
      <path
        d='M107.754 95.0037L101.084 91.8655C100.918 91.7875 100.79 91.6469 100.728 91.4748C100.666 91.3027 100.675 91.1131 100.754 90.9478C100.832 90.7824 100.973 90.6549 101.146 90.5932C101.319 90.5315 101.509 90.5407 101.675 90.6187L108.345 93.7569C108.427 93.7956 108.501 93.85 108.562 93.917C108.623 93.9841 108.67 94.0624 108.701 94.1477C108.732 94.2329 108.745 94.3233 108.741 94.4137C108.736 94.5042 108.714 94.5928 108.675 94.6747C108.637 94.7566 108.582 94.83 108.515 94.8909C108.447 94.9517 108.369 94.9987 108.283 95.0293C108.198 95.0598 108.107 95.0733 108.016 95.0689C107.925 95.0645 107.836 95.0424 107.754 95.0037Z'
        fill='white'
      />
      <path
        d='M108.793 92.8182L102.123 89.68C101.957 89.6019 101.829 89.4614 101.767 89.2893C101.705 89.1171 101.714 88.9276 101.793 88.7622C101.871 88.5969 102.012 88.4693 102.185 88.4076C102.358 88.3459 102.548 88.3551 102.714 88.4332L109.384 91.5714C109.55 91.6494 109.678 91.79 109.74 91.9621C109.802 92.1342 109.793 92.3238 109.714 92.4892C109.636 92.6545 109.495 92.782 109.322 92.8438C109.15 92.9055 108.959 92.8962 108.793 92.8182Z'
        fill='white'
      />
      <path
        d='M99.8806 24.1862L99.2596 24.254C96.4913 13.415 91.1253 5.9059 83.7412 2.53828C74.3542 -1.74256 62.7468 1.13211 51.0572 10.6329C39.3082 20.1821 28.9072 35.2658 21.7704 53.106L21.168 52.8313C28.3361 34.9132 38.7904 19.7566 50.6053 10.1543C62.4794 0.503398 74.3237 -2.39234 83.9562 2.00058C91.5521 5.4647 97.0586 13.1364 99.8806 24.1862Z'
        fill='#F2F2F2'
      />
      <path
        d='M31.3793 38.3024C29.7555 41.7104 28.2111 45.2412 26.7888 48.7964C18.6043 69.2558 14.4567 90.1502 14.7956 109.222L14.1559 109.154C13.8167 90.0112 17.9762 69.0449 26.1864 48.5217C27.6127 44.9564 29.1616 41.4157 30.79 37.998L31.3793 38.3024Z'
        fill='#F2F2F2'
      />
      <path
        d='M31.0707 52.361C29.447 55.769 27.9025 59.2998 26.4802 62.855C18.2957 83.3144 14.1481 104.209 14.487 123.28L13.8473 123.212C13.5081 104.07 17.6676 83.1035 25.8778 62.5803C27.3041 59.015 28.853 55.4743 30.4814 52.0566L31.0707 52.361Z'
        fill='#F2F2F2'
      />
      <path
        d='M13.8787 57.4724C14.5858 56.4135 14.1672 54.8977 12.9438 54.0867C11.7204 53.2758 10.1554 53.4768 9.44832 54.5357C8.7412 55.5947 9.15973 57.1105 10.3831 57.9215C11.6065 58.7324 13.1715 58.5314 13.8787 57.4724Z'
        fill='#CCCCCC'
      />
      <path
        d='M164.449 95.7146C165.156 94.6557 164.738 93.1399 163.514 92.3289C162.291 91.518 160.726 91.719 160.019 92.7779C159.312 93.8369 159.73 95.3527 160.953 96.1637C162.177 96.9746 163.742 96.7736 164.449 95.7146Z'
        fill='#CCCCCC'
      />
    </g>
    <defs>
      <clipPath id='clip0_389_18900'>
        <rect width='198' height='160' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Icon
