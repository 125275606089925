import React, { FC } from 'react'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CardMedia from '@mui/material/CardMedia'
import PromotionStatus from 'src/components/Statuses/PromotionStatus'
import Card from '@mui/material/Card'
import { Promotion, PromotionType } from 'src/models/promotions'
import { PromotionDraftUpdate } from 'src/store/createPromotion/types'
import DiscountLabel from './DiscountLabel'

interface PromotionCardProps {
  promotion: Promotion | PromotionDraftUpdate
  imageURL?: string
  displayStatus?: boolean
}

export const PromotionCard: FC<PromotionCardProps> = ({
  promotion,
  imageURL,
  displayStatus = false
}) => {
  const { discountPercentage, fixedDiscount, currency, locations, image, title, type } = promotion
  const location = locations?.[0]
  const promotionImageURL = image?.url || (image as any)?.preview
  const locationImageURL = location?.image?.url || (location?.image as any)?.preview

  const discount =
    type === PromotionType.percentageDiscount
      ? `${discountPercentage}% Discount`
      : `${fixedDiscount} ${currency || ''}`

  return (
    <Card
      sx={{
        display: 'flex',
        borderRadius: 4
      }}
      elevation={1}
    >
      <CardContent
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography
            fontWeight={600}
            fontSize={15}
            sx={{ maxHeight: '4.5rem', overflow: 'hidden' }}
          >
            {title}
          </Typography>
          <DiscountLabel data={promotion} />
        </Box>
        <Box
          component='div'
          overflow='hidden'
          whiteSpace='pre-line'
          textOverflow='ellipsis'
          lineHeight={30}
        >
          <Typography
            mt={2}
            fontSize={12}
            textAlign={'left'}
            fontWeight={'400'}
            color='text.secondary'
            sx={{ maxHeight: '16px' }}
          >
            {location?.address}
          </Typography>
        </Box>
      </CardContent>
      {imageURL || promotionImageURL || locationImageURL ? (
        <CardMedia
          component='img'
          sx={{ width: 160, height: 160 }}
          image={imageURL || promotionImageURL || locationImageURL}
          alt={'image'}
        />
      ) : null}
      {displayStatus && (
        <Box onClick={e => e.preventDefault()} sx={{ top: 6, right: 6, position: 'absolute' }}>
          <PromotionStatus promotion={promotion} type={'list'} />
        </Box>
      )}
    </Card>
  )
}
