import { ActiveOperation } from './types'
import { State } from 'src/models'

export const isFetchingCountryOTPRequestMethodsList = (state: State) =>
  state.auth.activeOperation === ActiveOperation.getCountryOTPRequestMethodsList
export const allowedOTPMethods = (state: State) => state.auth.allowedOTPMethods
export const isSendingPhoneVerification = (state: State) =>
  state.auth.activeOperation === ActiveOperation.sendPhoneVerification
export const nextPhoneVerificationRequestAvailableAt = (state: State) =>
  state.auth.signInData.nextPhoneVerificationRequestAvailableAt
export const isSendLimitReached = (state: State) => state.auth.signInData.sendLimitReached
export const signInData = (state: State) => state.auth.signInData
export const countriesOTPRequestMethodsList = (state: State) =>
  state.auth.countriesOTPRequestMethodsList
export const user = (state: State) => state.auth.user
export const providerAdmins = (state: State) => state.auth.providerAdmins
export const currentProvider = (state: State) => state.auth.currentProvider
export const isFoodicsConnectedToCurrentProvider = (state: State) =>
  !!state.auth.currentProvider?.integrations?.foodics?.user
export const editProfileData = (state: State) => state.auth.editProfileData
export const isSigningIn = (state: State) => state.auth.activeOperation === ActiveOperation.signIn
export const isSavingProfile = (state: State) =>
  state.auth.activeOperation === ActiveOperation.saveProfile
export const isUpdatingCurrentProvider = (state: State) =>
  state.auth.activeOperation === ActiveOperation.updateCurrentProvider
export const isCreatingProvider = (state: State) =>
  state.auth.activeOperation === ActiveOperation.createProvider
export const error = (state: State) => state.auth.error
export const redirectUrlAfterLogin = (state: State) => state.auth.redirectURL

// Sign Up
export const signUpData = (state: State) => state.auth.signUpData
export const validationErrors = (state: State) => state.auth.validationErrors
