import React, { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { Loader } from '@googlemaps/js-api-loader'
import { TextInput } from '..' // Assuming TextInput is a custom component you have

interface PlaceType {
  description: string
  place_id: string // Added to store the place ID from Google Places
}

interface AutocompleteCityProps {
  city?: string
  onChange: (value: string, googlePlaceId?: string) => void
  countryCode: string // ISO 3166-1 Alpha-2 country code
}

const apiKey = 'AIzaSyBABOnefpq2-1RrMIM_CX7BpMZ0xu6tyHY'

const AutocompleteCity: React.FC<AutocompleteCityProps> = ({ city, onChange, countryCode }) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<PlaceType[]>([])

  useEffect(() => {
    inputValue && onChange('')
    inputValue && setInputValue('')
  }, [countryCode])

  const fetchPlaces = async (input: string) => {
    const loader = new Loader({
      apiKey,
      libraries: ['places']
    })

    const google = await loader.load()

    const service = new google.maps.places.AutocompleteService()
    const request = {
      input,
      types: ['(cities)'],
      componentRestrictions: { country: countryCode }
    }

    service.getPlacePredictions(request, (results: any, status: any) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results) {
        const newOptions = results.map((result: any) => ({
          description: result.description,
          place_id: result.place_id // Capture the place_id for each result
        }))
        setOptions(newOptions)
      } else {
        setOptions([])
      }
    })
  }

  useEffect(() => {
    let active = true

    if (!inputValue) {
      setOptions([])
      return undefined
    }

    fetchPlaces(inputValue)

    return () => {
      active = false
    }
  }, [inputValue, countryCode])

  return (
    <Autocomplete
      //   freeSolo
      options={options}
      //@ts-ignore
      getOptionLabel={option => option.description}
      onInputChange={(event, newInputValue, reason) => {
        setInputValue(newInputValue)
        // Added logic to pass placeId to onChange
        if (reason === 'reset') {
          const selectedOption = options.find(option => option.description === newInputValue)
          onChange(newInputValue, selectedOption?.place_id)
        } else {
          onChange(newInputValue)
        }
      }}
      renderInput={params => (
        <TextInput {...params} autoComplete='off' placeholder={city || 'Enter your City'} />
      )}
    />
  )
}

export default AutocompleteCity
