import { useSelector, useDispatch } from 'react-redux'
import { ChangeEvent, useEffect, useState } from 'react'
import authStore from 'src/store/auth'
import { useLocation, useParams } from 'react-router-dom'
import { SignUpData } from 'src/store/auth/types'
import { OTPMethods } from 'src/models/auth'
import { validatePhoneNumber } from 'src/utils/validateField'
import usePrevious from 'src/hooks/usePrevious'
import axios from 'axios'

type ValidationErrors<K> = {
  [P in keyof K]?: string
}

const api = axios.create({
  baseURL: 'https://the-hub-devel.f17y.com'
})

function useSignUpStore() {
  const dispatch = useDispatch()
  const location = useLocation()
  const params = useParams()
  const draft = useSelector(authStore.selectors.signUpData)
  const validationErrors = useSelector(authStore.selectors.validationErrors)
  const allowedOTPMethods = useSelector(authStore.selectors.allowedOTPMethods)
  const isSendingPhoneVerification = useSelector(authStore.selectors.isSendingPhoneVerification)
  const isSendLimitReached = useSelector(authStore.selectors.isSendLimitReached)

  const nextOTPRequestAvailableAt = useSelector(
    authStore.selectors.nextPhoneVerificationRequestAvailableAt
  )
  const sendPhoneVerification = (recaptcha: string, messenger: OTPMethods) =>
    dispatch(
      authStore.actions.sendPhoneVerification.started({
        operation: 'signUp',
        messenger,
        recaptcha
      })
    )

  const signUp = () => dispatch(authStore.actions.signUp.started())

  const updateDraft = (fieldName: string, value: any) =>
    dispatch(
      authStore.actions.updateSignUpData({
        [fieldName]: value
      })
    )

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    updateDraft(name, value)
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    updateDraft(name, checked)
  }

  const handleSelectChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const name = event.target.name!
    const value = event.target.value as string // Assuming value is always a string
    updateDraft(name, value)
  }

  //@ts-ignore
  const validateStep1Fields = async (): ValidationErrors<SignUpData> => {
    const fields = draft
    const newErrors: ValidationErrors<SignUpData> = {}

    // Validate full name (simple non-empty check here, adjust as needed)
    if (!fields.fullName) {
      newErrors.fullName = 'Full name is required.'
    }

    if (!fields.email) {
      newErrors.email = 'Email is required.'
    }

    // Validate email using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (fields.email && !emailRegex.test(fields.email)) {
      newErrors.email = 'Invalid email format.'
    }

    if (!fields.phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required.'
    }

    // Validate phone number using libphonenumber-js
    if (fields.phoneNumber && !validatePhoneNumber(fields.phoneNumber)) {
      newErrors.phoneNumber = 'Invalid phone number.'
    }

    try {
      const response = await api.post('/api/auth/check-if-user-registered', {
        _type: 'CheckIfUserRegistered',
        phoneNumber: fields.phoneNumber,
        email: fields.email
      })

      if (response.data.phoneNumberIsTaken) {
        newErrors.phoneNumber = 'Phone number is already taken'
      }

      if (response.data.emailIsTaken) {
        newErrors.email = 'Email is already taken'
      }
      dispatch(authStore.actions.setValidationsErrors(newErrors))
      return newErrors
    } catch (error) {
      dispatch(authStore.actions.setValidationsErrors(newErrors))
      return newErrors
    }

    dispatch(authStore.actions.setValidationsErrors(newErrors))
    return newErrors
  }

  // ------ Step2
  const validateStep2Fields = (): ValidationErrors<SignUpData> => {
    const fields = draft
    const newErrors: ValidationErrors<SignUpData> = {}

    if (!fields.brandName) {
      newErrors.brandName = 'Brand name is required.'
    }

    if (!fields.providerName) {
      newErrors.providerName = 'Organisation name is required.'
    }

    if (!fields.contactEmail) {
      newErrors.contactEmail = 'Email is required.'
    }

    if (fields.contactPhoneNumber && !validatePhoneNumber(fields.contactPhoneNumber)) {
      newErrors.phoneNumber = 'Invalid phone number.'
    }

    if (!fields.countryCode) {
      newErrors.countryCode = 'Country is required.'
    }

    dispatch(authStore.actions.setValidationsErrors(newErrors))
    return newErrors
  }

  const isFirstStepCompleted = () => {
    const { fullName, email, phoneNumber } = draft

    const isValidNumber = phoneNumber && phoneNumber?.length > 5
    return !!fullName && !!email && !!isValidNumber
  }

  const isSecondStepCompleted = () => {
    const { countryCode, contactEmail, brandName, providerName, website, contactPhoneNumber } =
      draft

    const isValidNumber = contactPhoneNumber && contactPhoneNumber?.length > 5
    return (
      !!countryCode && !!contactEmail && !!isValidNumber && !!brandName && providerName && website
    )
  }

  return {
    draft,
    allowedOTPMethods,
    errors: validationErrors,
    nextOTPRequestAvailableAt,
    isSendingPhoneVerification,
    isSendLimitReached,
    isFirstStepCompleted,
    isSecondStepCompleted,
    validateStep2Fields,
    validateStep1Fields,
    updateDraft,
    handleInputChange,
    handleSelectChange,
    handleCheckboxChange,
    sendPhoneVerification,
    signUp
  }
}

export default useSignUpStore
