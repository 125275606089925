import actionCreatorFactory from 'typescript-fsa'

import {
  GetInTouchMessageCreate,
  ProviderSubscription,
  ProviderSubscriptionResponse,
  ProviderSubscriptionStatus,
  SubscriptionCreate,
  SubscriptionPlan,
  SubscriptionStatus
} from 'src/models/subscriptions'
import { SubscriptionUpdateParams } from './types'

// Actions
const actionCreator = actionCreatorFactory('subscriptions')

export const fetchPlans = actionCreator.async<string | undefined, SubscriptionPlan[], Error>(
  'FETCH_PLANS'
)
export const createSubscription = actionCreator.async<SubscriptionCreate, void, Error>('CREATE')
export const fetchSubscriptions = actionCreator.async<void, ProviderSubscriptionResponse, Error>(
  'FETCH_SUBSCRIPTIONS'
)

export const fetchStatus = actionCreator.async<void, ProviderSubscriptionStatus, Error>(
  'FETCH_STATUS'
)

export const getInTouchMessageCreate = actionCreator.async<GetInTouchMessageCreate, void, Error>(
  'MESSAGE_CREATE'
)

export const dismissError = actionCreator<void>('DISMISS_ERROR')

export const updateSubscription = actionCreator.async<
  SubscriptionUpdateParams,
  ProviderSubscription,
  Error
>('UPDATE_SUBSCRIPTIONS')
