import React, { useEffect } from 'react'
import { Container, Box, Typography, Button, TextField, Paper } from '@mui/material'
import PromotionAppPreview from 'src/components/AppPreview/Promotion'
import { PrimaryButton, SecondaryButton, Spinner } from 'src/components/index'
import { PageTitle, Text14pxPoppins } from 'src/components/Typography'
import { useDispatch, useSelector } from 'react-redux'
import promotions from 'src/store/promotions'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ProviderLocationId } from 'src/models/providerLocations'
import { State } from 'src/models'

const LocationCreationScreen = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()

  const locationId: ProviderLocationId = params?.id || (location?.state as any)?.id

  const item = useSelector((state: State) => promotions.selectors.promotionById(state, locationId))

  const isUpdatingStatus = useSelector(promotions.selectors.isUpdatingStatus)

  const onChangeStatus = () => {
    dispatch(promotions.actions.updateStatus.started({ id: locationId, status: 'live' }))
  }

  const addMore = () => {
    navigate('/promotions/create')
  }

  const back = () => {
    navigate('/promotions')
  }

  return (
    <Container maxWidth={false}>
      <Box my={4} display='flex' flexDirection='column' alignItems='center'>
        <PageTitle marginTop={2} marginBottom={4}>
          Congratulations! You have successfully created the promotion
        </PageTitle>

        <PromotionAppPreview item={item} />

        <Container maxWidth={'sm'}>
          <Box
            gap={2}
            mt={3}
            sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 2, p: 0 }}
          >
            <SecondaryButton onClick={back} variant='outlined' fullWidth>
              Save as draft
            </SecondaryButton>
            {item.status !== 'live' ? (
              <PrimaryButton onClick={onChangeStatus} fullWidth>
                Publish
              </PrimaryButton>
            ) : null}
          </Box>
          <Box
            gap={2}
            mt={3}
            sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 2, p: 0 }}
          >
            <Button onClick={addMore} sx={{ alignSelf: 'center' }} variant='text'>
              Create one more
            </Button>
          </Box>
        </Container>
      </Box>
      <Spinner loading={isUpdatingStatus} />
    </Container>
  )
}

export default LocationCreationScreen
