import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'

import authStore from 'src/store/auth'
import Typography from '@mui/material/Typography'
import Spinner from 'src/components/Spinner'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import ResendButtons from 'src/components/OTP/ResendButtons'
import moment from 'moment'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { OTPMethods } from 'src/models/auth'
import OtpInput from 'react-otp-input'

import LoginHeader from 'src/components/Header/LoginHeader'
import OTPIcon from 'src/assets/svg/OTP'
import { calculateResponsiveFontSize } from 'src/theme/index'
import { PrimaryButton, SecondaryButton } from 'src/components/index'
import AuthLayout from 'src/components/Layouts/AuthLayout'
import { PageTitle } from 'src/components/Typography'
import { ResendOTP } from 'src/components/OTP/ResendOTPLink'

const OneTimePassword = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'otp' })

  const [captchaToken, setCaptchaToken] = useState<string | undefined>()
  const [activeMessenger, setActiveMessenger] = useState<OTPMethods | undefined>()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const error = useSelector(authStore.selectors.error)
  const isSigningIn = useSelector(authStore.selectors.isSigningIn)
  const signInData = useSelector(authStore.selectors.signInData)
  const isSendingPhoneVerification = useSelector(authStore.selectors.isSendingPhoneVerification)
  const nextOTPRequestAvailableAt = useSelector(
    authStore.selectors.nextPhoneVerificationRequestAvailableAt
  )
  const isSendLimitReached = useSelector(authStore.selectors.isSendLimitReached)
  const allowedOTPMethods = useSelector(authStore.selectors.allowedOTPMethods)

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Recaptcha in not yet available')
      return
    }

    const token = await executeRecaptcha('resendOTP')

    setCaptchaToken(token)
  }, [executeRecaptcha])

  // When got captcha token request OTP
  useEffect(() => {
    if (!captchaToken) {
      return
    }

    resendOTP('sms_with_captcha')
  }, [captchaToken])

  useEffect(() => {
    if (
      !isSendingPhoneVerification &&
      !isSendLimitReached &&
      !isSigningIn &&
      !error &&
      !!signInData.phoneNumber
    ) {
      enqueueSnackbar(t('message_sent'), {
        variant: 'success'
      })
    }
  }, [isSendingPhoneVerification, isSigningIn, error])

  useEffect(() => {
    if (isSendLimitReached) {
      const date = moment(nextOTPRequestAvailableAt)
      const difference = moment(date).diff(moment())
      const duration = moment.duration(difference)

      // Show warning that we cannot send message, as we hit long time limit,
      // that's why we display hours and minutes left until next sms, instead of minutes and seconds
      enqueueSnackbar(
        t('otp_limit_reached') +
          (difference > 0
            ? t('try_again_in_long')
                .replace('%hours%', duration.hours().toString())
                .replace('%minutes%', duration.minutes().toString())
            : ''),
        {
          variant: 'warning'
        }
      )
    }
  }, [isSendLimitReached])

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    closeSnackbar()
    dispatch(authStore.actions.signIn.started())
  }

  const handleResendOTP: ResendOTP = (event, messenger) => {
    if ((isSendingPhoneVerification || isSendLimitReached) && messenger !== 'whatsapp') {
      event.preventDefault()
      return
    }

    if (messenger === 'sms_with_captcha') {
      handleReCaptchaVerify()
      return
    }

    resendOTP(messenger)
  }

  const resendOTP = (messenger: OTPMethods) => {
    if (messenger !== 'whatsapp') {
      const timeToNextMessageAvailable = moment.duration(
        moment(nextOTPRequestAvailableAt).diff(moment())
      )

      // Show warning that we cannot send one more message now, as we hit time limit
      if (timeToNextMessageAvailable.as('seconds') > 0) {
        enqueueSnackbar(
          `${t('otp_limit_reached')}${t('try_again_in_short')
            .replace('%minutes%', timeToNextMessageAvailable.minutes().toString())
            .replace('%seconds%', timeToNextMessageAvailable.seconds().toString())}`,
          {
            variant: 'warning'
          }
        )

        return
      }
    }

    setActiveMessenger(messenger)

    dispatch(
      authStore.actions.sendPhoneVerification.started({
        operation: 'signIn',
        messenger,
        recaptcha: captchaToken
      })
    )
  }

  const handleChange = (value: string) => {
    dispatch(
      authStore.actions.updateSignInData({
        otp: value
      })
    )
  }

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
        onExited: () => dispatch(authStore.actions.dismissError())
      })
    }
  }, [error])

  useEffect(() => {
    if (!signInData.phoneNumber) {
      navigateBack()
    }
  }, [])

  const navigateBack = () => {
    navigate(-1)
  }

  const otpInputStyle = {
    width: '5rem', // Width of each input field
    height: '4rem',
    margin: '0 1rem',
    fontSize: '1rem',
    backgroundColor: '#F7F9F8',
    borderRadius: '8px',
    border: '1px solid transparent'
  }

  return (
    <>
      <LoginHeader />
      <AuthLayout
        icon={<OTPIcon />}
        title={'Enhance visibility'}
        subtitle={`Enhance your visibility on our platform to attract and engage a broader audience,
        rightfully gaining the attention it deserves.`}
      >
        <PageTitle mb={5}>Log in</PageTitle>

        <Typography
          variant='subtitle1'
          sx={{
            mb: 2,
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: '#292B2A',
            lineHeight: `${calculateResponsiveFontSize(1)}rem`,
            fontSize: `${calculateResponsiveFontSize(0.875)}rem`,
            letterSpacing: '0em'
          }}
        >
          Verification code was sent to {signInData.phoneNumber}
        </Typography>

        <Typography
          variant='subtitle2'
          mb={2}
          sx={{
            fontFamily: 'Rany',
            fontWeight: 700,
            lineHeight: '21px',
            textAlign: 'left'
          }}
        >
          Enter verification code
        </Typography>

        <OtpInput
          value={signInData.otp}
          onChange={handleChange}
          numInputs={4}
          renderSeparator={<span>-</span>}
          inputStyle={otpInputStyle}
          renderInput={props => <input {...props} />}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            mt: 5,
            mb: 2
          }}
        >
          <SecondaryButton variant='outlined' sx={{ mr: 1, flexGrow: 1 }} onClick={navigateBack}>
            Back
          </SecondaryButton>
          <PrimaryButton
            variant='contained'
            color='success'
            sx={{ ml: 1, flexGrow: 1 }}
            //@ts-ignore
            onClick={onSubmit}
            disabled={signInData?.otp?.length !== 4}
          >
            Continue
          </PrimaryButton>
        </Box>

        <ResendButtons activeMessenger={activeMessenger} handleResendOTP={handleResendOTP} />

        <Spinner loading={isSigningIn} />
      </AuthLayout>
    </>
  )
}
export default OneTimePassword
