import { Outlet } from 'react-router-dom'
import { RequireAuth } from '../RequireAuth'
import { CheckProviderOrNavigateToCreate } from 'src/components/CheckProviderOrNavigateToCreate'
import SubscriptionRedirect from 'src/components/SubscriptionRedirect'
import Header from '../Header'

function Layout() {
  return (
    <div>
      <RequireAuth>
        <SubscriptionRedirect>
          <CheckProviderOrNavigateToCreate>
            <>
              <Header />
              <Outlet />
            </>
          </CheckProviderOrNavigateToCreate>
        </SubscriptionRedirect>
      </RequireAuth>
    </div>
  )
}

export default Layout
