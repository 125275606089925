import { useEffect, useState } from 'react'
// @mui
import { styled } from '@mui/material/styles'
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormLabel,
  Stack,
  Typography
} from '@mui/material'
// components
import Counter from './Counter'
import { SubscriptionPlan } from 'src/models/subscriptions'
import i18next from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import subscriptionsStore from 'src/store/subscriptions'
import { useTranslation } from 'react-i18next'
import Discount from './Price'
import { PrimaryButton, SecondaryButton } from 'src/components'
import { PlanCard, PlanTitle } from './Components'
import Price from './Price'
import { Text14pxRany } from 'src/components/Typography'
import { calculateResponsiveFontSize } from 'src/theme/index'
import { Link } from 'react-router-dom'

// ----------------------------------------------------------------------

type Props = {
  card: SubscriptionPlan
  index: number
}

export default function PricingPlanCard({ card, index }: Props) {
  const dispatch = useDispatch()
  const { t } = useTranslation('translation')

  const isCreating = useSelector(subscriptionsStore.selectors.isCreating)

  const [count, setCount] = useState(1)

  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    !isCreating && setProcessing(false)
  }, [isCreating])

  const onSubmit = (startTrial: boolean) => () => {
    setProcessing(true)
    dispatch(
      subscriptionsStore.actions.createSubscription.started({
        subscriptionPlanId: card.id,
        startTrial: startTrial,
        locationsCount: count
      })
    )
  }
  const { i18n } = card
  const strings = i18n?.[i18next?.language] || i18n?.['en']

  //@ts-ignore
  const isAnnual = card.id === 'hub_subscription_annual_2023'

  const btnPrice = card.discountPrice ? card.discountPrice : card.price
  // Button
  const buttonProps = {
    fullWidth: true,
    onClick: onSubmit(true),
    disabled: processing,
    children: processing ? (
      <CircularProgress size={24} color='inherit' />
    ) : (
      t('subscription_plan.btn_subscribe', {
        price: `$${(+btnPrice * count).toFixed(2)}`
      })
    )
  }

  const renderButton = () =>
    isAnnual ? (
      <PrimaryButton
        sx={{
          backgroundColor: 'white',
          color: '#04BC79',
          '&:hover': {
            backgroundColor: '#F0F0F0'
          }
        }}
        {...buttonProps}
      />
    ) : (
      <SecondaryButton variant='outlined' {...buttonProps} />
    )

  return (
    //@ts-ignore
    <PlanCard highlight={isAnnual}>
      <PlanTitle isAnnual={isAnnual} />
      <Price data={card} isAnnual={isAnnual} />
      <Text14pxRany
        style={{
          color: isAnnual ? 'white' : '#000000',
          fontSize: `${calculateResponsiveFontSize(0.875)}rem`,
          lineHeight: `${calculateResponsiveFontSize(1.3)}rem`
        }}
        textAlign={'left'}
      >
        {t(strings?.description)}
      </Text14pxRany>

      <Counter value={count} onChange={setCount} isAnnual={isAnnual} />
      {renderButton()}
    </PlanCard>
  )
}

export const CustomPlanCard = () => {
  return (
    //@ts-ignore
    <PlanCard style={{ height: '430px', justifyContent: 'space-between' }} highlight={false}>
      <PlanTitle isAnnual={false} text={'Enterprise'} />
      <Text14pxRany
        style={{
          flex: 'dispay',
          color: '#000000',
          fontSize: `${calculateResponsiveFontSize(0.875)}rem`,
          lineHeight: `${calculateResponsiveFontSize(1.3)}rem`
        }}
        textAlign={'left'}
      >
        {'Contact us to get information about pricing for organisations'}
      </Text14pxRany>
      <SecondaryButton
        //@ts-ignore
        component={Link}
        to={'/custom-plan'}
        display={'flex'}
        style={{ textDecoration: 'none' }}
        fullWidth
      >
        Contact us
      </SecondaryButton>
    </PlanCard>
  )
}
