import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { calculateResponsiveFontSize, resizePx } from 'src/theme'

// CustomButtonProps now extends the ButtonProps from MUI
interface CustomButtonProps extends ButtonProps {}

// Styled button with custom styles
const StyledButton = styled(Button)(({ theme }) => ({
  // Custom styles here
  // padding: '0px 16px',
  minHeight: '2.725rem', // Fixed height 40
  padding: '12px 24px', // Vertical padding 0px, Horizontal padding 24px
  borderRadius: 8, // Border radius
  color: '#292B2A',
  borderColor: '#292B2A',
  border: '1.5px solid', // Border width and style on hover

  textTransform: 'none',
  fontFamily: '"Rany", sans-serif', // Apply the custom font
  fontSize: `${calculateResponsiveFontSize(0.875)}rem`,
  fontWeight: 500, // Font weight
  lineHeight: '21px', // Line height

  // margin: '8px', // Gap outside the button (if you meant inner gap, this will not apply)

  '& .MuiButton-startIcon, & .MuiButton-endIcon': {
    color: '#292B2A' // Default icon color - replace with your desired color
  },
  // Hover effect
  '&:hover': {
    backgroundColor: '#F0F2F1',
    border: '1.5px solid' // Border width and style on hover
    // Other hover styles if needed
  },
  // Focus effect
  '&:focus': {
    backgroundColor: '#F0F2F1',
    border: '1.5px solid', // Border width and style on hover
    borderColor: '#F0F2F1'
    // Other focus styles if needed
  },
  // Disabled state
  '&.Mui-disabled': {
    backgroundColor: '#D1D9D6'
    //color: '#D1D9D6'
  },
  // Custom styles for icon placement
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(1)
  },
  '& .MuiButton-endIcon': {
    marginLeft: theme.spacing(1)
  }
}))

const CustomButton: React.FC<CustomButtonProps> = ({ children, ...props }) => {
  return (
    <StyledButton
      {...props} // Spread all props to the MUI Button
    >
      {children}
    </StyledButton>
  )
}

export default CustomButton
