import React from 'react'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import { ProviderLocationStatus } from 'src/models/providerLocations'
import { PromotionStatus } from 'src/models/promotions'
import { useTranslation } from 'react-i18next'
import { calculateResponsiveFontSize } from 'src/theme'
export type TStatus = ProviderLocationStatus | PromotionStatus

type LabelProps = {
  status: TStatus
}

const StyledChip = styled(Chip)<{ status: TStatus }>(({ theme, status }) => {
  let backgroundColor

  switch (status) {
    case 'declined':
      backgroundColor = '#E42121'
      break
    case 'draft':
      backgroundColor = '#FFC600'
      break
    case 'live':
      backgroundColor = '#5ABA1A'
      break
    default:
      backgroundColor = theme.palette.grey[500]
  }
  return {
    backgroundColor,
    color: theme.palette.common.white, // Assuming you want the text color to be white
    borderRadius: '4px', // border-radius set to 4px
    fontSize: `${calculateResponsiveFontSize(0.75)}rem`, // font-size set to 12px
    fontWeight: 400, // font-weight set to 400
    lineHeight: `1.5rem`, // line-height set to 18px
    //letterSpacing: '0em', // letter-spacing set to 0em
    textAlign: 'left', // text-align set to left
    minWidth: '54px', // width set to 54px
    height: `${calculateResponsiveFontSize(1.5)}rem`, // height set to 22px
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    '& .MuiChip-label': {
      padding: 0, // Remove padding from the label if necessary
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
})

const Label: React.FC<LabelProps> = ({ status }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'statuses' })

  return <StyledChip label={t([status])} size='small' status={status} />
}

export default Label
