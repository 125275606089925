import React from 'react'
import { MapPicker } from 'src/components'
import { InputLabelWrapper } from 'src/components/Base/InputWithLabel'
import useLocationStore from './useLocationsStore'
import { PlaceInfo } from 'src/components/Pickers/MapPicker/google/types'

const LocationAddressForm = () => {
  const { errros, draft, updateDraftData } = useLocationStore()

  const handleChangeLocation = (placeInfo: PlaceInfo) => {
    updateDraftData({
      location: placeInfo.location,
      address: placeInfo.fullAddress,
      googlePlaceId: placeInfo.placeId
    })
  }

  return (
    <InputLabelWrapper
      label={'Location address'}
      error={!!errros.location}
      helperText={errros.address || errros.location}
    >
      <MapPicker
        changeLocation={handleChangeLocation}
        location={draft?.location}
        address={draft?.address}
      />
    </InputLabelWrapper>
  )
}

export default LocationAddressForm
