import React from 'react'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { calculateResponsiveFontSize, resizePx } from 'src/theme'

export const CustomCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#F7F9F8',
  padding: theme.spacing(2),
  borderRadius: '12px',
  margin: '16px', // Adjusted for consistency, as 'm: 2' depends on the theme's spacing unit
  boxSizing: 'border-box',
  transition: 'box-shadow 0.3s ease-in-out',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)'
  }
}))

export const Label = styled(Typography)`
  font-family: 'Rany', sans-serif; /* Fallback to sans-serif in case 'Rany' is not available */
  font-size: 1rem;
  font-weight: 700; /* Bold */
  line-height: 1.5rem;
  letter-spacing: 0em; /* Normal spacing */
  text-align: left;
  color: ${({ theme }) => theme.palette.text.primary}; /* Using color from theme palette */
`

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rany, Arial, sans-serif', // Assuming 'Rany' is correctly loaded in your project
  fontSize: '0.875rem',
  fontWeight: 400,
  color: theme.palette.text.secondary,
  lineHeight: '1.31rem',
  letterSpacing: '0em',
  textAlign: 'left'
}))

export const Category = styled(Chip)({
  padding: '2px 12px',
  borderRadius: 4,
  backgroundColor: '#FF8A00',
  fontFamily: 'Poppins, sans-serif', // Ensure Poppins is loaded in your project
  fontSize: `${calculateResponsiveFontSize(0.875)}rem`,
  fontWeight: 400,
  lineHeight: '18px', // Adjusted for CSS, original line-height might not directly apply
  letterSpacing: '0em',
  textAlign: 'left', // Might not be needed as Chip content is centered by default
  '& .MuiChip-label': {
    fontFamily: 'Poppins, sans-serif',
    fontSize: `${calculateResponsiveFontSize(0.75)}rem`,
    fontWeight: 400,
    color: 'white',
    lineHeight: 18,
    letterSpacing: '0em',
    textAlign: 'left'
  }
})
