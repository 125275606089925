import React from 'react'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'

export const ProfileCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '67px', // Minimum height remains unchanged
  padding: theme.spacing(1, 2), // 8px top & bottom, 16px right & left
  borderRadius: theme.shape.borderRadius, // Utilize theme's border radius if applicable or set to '8px' if specific value is needed
  gap: theme.spacing(1), // 8px gap between items
  backgroundColor: '#F7F9F8',
  boxSizing: 'border-box',
  transition: 'box-shadow 0.3s ease-in-out',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)'
  }
}))
