import * as React from 'react'

import { InputLabelWrapper } from 'src/components/Base/InputWithLabel'
import { Dropdown, TextInput } from 'src/components'
import usePromotionStore from './usePromotionStore'
import providerLocationsStore from 'src/store/providerLocations'
import brandsStore from 'src/store/brands'
import { useSelector } from 'react-redux'
import { List, ListItem, Checkbox, Box, TextField, FormControlLabel, Switch } from '@mui/material'
import { InputLabel, Text14pxRany, Text16pxRany500 } from 'src/components/Typography'
import { uniq } from 'lodash'

export default function LocationList() {
  const { draft, updateDraft, handleSelectChange, errors } = usePromotionStore()
  const brandsOptions = useSelector(brandsStore.selectors.brandsOptions)
  const list = useSelector(providerLocationsStore.selectors.list)

  const handleBrandChange = () => true

  const handleToggle = (locationId: string) => {
    const ids = draft?.locationIds || []
    updateDraft(
      'locationIds',
      ids?.includes(locationId) ? ids?.filter(id => id !== locationId) : [...ids, locationId]
    )
  }

  const handleChange = (event: any) => updateDraft('foodicsRewardIsEnabled', event.target.checked)

  const locations = list?.filter(item => item.brandId === draft?.brandId)
  return (
    <>
      {brandsOptions?.length ? (
        <InputLabelWrapper label='Brand'>
          <Dropdown
            labelId='brand-label'
            id='brandId'
            name='brandId'
            fullWidth
            displayEmpty
            placeholder='Select your brand'
            //@ts-ignore
            onChange={handleSelectChange}
            value={undefined}
            options={brandsOptions}
            label='Brand*'
          />
        </InputLabelWrapper>
      ) : null}

      <Box sx={{ display: 'flex', mt: 3, flexDirection: 'row', justifyContent: 'space-between' }}>
        <InputLabel>Enable Foodics Reward</InputLabel>
        <FormControlLabel
          control={<Switch checked={draft?.foodicsRewardIsEnabled} onChange={handleChange} />}
          label={draft?.foodicsRewardIsEnabled ? 'Enabled' : 'Disabled'}
        />
      </Box>
      {locations.length ? (
        <InputLabelWrapper
          label='Locations'
          error={!!errors?.locationIds}
          helperText={errors?.locationIds || ''}
        >
          <Box sx={{ width: '100%', maxHeight: 'calc(50vh - 100px)', overflow: 'auto', ml: 1 }}>
            <List>
              {locations.map((location, index) => (
                <ListItem
                  key={index}
                  onClick={() => handleToggle(location.id!)}
                  style={{ cursor: 'pointer' }}
                >
                  <Checkbox
                    size='medium'
                    edge='start'
                    checked={draft?.locationIds?.includes(location?.id!)}
                  />
                  <Box ml={1}>
                    <Text16pxRany500 style={{ textAlign: 'left' }}>{location.name}</Text16pxRany500>
                    <Text14pxRany
                      mt={1}
                      style={{
                        color: '#B0B0B0',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '400px'
                      }}
                    >
                      {location.address}
                    </Text14pxRany>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </InputLabelWrapper>
      ) : null}
    </>
  )
}
