import React from 'react'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import { ProviderLocation } from 'src/models/providerLocations'
import SocialNetwork from 'src/assets/svg/SocialNetwork'
import LanguageIcon from '@mui/icons-material/Language'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import { IconButton } from '@mui/material'

interface Props {
  data: ProviderLocation
}

const SocialMediaList = ({ data }: Props) => {
  const socialLinks = [
    {
      url: `tel:${data.contactPhoneNumber}`,
      Icon: PhoneIcon,
      label: 'Phone'
    },
    {
      url: `mailto:${data.contactEmail}`,
      Icon: EmailIcon,
      label: 'Email'
    }
  ]

  const websiteUrl = data.websiteUrl || data.socialMedias?.[0]?.url

  if (websiteUrl) {
    socialLinks.push({
      url: websiteUrl,
      Icon: LanguageIcon,
      label: 'Website'
    })
  }

  // Example click handler
  const handleClick = (label: string, url: string) => {
    console.log(`Clicked on ${label}: ${url}`)
    // Here you can add more logic, such as tracking the click event
  }

  return (
    <Box>
      <List>
        {socialLinks.map((socialLink, index) => (
          <ListItem
            component='a'
            href={socialLink.url}
            onClick={() => handleClick(socialLink.label, socialLink.url)}
            sx={{
              paddingLeft: 0,
              paddingTop: 0,
              paddingBottom: 0,
              color: '#774BE1' // Apply color to text and icon button
            }}
            key={index}
          >
            <ListItemIcon>
              <IconButton
                edge='start'
                color='inherit'
                aria-label={socialLink.label}
                sx={{
                  color: '#774BE1' // Apply color to icon button
                }}
              >
                <socialLink.Icon />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={socialLink.url.replace(/^mailto:|^tel:/, '')} />
          </ListItem>
        ))}
      </List>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: 1 // Adds space between items
        }}
      >
        {data.socialMedias?.map((socialLink, index) => (
          <IconButton
            key={index}
            component='a'
            href={socialLink.url}
            aria-label={socialLink.socialMediaId}
            onClick={() => handleClick(socialLink.socialMediaId, socialLink.url)}
          >
            {
              //@ts-ignore
              SocialNetwork[socialLink.socialMediaId]?.({ color: '#774BE1', size: 18 })
            }
          </IconButton>
        ))}
      </Box>
    </Box>
  )
}

export default SocialMediaList
