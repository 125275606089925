import React, { useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import Item from './Item'
import { Promotion } from 'src/models/promotions'
import EmptyPlaceholder from 'src/components/List/EmptyPlaceholder'
import EmptyPromotions from 'src/assets/svg/EmptyPromotions'

type Props = {
  items: Promotion[]
}

const LocationstList = ({ items }: Props) => {
  if (!items.length) {
    return (
      <EmptyPlaceholder
        icon={<EmptyPromotions />}
        titleText={'You don’t have any promotions'}
        subtitleText={'You can add your first promotion by pressing the button below'}
        buttonText={'Add first promotion'}
        hrefLink={'create'}
      />
    )
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {items.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Item item={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default LocationstList
