import React from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { SecondaryButton } from 'src/components/index'
import { LocationCount, Text14pxRany, Text14pxRanyBold } from 'src/components/Typography'
import { Card, CardContent, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AdminProfile } from 'src/models/admins'
import LocationList from './LocationList'
import BrandsList from './BrandsList'

import ProfileIcon from 'src/assets/svg/Profile'

interface Props {
  item: AdminProfile
}

export const ProfileCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column', // stack items vertically
  justifyContent: 'space-between', // space between the items vertically
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#F7F9F8',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)'
  },
  marginTop: theme.spacing(2)
}))

const Brand = ({ item }: Props) => {
  const navigate = useNavigate()

  const role = item.isProviderOwner ? 'owner' : 'admin'

  const getLabelColor = () => {
    switch (role) {
      case 'owner':
        return '#5476FF'
      case 'admin':
        return '#FFC600'
      default:
        return 'gray'
    }
  }

  return (
    <ProfileCard>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', padding: 0, flex: '1' }}>
          <Box display={'flex'} mt={1} alignItems={'center'}>
            <ProfileIcon />
            <Text14pxRanyBold ml={2} gutterBottom>
              {item.name}
            </Text14pxRanyBold>
          </Box>

          <Box display={'flex'} mt={1} alignItems={'center'}>
            <Text14pxRany mr={2}>{'+' + item.phoneNumber}</Text14pxRany>
            <LocationCount sx={{ backgroundColor: getLabelColor() }} label={role} />
          </Box>
        </CardContent>
        <Box>
          <SecondaryButton
            onClick={() => navigate(`/admin/edit/${item.phoneNumber}`)}
            variant='outlined'
          >
            Edit
          </SecondaryButton>
        </Box>
      </Box>
      <BrandsList brands={item.brands} />
      <LocationList locations={item.locations} />
    </ProfileCard>
  )
}

export default Brand
