import { Action } from 'typescript-fsa'
import { SagaIterator } from 'redux-saga'

import { put, call, takeLatest, take, delay, select } from 'redux-saga/effects'
import { ProviderAdminCreate, Admin } from 'src/models/admins'
import { create, fetch, remove } from './actions'
import { draft, signUpDraft } from './selectors'

import { API } from 'src/data'
import auth from '../auth'
import mediaUploads from 'src/store/mediaUploads'
import { MediaUpload, MediaUploadList } from '../mediaUploads/types'
import { MediaFile, MediaUploads } from 'src/models/uploads'
import { Brand, BrandCreate, BrandId } from 'src/models/brands'
import { isEmpty } from 'lodash'
import { enqueueSnackbar } from 'notistack'

export default function createRootSaga() {
  function* createOrEditBrandSaga(): SagaIterator {
    try {
      let mediaUploadsInProgress: boolean = yield select(
        mediaUploads.selectors.mediaUploadsInProgress
      )
      while (mediaUploadsInProgress) {
        const update: Action<MediaUpload> = yield take(mediaUploads.actions.mediaUploadUpdate)
        mediaUploadsInProgress = yield select(mediaUploads.selectors.mediaUploadsInProgress)
      }

      const uploadedMedia: MediaUploadList = yield select(mediaUploads.selectors.mediaUploads)

      const images = uploadedMedia.map((item, index) =>
        (item as MediaFile).url
          ? { _type: 'ImageReorder', fileId: (item as MediaFile).id, order: index }
          : {
              _type: 'ImageCreate',
              uploadHandle: (item as MediaUpload).uploadHandle,
              order: index
            }
      ) as MediaUploads

      let data: BrandCreate = yield select(draft)
      if (isEmpty(data)) {
        data = yield select(signUpDraft)
      }
      const payload = {
        googlePlaceId: data.googlePlaceId,
        name: data.name,
        countryCode: data.countryCode,
        contactPhoneNumber: data.contactPhoneNumber,
        contactEmail: data.contactEmail,
        websiteUrl: data.websiteUrl,
        images: images
      } as BrandCreate
      let result: Brand
      if (data.id) {
        result = yield call([API, API.updateBrand], data.id, payload)
      } else {
        result = yield call([API, API.createBrand], payload)
      }

      yield put(create.done({ result }))
    } catch (error) {
      if (error instanceof Error) {
        yield put(create.failed({ error }))
        enqueueSnackbar?.(error.message, {
          variant: 'error'
        })
      }
    }
  }

  function* fetchBrandsSaga(): SagaIterator {
    try {
      const result = yield call([API, API.readBrands])
      yield put(fetch.done({ result: result }))
    } catch (error) {
      if (error instanceof Error) yield put(fetch.failed({ error }))
    }
  }

  function* removeSaga({ payload }: Action<BrandId>): SagaIterator {
    try {
      const result = yield call([API, API.deleteBrand], payload)
      yield put(remove.done({ params: payload, result }))
    } catch (error) {
      if (error instanceof Error) yield put(remove.failed({ params: payload, error }))
    }
  }

  function* authChangeSaga(): SagaIterator {
    try {
      const currentProvider = yield select(auth.selectors.currentProvider)
      if (currentProvider) {
        yield put(fetch.started())
      }
    } catch (error) {
      console.error('error')
    }
  }

  function* createProviderDoneSaga(): SagaIterator {
    try {
      const currentProvider = yield select(auth.selectors.currentProvider)
      if (currentProvider) {
        yield delay(1000)
        const draftAfterSignUp = yield select(signUpDraft)
        if (!isEmpty(draftAfterSignUp)) {
          yield call(createOrEditBrandSaga)
        }
      }
    } catch (error) {
      console.error('error')
    }
  }

  return [
    takeLatest(auth.actions.authChange, authChangeSaga),
    takeLatest(auth.actions.createProvider.done, createProviderDoneSaga),

    takeLatest(create.started, createOrEditBrandSaga),
    takeLatest(fetch.started, fetchBrandsSaga),
    takeLatest(remove.started, removeSaga)
  ]
}
