import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container } from '@mui/material'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import adminsStore from 'src/store/admins'

import Form from './Form'

import ContainerPageWithBack from 'src/components/Layouts/ContainerPageWithBack'
import { AdminProfileUpsert } from 'src/models/admins'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import usePrevious from 'src/hooks/usePrevious'

const App: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions' })
  const [adminProfile, setAdminProfile] = useState<AdminProfileUpsert>({})
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()
  const phoneNumber: string = params?.id || (location?.state as any)?.id

  const draft = useSelector((state: any) => adminsStore.selectors.adminById(state, phoneNumber))

  useEffect(() => {
    draft &&
      setAdminProfile({
        ...draft,
        brandIds: draft?.brands?.map(item => item.id) || [],
        locationIds: draft?.locations?.map(item => item.id!) || []
      })
  }, [draft])

  const updateProfile = (
    key: keyof AdminProfileUpsert,
    value: AdminProfileUpsert[keyof AdminProfileUpsert]
  ) => {
    setAdminProfile(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  const onSave = () => {
    dispatch(adminsStore.actions.upsert.started(adminProfile))
  }

  const isCreating = useSelector(adminsStore.selectors.isCreating)
  const error = useSelector(adminsStore.selectors.createError)
  const prevIsCreating = usePrevious(isCreating)

  useEffect(() => {
    if (!!isCreating !== !!prevIsCreating && !isCreating && !error) {
      navigate(`/users`)
    }
  }, [isCreating])

  return (
    <ContainerPageWithBack linkHref='/users' loading={isCreating} title={'Edit admin'}>
      <Box mt={2}></Box>
      <Container maxWidth='sm'>
        {adminProfile ? (
          <Form
            hidePhoneNumber={true}
            draft={adminProfile}
            updateProfile={updateProfile}
            onSave={onSave}
          />
        ) : null}
      </Container>
    </ContainerPageWithBack>
  )
}

export default App
