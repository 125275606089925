import { Brand } from 'src/models/brands'
import { Box, Checkbox, ListItem } from '@mui/material'
import { Text14pxRany, Text16pxRany500 } from 'src/components/Typography'
import { ProviderLocation } from 'src/models/providerLocations'
import { calculateResponsiveFontSize } from 'src/theme/index'

interface Props {
  location: ProviderLocation
  onToggle: (id: string) => void
  isChecked: boolean
}

const LocationListItem: React.FC<Props> = ({ location, onToggle, isChecked }) => {
  return (
    <ListItem
      key={location.id}
      onClick={() => onToggle(location.id!)}
      style={{ cursor: 'pointer' }}
    >
      <Checkbox size='medium' edge='start' checked={isChecked} />
      <Box ml={1}>
        <Text16pxRany500
          style={{
            textAlign: 'left'
          }}
        >
          {location.name}
        </Text16pxRany500>
        <Text14pxRany
          mt={1}
          style={{
            color: '#B0B0B0',
            fontSize: `${calculateResponsiveFontSize(0.7)}rem`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '400px'
          }}
        >
          {location.address}
        </Text14pxRany>
      </Box>
    </ListItem>
  )
}

export default LocationListItem
