import React, { ChangeEvent, KeyboardEvent, useState } from 'react'
import { Box } from '@mui/material'
import InputWithLabel, { InputLabelWrapper } from 'src/components/Base/InputWithLabel'
import { Dropdown, TextInput } from 'src/components/index'
import { TagsChip } from 'src/components/Typography'
import ClearIcon from '@mui/icons-material/Clear'
import useLocationStore from './useLocationsStore'

const BusinessTypeAndTags = () => {
  const { draft, updateDraft } = useLocationStore()
  const [businessType, setBusinessType] = useState('')
  const [inputValue, setInputValue] = useState<string>('')

  const tags = draft?.tags || []
  const typeOptions = [
    {
      value: 'Restaurant',
      label: 'Restaurant'
    },
    {
      value: 'brand2',
      label: 'Café'
    },
    {
      value: 'brand3',
      label: 'Bar'
    }
  ]

  const handleTypesChange = (event: any) => {
    setBusinessType(event.target.value)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && inputValue) {
      // Prevent form from being submitted
      event.preventDefault()

      // Add tag if it's not already in the list
      if (!tags.includes(inputValue)) {
        updateDraft('tags', [...tags, inputValue.trim()])
        setInputValue('')
      }
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleDelete = (tagToDelete: string) => () => {
    updateDraft(
      'tags',
      tags.filter(tag => tag !== tagToDelete)
    )
  }

  return (
    <div>
      <InputLabelWrapper label={'Your business type'}>
        <Dropdown
          labelId='brand-label'
          id='brand'
          fullWidth
          placeholder='Select your business type'
          value={businessType}
          options={typeOptions}
          onChange={handleTypesChange}
        />
      </InputLabelWrapper>

      <InputLabelWrapper label={'Tags'}>
        <TextInput
          value={inputValue}
          fullWidth
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder='Add a tag'
        />
        <Box sx={{ display: 'flex', mt: 2, flexWrap: 'wrap', maxWidth: '450px', gap: 1 }}>
          {draft?.tags?.map((tag, index) => (
            <TagsChip
              key={index}
              label={tag}
              deleteIcon={<ClearIcon style={{ color: 'white' }} />}
              onDelete={handleDelete(tag)}
              color='primary'
            />
          ))}
        </Box>
      </InputLabelWrapper>
    </div>
  )
}

export default BusinessTypeAndTags
