import { useSelector, useDispatch } from 'react-redux'
import { ChangeEvent, useEffect } from 'react'
import createLocationStore from 'src/store/createLocation'
import providerLocationStore from 'src/store/providerLocations'

import { PromotionId } from 'src/models/promotions'
import { useLocation, useParams } from 'react-router-dom'
import { LocationDraftUpdate } from 'src/store/createLocation/types'
import { validateForm, isCurrentStepCompleted } from './validationForm'

function usePromotionStore() {
  const dispatch = useDispatch()
  const location = useLocation()
  const params = useParams()

  const locationId: PromotionId = params?.id || (location?.state as any)?.id

  const errros = useSelector(createLocationStore.selectors.validationErrors)
  const isCreating = useSelector(createLocationStore.selectors.isCreating)

  const draft = useSelector((state: any) =>
    createLocationStore.selectors.currentDraft(state, locationId || 'new')
  )

  const save = () => {
    dispatch(createLocationStore.actions.createLocation.started(locationId))
  }

  const updateDraft = (fieldName: string, value: any) =>
    dispatch(
      createLocationStore.actions.updateDraft({
        id: locationId,
        [fieldName]: value
      })
    )

  const updateDraftData = (data: LocationDraftUpdate) =>
    dispatch(
      createLocationStore.actions.updateDraft({
        id: locationId,
        ...data
      })
    )

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    updateDraft(name, value)
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    updateDraft(name, checked)
  }

  const handleSelectChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }> | any
  ) => {
    const name = event.target.name!
    const value = event.target.value as string // Assuming value is always a string
    updateDraft(name, value)
  }

  const activeStepIsCompleted = (activeStep: number) => {
    return isCurrentStepCompleted(draft, activeStep)
  }

  const validate = (activeStep: number) => {
    const newErrors = validateForm(draft, activeStep)
    dispatch(createLocationStore.actions.setValidationsErrors(newErrors))
    return newErrors
  }

  const onRemove = () => {
    dispatch(providerLocationStore.actions.remove.started(locationId))
  }
  return {
    draft,
    isCreating,
    locationId,
    errros,
    activeStepIsCompleted,
    validate,
    save,
    onRemove,
    updateDraft,
    updateDraftData,
    handleInputChange,
    handleSelectChange,
    handleCheckboxChange
  }
}

export default usePromotionStore
