// @mui
import PricingPlanCard, { CustomPlanCard } from './PlanCard'
import { Box, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import subscriptionsStore from 'src/store/subscriptions'
import { useTranslation } from 'react-i18next'

export default function Pricing() {
  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions' })

  const subscriptionPlans = useSelector(subscriptionsStore.selectors.subscriptionPlans)

  return (
    <>
      <Box sx={{ mt: 5 }}>
        <Grid
          sx={{ my: 5 }} // Ensure the Grid also takes up 100% height
          container
          spacing={3}
          justifyContent={'center'}
          alignItems='center'
        >
          {subscriptionPlans.map((card, index) => (
            <Grid item xs={12} md={4} key={card.id}>
              <PricingPlanCard card={card} index={index} />
            </Grid>
          ))}
          <Grid item xs={12} md={4} key={'custom'}>
            <CustomPlanCard />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
