import React, { useState } from 'react'
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
import GridViewIcon from '@mui/icons-material/GridView'
import MapIcon from '@mui/icons-material/Map'
import { PromotionStatus } from 'src/models/promotions'
import { InputLabel } from '../Typography'

type ViewToggleProps = {
  view: PromotionStatus
  onChange: (view: PromotionStatus) => void
}

const ViewToggle: React.FC<ViewToggleProps> = ({ view, onChange }) => {
  const handleViewChange = (event: React.MouseEvent<HTMLElement>, nextView: PromotionStatus) => {
    if (nextView !== null) {
      // Prevents unselecting all options
      onChange(nextView)
    }
  }

  return (
    <Box mt={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <InputLabel>Status</InputLabel>
      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleViewChange}
        aria-label='View selection'
        sx={{
          padding: '4px',
          height: 40,
          backgroundColor: '#F7F9F8', // The background of the toggle button group
          '.MuiToggleButton-root': {
            minWidth: '140px', // Fixed width for each toggle button
            border: '1px solid transparent', // Default border to maintain consistent size
            '&.Mui-selected, &.Mui-selected:hover': {
              color: 'white', // Text color for selected state
              backgroundColor: view === 'live' ? '#04BC79' : '#FF8A00' // Change this to match your theme's primary color
              //   borderRight: '1px solid white' // Right border for selected state
            },
            '&:hover': {
              backgroundColor: '#c4c4c4' // A lighter color on hover
            }
          },
          '& .MuiToggleButtonGroup-groupedHorizontal:not(:first-of-type)': {
            borderRadius: '6px' // Border between buttons
          },
          '& .MuiToggleButton-root:first-of-type': {
            borderRadius: '6px' // Border between buttons
          }
        }}
      >
        <ToggleButton value='draft' aria-label='Grid view'>
          Draft
        </ToggleButton>
        <ToggleButton value='live' aria-label='Map view'>
          Live
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default ViewToggle
