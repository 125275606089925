import { BrandId } from './brands'
import { ProviderLocation, ProviderLocationId } from './providerLocations'
import { File, MediaFile } from './uploads'
import { MediaUploads } from './uploads'

export type PromotionId = string

export type PromotionStatus = 'draft' | 'review' | 'live' | 'declined'

export interface PromotionCreate {
  id?: string
  title?: string
  text?: string
  image: null | MediaFile
  type?: PromotionDiscountType
  endDate?: Date
  brandId: string
  startDate?: Date | null
  images?: MediaUploads
  discountPercentage?: number
  maxDiscountAmount?: number | null
  customOfferText?: string
  currency: string
  fixedDiscount?: string
  status?: PromotionStatus
  discountAvailableAtAnyTime?: boolean
  discountSchedule?: DiscountSchedule[]
  locations?: ProviderLocation[]
  locationIds?: ProviderLocationId[]
  foodicsRewardIsEnabled?: boolean
}

export type PromotionDiscountType = 'percentage_discount' | 'fixed_discount' | 'custom_offer'
export interface Promotion extends Omit<PromotionCreate, 'images'> {
  image: MediaFile | null
  images: File[] | null
  updatedAt: string
  createdAt: string
  latestProviderReview?: ProviderReview
}

export interface ProviderReview {
  id: number
  comment?: string
  status: string
  createdAt: string
  updatedAt: string
}

export interface DiscountSchedule {
  dayOfTheWeek: DayOfTheWeek
  startTime: number
  endTime: number
}

export type DayOfTheWeek =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'

export interface PromotionsResponse {
  data: Promotion[]
  totalCount: number
  hasMore: boolean
}

export enum PromotionType {
  percentageDiscount = 'percentage_discount',
  fixedDiscount = 'fixed_discount',
  customOffer = 'custom_offer'
}

export interface PromotionQueryParams {
  brandId?: BrandId
  title?: string
  status?: PromotionStatus
}
