import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'

import { useNavigate } from 'react-router-dom'
import { Spinner } from 'src/components'

import adminsStore from 'src/store/admins'
import { Admin } from 'src/models/admins'
import { useTranslation } from 'react-i18next'
import ButtonRemoveWithPrompt from 'src/components/ButtonRemoveWithPrompt'

export default function AdminsList() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'admins_list' })

  useEffect(() => {
    dispatch(adminsStore.actions.fetch.started())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const list = useSelector(adminsStore.selectors.list)
  const isRemoving = useSelector(adminsStore.selectors.isRemoving)
  const isFetching = useSelector(adminsStore.selectors.isFetching)

  const renderText = (title: string) => (
    <Typography
      component='h6'
      align='left'
      color={'text.secondary'}
      sx={{
        fontWeight: 400
      }}
    >
      {title}
    </Typography>
  )

  const navigateToAddAdmin = () => navigate('/create_admin')

  const remove = (item: Admin) => () => dispatch(adminsStore.actions.remove.started(item))

  const Item = (item: Admin) => {
    return (
      <Box
        key={item.id}
        sx={{
          display: 'flex',
          mt: 1,
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 2,
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: { xs: 'flex-start', sm: 'space-between' },
            alignItems: { xs: 'flex-start', sm: 'center' }
          }}
        >
          <Box>
            <Typography
              component='h6'
              sx={{
                color: '#293845',
                fontWeight: 500
              }}
            >
              {item.name}
            </Typography>
          </Box>
          <Box>
            <Typography
              component='h6'
              sx={{
                color: '#293845',
                fontWeight: 500
              }}
            >
              {'+' + item.phoneNumber}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            minWidth: { xs: '65px', sm: '160px' },
            width: { xs: '65px', sm: '160px' }
          }}
        >
          {item?.role !== 'owner' && !isRemoving ? (
            <ButtonRemoveWithPrompt
              btnText={t('btn_remove')}
              description={t('popup_removal_text').replace('%admin_name%', item.name)}
              onRemove={remove(item)}
            />
          ) : (
            <Spinner />
          )}
        </Box>
      </Box>
    )
  }

  return (
    <Grid item mt={1} xs={12}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDirection={'row'}
        mb={2}
      >
        {renderText(t('section_title_admins'))}

        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{ cursor: 'pointer' }}
          onClick={navigateToAddAdmin}
        >
          {renderText(t('btn_add_admin'))}
          <Fab size='medium' sx={{ ml: 3 }} color='primary' aria-label='add'>
            <AddIcon />
          </Fab>
        </Box>
      </Box>

      {!isFetching && list.length ? (
        //@ts-ignore
        list.map(Item)
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ color: 'text.secondary' }}>{t('section_no_admins_created')}</Typography>
        </Box>
      )}
    </Grid>
  )
}
