import React from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import { ProviderLocation } from 'src/models/providerLocations'
import { StatusLabel } from 'src/components'
import { Chip } from '@mui/material'
import { CustomCard, Label, Category, Subtitle } from 'src/components/List/Components'
import LocationLabel from 'src/components/List/LocationLabel'
import LocationActionButtons from 'src/components/List/LocationActionButtons'

import { calculateResponsiveFontSize } from 'src/theme'
import providerLocations from 'src/store/providerLocations'
import { useDispatch, useSelector } from 'react-redux'

type RestaurantItemProps = {
  item: ProviderLocation
}

const RestaurantItem: React.FC<RestaurantItemProps> = ({ item }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let subtitle = 'Jacks Burgers'

  const onEdit = () => navigate(`edit/${item.id}`)

  const onChangeStatus = () => {
    dispatch(providerLocations.actions.updateStatus.started({ id: item.id!, status: 'live' }))
  }

  const isUpdatingStatus = useSelector(providerLocations.selectors.isUpdatingStatus)

  const onRemove = () => {
    dispatch(providerLocations.actions.remove.started(item.id!))
  }

  return (
    <CustomCard>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <StatusLabel status={item.status!} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          src={item.image?.url}
          alt='Logo'
          sx={{
            width: `${calculateResponsiveFontSize(4.5)}rem`,
            height: `${calculateResponsiveFontSize(4.5)}rem`,
            marginRight: 2
          }}
        />
        <Box>
          <Label variant='h6'>{item.name}</Label>
          <Subtitle variant={'subtitle1'} mt={1}>
            {subtitle}
          </Subtitle>
          <Category label={'Food'} sx={{ mt: 1.5 }} />
        </Box>
      </Box>
      <LocationLabel address={item.address!} />
      <Box mt={2}>
        <LocationActionButtons
          onChangeStatus={onChangeStatus}
          onEdit={onEdit}
          onRemove={onRemove}
          isLoading={isUpdatingStatus}
          status={item.status!}
        />
      </Box>
    </CustomCard>
  )
}

export default RestaurantItem
