import { resizePx } from 'src/theme'

const Icon = () => (
  <svg
    width={resizePx(114)}
    height={resizePx(144)}
    viewBox='0 0 114 114'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_389_22625)'>
      <path
        d='M82.1796 113.876H41.8662L41.8585 113.65L39.9336 58.5977H84.112L82.1796 113.876ZM42.3185 113.408H81.7273L83.6274 59.0658H40.4182L42.3185 113.408Z'
        fill='#3F3D56'
      />
      <path d='M51.4804 68.7969H48.4141V106.742H51.4804V68.7969Z' fill='#3F3D56' />
      <path d='M63.5546 68.7969H60.4883V106.742H63.5546V68.7969Z' fill='#3F3D56' />
      <path d='M75.6288 68.7969H72.5625V106.742H75.6288V68.7969Z' fill='#3F3D56' />
      <path
        d='M109.729 74.7638L109.531 74.6388L59.457 42.9943L63.8006 36.1211L63.9986 36.2461L114.073 67.8905L109.729 74.7638ZM60.103 42.8487L109.583 74.1178L113.427 68.0364L63.9465 36.7669L60.103 42.8487Z'
        fill='#3F3D56'
      />
      <path
        d='M97.6374 40.4423C96.1819 39.5212 94.4927 39.0369 92.7702 39.047C91.0477 39.0571 89.3643 39.5612 87.9197 40.4994C86.475 41.4376 85.3299 42.7705 84.6201 44.34C83.9104 45.9095 83.6657 47.6496 83.9153 49.354L86.303 50.8629C85.7029 49.4696 85.5647 47.9209 85.9085 46.4433C86.2524 44.9658 87.0602 43.6372 88.2137 42.652C89.3673 41.6668 90.8059 41.0768 92.319 40.9683C93.8321 40.8599 95.3402 41.2387 96.6225 42.0493C97.9047 42.86 98.8937 44.0598 99.4448 45.4732C99.9958 46.8866 100.08 48.4392 99.6846 49.9038C99.2894 51.3684 98.4357 52.668 97.2484 53.6124C96.0612 54.5567 94.6028 55.0961 93.0868 55.1516L95.4745 56.6605C97.1212 56.155 98.5883 55.1877 99.7017 53.8734C100.815 52.559 101.528 50.9529 101.756 49.2455C101.984 47.5381 101.717 45.8011 100.988 44.2408C100.258 42.6804 99.096 41.3621 97.6396 40.4423H97.6374Z'
        fill='#3F3D56'
      />
      <path d='M111.425 113.553H-101.359V114.021H111.425V113.553Z' fill='#3F3D56' />
      <path
        d='M31.0689 68.4813L11.4053 54.7C11.2226 54.5714 11.0983 54.3757 11.0596 54.1557C11.0209 53.9357 11.071 53.7093 11.1989 53.5261L26.6325 31.5049C26.7611 31.3222 26.9568 31.1979 27.1768 31.1593C27.3968 31.1206 27.6232 31.1706 27.8064 31.2985L47.47 45.0798C47.6527 45.2084 47.777 45.4041 47.8157 45.6241C47.8544 45.8441 47.8043 46.0705 47.6765 46.2537L32.2432 68.2743C32.1148 68.4572 31.919 68.5817 31.6989 68.6205C31.4788 68.6593 31.2522 68.6092 31.0689 68.4813Z'
        fill='#04BC79'
      />
      <path
        d='M35.4927 53.5271L23.9544 45.4405C23.821 45.347 23.7073 45.2281 23.6198 45.0906C23.5323 44.9532 23.4728 44.7998 23.4445 44.6393C23.4163 44.4788 23.42 44.3144 23.4553 44.1553C23.4906 43.9962 23.557 43.8457 23.6505 43.7123C23.744 43.5788 23.8629 43.4651 24.0003 43.3776C24.1378 43.2901 24.2912 43.2306 24.4516 43.2024C24.6121 43.1741 24.7766 43.1778 24.9357 43.2131C25.0947 43.2485 25.2453 43.3148 25.3787 43.4083L36.917 51.495C37.1865 51.6838 37.3699 51.972 37.4269 52.2961C37.4839 52.6202 37.4098 52.9537 37.221 53.2232C37.0321 53.4927 36.7439 53.6761 36.4198 53.7331C36.0957 53.7901 35.7622 53.716 35.4927 53.5271Z'
        fill='white'
      />
      <path
        d='M32.8413 57.3051L21.303 49.2185C21.1694 49.125 21.0555 49.0061 20.9679 48.8687C20.8803 48.7312 20.8206 48.5778 20.7923 48.4172C20.764 48.2566 20.7676 48.0921 20.8029 47.9329C20.8382 47.7738 20.9046 47.6231 20.9981 47.4896C21.0917 47.3561 21.2107 47.2423 21.3482 47.1548C21.4858 47.0673 21.6392 47.0078 21.7998 46.9796C21.9604 46.9514 22.125 46.9552 22.2841 46.9906C22.4432 47.0261 22.5938 47.0926 22.7273 47.1863L34.2655 55.2729C34.5347 55.4619 34.7178 55.75 34.7747 56.074C34.8315 56.3979 34.7574 56.7312 34.5686 57.0005C34.3798 57.2699 34.0919 57.4532 33.768 57.5103C33.4441 57.5675 33.1108 57.4936 32.8413 57.3051Z'
        fill='white'
      />
      <path
        d='M30.1685 61.1248L18.6302 53.0382C18.4968 52.9446 18.3831 52.8257 18.2956 52.6883C18.2081 52.5508 18.1485 52.3975 18.1203 52.237C18.0921 52.0765 18.0958 51.912 18.1311 51.753C18.1664 51.5939 18.2327 51.4434 18.3263 51.3099C18.4198 51.1765 18.5387 51.0628 18.6761 50.9753C18.8136 50.8878 18.9669 50.8282 19.1274 50.8C19.2879 50.7718 19.4524 50.7754 19.6114 50.8108C19.7705 50.8461 19.9211 50.9124 20.0545 51.006L31.5928 59.0926C31.8623 59.2815 32.0457 59.5697 32.1027 59.8938C32.1597 60.2179 32.0856 60.5514 31.8967 60.8208C31.7079 61.0903 31.4197 61.2738 31.0956 61.3308C30.7715 61.3878 30.438 61.3137 30.1685 61.1248Z'
        fill='white'
      />
      <path
        d='M35.4596 46.0538L30.9905 42.9216C30.8569 42.8281 30.743 42.7093 30.6554 42.5718C30.5678 42.4343 30.5081 42.2809 30.4798 42.1203C30.4515 41.9598 30.4551 41.7952 30.4904 41.636C30.5257 41.4769 30.5921 41.3262 30.6856 41.1927C30.7792 41.0592 30.8982 40.9455 31.0357 40.858C31.1733 40.7704 31.3268 40.7109 31.4873 40.6827C31.6479 40.6546 31.8125 40.6583 31.9716 40.6938C32.1308 40.7292 32.2813 40.7957 32.4148 40.8894L36.8839 44.0216C37.1534 44.2105 37.3368 44.4986 37.3938 44.8227C37.4508 45.1469 37.3767 45.4803 37.1878 45.7498C36.999 46.0193 36.7108 46.2027 36.3867 46.2597C36.0626 46.3167 35.7291 46.2426 35.4596 46.0538Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_389_22625'>
        <rect width='114' height='114' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Icon
