import React, { useState } from 'react'
import {
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  styled
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: 'none' // removing any border top if it's there
}))

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  '&:before': {
    display: 'none' // this line will hide the default border top
  },
  '&.Mui-expanded': {
    margin: 'auto'
  }
}))

interface SectionProps {
  label: string
  checked: boolean
  onToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  children?: React.ReactNode
  hasArrow?: boolean
}

const Section: React.FC<SectionProps> = ({
  label,
  checked,
  onToggle,
  children,
  hasArrow = false
}) => {
  return hasArrow ? (
    <CustomAccordion
      expanded={checked}
      elevation={0}
      sx={{ padding: 0, borderColor: 'red' }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        sx={{ padding: 0, borderColor: 'red' }}
        expandIcon={
          <ArrowDropDownIcon
            style={{
              transition: 'transform 0.3s'
            }}
          />
        }
      >
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={onToggle} name={label} />}
          label={label}
        />
      </AccordionSummary>
      <CustomAccordionDetails>{children}</CustomAccordionDetails>
    </CustomAccordion>
  ) : (
    <Box>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onToggle} name={label} />}
        label={label}
      />
      {checked && children}
    </Box>
  )
}

export default Section
