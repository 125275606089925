import React, { useState } from 'react'
import { Button, Box } from '@mui/material'
import { SecondaryButton } from '..'
import PrimaryButton from '../Base/DisabledPrimaryButton'
import LeavePageDialog from '../Dialogs/LeavePage'
import { useNavigate } from 'react-router-dom'

// Define a type for the props that will be accepted by the component
type ButtonGroupProps = {
  activeStep: number
  backHref: string
  disabled?: boolean
  onBack: () => void
  onContinue: () => void
  onSaveDraft?: () => void
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  disabled,
  backHref,
  activeStep,
  onBack,
  onContinue,
  onSaveDraft
}) => {
  const [modalVisible, setModalVisible] = useState(false)
  const toggleModal = () => setModalVisible(!modalVisible)
  const navigate = useNavigate()

  const onConfirmModal = () => {
    toggleModal()
    navigate(backHref)
  }

  const onBtnBackClick = () => {
    if (activeStep === 1 || !activeStep) {
      toggleModal()
    } else {
      onBack()
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 0
      }}
    >
      <Box
        gap={2}
        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 2, p: 0 }}
      >
        <SecondaryButton variant='outlined' fullWidth onClick={onBtnBackClick}>
          Back
        </SecondaryButton>
        <PrimaryButton isDisabled={!!disabled} onClick={onContinue} text={'Continue'} />
      </Box>
      {onSaveDraft ? (
        <Button variant='text' onClick={onSaveDraft}>
          Save as draft
        </Button>
      ) : null}
      <LeavePageDialog open={modalVisible} onClose={toggleModal} onConfirm={onConfirmModal} />
    </Box>
  )
}

export default ButtonGroup
