import React, { useEffect, useState } from 'react'
import CustomBreadcrumbs from 'src/components/Breadcrumbs'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'

import authStore from 'src/store/auth'
import { enqueueSnackbar } from 'notistack'

import { useDispatch, useSelector } from 'react-redux'
import SignUp from 'src/assets/svg/SignUp'
import SignupHeader from 'src/components/Header/SignupHeader'
import NavigationButtons from './NavigationButtons'
import AuthLayout from 'src/components/Layouts/AuthLayout'
import { PageTitle } from 'src/components/Typography'
import usePrevious from 'src/hooks/usePrevious'

const ParentComponent: React.FC = () => {
  const dispatch = useDispatch()
  const error = useSelector(authStore.selectors.error)

  const [activeStep, setActiveStep] = useState(1)

  const isSending = useSelector(authStore.selectors.isSendingPhoneVerification)

  const prevIsSending = usePrevious(isSending)

  useEffect(() => {
    if (!!prevIsSending !== isSending && !isSending && !error) {
      setActiveStep(4)
    }
  }, [isSending])

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message || '', {
        variant: 'error',
        onExited: () => dispatch(authStore.actions.dismissError())
      })
    }
  }, [error])

  // Function to render the current step
  const renderStep = () => {
    switch (activeStep) {
      case 1:
        return <Step1 />
      case 2:
        return <Step2 />
      case 3:
        return <Step3 />
      case 4:
        return <Step4 />
      // Add more cases for additional steps
    }
  }

  return (
    <>
      <SignupHeader />
      <AuthLayout
        icon={<SignUp />}
        title={'Make a Unique Offer'}
        subtitle={`Whether it's a special discount, a limited-time promotion, or an exclusive bundle,
    capture the attention of your audience`}
      >
        {/* Right part with the login form */}
        <>
          <PageTitle mt={3} mb={5}>
            Sign up to Elevate Your Business
          </PageTitle>
          <CustomBreadcrumbs stepsCount={4} activeStep={activeStep - 1} />
          {renderStep()}
          <NavigationButtons activeStep={activeStep} setActiveStep={setActiveStep} />
        </>
      </AuthLayout>
    </>
  )
}

export default ParentComponent
