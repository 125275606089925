import React, { useEffect } from 'react'
import Form from './Form'
import ContainerPageWithBack from 'src/components/Layouts/ContainerPageWithBack'

import { useDispatch } from 'react-redux'
import { Container } from '@mui/material'
import ActionsButtonsEdit from 'src/components/Layouts/ActionsButtonsEdit'
import { useLocation, useParams } from 'react-router-dom'
import useBrandsStore from './useBrandsStore'
import { FoodicsSettings } from 'src/components/Foodics/FoodicsSettings'

const CreatePromotion: React.FC = () => {
  const location = useLocation()
  const params = useParams()
  const {
    initDraft,
    handleInputChange,
    createBrand,
    errors,
    draft,
    updateDraft,
    isRemoving,
    isCreating,
    onRemove
  } = useBrandsStore()
  useEffect(() => initDraft(), [])

  return (
    <ContainerPageWithBack loading={isRemoving || isCreating} title={'Create new brand'}>
      <Container sx={{ mt: 5 }} maxWidth='sm'>
        <Form
          draft={draft}
          errors={errors}
          updateDraft={updateDraft}
          handleInputChange={handleInputChange}
        />
        <FoodicsSettings brandId={draft?.id} />
        <ActionsButtonsEdit
          modalText='Are you sure you want to delete the brand? This action will delete all the promotions and locations as well.'
          modalTitle='Delete brand'
          btnDeleteTxt='Delete brand'
          backHref={'/brands'}
          onPrimaryClick={createBrand}
          onDelete={onRemove}
        />
      </Container>
    </ContainerPageWithBack>
  )
}

export default CreatePromotion
