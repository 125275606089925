import React, { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import ListItemButton from '@mui/material/ListItemButton'
import Typography from '@mui/material/Typography'
import { createStyles, makeStyles } from '@mui/styles'
import countryInfo from 'src/assets/json/countryInfo.json'
import { TextInput } from 'src/components'
import { Rany500Gray70, Text14pxRanyBold } from 'src/components/Typography'

const useStyles = makeStyles((theme: any) =>
  createStyles({
    optionContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    optionDetails: {
      marginLeft: theme.spacing(2)
    }
  })
)

type Country = {
  name: string
  name_ar: string
  dial_code: string
  currency: string
  iso2: string
  iso3: string
  is_arabian_country: boolean
  is_khaliji_country: boolean
  flag: string
  order: number
}

interface Props {
  country: any
  onChange: (value: string) => void
}
const CountryPicker: React.FC<Props> = ({ country, onChange }) => {
  const classes = useStyles()

  const defaultCountry = React.useMemo(
    () => (country ? countryInfo.find(item => item.iso2 === country) : null),
    [country]
  )

  const filterOptions = (options: Country[], { inputValue }: { inputValue: string }) => {
    return options.filter(
      currency =>
        currency.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        currency.iso2.toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  const onChangeCountry = (event: any, newValue: Country | null) => {
    onChange(newValue ? newValue.iso2 : '')
  }

  const renderOption = (props: any, option: Country) => {
    return (
      <ListItemButton
        key={option?.iso2}
        onClick={e => {
          props.onClick(e)
          onChange(option.iso2)
          //   setSelectedCurrency(option)
        }}
      >
        <div className={classes.optionContainer}>
          <Rany500Gray70>{`${option?.flag}   ${option?.name || ''}`}</Rany500Gray70>
          <div className={classes.optionDetails}>
            <Text14pxRanyBold color={'primary'}>{option?.iso2}</Text14pxRanyBold>
          </div>
        </div>
      </ListItemButton>
    )
  }

  const renderInput = (params: any) => {
    return (
      <TextInput
        {...params}
        placeholder={'Select country'}
        autoComplete='off'
        fullWidth
        type='outlite'
      />
    )
  }

  return (
    <Autocomplete
      options={countryInfo}
      getOptionLabel={option => option.name}
      //@ts-ignore
      value={defaultCountry || null} // Ensure value is consistently null instead of undefined for unselected
      disableClearable
      filterOptions={filterOptions}
      onChange={onChangeCountry}
      renderInput={renderInput}
      renderOption={renderOption}
    />
  )
}

export default CountryPicker
