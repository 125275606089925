import React from 'react'
import { Box } from '@mui/material'
import PhotoPicker from 'src/components/FilesDropzone/PhotoPicker'
import InputWithLabel, { InputLabelWrapper } from 'src/components/Base/InputWithLabel'
import CountryPicker from 'src/components/Pickers/CountryPicker'
import CityPicker from 'src/components/Pickers/CityPicker'

import PhoneNumberInput from 'src/components/PhoneNumberInput'

import { BrandCreate } from 'src/models/brands'
import { ValidationErrors } from 'src/models'

interface Props {
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  updateDraft: (fieldName: string, value: any) => void
  errors: ValidationErrors<BrandCreate>
  draft: BrandCreate
}
export const FormComponent = ({ draft, errors, updateDraft, handleInputChange }: Props) => {
  const onChangeCountryCode = (value: string) => updateDraft('countryCode', value)
  const handlePhone = (value: string) => updateDraft('contactPhoneNumber', value)

  const onChangeCity = (value: string, googlePlaceId?: string) => {
    updateDraft('city', value)
    updateDraft('googlePlaceId', googlePlaceId)
  }

  return (
    <Box sx={{ mt: 1 }}>
      <InputLabelWrapper required id='providerName' name='providerName' label='Brand Logo'>
        <PhotoPicker uploadType='brand-image' />
      </InputLabelWrapper>

      <InputWithLabel
        required
        id='brandName'
        name='name'
        label='Brand name'
        placeholder='Enter your Brand Name'
        fullWidth
        error={!!errors.name}
        helperText={errors.name || 'Enter the name associated with your brand or product'}
        onChange={handleInputChange}
        value={draft.name}
      />

      <InputLabelWrapper label='Country'>
        <CountryPicker country={draft.countryCode} onChange={onChangeCountryCode} />
      </InputLabelWrapper>

      {draft.countryCode ? (
        <InputLabelWrapper label='City' error={!!errors.city} helperText={errors.city}>
          <CityPicker
            //@ts-ignore
            city={draft.city?.locality || draft?.city}
            countryCode={draft.countryCode!}
            onChange={onChangeCity}
          />
        </InputLabelWrapper>
      ) : null}

      <InputWithLabel
        label='Contact Email'
        required
        id='email'
        name='contactEmail'
        autoComplete='off'
        placeholder='Enter your contact email'
        fullWidth
        error={!!errors.contactEmail}
        helperText={errors.contactEmail || 'This will be shown in promotion of the app.'}
        onChange={handleInputChange}
        value={draft.contactEmail}
      />

      <InputLabelWrapper
        helperText={errors.contactPhoneNumber || 'This will be shown in promotion of the app.'}
        error={!!errors.contactPhoneNumber}
        id='phone-number'
        label='Contact Phone number'
      >
        <PhoneNumberInput defaultValue={draft.contactPhoneNumber} onChangePhone={handlePhone} />
      </InputLabelWrapper>

      <InputWithLabel
        helperText={errors.websiteUrl}
        error={!!errors.websiteUrl}
        name='websiteUrl'
        id='phone-number'
        placeholder='Enter website'
        label='Web Site'
        onChange={handleInputChange}
        value={draft.websiteUrl}
        fullWidth
      />
    </Box>
  )
}

export default FormComponent
