import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import { SecondaryButton, PrimaryButton, IconButton } from 'src/components'

import TrashIcon from 'src/assets/svg/Trash' // Import MUI Delete icon
import { ProviderLocationStatus } from 'src/models/providerLocations'
import { PromotionStatus } from 'src/models/promotions'
import DeletePopup from 'src/components/Dialogs/DeletePopup'
import { CircularProgress } from '@mui/material'

export type TStatus = ProviderLocationStatus | PromotionStatus

type Props = {
  isLoading?: boolean
  onChangeStatus: () => void
  onRemove: () => void
  onEdit: () => void
  status: TStatus
}

const ActionButtons = ({ isLoading, onRemove, onChangeStatus, status, onEdit }: Props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const handleOpenPopup = () => {
    setIsPopupOpen(true)
  }

  const handleClosePopup = () => {
    setIsPopupOpen(false)
  }

  const handleConfirmDeletion = () => {
    onRemove()
    setIsPopupOpen(false)
  }

  return (
    <>
      <DeletePopup
        open={isPopupOpen}
        title='Delete location'
        text='Are you sure you want to delete the location? This action will delete all the promotions and locations as well.'
        onClose={handleClosePopup}
        onConfirm={handleConfirmDeletion}
      />
      <Box display='flex' alignItems='center' justifyContent='center' gap={2}>
        <SecondaryButton onClick={onEdit} variant='outlined' fullWidth>
          Edit
        </SecondaryButton>
        {status !== 'live' ? (
          <PrimaryButton
            disabled={isLoading}
            onClick={onChangeStatus}
            startIcon={isLoading ? <CircularProgress color='inherit' size={24} /> : null}
            variant='contained'
            fullWidth
          >
            Publish
          </PrimaryButton>
        ) : null}
        <IconButton bordercolor='#E52121' onClick={handleOpenPopup}>
          <TrashIcon />
        </IconButton>
      </Box>
    </>
  )
}

export default ActionButtons
