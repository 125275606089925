import * as React from 'react'
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Box } from '@mui/material'
import { InputLabelWrapper } from 'src/components/Base/InputWithLabel'
import TextField from 'src/components/Base/TextField'
import useSignUpStore from './useSignUpStore'

export default function POSForm() {
  const { draft, handleSelectChange, handleInputChange } = useSignUpStore()

  if (draft.countryCode !== 'SA') {
    return null
  }
 // TODO clear
  return (
    <InputLabelWrapper label='POS system'>
      <RadioGroup
        aria-label='pos-system'
        name='posSystemType'
        value={draft.posSystemType}
        sx={{ ml: 2 }}
        onChange={handleSelectChange}
      >
        <FormControlLabel value='foodics' control={<Radio />} label='Foodics' />
        <FormControlLabel value='other' control={<Radio />} label='Other' />
      </RadioGroup>
      {draft.posSystemType === 'other' && (
        <Box sx={{ ml: 2, mt: 2, mb: 2 }}>
          <TextField
            value={draft.posSystemOther}
            name='posSystemOther'
            onChange={handleInputChange}
            label='Enter POS system name'
            fullWidth
          />
        </Box>
      )}
    </InputLabelWrapper>
  )
}
