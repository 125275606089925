import React from 'react'
import { styled } from '@mui/material/styles'
import { Chip, Typography, TypographyProps } from '@mui/material'
import { calculateResponsiveFontSize, colors } from 'src/theme/index'

// Define the styled component using the specified styles
export const Text12pxPoppins = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: `${calculateResponsiveFontSize(0.75)}rem`,
  fontWeight: 400,
  color: colors.grey90,
  lineHeight: 1.5, // Alternatively, '18px'
  letterSpacing: '0em',
  textAlign: 'left'
}))

export const Text14pxPoppins = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: `${calculateResponsiveFontSize(0.875)}rem`,
  fontWeight: 400,
  color: '#64716C',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'left'
}))

export const Text14pxRany = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: 'Rany, sans-serif',
  fontSize: `${calculateResponsiveFontSize(0.875)}rem`,
  fontWeight: 400,
  color: '#95A19C',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'left'
}))

export const Text14pxRanyBold = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: 'Rany, sans-serif',
  fontSize: `${calculateResponsiveFontSize(0.875)}rem`,
  fontWeight: 700,
  color: '#292B2A',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'left'
}))

export const Text16pxRany500 = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: 'Rany, sans-serif',
  fontSize: `${calculateResponsiveFontSize(1)}rem`,
  fontWeight: 500,
  color: '#292B2A',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'center'
}))

export const PageTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: 'Rany, sans-serif' /* Fallback to sans-serif in case 'Rany' is not available */,
  fontSize: `${calculateResponsiveFontSize(2)}rem`,
  fontWeight: 700 /* Bold */,
  textAlign: 'center',
  lineHeight: `${calculateResponsiveFontSize(2.1)}rem`,
  letterSpacing: '0em' /* Normal spacing */,
  color: theme.palette.text.primary
}))

export const PageSubtitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: 'Rany, sans-serif' /* Fallback to sans-serif in case 'Rany' is not available */,
  fontSize: `${calculateResponsiveFontSize(1.5)}rem`,
  fontWeight: 700 /* Bold */,
  lineHeight: `${calculateResponsiveFontSize(1.8)}rem`,
  letterSpacing: '0em' /* Normal spacing */,
  textAlign: 'center',
  color: theme.palette.text.primary
}))

export const LocationCount = styled(Chip)({
  padding: '2px 12px',
  borderRadius: 4,
  backgroundColor: '#FF8A00',
  fontFamily: 'Poppins, sans-serif', // Ensure Poppins is loaded in your project
  fontSize: `${calculateResponsiveFontSize(0.875)}rem`,
  fontWeight: 400,
  lineHeight: '18px', // Adjusted for CSS, original line-height might not directly apply
  letterSpacing: '0em',
  textAlign: 'left', // Might not be needed as Chip content is centered by default
  '& .MuiChip-label': {
    fontFamily: 'Poppins, sans-serif',
    fontSize: `${calculateResponsiveFontSize(0.75)}rem`,
    fontWeight: 400,
    color: 'white',
    lineHeight: 18,
    letterSpacing: '0em',
    textAlign: 'left'
  }
})

export const TagsChip = LocationCount

export const InputLabel = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: 'Rany, sans-serif' /* Fallback to sans-serif in case 'Rany' is not available */,
  fontSize: `${calculateResponsiveFontSize(0.875)}rem`,
  fontWeight: 700 /* Bold */,
  lineHeight: `${calculateResponsiveFontSize(1.3)}rem`,
  letterSpacing: '0em' /* Normal spacing */,
  textAlign: 'left',
  color: '#3E4643'
}))

export const InputHelperText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif' /* Fallback to sans-serif in case 'Rany' is not available */,
  fontSize: `${calculateResponsiveFontSize(0.7)}rem`,
  fontWeight: 400 /* Bold */,
  lineHeight: `${calculateResponsiveFontSize(1.1)}rem`,
  letterSpacing: '0em' /* Normal spacing */,
  textAlign: 'left',
  color: '#95A19C'
}))

export const Rany500Gray70 = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: 'Rany, sans-serif' /* Fallback to sans-serif in case 'Rany' is not available */,
  fontSize: `${calculateResponsiveFontSize(0.875)}rem`,
  fontWeight: 500 /* Bold */,
  letterSpacing: '0em' /* Normal spacing */,
  textAlign: 'left',
  color: '#78827E'
}))

export const Rany18px700 = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: 'Rany, sans-serif' /* Fallback to sans-serif in case 'Rany' is not available */,
  fontSize: `${calculateResponsiveFontSize(1.125)}rem`,
  fontWeight: 500 /* Bold */,
  letterSpacing: '0em' /* Normal spacing */,
  textAlign: 'left',
  color: '#292B2A'
}))
