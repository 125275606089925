import React, { useState } from 'react'
import { Box, Button, Typography, Fade } from '@mui/material'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import EditContainer from 'src/components/Layouts/ContainerPageWithBack'
import ActionsButtonsEdit from 'src/components/Layouts/ActionsButtonsEdit'
import usePromotionStore from './usePromotionStore'
import StatusToggle from 'src/components/CreateLocation/StatusToggle'
import { PromotionStatus } from 'src/models/promotions'

const CreatePromotion: React.FC = () => {
  const { draft, save, updateDraft, onRemove } = usePromotionStore()

  const onChangeStatus = (value: PromotionStatus) => updateDraft('status', value)

  return (
    <EditContainer title='Edit your promotion'>
      <div>
        <StatusToggle view={draft.status!} onChange={onChangeStatus}></StatusToggle>

        <Step1 />
        <Step2 />
        <Step3 />
        <Step4 />
        <Step5 />
        <ActionsButtonsEdit
          modalText='Are you sure you want to delete the promotion? This action will delete the promotion permanently.'
          modalTitle='Delete promotion'
          btnDeleteTxt='Delete promotion'
          backHref={'/promotions'}
          onPrimaryClick={save}
          onDelete={onRemove}
        />
      </div>
    </EditContainer>
  )
}

export default CreatePromotion
