import { all, fork } from 'redux-saga/effects'
import authSagas from './auth/sagas'
import createLocationSagas from './createLocation/sagas'
import providerLocationsSagas from './providerLocations/sagas'
import createPromotionSagas from './createPromotion/sagas'
import promotionsSagas from './promotions/sagas'
import adminsSagas from './admins/sagas'
import locationAdminsSagas from './locationAdmins/sagas'
import remoteConfigSagas from './remoteConfig/sagas'
import mediaUploadsSaga from './mediaUploads/sagas'
import foodicsSagas from './foodics/sagas'
import subscriptionsSaga from './subscriptions/sagas'
import adminPreviewSaga from './adminPreview/sagas'
import brandsSaga from './brands/sagas'

export default function* rootSaga() {
  yield all([
    ...authSagas(),
    ...createLocationSagas(),
    ...providerLocationsSagas(),
    ...createPromotionSagas(),
    ...promotionsSagas(),
    ...adminsSagas(),
    ...locationAdminsSagas(),
    ...remoteConfigSagas(),
    ...mediaUploadsSaga(),
    ...foodicsSagas(),
    ...subscriptionsSaga(),
    ...adminPreviewSaga(),
    ...brandsSaga()
  ])
}
