import { useSelector, useDispatch } from 'react-redux'
import { ChangeEvent, useEffect, useState } from 'react'
import brandsStore from 'src/store/brands'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { SignUpData } from 'src/store/auth/types'
import { validatePhoneNumber } from 'src/utils/validateField'
import { Brand, BrandCreate } from 'src/models/brands'
import isEmpty from 'lodash/isEmpty'
import { State } from 'src/models'
import usePrevious from 'src/hooks/usePrevious'
import { enqueueSnackbar } from 'notistack'
import mediaUploads from 'src/store/mediaUploads'

type ValidationErrors<K> = {
  [P in keyof K]?: string
}

export default function usePromotionStore() {
  const dispatch = useDispatch()
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  const draft = useSelector(brandsStore.selectors.draft)
  const isCreating = useSelector(brandsStore.selectors.isCreating)
  const isRemoving = useSelector(brandsStore.selectors.isRemoving)
  const error = useSelector(brandsStore.selectors.createError)
  const navigateId = useSelector(brandsStore.selectors.navigateId)

  const prevIsCreating = usePrevious(isCreating)
  const prevIsRemoving = usePrevious(isRemoving)

  const [errors, setErrors] = useState<ValidationErrors<BrandCreate>>({})
  const brandId: string = params?.id || (location?.state as any)?.id

  const editableBrand = useSelector((state: State) =>
    brandsStore.selectors.brandById(state, brandId)
  )

  useEffect(() => {
    if (!!isCreating !== !!prevIsCreating && !isCreating && !error) {
      brandId || !navigateId ? navigate('/brands') : navigate(`/brands/foodics/${navigateId}`)
    }
  }, [isCreating])

  useEffect(() => {
    if (!!isRemoving !== !!prevIsRemoving && !isCreating && !error) {
      navigate('/brands')
    }
  }, [isRemoving])

  /*
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
        onExited: () => {
          dispatch(brandsStore.actions.dismissError())
        }
      })
    }
  }, [error])
  */

  useEffect(
    () => () => {
      dispatch(brandsStore.actions.clearDraft())
    },
    []
  )

  const initDraft = () => {
    if (brandId) {
      dispatch(brandsStore.actions.updateDraft(editableBrand as BrandCreate))
      //  dispatch(brandsStore.actions.edit(editableBrand!))
    } else {
      dispatch(mediaUploads.actions.clearDraft())
      dispatch(brandsStore.actions.clearDraft())
    }
  }

  const createBrand = () => {
    const errors = validate()
    if (isEmpty(errors)) {
      dispatch(brandsStore.actions.create.started())
    }
  }

  const updateDraft = (fieldName: string, value: any) => {
    !isEmpty(errors) && setErrors({})
    dispatch(
      brandsStore.actions.updateDraft({
        [fieldName]: value
      })
    )
  }
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    updateDraft(name, value)
  }

  const handleSelectChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const name = event.target.name!
    const value = event.target.value as string // Assuming value is always a string
    updateDraft(name, value)
  }

  // ------ Step2
  const validate = (): ValidationErrors<BrandCreate> => {
    const fields = draft
    const newErrors: ValidationErrors<BrandCreate> = {}

    if (!fields.name) {
      newErrors.name = 'Brand name is required.'
    }

    if (!fields.contactEmail) {
      newErrors.contactEmail = 'Email is required.'
    }

    if (fields.contactPhoneNumber && !validatePhoneNumber(fields.contactPhoneNumber)) {
      newErrors.contactPhoneNumber = 'Invalid phone number.'
    }

    if (fields.city && !fields.googlePlaceId) {
      newErrors.city = 'City not found. Try choosing a suggestion as you type'
    }

    if (!fields.countryCode) {
      newErrors.countryCode = 'Country is required.'
    }
    setErrors(newErrors)
    return newErrors
  }

  const onRemove = () => {
    dispatch(brandsStore.actions.remove.started(brandId))
  }
  return {
    draft,
    errors,
    isRemoving,
    isCreating,
    createBrand,
    onRemove,
    validate,
    initDraft,
    updateDraft,
    handleInputChange,
    handleSelectChange
  }
}
