import CloseIcon from '@mui/icons-material/Close'
import IconButtonWrapper from './IconButton'
import { ButtonProps } from '@mui/material'
import { useNavigate } from 'react-router-dom'

interface CustomButtonProps extends ButtonProps {
  linkHref?: string
}

const ButtonBack: React.FC<CustomButtonProps> = props => {
  const navigate = useNavigate()

  const navigateBack = () => {
    const link = props.linkHref ? props.linkHref : -1
    //@ts-ignore
    navigate(link)
  }
  return (
    <IconButtonWrapper
      bordercolor={'#292B2A'}
      onClick={navigateBack}
      sx={{
        border: `2px solid #292B2A` // Dynamic border color based on props
      }}
      {...props}
    >
      <CloseIcon />
    </IconButtonWrapper>
  )
}

export default ButtonBack
