import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { updateDraft, create, setValidationsErrors, edit, dismissError } from './actions'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

// Types imports
import { StateCreatePromotion } from './types'

const initialState: StateCreatePromotion = {
  drafts: {},
  validationErrors: {}
}

export function createPromotion(
  state: StateCreatePromotion = initialState,
  action: Action
): StateCreatePromotion {
  if (isType(action, setValidationsErrors)) {
    return {
      ...state,
      validationErrors: action.payload
    }
  }

  if (isType(action, create.started)) {
    return {
      ...state,
      navigateId: undefined,
      isCreating: true,
      error: undefined
    }
  }

  if (isType(action, create.done)) {
    return {
      ...state,
      drafts: {},
      navigateId: action.payload.result.result.id,
      isCreating: false
    }
  }

  if (isType(action, create.failed)) {
    return {
      ...state,
      isCreating: false,
      error: action.payload.error
    }
  }

  if (isType(action, updateDraft)) {
    const id = action.payload?.id || 'new'

    return {
      ...state,
      validationErrors: {},
      drafts: {
        ...state.drafts,
        [id]: {
          ...(state.drafts[id] || {}),
          ...action.payload
        }
      }
    }
  }
  if (isType(action, dismissError)) {
    return {
      ...state,
      error: undefined
    }
  }

  if (isType(action, edit)) {
    const promotion = action.payload

    return {
      ...state,
      navigateId: undefined,

      //@ts-ignore
      drafts: {
        ...state.drafts,
        [promotion.id!]: {
          ...promotion,
          locationIds: promotion.locations?.map(item => item.id)
        }
      }
    }
  }

  return state
}
