import { PrimaryButton, SecondaryButton } from 'src/components'
import useSignUpStore from './useSignUpStore'
import { AuthTextWithLink, AuthButtonsContainer } from 'src/components/Layouts/AuthLayout'
import isEmpty from 'lodash/isEmpty'
import { OTPMethods } from 'src/models/auth'
import { Text14pxRany, Text14pxRanyBold } from 'src/components/Typography'
import { useCallback, useEffect, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { useReCaptcha } from 'src/hooks/useReCaptcha'
import Button from 'src/components/Base/DisabledPrimaryButton'
import { draft } from 'src/store/brands/selectors'
import { useNavigate } from 'react-router-dom'
interface Props {
  activeStep: number
  setActiveStep: (index: number) => void
}
const ParentComponent = ({ activeStep, setActiveStep }: Props) => {
  const {
    draft,
    signUp,
    isFirstStepCompleted,
    isSecondStepCompleted,
    validateStep2Fields,
    validateStep1Fields,
    sendPhoneVerification
  } = useSignUpStore()
  const navigate = useNavigate()
  const { captchaToken, handleReCaptchaVerify } = useReCaptcha()

  useEffect(() => {
    if (captchaToken) {
      sendCode('sms')()
    }
  }, [captchaToken])

  const sendCode = (messenger: OTPMethods) => () => {
    if (messenger === 'sms' && !captchaToken) {
      handleReCaptchaVerify()
      return
    }
    sendPhoneVerification(captchaToken!, messenger)
  }

  const handleNext = async () => {
    if (activeStep === 1) {
      const errors = await validateStep1Fields()

      if (draft) isEmpty(errors) && setActiveStep(activeStep + 1)
      return
    }
    if (activeStep === 2) {
      const errors = validateStep2Fields()
      isEmpty(errors) && setActiveStep(activeStep + 1)
      return
    }

    if (activeStep === 3) {
      setActiveStep(activeStep + 1)
      return
    }
    if (activeStep === 4) {
      signUp()
    }
  }

  const handleBack = () => {
    if (activeStep === 1) {
      return navigate('/welcome')
    }
    setActiveStep(activeStep - 1)
  }

  const isDisabled = () => {
    switch (activeStep) {
      case 1:
        return !isFirstStepCompleted()
      case 2:
        return isSecondStepCompleted()
      case 3:
        return isFirstStepCompleted() && isSecondStepCompleted()
      default:
        return false
    }
  }

  if (activeStep === 3) {
    return (
      <>
        <AuthButtonsContainer>
          <SecondaryButton fullWidth variant='outlined' onClick={sendCode('whatsapp')}>
            {'Get code - WhatsApp'}
          </SecondaryButton>

          <Button text={'Get code - SMS'} isDisabled={!!isDisabled()} onClick={sendCode('sms')} />
        </AuthButtonsContainer>

        <Text14pxRanyBold mt={2} style={{ textAlign: 'center' }} onClick={handleBack}>
          Back
        </Text14pxRanyBold>
      </>
    )
  }

  return (
    <>
      <AuthButtonsContainer>
        <SecondaryButton
          fullWidth
          variant='outlined'
          onClick={handleBack}
          disabled={activeStep === 0}
        >
          Back
        </SecondaryButton>

        <Button text={'Continue'} isDisabled={!!isDisabled()} onClick={handleNext} />
      </AuthButtonsContainer>

      <AuthTextWithLink linkText='Log in' linkHref='login' text='Already have an account?' />
    </>
  )
}

export default ParentComponent
