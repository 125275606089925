import { ProviderLocationId } from 'src/models/providerLocations'
import { State } from 'src/models'

export const list = (state: State) => Object.values(state.providerLocations.cache || {})
export const isFetching = (state: State) => state.providerLocations.isFetching
export const isRemoving = (state: State) => state.providerLocations.isRemoving
export const error = (state: State) => state.providerLocations.error
export const locationById = (state: State, id: ProviderLocationId) =>
  state.providerLocations.cache[id]
export const isUpdatingStatus = (state: State) => state.providerLocations.isUpdatingStatus
export const listFilters = (state: State) => state.providerLocations.listFilters
