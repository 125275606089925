import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { resizePx } from 'src/theme'
import LocationSVG from 'src/assets/svg/Location.svg'

// Styled components using the theme's spacing utility
const LocationCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(2)
}))

const AddressText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rany, Arial, sans-serif', // Ensure 'Rany' font is loaded in your project
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: theme.typography.pxToRem(21),
  marginLeft: theme.spacing(1), // 8px if the default theme spacing is 8
  color: theme.palette.text.secondary
}))

const LocationIcon = () => (
  <svg
    width={resizePx(12)}
    height={resizePx(14)}
    viewBox='0 0 12 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.0013 0.333496C4.58681 0.333496 3.23026 0.895399 2.23007 1.89559C1.22987 2.89579 0.667969 4.25234 0.667969 5.66683C0.667969 9.26683 5.36797 13.3335 5.56797 13.5068C5.68872 13.6101 5.8424 13.6669 6.0013 13.6669C6.1602 13.6669 6.31388 13.6101 6.43463 13.5068C6.66797 13.3335 11.3346 9.26683 11.3346 5.66683C11.3346 4.25234 10.7727 2.89579 9.77254 1.89559C8.77234 0.895399 7.41579 0.333496 6.0013 0.333496ZM6.0013 12.1002C4.5813 10.7668 2.0013 7.8935 2.0013 5.66683C2.0013 4.60596 2.42273 3.58855 3.17287 2.8384C3.92302 2.08826 4.94044 1.66683 6.0013 1.66683C7.06217 1.66683 8.07958 2.08826 8.82973 2.8384C9.57987 3.58855 10.0013 4.60596 10.0013 5.66683C10.0013 7.8935 7.4213 10.7735 6.0013 12.1002ZM6.0013 3.00016C5.47389 3.00016 4.95831 3.15656 4.51978 3.44958C4.08125 3.74259 3.73946 4.15907 3.53762 4.64634C3.33579 5.13361 3.28298 5.66979 3.38587 6.18707C3.48877 6.70435 3.74274 7.17951 4.11568 7.55245C4.48862 7.92539 4.96378 8.17936 5.48106 8.28226C5.99834 8.38515 6.53452 8.33234 7.02179 8.13051C7.50906 7.92868 7.92554 7.58688 8.21855 7.14835C8.51157 6.70982 8.66797 6.19425 8.66797 5.66683C8.66797 4.95959 8.38702 4.28131 7.88692 3.78121C7.38682 3.28111 6.70855 3.00016 6.0013 3.00016ZM6.0013 7.00016C5.73759 7.00016 5.47981 6.92196 5.26054 6.77546C5.04128 6.62895 4.87038 6.42071 4.76946 6.17707C4.66855 5.93344 4.64214 5.66535 4.69359 5.40671C4.74504 5.14807 4.87202 4.91049 5.05849 4.72402C5.24496 4.53755 5.48254 4.41056 5.74118 4.35912C5.99982 4.30767 6.26791 4.33407 6.51155 4.43499C6.75518 4.53591 6.96342 4.7068 7.10993 4.92607C7.25644 5.14533 7.33463 5.40312 7.33463 5.66683C7.33463 6.02045 7.19416 6.35959 6.94411 6.60964C6.69406 6.85969 6.35492 7.00016 6.0013 7.00016Z'
      fill='#78827E'
    />
  </svg>
)
// LocationComponent
const LocationComponent = ({ address, fontSize }: { address: string; fontSize?: number }) => {
  return (
    <LocationCard>
      <LocationIcon />
      <AddressText style={{ fontSize }} variant='subtitle2'>
        {address}
      </AddressText>
    </LocationCard>
  )
}

export default LocationComponent
