import { useEffect, useState } from 'react'
// @mui
import { styled } from '@mui/material/styles'
import { Box, Button, Card, Chip } from '@mui/material'
import { Rany18px700 } from 'src/components/Typography'
import { calculateResponsiveFontSize } from 'src/theme/index'
// components

export const PlanCard = styled(Card)(({ theme, highlight }: any) => ({
  maxWidth: 400,
  backgroundColor: highlight ? '#04BC79' : '#F7F9F8',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '16px',
  gap: theme.spacing(4),
  padding: highlight ? theme.spacing(8, 3) : theme.spacing(3, 3),
  boxSizing: 'border-box',
  transition: 'box-shadow 0.3s ease-in-out',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)'
  }
}))

const SaveChip = styled(Chip)({
  backgroundColor: '#FFAA00',
  fontFamily: 'Rany, Arial', // Fallback to Arial if Rany isn't available
  fontSize: `${calculateResponsiveFontSize(0.875)}rem`,
  color: 'white',
  padding: '2px 12px',
  fontWeight: 500,
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'left'
})

export const PlanTitle = ({ isAnnual, text }: { isAnnual: boolean; text?: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Rany18px700 sx={{ color: isAnnual ? 'white' : '#292B2A' }}>
        {text || (isAnnual ? 'Annual Plan' : '3 Months Plan')}
      </Rany18px700>
      {isAnnual ? <SaveChip label='Save 15%' /> : null}
    </Box>
  )
}
