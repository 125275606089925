import { useSelector, useDispatch } from 'react-redux'
import { ChangeEvent, useEffect, useState } from 'react'
import createPromotionStore from 'src/store/createPromotion'
import { PromotionId } from 'src/models/promotions'
import { useLocation, useParams } from 'react-router-dom'
import { validateForm, isCurrentStepCompleted } from './validationForm'
import { PromotionDraftUpdate } from 'src/store/createPromotion/types'

type ValidationErrors<T> = {
  [Property in keyof T]?: string
}

function usePromotionStore() {
  const dispatch = useDispatch()
  const location = useLocation()
  const params = useParams()

  const promotionId: PromotionId = params?.id || (location?.state as any)?.id
  const errors = useSelector(createPromotionStore.selectors.validationErrors)
  const draft = useSelector((state: any) =>
    createPromotionStore.selectors.currentDraft(state, promotionId || 'new')
  )

  const updateDraft = (fieldName: string, value: any) =>
    dispatch(
      createPromotionStore.actions.updateDraft({
        id: promotionId,
        [fieldName]: value
      })
    )

  const save = () => {
    dispatch(createPromotionStore.actions.create.started(promotionId))
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    updateDraft(name, value)
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    updateDraft(name, checked)
  }

  const handleSelectChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const name = event.target.name!
    const value = event.target.value as string // Assuming value is always a string
    updateDraft(name, value)
  }

  const activeStepIsCompleted = (activeStep: number) => {
    return isCurrentStepCompleted(draft, activeStep)
  }

  const validate = (activeStep: number) => {
    const newErrors = validateForm(draft, activeStep)
    dispatch(createPromotionStore.actions.setValidationsErrors(newErrors))
    return newErrors
  }

  const onRemove = () => {}
  return {
    draft,
    promotionId,
    errors,
    validate,
    save,
    activeStepIsCompleted,
    updateDraft,
    onRemove,
    handleInputChange,
    handleSelectChange,
    handleCheckboxChange
  }
}

export default usePromotionStore
