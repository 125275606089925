import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import subscriptionsStore from 'src/store/subscriptions'
import { useSearchParams } from 'react-router-dom'

import Pricing from './Pricing'
import PromoCode from './PromoCode'

import { useSnackbar } from 'notistack'
import ContainerPageWithBack from 'src/components/Layouts/ContainerPageWithBack'
import { Text14pxPoppins } from 'src/components/Typography'

// App component
const App: React.FC = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const error = useSelector(subscriptionsStore.selectors.error)
  const subscriptionPromoCode = useSelector(subscriptionsStore.selectors.subscriptionPromoCode)

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
        onExited: () => dispatch(subscriptionsStore.actions.dismissError())
      })
    }
  }, [error])

  useEffect(() => {
    dispatch(
      subscriptionsStore.actions.fetchPlans.started(
        searchParams?.get('promoCode') || subscriptionPromoCode
      )
    )
    dispatch(subscriptionsStore.actions.fetchStatus.started())
    dispatch(subscriptionsStore.actions.fetchSubscriptions.started())
  }, [])

  return (
    <ContainerPageWithBack title='Subscription plans'>
      <Text14pxPoppins mt={2} style={{ color: 'black' }}>
        Subscribe and add your location&promotion to start getting traffic to your business
      </Text14pxPoppins>
      <PromoCode />
      <Pricing />
    </ContainerPageWithBack>
  )
}

export default App
