import React, { useEffect, useState } from 'react'
import { Box, Typography, Paper, Grid, IconButton } from '@mui/material'
import Item from './Item'
import Marker from 'src/components/MapList/Marker'
import Container from 'src/components/MapList/Container'
import GoogleMap from 'src/components/MapList/Map'
import List from 'src/components/MapList/List'

import { calculateCenterAndZoom } from 'src/components/MapList/utilities'

import { ProviderLocation } from 'src/models/providerLocations'

type Props = {
  items: ProviderLocation[]
}

const Map = ({ items }: Props) => {
  const [selectedId, setSelectedId] = useState<string | null>(null) // Track the ID of the selected marker

  const { center, zoom } = calculateCenterAndZoom(items)

  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>(center)
  const [focusZoom, setZoom] = useState<number>(zoom) // Default zoom level

  const handleItemFocus = (item: any) => {
    setSelectedId(item.id)
    setMapCenter({ lat: item.location.latitude, lng: item.location.longitude })
    setZoom(15) // Example zoom level for focusing
  }

  return (
    <Container>
      <Grid item xs={3} style={{ height: '90%', overflow: 'auto' }}>
        <List
          items={items as ProviderLocation[] & { id: string }[]}
          renderItem={Item as any}
          onItemFocus={handleItemFocus}
          onItemSelected={setSelectedId}
          selectedItem={selectedId}
        />
      </Grid>

      <Grid item xs={9}>
        <Box style={{ height: '90%', position: 'relative' }}>
          {zoom ? (
            <GoogleMap center={mapCenter} zoom={focusZoom}>
              {items.map(location => (
                <Marker
                  key={location.name}
                  lat={location.location?.latitude!}
                  lng={location.location?.longitude!}
                  id={location.id!}
                  status={location.status!}
                  selected={selectedId === location.id} // Determine if the marker is selected based on the selectedId
                  setSelected={setSelectedId} // Pass the setSelectedId function to manage selection
                />
              ))}
            </GoogleMap>
          ) : null}
        </Box>
      </Grid>
    </Container>
  )
}

export default Map
