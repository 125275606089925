import React, { useState } from 'react'
import { Box, Button, Typography, Fade } from '@mui/material'

import Step1 from '../Step1'
import Step2 from '../Step2'
import Step3 from '../Step3'
import Step4 from '../Step4'
import Step5 from '../Step5'
import ActionsButtonsEdit from 'src/components/Layouts/ActionsButtonsEdit'
import useLocationsStore from '../useLocationsStore'
import EditContainer from 'src/components/Layouts/ContainerPageWithBack'
import StatusToggle from 'src/components/CreateLocation/StatusToggle'
import { PromotionStatus } from 'src/models/promotions'

const CreatePromotion: React.FC = () => {
  const { save, draft, updateDraft, onRemove, isCreating } = useLocationsStore()

  const onChangeStatus = (value: PromotionStatus) => updateDraft('status', value)

  return (
    <EditContainer loading={isCreating} title='Edit your location'>
      <div>
        <StatusToggle view={draft.status!} onChange={onChangeStatus}></StatusToggle>

        <Step1 />
        <Step2 />
        <Step3 />
        <Step4 />
        <Step5 />
        <ActionsButtonsEdit
          modalText='Are you sure you want to delete the location? This action will delete all the promotions and locations as well.'
          modalTitle='Delete location'
          btnDeleteTxt='Delete location'
          backHref={'/locations'}
          onPrimaryClick={save}
          onDelete={onRemove}
        />
      </div>
    </EditContainer>
  )
}

export default CreatePromotion
