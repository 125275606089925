import React, { useState } from 'react'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import GridViewIcon from '@mui/icons-material/GridView'
import MapIcon from '@mui/icons-material/Map'

type ViewToggleProps = {
  view: string
  setView: (view: string) => void
}

const ViewToggle: React.FC<ViewToggleProps> = ({ view, setView }) => {
  const handleViewChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (nextView !== null) {
      // Prevents unselecting all options
      setView(nextView)
    }
  }

  return (
    <ToggleButtonGroup
      value={view}
      exclusive
      onChange={handleViewChange}
      aria-label='View selection'
      sx={{
        padding: '4px',
        height: 40,
        backgroundColor: '#F7F9F8', // The background of the toggle button group
        '.MuiToggleButton-root': {
          minWidth: '140px', // Fixed width for each toggle button
          border: '1px solid transparent', // Default border to maintain consistent size
          '&.Mui-selected, &.Mui-selected:hover': {
            color: '#292B2A', // Text color for selected state
            backgroundColor: 'white' // Change this to match your theme's primary color
            //   borderRight: '1px solid white' // Right border for selected state
          },
          '&:hover': {
            backgroundColor: '#c4c4c4' // A lighter color on hover
          }
        },
        '& .MuiToggleButtonGroup-groupedHorizontal:not(:first-of-type)': {
          borderRadius: '6px' // Border between buttons
        },
        '& .MuiToggleButton-root:first-of-type': {
          borderRadius: '6px' // Border between buttons
        }
      }}
    >
      <ToggleButton value='grid' aria-label='Grid view'>
        <GridViewIcon sx={{ mr: 1 }} />
        Grid
      </ToggleButton>
      <ToggleButton value='map' aria-label='Map view'>
        <MapIcon sx={{ mr: 1 }} />
        Map view
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default ViewToggle
