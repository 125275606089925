import parsePhoneNumberFromString, { isValidPhoneNumber } from "libphonenumber-js"

const getCountryCodeFromNumber = (phoneNumber: string) => {
  const parsedNumber = parsePhoneNumberFromString('+' + phoneNumber)
  return parsedNumber ? parsedNumber.country : null
}

export const validatePhoneNumber = (phoneNumber: string) => {
  const countryCode = getCountryCodeFromNumber(phoneNumber)
  return countryCode ? isValidPhoneNumber(phoneNumber, countryCode) : false
}
