import React, { ReactNode } from 'react'
import Typography from '@mui/material/Typography'
import CustomInput from './TextField'
import { StandardTextFieldProps } from '@mui/material/TextField'
import { InputHelperText, InputLabel } from '../Typography'
import { Box } from '@mui/material'

interface CustomInputProps extends StandardTextFieldProps {
  success?: boolean
}

interface InputWithLabelProps extends CustomInputProps {
  label: string
  mt?: number
  childred?: ReactNode
}

interface InputWrapperProps {
  children: React.ReactNode
  label: string
  mt?: number
  helperText: string
  error?: boolean // This type accepts any valid React node
}
export const InputLabelWrapper: React.FC<InputWithLabelProps> = ({
  mt = 2,
  label,
  helperText,
  children,
  error
}) => {
  return (
    <Box mt={mt}>
      <InputLabel mb={1}>{label}</InputLabel>
      {children}
      {helperText && (
        <InputHelperText mt={0.6} style={{ color: error ? '#E42121' : undefined }}>
          {helperText}
        </InputHelperText>
      )}
    </Box>
  )
}

const InputWithLabel: React.FC<InputWithLabelProps> = ({
  childred,
  mt = 2,
  label,
  helperText,
  ...props
}) => {
  return (
    <Box mt={mt}>
      <InputLabel mb={1}>{label}</InputLabel>
      {<CustomInput {...props} />}
      {helperText && (
        <InputHelperText mt={0.6} style={{ color: props.error ? '#E42121' : undefined }}>
          {helperText}
        </InputHelperText>
      )}
    </Box>
  )
}

export default InputWithLabel
