import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Spinner } from 'src/components'
import createPromotion from 'src/store/createPromotion'
import promotionsStore from 'src/store/promotions'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Promotion, PromotionId } from 'src/models/promotions'
import usePrevious from 'src/hooks/usePrevious'
import { useSnackbar } from 'notistack'
import { State } from 'src/models'
import { useTranslation } from 'react-i18next'
import { ReviewStatusAlert } from 'src/components/Statuses/ReviewStatusAlert'
import { API } from 'src/data/index'
import { Alert, AlertTitle } from '@mui/material'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import EditView from './EditView'

import authStore from 'src/store/auth'
import mediaUploadsStore from 'src/store/mediaUploads'
import View from './View'

export default function CreatePromotion() {
  const user = useSelector(authStore.selectors.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation('translation', { keyPrefix: 'create_promotion' })

  const location = useLocation()
  const params = useParams()
  const promotionId: PromotionId = params?.id || (location?.state as any)?.id
  const isRemoving = useSelector(promotionsStore.selectors.isRemoving)
  const error = useSelector(promotionsStore.selectors.error)

  const isCreating = useSelector(createPromotion.selectors.isCreating)
  const navigateId = useSelector(createPromotion.selectors.navigateId)

  const createError = useSelector(createPromotion.selectors.error)
  const draft = useSelector((state: any) =>
    createPromotion.selectors.currentDraft(state, promotionId || 'new')
  )

  const promotionFromCache: Promotion = useSelector((state: State) =>
    promotionsStore.selectors.promotionById(state, promotionId!)
  )

  const prevIsRemoving = usePrevious(isRemoving)
  const prevIsCreating = usePrevious(isCreating)

  const fetchPromotionFromAPI = async () => {
    try {
      const promotion = await API.getPromotionById(promotionId)

      dispatch(createPromotion.actions.edit(promotion))
    } catch (error) {
      console.error('failed to get promotion from api', error)

      enqueueSnackbar('Failed to get promotion from API', {
        variant: 'error',
        onExited: () => dispatch(createPromotion.actions.dismissError())
      })

      navigate('/promotions')
    }
  }

  useEffect(() => {
    if (promotionId) {
      if (!draft) {
        if (promotionFromCache) {
          dispatch(createPromotion.actions.edit(promotionFromCache))
        } else {
          fetchPromotionFromAPI()
        }
      } else {
        //TO DO change logic for set images if draft exist
        draft?.images?.length && dispatch(mediaUploadsStore.actions.setImages(draft.images as any))
      }
    }
  }, [promotionFromCache])

  const handleBack = () => {
    navigate(-1)
    if (promotionFromCache) {
      dispatch(createPromotion.actions.edit(promotionFromCache))
    }
  }

  useEffect(
    () => () => {
      dispatch(mediaUploadsStore.actions.clearDraft())
    },
    []
  )

  useEffect(() => {
    if (!!isRemoving !== !!prevIsRemoving && !isRemoving && !error) {
      navigate('/promotions')
    }
  }, [isRemoving])

  useEffect(() => {
    if (!!isCreating !== !!prevIsCreating && !isCreating && !createError) {
      // Navigate to promotions page, after successfully editing or creating promotion
      promotionId ? navigate('/promotions') : navigate(`/promotions/complete/${navigateId}`)
      // Before it was done with
      // handleBack()
      // But it's working properly when we create new promotion
    }
  }, [prevIsCreating])

  useEffect(() => {
    if (createError || error) {
      enqueueSnackbar(createError?.message || error?.message, {
        variant: 'error',
        onExited: () => {
          dispatch(promotionsStore.actions.dismissError())
          dispatch(createPromotion.actions.dismissError())
        }
      })
    }
  }, [error, createError])

  if (promotionId && !draft) {
    return <Spinner loading={true} />
  }

  if (promotionId && draft) {
    return (
      <>
        <EditView />
        <Spinner loading={isCreating} />
      </>
    )
  }

  return (
    <>
      <View />
      <Spinner loading={isCreating} />
    </>
  )
}
