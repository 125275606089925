import { resizePx } from 'src/theme'

const Icon = () => (
  <svg
    width={resizePx(179)}
    height={resizePx(188)}
    viewBox='0 0 179 188'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_389_24389)'>
      <path
        d='M179.049 157.777H0.222656V45.627H179.049V157.777ZM4.38345 153.585H174.888V49.8184H4.38345V153.585Z'
        fill='#E5E5E5'
      />
      <path d='M176.97 47.7227H2.30469V155.681H176.97V47.7227Z' fill='white' />
      <path
        d='M176.97 75.0842V68.4555H152.74V47.7227H146.16V68.4555H103.023V47.7227H96.4424V68.4555H29.9088V47.7227H23.3286V68.4555H2.30469V75.0842H23.3286V100.862H2.30469V107.491H23.3286V135.11H2.30469V141.739H58.7889V155.681H65.369V141.739H96.4424V155.681H103.023V141.739H176.97V135.11H103.023V107.491H176.97V100.862H152.74V75.0841L176.97 75.0842ZM96.4424 75.0842V83.1858H29.9088V75.0842H96.4424ZM29.9088 89.8144H96.4424V100.862H29.9088V89.8144ZM29.9088 135.11V107.491H58.7889V135.11L29.9088 135.11ZM96.4424 135.11H65.369V107.491H96.4424V135.11ZM146.16 100.862H103.023V75.0841H146.16V100.862Z'
        fill='#E6E6E6'
      />
      <path
        d='M131.48 31.7845C131.48 49.3386 99.9275 88.4438 99.9275 88.4438C99.9275 88.4438 68.375 49.3386 68.375 31.7845C68.375 23.3547 71.6993 15.2702 77.6165 9.30946C83.5337 3.34871 91.5592 0 99.9275 0C108.296 0 116.321 3.34871 122.238 9.30946C128.156 15.2702 131.48 23.3547 131.48 31.7845Z'
        fill='#04BC79'
      />
      <path
        d='M114.348 30.4031C114.348 33.2769 113.502 36.0861 111.917 38.4756C110.332 40.865 108.08 42.7274 105.444 43.8271C102.808 44.9269 99.908 45.2146 97.11 44.654C94.3121 44.0933 91.7419 42.7095 89.7247 40.6774C87.7075 38.6454 86.3337 36.0563 85.7772 33.2378C85.2206 30.4192 85.5063 27.4977 86.598 24.8427C87.6897 22.1877 89.5384 19.9184 91.9105 18.3218C94.2825 16.7252 97.0712 15.873 99.924 15.873C101.818 15.8722 103.694 16.2474 105.445 16.9773C107.195 17.7072 108.786 18.7774 110.125 20.1269C111.465 21.4763 112.527 23.0784 113.252 24.8417C113.976 26.6049 114.349 28.4948 114.348 30.4031Z'
        fill='white'
      />
      <path
        d='M99.9234 111.384C104.621 111.384 108.429 107.548 108.429 102.816C108.429 98.0841 104.621 94.248 99.9234 94.248C95.226 94.248 91.418 98.0841 91.418 102.816C91.418 107.548 95.226 111.384 99.9234 111.384Z'
        fill='#04BC79'
      />
    </g>
    <defs>
      <clipPath id='clip0_389_24389'>
        <rect width='179' height='188' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Icon
