import { Box, Grid } from '@mui/material'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'

function LayoutProfile() {
  return (
    <Box px={6} pt={2.5} sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
      {/* Sidebar navigation using Box */}
      <Sidebar />
      <Grid container spacing={2} justifyContent='center'>
        <Outlet />
      </Grid>
    </Box>
  )
}

export default LayoutProfile
