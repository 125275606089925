import React, { useEffect, ChangeEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container, List } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import brandsStore from 'src/store/brands'

import { PrimaryButton } from 'src/components'
import { PageTitle, PageSubtitle } from 'src/components/Typography'
import BrandItem from './BrandItem'

export default function MyProfile() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'my_profile' })

  useEffect(() => {
    dispatch(brandsStore.actions.fetch.started())
  }, [])

  const brands = useSelector(brandsStore.selectors.list)

  return (
    <Container maxWidth={false}>
      {/* This makes the Container full width */}
      <PageTitle mt={4}>Profile settings</PageTitle>
      <PageSubtitle mt={5}>Brand information</PageSubtitle>
      {/* Brands list */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            marginTop: 1,
            minWidth: '35%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <List>
            {brands?.map((brand, index) => (
              <BrandItem brand={brand} />
            ))}
          </List>
          <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
            <PrimaryButton
              onClick={() => navigate('/brand/create')}
              variant='contained'
              color='primary'
            >
              + New brand
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
