import React, { useEffect, useState } from 'react'
import { Box, Container } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { Text14pxPoppins } from 'src/components/Typography'

import ContainerPageWithBack from 'src/components/Layouts/ContainerPageWithBack'
import InputWithLabel, { InputLabelWrapper } from 'src/components/Base/InputWithLabel'
import PhoneNumberInput from 'src/components/PhoneNumberInput'
import { Dropdown, PrimaryButton, SecondaryButton, Spinner } from 'src/components/index'
import CountryPicker from 'src/components/Pickers/CountryPicker'
import useGetInTouchForm from './useGetInTouchForm' // adjust the import path

const App: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions' })
  const {
    goBack,
    isCreating,
    formData,
    updateState,
    handleInputChange,
    handleSubmit,
    isFormValid
  } = useGetInTouchForm()

  const onChangeCountryCode = (value: string) => updateState('countryCode', value)
  const handlePhone = (value: string) => updateState('contactPhoneNumber', value)

  return (
    <ContainerPageWithBack title={'Get in touch'}>
      <Text14pxPoppins mt={2} mb={5} style={{ color: 'black' }}>
        Contact us to get information about pricing for organisations
      </Text14pxPoppins>
      <Container maxWidth='sm'>
        <InputWithLabel
          name={'contactName'}
          label='Contact name'
          value={formData.contactName}
          onChange={handleInputChange}
          fullWidth
          placeholder='Enter your contact name'
        />

        <InputWithLabel
          fullWidth
          label='Contact email'
          name={'contactEmail'}
          value={formData.contactEmail}
          onChange={handleInputChange}
          placeholder='jackburgers@gmail.com'
        />

        <InputLabelWrapper
          id='location-phone-number'
          label='Location phone number'
          helperText='This will be shown in promotion of the app'
        >
          <PhoneNumberInput
            defaultValue={formData.contactPhoneNumber}
            onChangePhone={handlePhone}
          />
        </InputLabelWrapper>

        <InputWithLabel
          fullWidth
          label='Organization name'
          name={'organizationName'}
          value={formData.organizationName}
          onChange={handleInputChange}
          placeholder='Enter your organization'
          helperText={'Enter the official name of your organization'}
        />

        <InputWithLabel
          fullWidth
          label='Brand name'
          name={'brandName'}
          value={formData.brandName}
          onChange={handleInputChange}
          placeholder='Enter your brand name'
          helperText='Enter the name associated with your brand or product'
        />

        <InputLabelWrapper label={'Country'}>
          <CountryPicker country={formData.countryCode} onChange={onChangeCountryCode} />
        </InputLabelWrapper>

        {/*
        <InputLabelWrapper label={'City'}>
          <Dropdown
            labelId='brand-label'
            id='brand'
            fullWidth
            placeholder='Select your city'
            options={cityOptions}
            label='Brand*'
          />
        </InputLabelWrapper>
  */}

        <InputWithLabel
          name={'contactWebsite'}
          value={formData.contactWebsite}
          onChange={handleInputChange}
          fullWidth
          label='Website'
          placeholder='Enter website'
        />

        <InputWithLabel
          fullWidth
          label='Message'
          placeholder='Type your message'
          name={'message'}
          value={formData.message}
          onChange={handleInputChange}
          multiline
          maxRows={10}
          rows={4}
        />

        <Box
          gap={2}
          mt={3}
          sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 30, p: 0 }}
        >
          <SecondaryButton variant='outlined' fullWidth onClick={goBack}>
            Back
          </SecondaryButton>
          <PrimaryButton
            disabled={!isFormValid}
            onClick={handleSubmit}
            variant='contained'
            fullWidth
            color='primary'
          >
            Continue
          </PrimaryButton>
        </Box>
      </Container>
      <Spinner loading={isCreating} />
    </ContainerPageWithBack>
  )
}

export default App
