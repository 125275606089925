import { PromotionDraftUpdate, PromotionsValidationErrors } from 'src/store/createPromotion/types'

const StepsCount = 5 + 1

export const isCurrentStepCompleted = (draft: PromotionDraftUpdate = {}, activeStep: number) => {
  switch (activeStep) {
    case 1:
      return draft?.locationIds
    case 2:
      return draft?.title && draft?.type && draft?.text
    case 3:
      if (draft?.type === 'percentage_discount') {
        return draft?.currency && draft?.maxDiscountAmount && draft?.discountPercentage
      } else if (draft?.type === 'fixed_discount') {
        return draft?.currency && draft?.fixedDiscount
      } else {
        return draft?.customOfferText
      }
    case 4:
      return true

    case 5:
      return draft.discountAvailableAtAnyTime || draft.discountSchedule
    default:
      return false
  }
}

export const validateForm = (draft: PromotionDraftUpdate = {}, activeStep: number) => {
  const newErrors: PromotionsValidationErrors = {}

  // 1 Step
  if (!draft.locationIds && activeStep < StepsCount) {
    newErrors.locationIds = 'Select the locations for which the promotion will be available'
  }

  // 2 Step

  if (activeStep > 1) {
    if (!draft.title) {
      newErrors.title = 'Title is required.'
    }

    // Validate text
    if (!draft.text) {
      newErrors.text = 'Text is required.'
    }

    if (!draft.type) {
      newErrors.type = 'Promotion Type is required.'
    }
  }

  // 3 Step

  if (activeStep > 2) {
    if (draft?.type === 'percentage_discount') {
      if (!draft.currency) {
        newErrors.currency = 'Currency is required for a percentage discount.'
      }
      if (!draft.maxDiscountAmount) {
        newErrors.maxDiscountAmount = 'Max discount amount is required for a percentage discount.'
      }
      if (!draft.discountPercentage) {
        newErrors.discountPercentage = 'Discount percentage is required for a percentage discount.'
      }
    } else if (draft?.type === 'fixed_discount') {
      if (!draft.currency) {
        newErrors.currency = 'Currency is required for a fixed discount.'
      }
      if (!draft.fixedDiscount) {
        newErrors.fixedDiscount = 'Fixed discount is required for a fixed discount.'
      }
    } else {
      if (!draft.customOfferText) {
        newErrors.customOfferText = 'Custom offer text is required for other types of offers.'
      }
    }
  }

  /*
  // Validate images
  if (!promotion.images || !Array.isArray(promotion.images) || promotion.images.length === 0) {
    newErrors.images = 'At least one image is required.'
  } else {
    promotion.images.forEach((image, index) => {
      if (
        !image._type ||
        (image._type !== 'PromotionImageCreate' && image._type !== 'PromotionImageReorder')
      ) {
        newErrors[`images[${index}]._type`] =
          "Image type must be either 'PromotionImageCreate' or 'PromotionImageReorder'."
      }
      if (image._type === 'PromotionImageCreate' && !image.uploadHandle) {
        newErrors[`images[${index}].uploadHandle`] = 'Upload handle is required for image creation.'
      }
      if (image._type === 'PromotionImageReorder' && !image.fileId) {
        newErrors[`images[${index}].fileId`] = 'File ID is required for image reorder.'
      }
    })
  }

  // Validate type
  if (!promotion.type) {
    newErrors.type = 'Promotion type is required.'
  }

  // Add more validations here as per the Python example...

  // Validate discountSchedule
  if (promotion.discountSchedule && Array.isArray(promotion.discountSchedule)) {
    promotion.discountSchedule.forEach((schedule, index) => {
      if (!schedule.dayOfTheWeek) {
        newErrors[`discountSchedule[${index}].dayOfTheWeek`] = 'Day of the week is required.'
      }
      if (
        typeof schedule.startTime !== 'number' ||
        schedule.startTime < 0 ||
        schedule.startTime > 23
      ) {
        newErrors[`discountSchedule[${index}].startTime`] = 'Start time must be between 0 and 23.'
      }
      if (typeof schedule.endTime !== 'number' || schedule.endTime < 0 || schedule.endTime > 23) {
        newErrors[`discountSchedule[${index}].endTime`] = 'End time must be between 0 and 23.'
      }
      if (schedule.startTime >= schedule.endTime) {
        newErrors[`discountSchedule[${index}]`] = 'End time must be greater than start time.'
      }
    })
  }

  // Validate dates
  if (!promotion.startDate || !Date.parse(promotion.startDate)) {
    newErrors.startDate = 'Invalid or missing start date.'
  }
  if (!promotion.endDate || !Date.parse(promotion.endDate)) {
    newErrors.endDate = 'Invalid or missing end date.'
  }
  if (
    promotion.startDate &&
    promotion.endDate &&
    Date.parse(promotion.startDate) >= Date.parse(promotion.endDate)
  ) {
    newErrors.dateRange = 'Start date must be before end date.'
  }

  */
  return newErrors
}
