import React from 'react'
import { Checkbox, FormGroup, FormControlLabel, TextField, Box, Typography } from '@mui/material'
import usePromotionStore from '../usePromotionStore'
import { DayOfTheWeek, DiscountSchedule } from 'src/models/promotions'
import { daysOfWeek } from './utils'
const ScheduleComponent: React.FC = () => {
  const { draft, updateDraft } = usePromotionStore()

  // Helper function to find a day's schedule or return null if not found
  const findDaySchedule = (day: string): DiscountSchedule | null => {
    return draft?.discountSchedule?.find(sch => sch.dayOfTheWeek === day) || null
  }

  // Convert decimal time to HH:MM format
  const decimalToHHMM = (decimalTime: number): string => {
    const hours = Math.floor(decimalTime)
    const minutes = Math.round((decimalTime - hours) * 60)
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
  }

  // Function to update the schedule in the state
  const updateSchedule = (day: DayOfTheWeek, updatedSchedule: DiscountSchedule | null) => {
    let newSchedules = draft?.discountSchedule?.filter(sch => sch.dayOfTheWeek !== day) || []
    if (updatedSchedule) {
      newSchedules.push(updatedSchedule)
    }
    updateDraft('discountSchedule', newSchedules)
  }

  // Toggle the presence of the day in the discount schedule
  const handleCheckboxChange = (day: DayOfTheWeek) => {
    const daySchedule = findDaySchedule(day)
    if (daySchedule) {
      // If found, remove it
      updateSchedule(day, null)
    } else {
      // If not found, add a new entry with default times
      updateSchedule(day, { dayOfTheWeek: day, startTime: 9, endTime: 17 })
    }
  }

  // Handle time change
  const handleTimeChange = (day: DayOfTheWeek, isStart: boolean, time: string) => {
    let daySchedule = findDaySchedule(day)
    const [hours, minutes] = time.split(':').map(Number)
    const decimalTime = hours + minutes / 60
    if (!daySchedule) {
      // If the day is not found, create a new schedule with default times
      daySchedule = { dayOfTheWeek: day, startTime: 9, endTime: 17 }
    }
    const updatedSchedule = {
      ...daySchedule,
      ...(isStart ? { startTime: decimalTime } : { endTime: decimalTime })
    }
    updateSchedule(day, updatedSchedule)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <FormGroup>
        {daysOfWeek.map(day => {
          const schedule = findDaySchedule(day)
          const isSelected = schedule !== null
          return (
            <Box key={day} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSelected}
                    onChange={() => handleCheckboxChange(day)}
                    color='primary'
                  />
                }
                label={
                  <Typography sx={{ width: '10rem', mr: 3 }}>
                    {day.charAt(0).toUpperCase() + day.slice(1)}
                  </Typography>
                }
              />

              <TextField
                label='Start Time'
                type='time'
                fullWidth
                disabled={!isSelected}
                value={isSelected ? decimalToHHMM(schedule.startTime) : ''}
                onChange={e => handleTimeChange(day, true, e.target.value)}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }} // 5 min steps
                sx={{ marginRight: 2 }}
              />
              <TextField
                label='End Time'
                type='time'
                fullWidth
                disabled={!isSelected}
                value={isSelected ? decimalToHHMM(schedule.endTime) : ''}
                onChange={e => handleTimeChange(day, false, e.target.value)}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }} // 5 min steps
              />
            </Box>
          )
        })}
      </FormGroup>
    </Box>
  )
}

export default ScheduleComponent
