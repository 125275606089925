import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PageLeave from 'src/assets/svg/PageLeave'
import { PrimaryButton, SecondaryButton } from '..'

interface DeletionConfirmPopupProps {
  open: boolean
  title?: string
  text?: string
  onClose: () => void
  onConfirm: () => void
}

const DeletionConfirmPopup: React.FC<DeletionConfirmPopupProps> = ({
  open,
  title,
  text,
  onClose,
  onConfirm
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button onClick={onClose}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent dividers={false} sx={{ pt: 2, pb: 3 }}>
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          <PageLeave />
          <Typography variant='h6' gutterBottom>
            {'Are you sure you want to leave the page?'}
          </Typography>
          <Typography gutterBottom>
            {'Changes may not be saved. You will lose all the progress.'}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pt: 3, pb: 5, px: 3 }}>
        <SecondaryButton fullWidth onClick={onConfirm} color='error'>
          Leave the page
        </SecondaryButton>
        <PrimaryButton fullWidth onClick={onClose} color='primary' variant='contained'>
          Cancel
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeletionConfirmPopup
