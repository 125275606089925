import { resizePx } from 'src/theme'

const Icon = () => (
  <svg
    width={resizePx(386)}
    height={resizePx(184)}
    viewBox='0 0 306 184'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_389_20074)'>
      <path
        d='M15.9634 183.589C15.943 183.499 13.9632 174.47 18.0064 166.971C22.0497 159.472 30.6803 156.165 30.767 156.132L31.1615 155.984L31.2546 156.396C31.2751 156.486 33.2549 165.515 29.2117 173.014C25.1686 180.513 16.5378 183.82 16.4511 183.852L16.0564 184L15.9634 183.589ZM30.6205 156.988C28.8917 157.732 22.0687 160.987 18.6538 167.32C15.2386 173.654 16.269 181.145 16.5976 182.997C18.3256 182.255 25.146 179.005 28.5642 172.665C31.9793 166.331 30.9493 158.841 30.6205 156.988Z'
        fill='#F1F1F1'
      />
      <path
        d='M26.1228 170.383C18.8569 174.755 16.0586 183.438 16.0586 183.438C16.0586 183.438 25.0406 185.034 32.3065 180.662C39.5724 176.29 42.3708 167.606 42.3708 167.606C42.3708 167.606 33.3887 166.011 26.1228 170.383Z'
        fill='#F1F1F1'
      />
      <path
        d='M136.156 100.297C136.266 99.725 136.251 99.1357 136.109 98.5705C135.967 98.0054 135.704 97.4781 135.336 97.026C134.969 96.5738 134.507 96.2077 133.983 95.9534C133.458 95.6992 132.885 95.563 132.302 95.5543L128.938 87.0918L123.297 87.8825L128.216 99.7665C128.267 100.746 128.675 101.673 129.363 102.372C130.051 103.071 130.972 103.493 131.95 103.559C132.929 103.624 133.898 103.328 134.673 102.727C135.448 102.126 135.975 101.261 136.156 100.297Z'
        fill='#9F616A'
      />
      <path
        d='M125.765 97.3242L124.017 93.1558L113.125 72.421L113.717 34.054L113.831 34.039C120.8 33.1181 125.516 65.2336 125.713 66.6014L132.595 90.1396L134.233 95.3278L125.765 97.3242Z'
        fill='#04BC79'
      />
      <path
        d='M109.028 178.227L114.324 178.227L116.843 157.799L109.027 157.799L109.028 178.227Z'
        fill='#9F616A'
      />
      <path
        d='M107.68 176.498L118.109 176.498H118.109C119.872 176.498 121.562 177.198 122.809 178.445C124.055 179.691 124.755 181.382 124.755 183.145V183.361L107.68 183.361L107.68 176.498Z'
        fill='#2F2E41'
      />
      <path
        d='M60.3359 170.159L64.681 173.187L78.4263 157.868L72.0136 153.398L60.3359 170.159Z'
        fill='#9F616A'
      />
      <path
        d='M60.2162 167.967L68.7727 173.93L68.773 173.93C70.2191 174.938 71.2056 176.479 71.5156 178.215C71.8255 179.95 71.4336 181.737 70.4259 183.184L70.3024 183.361L56.293 173.598L60.2162 167.967Z'
        fill='#2F2E41'
      />
      <path
        d='M106.12 173.425L105.891 135.103L102.559 111.006L96.3466 132.197L96.3345 132.214L73.8222 164.901L65.0625 160.462L65.1105 160.348C65.6458 159.075 78.2601 129.166 81.6507 129.166C81.9468 129.187 82.2435 129.135 82.5153 129.016C82.787 128.897 83.0257 128.713 83.2106 128.481C84.2058 127.152 83.3954 124.332 83.3871 124.303C82.4165 120.278 84.8569 118.907 85.343 118.678L86.6503 91.6875L86.7859 91.6968L122.228 94.1832L120.741 127.929L117.543 172.143L117.433 172.155L106.12 173.425Z'
        fill='#2F2E41'
      />
      <path
        d='M122.767 100.165L122.613 100.152L84.4961 97.0792L84.5902 84.4658L86.4558 51.5318L86.4664 51.509L94.064 35.0128L99.4498 32.5737L107.308 32.1602L107.327 32.165L114.498 33.9418L121.958 87.4987L122.767 100.165Z'
        fill='#04BC79'
      />
      <path
        d='M108.539 27.9324C113.402 25.2735 115.19 19.1751 112.531 14.3111C109.873 9.44717 103.775 7.65961 98.9115 10.3185C94.048 12.9774 92.2607 19.0758 94.9193 23.9398C97.5778 28.8037 103.676 30.5913 108.539 27.9324Z'
        fill='#9F616A'
      />
      <path
        d='M93.6328 21.6451V17.7345C93.6328 17.7345 90.4795 14.0202 93.9482 13.0846C93.9482 13.0846 94.8942 3.72826 103.093 8.09453C103.093 8.09453 114.445 6.22327 113.184 12.7727C113.184 12.7727 116.022 11.0628 115.076 15.4291L113.246 22.4624C113.246 22.4624 114.13 17.8164 111.292 17.1927L109.715 16.257C109.715 16.257 105.3 20.6233 98.6782 15.3214C98.6782 15.3214 95.8402 14.644 96.1555 17.4509C96.4708 20.2577 93.6328 21.6451 93.6328 21.6451Z'
        fill='#2F2E41'
      />
      <path
        d='M102.682 90.7442C102.689 90.1616 102.567 89.5847 102.327 89.0542C102.086 88.5236 101.732 88.0523 101.289 87.6734C100.847 87.2945 100.326 87.0173 99.7651 86.8612C99.2039 86.7051 98.6152 86.674 98.0406 86.7701L93.212 79.0488L87.8047 80.839L94.7767 91.6472C95.002 92.6019 95.5697 93.4408 96.3722 94.0048C97.1746 94.5689 98.1561 94.819 99.1306 94.7076C100.105 94.5963 101.005 94.1314 101.659 93.4009C102.314 92.6704 102.678 91.7251 102.682 90.7442Z'
        fill='#9F616A'
      />
      <path
        d='M90.2122 88.311L81.4766 70.5733L81.4843 70.5301C81.5195 70.3338 85.0359 50.807 87.7253 43.6295C90.4317 36.4066 94.1363 34.9564 94.2926 34.8982L94.3708 34.8691L97.6925 38.0557L93.0912 68.7717L98.051 83.5857L90.2122 88.311Z'
        fill='#04BC79'
      />
      <path d='M306 0H289.086V16.9159H306V0Z' fill='#F1F1F1' />
      <path d='M173.629 65.8242H156.715V82.7402H173.629V65.8242Z' fill='#F1F1F1' />
      <path d='M166.207 75.3288H299.929V7.625H166.207V75.3288Z' fill='white' />
      <path
        d='M301.034 76.4317H165.105V6.52148H301.034V76.4317ZM167.312 74.2253H298.828V8.72791H167.312V74.2253Z'
        fill='#E5E5E5'
      />
      <path d='M285.142 31.373H182.258V34.7853H285.142V31.373Z' fill='#E5E5E5' />
      <path d='M285.142 39.6484H182.258V43.0607H285.142V39.6484Z' fill='#E5E5E5' />
      <path d='M285.144 51.8672H266.023V54.8285H285.144V51.8672Z' fill='#04BC79' />
      <path
        d='M140.46 183.985H0.367697C0.270178 183.985 0.176652 183.947 0.107696 183.878C0.0387392 183.809 0 183.715 0 183.618C0 183.52 0.0387392 183.427 0.107696 183.358C0.176652 183.289 0.270178 183.25 0.367697 183.25H140.46C140.558 183.25 140.651 183.289 140.72 183.358C140.789 183.427 140.828 183.52 140.828 183.618C140.828 183.715 140.789 183.809 140.72 183.878C140.651 183.947 140.558 183.985 140.46 183.985Z'
        fill='#CBCBCB'
      />
    </g>
    <defs>
      <clipPath id='clip0_389_20074'>
        <rect width='306' height='184' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Icon
