import actionCreatorFactory from 'typescript-fsa'
import {
  ProviderLocationResponse,
  ProviderLocation,
  ProviderLocationId,
  LocationsListFilters,
  ProviderLocationQueryParams
} from 'src/models/providerLocations'
import { UpdateStatusPayload } from './types'

// Actions
const actionCreator = actionCreatorFactory('providerLocations')

export const fetch = actionCreator.async<void, ProviderLocationResponse, Error>('FETCH')
export const remove = actionCreator.async<ProviderLocationId, void, Error>('REMOVE')
export const setListFilters = actionCreator<ProviderLocationQueryParams>('SET_LIST_FITERS')
export const dismissError = actionCreator<void>('DISMISS_ERROR')
export const updateStatus = actionCreator.async<UpdateStatusPayload, ProviderLocation, Error>(
  'UPDATE_STATUS'
)
