import './styles.css'
import { MediaUpload } from 'src/store/mediaUploads/types'
import { makeStyles } from '@mui/styles'
import { File } from 'src/models/uploads'
import LinearProgress from '@mui/material/LinearProgress'
import TrashIcon from 'src/assets/svg/Trash' // Import MUI Delete icon
import IconButtonWrapper from '../Base/IconButton'

interface Props {
  item: MediaUpload | File
  index: number
  onDelete: (item: MediaUpload | File) => void
  isOdd?: boolean
}
const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'column',
    width: 140, // Adjust the width as needed
    marginRight: 8, // Add some space between the thumbnails
    marginBottom: 8,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' // Example shadow, adjust as needed
  },
  image: {
    height: 140, // Adjust the height as needed to match your design
    width: '100%',
    objectFit: 'cover',
    border: '2px solid transparent', // Adjust border color and size as needed
    borderRadius: 12, // Match the border-radius
    transition: 'border 0.3s ease, transform 0.3s ease', // Smooth transition for border and transform
    '&:hover': {
      border: '2px solid #04BC79', // Adjust border color and size as needed
      transform: 'scale(1.03)' // Optional: Slight scale on hover
    }
  },

  iconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2
  },
  deleteIcon: {
    color: '#ffffff',
    margin: 4,
    padding: 5,
    backgroundColor: 'white', // Semi-transparent black background
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Smaller shadow for the icon, adjust as needed
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)' // Darken on hover
    }
  },
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white overlay for progress bar
    borderRadius: '0 0 4px 4px' // Only round the bottom corners
  }
  // ... other styles if needed
})

const Image = (props: Props) => {
  const { item, isOdd } = props
  const classes = useStyles()

  const fileURL = (item as File).url || URL.createObjectURL((item as MediaUpload).file)

  const onDelete = () => {
    props.onDelete(item)
  }

  return (
    <div className={classes.container}>
      <img src={fileURL} alt='Preview' className={classes.image} />
      {(item as MediaUpload).state === 'uploading' && (
        <div className={classes.progress}>
          <LinearProgress />
        </div>
      )}
      <div className={classes.iconContainer}>
        <IconButtonWrapper
          bordercolor='#E52121'
          onClick={onDelete}
          size='small'
          className={classes.deleteIcon}
        >
          <TrashIcon size={15} />
        </IconButtonWrapper>
      </div>
    </div>
  )
}

export default Image
