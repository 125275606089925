import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import Add from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '..'
import { Rany18px700, Text14pxPoppins } from '../Typography'

// Define the types for the component's props
interface NoLocationsPlaceholderProps {
  icon: JSX.Element
  titleText?: string // Optional title text
  subtitleText?: string // Optional subtitle text
  buttonText: string // The text for the button
  hrefLink: string
}

const NoLocationsPlaceholder: React.FC<NoLocationsPlaceholderProps> = ({
  icon,
  buttonText,
  titleText,
  subtitleText,
  hrefLink
}) => {
  const navigate = useNavigate()
  const onClick = () => navigate(hrefLink)
  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%', // Adjust the height as needed
        textAlign: 'center'
      }}
    >
      {icon}
      <Rany18px700 marginTop={3}>{titleText}</Rany18px700>
      <Text14pxPoppins marginTop={2}>{subtitleText}</Text14pxPoppins>
      <PrimaryButton
        sx={{
          marginTop: 4
        }}
        startIcon={<Add />}
        onClick={onClick}
      >
        {buttonText}
      </PrimaryButton>
    </Box>
  )
}

export default NoLocationsPlaceholder
