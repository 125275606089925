import React, { useState, useCallback } from 'react'
import type { FC } from 'react'
import clsx from 'clsx'
import { useDropzone } from 'react-dropzone'
//import { Box, LinearProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

import UploadIcon from 'src/assets/svg/Upload'
//import ImageCropModal from './ImageCropModal'
import MediaList from './MediaList'
import { useDispatch } from 'react-redux'
import mediaUploads from 'src/store/mediaUploads'
import { UploadType } from 'src/models/uploads'
import { MediaUpload } from 'src/store/mediaUploads/types'
import { Text14pxRany } from '../Typography'

interface FilesDropzoneProps {
  uploadType: UploadType
  className?: string
  handleUpload?: (files: File[]) => void
  showDropzone?: boolean
  showTitle?: boolean
  isSubmitting?: boolean
  progress?: number
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(3)
  },
  mediaListContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    '& > img': {
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[1],
      width: 'auto',
      height: 80 // You can adjust the height as needed
    }
  },
  dropZone: {
    border: `2px dashed #D1D9D6`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F7F9F8',
    alignItems: 'center',
    padding: theme.spacing(6),
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  icon: {},
  text: {
    color: 'black'
  },
  browseButton: {
    color: '#04BC79'
  }
}))

const FilesDropzone: FC<FilesDropzoneProps> = ({
  className,
  handleUpload,
  showDropzone,
  showTitle,
  isSubmitting,
  progress,
  uploadType,
  ...rest
}) => {
  const dispatch = useDispatch()

  const classes = useStyles()
  const [cropModalOpen, setCropModalOpen] = useState(false)
  const { t } = useTranslation('translation', { keyPrefix: 'create_location' })

  const onDrop = async (newAcceptedFiles: File[]) => {
    if (newAcceptedFiles && newAcceptedFiles.length > 0) {
      const newFiles: File[] = []
      const readFilePromiseFns: (() => Promise<string>)[] = []

      newAcceptedFiles.forEach(acceptedFile => {
        readFilePromiseFns.push(() => readFile(acceptedFile))
        newFiles.push(acceptedFile)
      })

      const data = newAcceptedFiles.map((item, idx) => ({
        uid: Date.now() + idx,
        order: idx + 1,
        file: item,
        uploadType,
        state: 'queued'
      })) as MediaUpload[]
      dispatch(mediaUploads.actions.addMediaUploads(data))
    }
  }

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    multiple: true,
    onDrop: onDrop,
    maxFiles: 10,
    accept: 'image/jpeg, image/png'
  })

  const handleCropModalClose = (): void => {
    setCropModalOpen(false)
  }

  const onCropModalSubmit = (file: File) => {
    // setFiles([file])
  }

  const readFile = (file: File) =>
    new Promise<string>(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result as string), false)
      reader.readAsDataURL(file)
    })

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={classes.mediaListContainer}>
        {/* Here, you should map over the uploaded files and display them as thumbnails. */}
        {/* This is just a placeholder; you'll need to replace it with your MediaList component logic. */}
        <MediaList />
      </div>
      <div {...getRootProps()} onClick={open} className={classes.dropZone}>
        <input {...getInputProps()} />
        <UploadIcon alt='Upload icon' className={classes.icon} />
        <Text14pxRany mt={3} className={classes.text}>
          Drag and drop photos or <span className={classes.browseButton}>Browse</span>
        </Text14pxRany>
      </div>
    </div>
  )
}

export default FilesDropzone
