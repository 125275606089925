import React from 'react'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { Text14pxRanyBold } from 'src/components/Typography'

type Props = {
  tags: string[] | undefined
}

const Tags = ({ tags }: Props) => {
  return (
    <Stack direction='row' sx={{ flexWrap: 'wrap' }}>
      {tags?.map((tag, index) => (
        <Chip
          key={index}
          label={'#' + tag}
          color='primary'
          sx={{ bgcolor: 'orange', mr: 2, mt: 1, color: 'white' }}
        />
      ))}
    </Stack>
  )
}

// Example usage of the component
const TagsSection = (props: Props) => {
  if (!props.tags?.length) {
    return null
  }
  return (
    <div>
      <Text14pxRanyBold mb={1}>Tags</Text14pxRanyBold>
      <Tags tags={props.tags} />
    </div>
  )
}

export default TagsSection
