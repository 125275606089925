import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PhoneNumberInput from '../../components/PhoneNumberInput'

import authStore from 'src/store/auth'
import usePrevious from 'src/hooks/usePrevious'
import { Box, Container, Typography, TextField, Button, Grid, Link } from '@mui/material'
import LoginHeader from 'src/components/Header/LoginHeader'

import { useTranslation } from 'react-i18next'
import LoginIcon from 'src/assets/svg/Login'
import { useSnackbar } from 'notistack'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'
import { OTPMethods } from 'src/models/auth'
import AuthLayout, {
  AuthButtonsContainer,
  AuthTextWithLink
} from 'src/components/Layouts/AuthLayout'
import { calculateResponsiveFontSize } from 'src/theme/index'
import { PrimaryButton, SecondaryButton } from 'src/components/index'
import { PageTitle } from 'src/components/Typography'
import InputWithLabel, { InputLabelWrapper } from 'src/components/Base/InputWithLabel'

interface LoginProps {
  // This stuff allows us to use the same form for login/register and pass translation key
  // so that we can write Register/Login on submit button by default it's btn_login
  submitButtonTranslateKey?: string
}

function Login({ submitButtonTranslateKey = 'btn_login' }: LoginProps) {
  const [captchaToken, setCaptchaToken] = useState<string | undefined>()
  const [phoneCountryCode, setPhoneCountryCode] = useState<CountryCode | undefined>()

  const { t } = useTranslation('translation', { keyPrefix: 'login_page' })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const error = useSelector(authStore.selectors.error)
  const allowedOTPMethods = useSelector(authStore.selectors.allowedOTPMethods)
  const signInData = useSelector(authStore.selectors.signInData)
  const isFetchingCountriesOTPRequestMethods = useSelector(
    authStore.selectors.countriesOTPRequestMethodsList
  )
  const countriesOTPRequestMethodsList = useSelector(
    authStore.selectors.countriesOTPRequestMethodsList
  )
  const isSending = useSelector(authStore.selectors.isSendingPhoneVerification)

  const prevIsSending = usePrevious(isSending)

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Recaptcha in not yet available')
      return
    }

    const token = await executeRecaptcha('sendOTP')

    setCaptchaToken(token)
  }, [executeRecaptcha])

  useEffect(() => {
    dispatch(authStore.actions.resetLimits())

    dispatch(authStore.actions.getCountriesOTPRequestMethodsList.started())
  }, [])

  useEffect(() => {
    if (!!prevIsSending !== isSending && !isSending && !error) {
      navigate('/password')
    }
  }, [isSending])

  const handleChangePhone = (phoneNumber: string) => {
    dispatch(
      authStore.actions.updateSignInData({
        phoneNumber
      })
    )

    const parsedPhoneNumber = parsePhoneNumber('+' + phoneNumber)

    console.log('parsedPhoneNumber', parsedPhoneNumber)

    setPhoneCountryCode(parsedPhoneNumber.country)
  }

  // Get allowed OTP methods for entered phone number
  useEffect(() => {
    if (!phoneCountryCode || !countriesOTPRequestMethodsList) {
      return
    }

    const allowedOTPMethods = countriesOTPRequestMethodsList.find(
      country => country.countryCode === phoneCountryCode.toLowerCase()
    )

    if (allowedOTPMethods?.methods) {
      dispatch(authStore.actions.allowedOTPMethodsChange(allowedOTPMethods.methods))
    }
  }, [phoneCountryCode, countriesOTPRequestMethodsList])

  const handleSubmit = () => {
    if (!signInData?.phoneNumber) return

    if (!allowedOTPMethods) {
      enqueueSnackbar('No allowed OTP methods for your country', {
        variant: 'error',
        onExited: () => dispatch(authStore.actions.dismissError())
      })
    }

    if (allowedOTPMethods?.includes('sms')) {
      handleSignIn()
    } else if (allowedOTPMethods?.includes('sms_with_captcha')) {
      handleReCaptchaVerify()
    } else if (allowedOTPMethods?.includes('whatsapp')) {
      handleSignIn()
    }
  }

  // When got captcha token request OTP
  useEffect(() => {
    if (!captchaToken) {
      return
    }

    handleSignIn()
  }, [captchaToken])

  const handleSignIn = () => {
    let messenger: OTPMethods | undefined

    if (allowedOTPMethods?.includes('sms')) {
      messenger = 'sms'
    } else if (allowedOTPMethods?.includes('sms_with_captcha') && captchaToken) {
      messenger = 'sms'
    } else if (allowedOTPMethods?.includes('whatsapp')) {
      messenger = 'whatsapp'
    }

    if (!messenger) {
      enqueueSnackbar('No allowed OTP methods for your country', {
        variant: 'error',
        onExited: () => dispatch(authStore.actions.dismissError())
      })
      return
    }

    dispatch(
      authStore.actions.sendPhoneVerification.started({
        operation: 'signIn',
        recaptcha: captchaToken,
        messenger
      })
    )
  }

  // Process errors
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
        onExited: () => dispatch(authStore.actions.dismissError())
      })
    }
  }, [error])

  const handleBack = () => {
    navigate('/')
  }

  return (
    <>
      <LoginHeader />
      <AuthLayout
        icon={<LoginIcon />}
        title={'Elevate your business'}
        subtitle='Your brand will reach and resonate with a larger audience, gaining the attention and
      recognition it rightfully deserves'
      >
        {/* Right part with the login form */}

        <>
          <PageTitle mb={5}>Log in</PageTitle>

          <InputLabelWrapper label='Phone number'>
            <PhoneNumberInput
              defaultValue={signInData?.phoneNumber}
              onChangePhone={handleChangePhone}
              onEnterKeyPress={handleSubmit}
            />
          </InputLabelWrapper>

          <AuthButtonsContainer>
            <SecondaryButton
              variant='outlined'
              fullWidth
              onClick={handleSubmit}
              disabled={
                !signInData?.phoneNumber || !isFetchingCountriesOTPRequestMethods || isSending
              }
            >
              Get code – WhatsApp
            </SecondaryButton>
            <PrimaryButton
              variant='contained'
              color='success'
              fullWidth
              onClick={handleSubmit}
              disabled={
                !signInData?.phoneNumber || !isFetchingCountriesOTPRequestMethods || isSending
              }
            >
              Get code – SMS
            </PrimaryButton>
          </AuthButtonsContainer>

          <AuthTextWithLink linkHref='signup' text={'Or create an account'} linkText='Sign up' />
        </>
      </AuthLayout>
    </>
  )
}
export default React.memo(Login)
