import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Box, InputAdornment, IconButton, Grid } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { Dropdown as CustomDropdown, TextInput, PrimaryButton } from 'src/components' // Adjust the import path as necessary
import ViewToggle from 'src/components/List/ViewToggle' // Adjust the import path as necessary
import { PageTitle } from 'src/components/Typography'
import brandsStore from 'src/store/brands'
import providerLocationsStore from 'src/store/providerLocations'
import debounce from 'lodash/debounce'

import { useDispatch, useSelector } from 'react-redux'

type ViewToggleProps = {
  view: string
  setView: (view: string) => void
  totalCount: number
}
const MyPromotions = ({ view, setView, totalCount }: ViewToggleProps) => {
  const dispatch = useDispatch()
  const brandsOptions = useSelector(brandsStore.selectors.brandsOptions)
  const listFilters = useSelector(providerLocationsStore.selectors.listFilters)

  const updateFilters = (field: any, value: string) => {
    dispatch(
      providerLocationsStore.actions.setListFilters({
        [field]: value
      })
    )
  }

  // Debounce the updateFilters function
  const debouncedUpdateFilters = useCallback(debounce(updateFilters, 300), [])

  useEffect(() => {
    // Fetch data based on filters
    dispatch(providerLocationsStore.actions.fetch.started())

    // Cleanup function in case the component unmounts before the fetch is completed
    return () => {
      debouncedUpdateFilters.cancel()
    }
  }, [listFilters, dispatch, debouncedUpdateFilters])

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    updateFilters(name, value)
  }

  const handleSelectChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }> | any
  ) => {
    const name = event.target.name!
    const value = event.target.value as string // Assuming value is always a string
    updateFilters(name, value)
  }

  return (
    <Box sx={{ px: 0 }}>
      <Grid container alignItems='center' justifyContent='space-between'>
        <Grid item>
          <PageTitle>My locations ({totalCount})</PageTitle>
        </Grid>
        <Grid item>
          <PrimaryButton href={'/locations/create'} variant='contained' startIcon={<AddIcon />}>
            New location
          </PrimaryButton>
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1, mt: 2, mb: 2 }}>
        <Grid container spacing={2} alignItems='center'>
          {/* Search field */}
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <TextInput
              fullWidth
              id='search-location'
              name='name'
              value={listFilters.name}
              onChange={handleInputChange}
              placeholder='Find your location'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: listFilters.name && (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => updateFilters('name', '')} edge='end'>
                      <CloseIcon /> {/* Ensure you have imported CloseIcon */}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          {/* Dropdowns */}
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Box display='flex' gap={2}>
              {/* Replace 'option1', 'option2', etc. with your actual data */}
              <CustomDropdown
                onChange={handleSelectChange}
                value={listFilters.brandId}
                name='brandId'
                placeholder='Brand'
                label='Brand'
                options={brandsOptions!}
              />
              {listFilters.brandId ? (
                <IconButton onClick={() => updateFilters('brandId', '')} edge='start'>
                  <CloseIcon /> {/* Ensure you have imported CloseIcon */}
                </IconButton>
              ) : null}

              <CustomDropdown
                placeholder='Status'
                onChange={handleSelectChange}
                value={listFilters.status}
                name='status'
                label='Status'
                options={[
                  { label: 'Live', value: 'live' },
                  { label: 'Draft', value: 'draft' }
                ]}
              />
              {listFilters.status ? (
                <IconButton onClick={() => updateFilters('status', '')} edge='end'>
                  <CloseIcon /> {/* Ensure you have imported CloseIcon */}
                </IconButton>
              ) : null}
            </Box>
          </Grid>
          {/* ToggleButtonGroup */}
          <Grid item xs={12} sm={12} md={3} lg={6}>
            <Box display='flex' justifyContent='flex-end'>
              <ViewToggle view={view} setView={setView} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default MyPromotions
