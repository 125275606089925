import React from 'react'
import Box from '@mui/material/Box'

import { StatusLabel } from 'src/components'
import { CustomCard, Label, Category, Subtitle } from 'src/components/List/Components'
import LocationLabel from 'src/components/List/LocationLabel'
import LocationActionButtons from 'src/components/List/LocationActionButtons'
import PreviewIcon from '@mui/icons-material/RemoveRedEye' // Choose the appropriate icon
import { Text12pxPoppins, Text14pxPoppins } from 'src/components/Typography'

import { calculateResponsiveFontSize, colors } from 'src/theme'
import promotionsStore from 'src/store/promotions'

import { Promotion, PromotionType } from 'src/models/promotions'
import { CardMedia } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

type RestaurantItemProps = {
  item: Promotion
}

const RestaurantItem: React.FC<RestaurantItemProps> = ({ item }) => {
  // Convert the status to a format suitable for display
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { type, discountPercentage, fixedDiscount, status, currency, text } = item
  let subtitle = 'Jacks Burgers'

  const aspectRatio = 232 / 292

  const discount =
    type === PromotionType.percentageDiscount
      ? `${discountPercentage}% Discount`
      : `${fixedDiscount} ${currency || ''}`

  const onEdit = () => navigate(`edit/${item.id}`)

  const onChangeStatus = () => {
    dispatch(promotionsStore.actions.updateStatus.started({ id: item.id!, status: 'live' }))
  }

  const onRemove = () => {
    dispatch(promotionsStore.actions.remove.started(item.id!))
  }
  const isUpdatingStatus = useSelector(promotionsStore.selectors.isUpdatingStatus)

  if (!item) {
    return null
  }

  return (
    <CustomCard>
      <Box display={'flex'} alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center' gap={1}>
          <PreviewIcon sx={{ color: colors.grey90 }} /> {/* This is your icon */}
          <Text12pxPoppins>{'User preview'}</Text12pxPoppins>
        </Box>
        <StatusLabel status={item.status!} />
      </Box>

      <Label mt={1.5} variant='h6'>
        {item.title}
      </Label>
      <Subtitle variant={'subtitle1'} mt={1}>
        {subtitle}
      </Subtitle>

      <CardMedia
        sx={{
          borderRadius: 1,
          objectFit: 'cover',
          backgroundSize: 'cover',
          mt: 2,
          mb: 2,
          width: '100%', // Image takes full width of the card
          // paddingTop is a percentage of the width, which maintains the aspect ratio
          paddingTop: `${aspectRatio * 100}%`
        }}
        image={item.image?.url || item.locations?.[0].image?.url}
      />

      <Box sx={{ alignItems: 'center' }}>
        <Category sx={{ backgroundColor: '#5ABA1A' }} label={discount} />
        <Category sx={{ ml: 2 }} label={'Locations: 1'} />
      </Box>
      <Box component='div' mt={2} overflow='hidden'>
        <Text14pxPoppins
          sx={{
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2, // Number of lines you want to display
            WebkitBoxOrient: 'vertical'
          }}
        >
          {text}
        </Text14pxPoppins>
      </Box>
      <Box mt={2}>
        <LocationActionButtons
          isLoading={isUpdatingStatus}
          onChangeStatus={onChangeStatus}
          onRemove={onRemove}
          onEdit={onEdit}
          status={status!}
        />
      </Box>
    </CustomCard>
  )
}

export default RestaurantItem
