import React, { useState, useEffect } from 'react'
import { TextField, Box, Button, Typography, Link } from '@mui/material'
import OtpInput from 'react-otp-input'
import useSignUpStore from './useSignUpStore'
import InputWithLabel, { InputLabelWrapper } from 'src/components/Base/InputWithLabel'
import ResendButtons from 'src/components/OTP/ResendButtons'
import moment from 'moment'
import { enqueueSnackbar } from 'notistack'
import { OTPMethods } from 'src/models/auth'
import { useTranslation } from 'react-i18next'
import { ResendOTP } from 'src/components/OTP/ResendOTPLink'
import { useReCaptcha } from 'src/hooks/useReCaptcha'

const OTP_LENGTH = 4 // You can set OTP length as needed

interface Props {}
const OTPInputForm: React.FC<Props> = () => {
  const [timer, setTimer] = useState<number>(30) // 30 seconds countdown
  const { t } = useTranslation('translation', { keyPrefix: 'otp' })
  const [activeMessenger, setActiveMessenger] = useState<OTPMethods | undefined>()
  const { captchaToken, handleReCaptchaVerify } = useReCaptcha()

  const {
    draft,
    isSendingPhoneVerification,
    nextOTPRequestAvailableAt,
    isSendLimitReached,
    sendPhoneVerification,
    errors,
    updateDraft,
    handleInputChange
  } = useSignUpStore()

  const setOtp = (value: string) => updateDraft('otp', value)

  useEffect(() => {
    // Start a timer if it's not already at 0
    let countdown: number | undefined
    if (timer > 0) {
      countdown = window.setInterval(() => {
        setTimer(prevTime => (prevTime > 0 ? prevTime - 1 : 0))
      }, 1000)
    }

    // Clear interval on component unmount
    return () => {
      if (countdown) clearInterval(countdown)
    }
  }, [timer])

  const handleResendClick = () => {
    setTimer(30) // reset timer to 30 seconds
    // Here you can trigger a function to resend the code
  }

  const otpInputStyle = {
    width: '5rem', // Width of each input field
    height: '4rem',
    margin: '0 1rem',
    fontSize: '1rem',
    backgroundColor: '#F7F9F8',
    borderRadius: '8px',
    border: '1px solid transparent'
  }

  const resendOTP = (messenger: OTPMethods) => {
    if (messenger !== 'whatsapp') {
      const timeToNextMessageAvailable = moment.duration(
        moment(nextOTPRequestAvailableAt).diff(moment())
      )

      // Show warning that we cannot send one more message now, as we hit time limit
      if (timeToNextMessageAvailable.as('seconds') > 0) {
        enqueueSnackbar(
          `${t('otp_limit_reached')}${t('try_again_in_short')
            .replace('%minutes%', timeToNextMessageAvailable.minutes().toString())
            .replace('%seconds%', timeToNextMessageAvailable.seconds().toString())}`,
          {
            variant: 'warning'
          }
        )

        return
      }
    }

    setActiveMessenger(messenger)

    sendPhoneVerification(captchaToken!, messenger)
  }

  const handleResendOTP: ResendOTP = (event, messenger) => {
    if ((isSendingPhoneVerification || isSendLimitReached) && messenger !== 'whatsapp') {
      event.preventDefault()
      return
    }

    if (messenger === 'sms_with_captcha') {
      handleReCaptchaVerify()
      return
    }

    resendOTP(messenger)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box
        mt={2}
        sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '20px' }}
      >
        <InputLabelWrapper label='Enter verification code'>
          <OtpInput
            value={draft.otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span>-</span>}
            inputStyle={otpInputStyle}
            renderInput={props => <input {...props} />}
          />
        </InputLabelWrapper>
      </Box>
      <ResendButtons activeMessenger={undefined} handleResendOTP={handleResendOTP} />
    </Box>
  )
}

export default OTPInputForm
