import React from 'react'
import { useSelector } from 'react-redux'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import { useNavigate, useParams } from 'react-router-dom'
import promotionsStore from 'src/store/promotions'
import providerLocationsStore from 'src/store/providerLocations'
import { PromotionType } from 'src/models/promotions'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { PromotionId } from 'src/models/promotions'
import { useLocation } from 'react-router-dom'
import { State } from 'src/models'
import createPromotion from 'src/store/createPromotion'
import ArrowBack from '@mui/icons-material/ArrowBack'

import BusinessOperationHours from './WeekHours'
import { PromotionCard } from 'src/components/Promotion/PromotionCard'

const PromotionsPage = () => {
  const navigate = useNavigate()
  const domLocation = useLocation()
  const { id } = useParams()

  const promotionId: PromotionId | undefined = (domLocation?.state as any)?.promotionId || id

  const promotionData = useSelector((state: State) => {
    if (id) {
      return promotionsStore.selectors.promotionById(state, promotionId!)
    } else {
      return createPromotion.selectors.currentDraft(state, promotionId || 'new')
    }
  })

  const handleBack = () => {
    navigate(-1)
  }

  const {
    title,
    discountPercentage,
    discountSchedule,
    discountAvailableAtAnyTime,
    fixedDiscount,
    text,
    currency,
    image,
    locations,
    type
  } = promotionData || {}

  if (!promotionData) {
    return null
  }

  const discount =
    type === PromotionType.percentageDiscount
      ? `${discountPercentage}% Discount`
      : `${fixedDiscount} ${currency || ''}`

  // Get image directly from promotion data
  const promotionImageURL = image?.url || (image as any)?.preview
  // Get image from promotion.location object (currently it's available only when we fetch promotion from api)
  const locationImageURL = locations?.[0]?.image?.url || (locations?.[0]?.image as any)?.preview
  // Get image from cached location info (used when we are creating promotion, and we don't have location image from api)

  const promotionImageToDisplayURL = promotionImageURL || locationImageURL

  return (
    <div className='full-page full-page-gray-bg'>
      <Container>
        <Box pt={2} mb={2}>
          <IconButton onClick={handleBack}>
            <ArrowBack />
          </IconButton>
        </Box>
        <Box>
          <Grid
            justifyContent={'center'}
            container
            xs={12}
            direction={{ xs: 'column', md: 'row' }}
            alignItems={'center'}
            rowSpacing={2}
            wrap='wrap'
          >
            <Grid item xs={4} sm={6} xl={5}>
              <PromotionCard promotion={promotionData} imageURL={promotionImageToDisplayURL} />
            </Grid>

            <Grid item xs={6}>
              <Container
                component='main'
                maxWidth='xs'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  flexDirection: 'column',
                  padding: '32px',
                  borderRadius: '16px',
                  minWidth: '320px',
                  boxShadow: 3
                }}
              >
                <IconButton
                  sx={{
                    alignSelf: 'flex-start',
                    mt: -2,
                    ml: -2
                  }}
                  onClick={handleBack}
                >
                  <CloseIcon />
                </IconButton>
                {promotionImageToDisplayURL ? (
                  <CardMedia
                    component='img'
                    sx={{ width: 220, height: 220, mt: -4, borderRadius: 4 }}
                    image={promotionImageToDisplayURL}
                    alt={'image'}
                  />
                ) : null}
                <Typography fontWeight={600} fontSize={16} mt={2}>
                  {title}
                </Typography>
                <Typography
                  component='h1'
                  fontWeight={700}
                  variant='h6'
                  fontSize={'26px'}
                  color='#EA4235'
                >
                  {discount}
                </Typography>
                <Typography
                  mt={2}
                  fontSize={13}
                  textAlign={'center'}
                  fontWeight={'400'}
                  color='text.secondary'
                >
                  {locations?.[0]?.address || locations?.[0]?.address}
                </Typography>
                <Typography
                  mt={2}
                  textAlign={'center'}
                  fontSize={14}
                  fontFamily={'Share'}
                  letterSpacing={'-0.025em'}
                  fontWeight={'400'}
                  sx={{ color: '#293845', opacity: 0.7 }}
                >
                  {text}
                </Typography>
                <BusinessOperationHours
                  discountSchedule={discountSchedule}
                  discountAvailableAtAnyTime={discountAvailableAtAnyTime}
                />
              </Container>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  )
}

export default PromotionsPage
