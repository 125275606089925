import React, { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import ButtonBase from '@mui/material/ButtonBase'
import { IconButtonProps } from '@mui/material'

// Define a styled button that includes hover effects
const IconButton = styled(ButtonBase)(({ theme, color }) => ({
  // Base styles
  borderRadius: theme.spacing(1.2), // Circular border
  padding: theme.spacing(1.5), // Size of the button
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `2px solid transparent`, // Dynamic border color based on props

  transition: 'border-color 0.3s, background-color 0.3s', // Smooth transition for the border color and background

  // Hover styles
  '&:hover': {
    border: `2px solid ${color}`, // Dynamic border color based on props
    // backgroundColor: theme.palette.action.hover, // Slight background color on hover
    borderColor: color // Change border color on hover
  },

  // Focus styles
  '&:focus-visible': {
    outline: '4px auto', // Adjust focus outline as needed
    outlineColor: theme.palette.primary.main // Use theme's primary color or any color of choice
  }
}))

interface IconButtonWrapperProps extends IconButtonProps {
  bordercolor?: string
}

const IconButtonWrapper: React.FC<IconButtonWrapperProps> = props => {
  return (
    <IconButton color={props.bordercolor} {...props}>
      {props.children}
    </IconButton>
  )
}

export default IconButtonWrapper
