import { createTheme, responsiveFontSizes } from '@mui/material/styles'

export const calculateResponsiveFontSize = (sizeAt1440px: number) => {
  // Assuming the design is based on a width of 1440px
  const baseWidth = 1440
  const viewportWidth = window.innerWidth
  const ratio = viewportWidth / baseWidth
  if (sizeAt1440px * ratio < sizeAt1440px) {
    return sizeAt1440px
  }

  if (ratio > 1.4) {
    return sizeAt1440px * 1.4
  }
  return sizeAt1440px * ratio
}

export const resizePx = (sizeAt1440px: number) => {
  // Ensure the input size is in pixels and a number
  if (typeof sizeAt1440px !== 'number') {
    console.error('Input size must be a number representing pixels')
    return sizeAt1440px // Return the original size if not a number
  }

  const baseWidth = 1440 // The width the original design is based on
  const viewportWidth = window.innerWidth // The width of the current viewport
  const ratio = viewportWidth / baseWidth // Calculate the ratio of the viewport width to the base width
  const calculatedSize = sizeAt1440px * ratio // Calculate the responsive font size

  // Optional: Round the result to avoid subpixel values
  const roundedSize = Math.round(calculatedSize)

  return roundedSize // Return the new size in pixels
}

// Define a base theme with typography for "Rany" and "Poppins" fonts
const muiTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#04BC79'
    },
    text: {
      primary: '#292B2A', // Your custom text color
      secondary: '#78827E',
      //@ts-ignore
      gray60: '#95A19C'
    },
    background: {
      default: '#f0f0f0',
      paper: '#ffffff'
    }
  },
  typography: {
    fontFamily: 'Rany, Poppins, Arial, sans-serif',
    h1: {
      fontSize: '3.25rem' // base size for h1
    },
    h2: {
      fontSize: '2.5rem' // base size for h2
    },
    h3: {
      fontSize: `${calculateResponsiveFontSize(2)}rem`
    },
    h4: {
      fontSize: '1.75rem' // base size for h4
    },
    h5: {
      fontSize: '1.5rem' // base size for h5
    },
    h6: {
      fontSize: `${calculateResponsiveFontSize(1)}rem` // For 16px with 3rem at 1440px width
    },
    subtitle1: {
      fontSize: `${calculateResponsiveFontSize(0.875)}rem` // For 14px with 3rem at 1440px width
    },
    subtitle2: {
      fontSize: `${calculateResponsiveFontSize(0.75)}rem` // For 12px with 3rem at 1440px width
    }
  },
  shape: {
    borderRadius: 12 // Custom border radius for components
  }
  // Define other theme customizations like palette, components overrides, etc.
})

// Use responsiveFontSizes to make the typography responsive
export default responsiveFontSizes(muiTheme, {
  breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
  factor: 2 // The factor that scales the font size between breakpoints.
})

export const colors = {
  grey90: '#3E4643'
}

export interface Theme {
  shape: {
    borderRadius: number
  }
  // Define other custom properties here
}
// Allow configuration using `createTheme`
interface ThemeOptions {
  shape?: {
    borderRadius?: number
  }
  // Define other custom properties here
}

/*
import _ from 'lodash'
import { colors, responsiveFontSizes } from '@material-ui/core'
import { createTheme as createMuiTheme } from '@material-ui/core/styles'
import type { Theme as MuiTheme } from '@material-ui/core'
import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows'
import type {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground
} from '@material-ui/core/styles/createPalette'
import { softShadows, strongShadows } from './shadows'
import typography from './typography'

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK'
}

interface TypeBackground extends MuiTypeBackground {
  dark: string
}

interface Palette extends MuiPalette {
  background: TypeBackground
}

export interface Theme extends MuiTheme {
  name: string
  palette: Palette
}

type Direction = 'ltr' | 'rtl'

interface ThemeConfig {
  direction?: Direction
  responsiveFontSizes?: boolean
  theme?: string
}

interface ThemeOptions {
  name?: string
  direction?: Direction
  typography?: Record<string, any>
  overrides?: Record<string, any>
  palette?: Record<string, any>
  shadows?: MuiShadows
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
}

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: '#39a0d9'
      },
      secondary: {
        main: '#4287f5'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: 'rgba(41, 56, 69, 0.7)',
        red: '#EA4235'
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.DARK,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#FFFFFF'
      },
      secondary: {
        main: '#4287f5'
      },
      text: {
        primary: '#e6e5e8',
        secondary: 'rgba(41, 56, 69, 0.7)',
        red: '#EA4235'
      }
    },
    shadows: strongShadows
  }
]

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find(theme => theme.name === config.theme)

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`))
    ;[themeOptions] = themesOptions
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  )

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme as Theme
}

*/
