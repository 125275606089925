import { LocationDraftUpdate, LocationValidationErrors } from 'src/store/createLocation/types'
import { validatePhoneNumber } from 'src/utils/validateField'

const StepsCount = 5 + 1

export const isCurrentStepCompleted = (draft: LocationDraftUpdate = {}, activeStep: number) => {
  switch (activeStep) {
    case 1:
      return draft?.name && draft?.brandId && draft?.contactEmail && draft?.contactPhoneNumber
    case 2:
      return true
    case 3:
      return draft.address && draft.location?.latitude && draft.location?.longitude
    case 4:
      return true
    case 5:
      return true
    default:
      return false
  }
}

export const validateForm = (draft: LocationDraftUpdate = {}, activeStep: number) => {
  const newErrors: LocationValidationErrors = {}

  // 1 Step
  if (!draft.name) {
    newErrors.name = 'Location name is required.'
  }
  if (!draft.brandId) {
    newErrors.brandId = 'Brand is required.'
  }
  if (!draft.contactEmail) {
    newErrors.contactEmail = 'Location name is required.'
  }

  if (!draft.contactPhoneNumber) {
    newErrors.contactPhoneNumber = 'Contact phone number is required.'
  }

  if (!validatePhoneNumber(draft?.contactPhoneNumber || '')) {
    newErrors.contactPhoneNumber = 'Contact phone number is invalid'
  }

  if (!draft.contactEmail?.match(/^[^@]+@[^@]+\.[^@]+$/)) {
    newErrors.contactEmail = 'Contact email is invalid.'
  }

  // 3 Step

  if (activeStep > 2) {
    if (
      typeof draft.location?.latitude !== 'number' ||
      typeof draft.location?.longitude !== 'number'
    ) {
      newErrors.location = 'Location is invalid, must contain latitude and longitude.'
    }
    if (!draft.address) {
      newErrors.address = 'Address is required.'
    }
  }

  return newErrors
}
