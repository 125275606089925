import React, { ReactNode, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Container,
  Select,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { PageTitle } from '../Typography'
import CustomBreadcrumbs from '../Breadcrumbs'
import ButtonBack from '../Base/ButtonBack'

interface Props {
  stepsCount: number
  activeStep: number
  title: string
  children: ReactNode
}

const CreateContainer = ({ title, stepsCount, activeStep, children }: Props) => {
  return (
    <Container
      maxWidth={false}
      style={{
        padding: '2rem',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Box width={'100%'} display='flex' justifyContent='space-between'>
        <div />
        <PageTitle>{title}</PageTitle>
        <ButtonBack />
      </Box>

      <Box marginTop={3} flexDirection='column' minWidth={5 * 140}>
        <CustomBreadcrumbs stepsCount={stepsCount} activeStep={activeStep - 1}></CustomBreadcrumbs>
      </Box>
      <Box mt={5} minWidth={500}>
        {children}
      </Box>
    </Container>
  )
}

export default CreateContainer
