import React, { useEffect, useRef, ReactElement, ComponentType } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'

import ListItemButton from '@mui/material/ListItemButton'

interface ListProps<T> {
  items: T[]
  renderItem: ComponentType<{ item: T }> // Component to render each item
  onItemSelected: (id: string) => void
  selectedItem: string | null
  onItemFocus: (item: T) => void
}

function ItemList<T extends { id: string }>({
  items,
  renderItem: ItemComponent,
  onItemSelected,
  selectedItem,
  onItemFocus
}: ListProps<T>): ReactElement {
  const itemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})

  useEffect(() => {
    if (selectedItem && itemRefs.current[selectedItem]) {
      itemRefs.current[selectedItem]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
  }, [selectedItem])

  return (
    <List sx={{ padding: 0 }}>
      {items.map(item => (
        <ListItem
          key={item.id}
          onMouseEnter={() => onItemFocus(item)} // Or use another event as appropriate
          ref={el => ((itemRefs.current[item.id] as any) = el)}
          sx={{
            padding: 0,
            bgcolor: selectedItem === item.id ? 'action.selected' : 'background.paper'
          }}
          onClick={() => onItemSelected(item.id)}
        >
          <ListItemButton>
            <Box sx={{ width: '100%' }}>
              <ItemComponent item={item} />
            </Box>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

export default ItemList
