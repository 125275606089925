import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

const ScrollableButtons = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        scrollbarWidth: 'none',
        p: 1 // Add padding around the container if needed
      }}
    >
      {/* Unique styled button */}
      <Button
        variant='contained'
        sx={{
          mr: 1,
          minWidth: 165,
          borderRadius: '20px', // Rounded borders
          backgroundColor: '#774BE1', // The button color seems to be purple
          color: 'white', // Text color is white
          textTransform: 'none', // Prevent uppercase transformation
          fontWeight: 'bold', // Bold font weight
          '&:hover': {
            backgroundColor: 'darkpurple' // Darken the button when hovering
          }
        }}
      >
        Create invitation
      </Button>

      {/* These buttons have less emphasis and can have similar styles */}
      <Button
        variant='outlined'
        sx={{
          mr: 1,
          minWidth: 100,
          borderRadius: '20px', // Match border radius
          border: '1px solid black', // Border color appears to be black
          color: 'black', // Text color is black
          textTransform: 'none' // Prevent uppercase transformation
        }}
      >
        Directions
      </Button>

      <Button
        variant='outlined'
        sx={{
          mr: 1,
          minWidth: 100,
          borderRadius: '20px',
          border: '1px solid black',
          color: 'black',
          textTransform: 'none'
        }}
      >
        Share
      </Button>

      {/* Add more buttons as needed */}
    </Box>
  )
}

export default ScrollableButtons
