import { resizePx } from 'src/theme'

const Icon = () => (
  <svg
    width={resizePx(256)}
    height={resizePx(160)}
    viewBox='0 0 256 160'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_389_18960)'>
      <path
        d='M0 159.472C0 159.718 0.197891 159.916 0.444318 159.916H255.556C255.802 159.916 256 159.718 256 159.472C256 159.225 255.802 159.027 255.556 159.027H0.444318C0.197891 159.027 0 159.225 0 159.472Z'
        fill='#3F3D56'
      />
      <path
        d='M100.939 160H31.0608C28.2703 160 26 157.746 26 154.976V26.0236C26 23.2535 28.2703 21 31.0608 21H100.939C103.73 21 106 23.2535 106 26.0236V154.976C106 157.746 103.73 160 100.939 160ZM31.0608 21.6495C28.6311 21.6495 26.6544 23.6118 26.6544 26.0236V154.976C26.6544 157.388 28.6311 159.35 31.0608 159.35H100.939C103.369 159.35 105.346 157.388 105.346 154.976V26.0236C105.346 23.6118 103.369 21.6495 100.939 21.6495H31.0608Z'
        fill='#3F3D56'
      />
      <path
        d='M248.854 75.3861C248.539 74.8546 247.176 75.4237 246.925 74.9413C246.675 74.4606 247.942 73.7282 248.666 72.0552C248.796 71.7535 249.619 69.8522 249.01 69.2655C247.856 68.1534 242.574 72.7426 240.725 71.2408C240.319 70.9112 240.036 70.2533 238.808 69.185C238.32 68.7601 238.024 68.5682 237.738 68.6396C237.333 68.7409 237.301 69.2837 236.866 70.3511C236.214 71.9519 235.828 71.8552 235.448 73.1324C235.166 74.0805 235.23 74.6317 234.898 74.7391C234.428 74.891 233.996 73.8854 233.401 73.9755C232.793 74.0674 232.426 75.2382 232.31 76.1149C232.092 77.76 232.696 78.8455 233.031 79.987C233.395 81.2275 233.611 83.114 232.77 85.7846L224.453 109.22C226.289 104.411 231.606 91.2287 233.84 86.3301C234.485 84.9159 235.197 83.4815 236.659 82.9155C238.067 82.3704 240 82.6958 242.465 82.102C242.753 82.0327 243.552 81.8294 243.652 81.3596C243.735 80.9711 243.266 80.7543 243.338 80.3905C243.435 79.9025 244.359 79.8965 245.494 79.3335C246.295 78.9364 246.794 78.4643 247.222 78.0583C247.352 77.9359 249.274 76.0937 248.854 75.3861Z'
        fill='#F2F2F2'
      />
      <path
        d='M216.131 87.7236C215.779 87.725 215.665 88.5585 215.356 88.5424C215.048 88.5263 215.054 87.6931 214.441 86.8551C214.331 86.704 213.635 85.7516 213.172 85.8812C212.293 86.1269 213.021 90.0437 211.751 90.5177C211.472 90.6218 211.067 90.571 210.189 90.8655C209.839 90.9826 209.66 91.0722 209.612 91.2331C209.545 91.4614 209.802 91.6338 210.2 92.1553C210.798 92.9373 210.639 93.0988 211.156 93.6538C211.54 94.0658 211.829 94.1932 211.786 94.3871C211.725 94.6616 211.107 94.5836 210.979 94.9018C210.849 95.2265 211.318 95.7446 211.715 96.0545C212.459 96.6361 213.166 96.6527 213.823 96.8178C214.537 96.9971 215.525 97.4354 216.593 98.6184L225.695 109.463C223.865 107.174 218.929 100.761 217.17 98.2508C216.662 97.5262 216.163 96.7628 216.307 95.8818C216.446 95.0336 217.164 94.1785 217.583 92.7974C217.632 92.6361 217.763 92.1854 217.561 92.0005C217.395 91.8476 217.153 92.0157 216.995 91.8753C216.783 91.6869 217.047 91.2317 217.098 90.5119C217.135 90.0044 217.047 89.6233 216.971 89.2956C216.949 89.1968 216.599 87.7218 216.131 87.7236Z'
        fill='#F2F2F2'
      />
      <path
        d='M223.149 89.2992L222.938 87.4069L223.034 87.2635C223.483 86.6001 223.711 85.9523 223.714 85.3373C223.714 85.2396 223.71 85.1418 223.705 85.0422C223.687 84.6475 223.664 84.1568 223.92 83.5848C224.063 83.2659 224.466 82.5276 225.072 82.619C225.235 82.6419 225.358 82.7168 225.452 82.8045C225.466 82.7826 225.48 82.7606 225.495 82.7369C225.684 82.449 225.833 82.3257 225.977 82.2069C226.087 82.1155 226.202 82.0214 226.381 81.7985C226.459 81.7007 226.52 81.6148 226.572 81.5426C226.729 81.3243 226.933 81.0638 227.294 81.0684C227.68 81.0867 227.882 81.4056 228.016 81.6166C228.255 81.9931 228.363 82.2645 228.435 82.4445C228.461 82.5103 228.49 82.5843 228.506 82.6071C228.63 82.7899 229.64 82.619 230.02 82.5569C230.875 82.4143 231.615 82.291 231.919 82.8301C232.138 83.2157 231.976 83.7255 231.423 84.3861C231.251 84.5917 231.067 84.748 230.905 84.8704C231.04 84.9508 231.16 85.0742 231.209 85.2679C231.324 85.7266 230.93 86.1862 230.038 86.6357C229.816 86.7481 229.518 86.898 229.1 86.9702C228.903 87.004 228.726 87.0122 228.574 87.0168C228.571 87.1045 228.55 87.1995 228.497 87.2973C228.34 87.5851 228.014 87.7222 227.522 87.6893C226.981 87.6609 226.536 87.5577 226.143 87.4672C225.8 87.3887 225.505 87.322 225.272 87.3375C224.842 87.3722 224.51 87.7167 224.121 88.1635L223.149 89.2992Z'
        fill='#F2F2F2'
      />
      <path
        d='M224.133 78.0317L222.357 78.7168L222.193 78.6595C221.438 78.3942 220.753 78.3376 220.158 78.4909C220.063 78.5153 219.97 78.5445 219.875 78.5742C219.498 78.692 219.029 78.8385 218.411 78.7361C218.066 78.6782 217.249 78.4757 217.184 77.8666C217.165 77.703 217.206 77.5647 217.267 77.4518C217.242 77.4442 217.218 77.436 217.191 77.4275C216.865 77.318 216.707 77.2047 216.556 77.0957C216.44 77.0119 216.32 76.9253 216.058 76.8086C215.944 76.7574 215.845 76.72 215.762 76.6883C215.511 76.5921 215.208 76.4602 215.121 76.1099C215.04 75.7323 215.298 75.4561 215.468 75.2731C215.771 74.9465 216.006 74.7729 216.162 74.6579C216.219 74.616 216.283 74.5685 216.302 74.5481C216.447 74.3815 216.025 73.4478 215.869 73.0954C215.514 72.3048 215.207 71.6206 215.651 71.1891C215.969 70.88 216.503 70.9076 217.282 71.2748C217.525 71.3892 217.723 71.5268 217.882 71.6531C217.926 71.5023 218.015 71.3543 218.19 71.2579C218.604 71.0302 219.149 71.295 219.81 72.0441C219.975 72.2299 220.195 72.4804 220.371 72.8665C220.454 73.0484 220.507 73.2178 220.55 73.3633C220.635 73.3442 220.732 73.3399 220.84 73.3668C221.159 73.4454 221.374 73.7262 221.467 74.21C221.577 74.7405 221.59 75.1975 221.602 75.6005C221.613 75.9523 221.623 76.2552 221.697 76.4757C221.84 76.8832 222.257 77.1167 222.788 77.3799L224.133 78.0317Z'
        fill='#F2F2F2'
      />
      <path
        d='M223.149 72.4575L222.938 70.5651L223.034 70.4217C223.483 69.7583 223.711 69.1105 223.714 68.4955C223.714 68.3978 223.71 68.3 223.705 68.2004C223.687 67.8057 223.664 67.315 223.92 66.743C224.063 66.4241 224.466 65.6858 225.072 65.7772C225.235 65.8001 225.358 65.875 225.452 65.9627C225.466 65.9408 225.48 65.9188 225.495 65.8951C225.684 65.6073 225.833 65.4839 225.977 65.3651C226.087 65.2738 226.202 65.1796 226.381 64.9567C226.459 64.8589 226.52 64.773 226.572 64.7008C226.729 64.4825 226.933 64.2221 227.294 64.2266C227.68 64.2449 227.882 64.5638 228.016 64.7749C228.255 65.1513 228.363 65.4227 228.435 65.6027C228.461 65.6685 228.49 65.7425 228.506 65.7653C228.63 65.9481 229.64 65.7772 230.02 65.7151C230.875 65.5725 231.615 65.4492 231.919 65.9883C232.138 66.3739 231.976 66.8837 231.423 67.5444C231.251 67.7499 231.067 67.9062 230.905 68.0286C231.04 68.109 231.16 68.2324 231.209 68.4261C231.324 68.8848 230.93 69.3444 230.038 69.794C229.816 69.9063 229.518 70.0562 229.1 70.1284C228.903 70.1622 228.726 70.1704 228.574 70.175C228.571 70.2627 228.55 70.3577 228.497 70.4555C228.34 70.7433 228.014 70.8804 227.522 70.8475C226.981 70.8191 226.536 70.7159 226.143 70.6254C225.8 70.5469 225.505 70.4802 225.272 70.4957C224.842 70.5304 224.51 70.8749 224.121 71.3217L223.149 72.4575Z'
        fill='#F2F2F2'
      />
      <path
        d='M225.088 109.188L224.691 108.94L224.229 108.993C224.221 108.95 224.196 108.851 224.158 108.697C223.951 107.851 223.321 105.276 222.794 101.221C222.427 98.3899 222.219 95.4797 222.176 92.5694C222.133 89.6547 222.271 87.448 222.382 85.6745C222.465 84.3368 222.567 83.0631 222.666 81.8222C222.931 78.5072 223.181 75.3759 222.995 71.9092C222.954 71.1353 222.868 69.5244 221.927 67.8103C221.381 66.8161 220.629 65.9335 219.691 65.1879L220.274 64.4551C221.309 65.2793 222.141 66.2569 222.747 67.3607C223.791 69.2613 223.885 71.0165 223.93 71.8599C224.119 75.3878 223.866 78.5493 223.599 81.8963C223.5 83.1325 223.399 84.4008 223.316 85.733C223.206 87.4901 223.069 89.6775 223.112 92.5548C223.154 95.4303 223.359 98.3049 223.722 101.1C224.242 105.104 224.863 107.64 225.067 108.474C225.176 108.919 225.199 109.012 225.088 109.188Z'
        fill='#F2F2F2'
      />
      <path
        d='M217.971 66.6753C217.933 66.6753 217.894 66.6744 217.855 66.6716C217.058 66.6287 216.32 66.1517 215.661 65.2544C215.353 64.8323 215.194 64.3507 214.879 63.3904C214.83 63.2424 214.593 62.4913 214.448 61.4579C214.354 60.7826 214.366 60.5003 214.5 60.2509C214.648 59.9731 214.888 59.7794 215.159 59.6487C215.147 59.5592 215.157 59.4669 215.195 59.3728C215.355 58.9826 215.779 59.0338 216.008 59.0585C216.124 59.0731 216.269 59.0923 216.425 59.084C216.671 59.0722 216.803 58.9991 217.003 58.8894C217.195 58.7843 217.433 58.6537 217.786 58.5815C218.482 58.4362 219.065 58.6336 219.258 58.6984C220.27 59.0356 220.768 59.8223 221.346 60.7333C221.461 60.916 221.856 61.5812 222.117 62.4931C222.305 63.151 222.279 63.4434 222.222 63.6609C222.107 64.1077 221.835 64.3635 221.145 64.9282C220.424 65.5194 220.062 65.8154 219.751 65.9954C219.028 66.413 218.574 66.6753 217.971 66.6753Z'
        fill='#F2F2F2'
      />
      <path d='M106 78H26V99H106V78Z' fill='#17D48F' />
      <path d='M77 21H56V159H77V21Z' fill='#17D48F' />
      <path
        opacity='0.2'
        d='M77.5734 97.2714C77.7215 96.7004 77.8098 96.107 77.8313 95.4954L104 71.8832L81.5077 61L71.5947 87.1907C70.9171 87.0153 70.2099 86.9124 69.4769 86.9124C69.0877 86.9124 68.7078 86.9479 68.3327 86.9988L58.4923 61L36 71.8832L61.1402 94.5674C61.1239 94.7783 61.1077 94.9893 61.1077 95.2044C61.1077 95.9194 61.2087 96.6095 61.3805 97.2714C45.9702 102.043 39.6615 116.453 39.6615 116.453L59.0154 132C57.8611 120.564 64.9102 108.855 68.7211 103.459C68.9704 103.481 69.2217 103.496 69.4769 103.496C69.7321 103.496 69.9834 103.481 70.2328 103.459C74.0436 108.855 81.0928 120.564 79.9385 132L99.2923 116.453C99.2923 116.453 92.9836 102.043 77.5734 97.2714Z'
        fill='black'
      />
      <path
        d='M80.4277 126C80.2026 126 79.9758 125.951 79.7616 125.852C79.2199 125.601 78.8777 125.079 78.8683 124.489C78.7101 114.488 72.9568 104.673 69.5412 99.7124C69.3131 99.3811 68.9192 99.1834 68.4873 99.1834H68.4854C68.0377 99.1834 67.6438 99.3811 67.4157 99.7124C64.0003 104.673 58.2469 114.488 58.0886 124.489C58.0792 125.079 57.737 125.601 57.1953 125.852C56.6481 126.106 56.017 126.029 55.5485 125.653L39.5092 112.763C38.9366 112.303 38.7686 111.496 39.1096 110.846C40.731 107.751 46.9729 97.3208 59.223 93.0317C59.7696 92.8403 60.1016 92.3008 60.0301 91.7196C59.9904 91.395 59.9703 91.0691 59.9703 90.7508C59.9711 90.3712 59.8247 90.0263 59.5687 89.7953L36.5096 68.981C36.1271 68.6358 35.9426 68.1268 36.0157 67.6193C36.089 67.1118 36.4103 66.6742 36.8751 66.4493L56.074 57.1565C56.4677 56.9657 56.9273 56.9487 57.3351 57.1091C57.7426 57.2699 58.0645 57.5956 58.2185 58.0025L67.1204 81.5305C67.3041 82.0161 67.7753 82.3332 68.3101 82.3199C68.7135 82.3107 69.1226 82.3338 69.5304 82.3863C70.1059 82.4626 70.6491 82.1408 70.8505 81.6074L79.7816 58.0025C79.9356 57.5956 80.2576 57.2699 80.665 57.1091C81.0725 56.9489 81.5325 56.9658 81.9261 57.1565L101.125 66.4493C101.59 66.6742 101.911 67.1118 101.984 67.6196C102.057 68.1268 101.873 68.6358 101.49 68.981L77.3319 90.7878C77.1067 90.991 76.9665 91.2685 76.9371 91.5687C76.9308 91.6336 76.9239 91.6984 76.9161 91.763C76.8498 92.3091 77.1922 92.8422 77.7301 93.0305C89.9829 97.3185 96.2257 107.751 97.8474 110.846C98.1885 111.496 98.0204 112.303 97.4479 112.763L81.4084 125.653C81.1233 125.882 80.7777 126 80.4277 126Z'
        fill='#17D48F'
      />
      <path
        d='M237.328 159.95H160.393C157.208 159.95 154.617 157.359 154.617 154.174V115.028C154.617 111.843 157.208 109.252 160.393 109.252H237.328C240.512 109.252 243.103 111.843 243.103 115.028V154.174C243.103 157.359 240.512 159.95 237.328 159.95ZM160.393 109.999C157.62 109.999 155.364 112.255 155.364 115.028V154.174C155.364 156.947 157.62 159.203 160.393 159.203H237.328C240.1 159.203 242.356 156.947 242.356 154.174V115.028C242.356 112.255 240.1 109.999 237.328 109.999H160.393Z'
        fill='#3F3D56'
      />
      <path d='M206.582 109.297H193.438V159.905H206.582V109.297Z' fill='#17D48F' />
      <path d='M242.727 128.357H154.988V141.502H242.727V128.357Z' fill='#17D48F' />
      <path
        opacity='0.2'
        d='M196.294 143.109C196.656 143.202 197.032 143.257 197.42 143.271L212.392 159.712L219.293 145.581L202.686 139.353C202.797 138.927 202.863 138.483 202.863 138.022C202.863 137.778 202.84 137.539 202.808 137.303L219.293 131.121L212.392 116.99L198.009 132.785C197.875 132.774 197.741 132.764 197.605 132.764C197.151 132.764 196.714 132.828 196.294 132.936C193.269 123.254 184.132 119.291 184.132 119.291L174.273 131.45C181.525 130.725 188.949 135.153 192.371 137.547C192.357 137.704 192.347 137.862 192.347 138.022C192.347 138.183 192.357 138.34 192.371 138.497C188.949 140.891 181.525 145.32 174.273 144.595L184.132 156.754C184.132 156.754 193.268 152.79 196.294 143.109Z'
        fill='black'
      />
      <path
        d='M212.933 157.45C212.635 157.45 212.349 157.326 212.145 157.102L198.282 141.879C198.167 141.753 198.001 141.674 197.813 141.655L197.753 141.649C197.415 141.611 197.106 141.794 197 142.095C194.263 149.853 187.601 153.802 185.625 154.827C185.179 155.059 184.626 154.942 184.309 154.55L176.114 144.443C175.855 144.122 175.802 143.691 175.977 143.316C176.15 142.947 176.509 142.714 176.914 142.707C183.25 142.61 189.475 138.993 192.621 136.846C192.811 136.717 192.924 136.494 192.923 136.25C192.924 135.994 192.811 135.771 192.621 135.642C189.475 133.495 183.251 129.879 176.914 129.781C176.509 129.775 176.15 129.542 175.977 129.172C175.802 128.797 175.855 128.366 176.114 128.046L184.31 117.938C184.627 117.547 185.18 117.43 185.625 117.661C187.601 118.686 194.261 122.635 196.998 130.39C197.104 130.691 197.407 130.878 197.712 130.841C198.044 130.8 198.375 130.79 198.695 130.814L212.145 116.044C212.383 115.782 212.735 115.656 213.084 115.706C213.434 115.757 213.736 115.976 213.891 116.294L219.799 128.392C219.931 128.661 219.942 128.976 219.832 129.254C219.721 129.532 219.496 129.752 219.216 129.857L204.258 135.467C203.983 135.57 203.803 135.839 203.811 136.136L203.812 136.244C203.812 136.477 203.796 136.713 203.764 136.947C203.723 137.249 203.911 137.548 204.21 137.661L219.216 143.288C219.496 143.394 219.721 143.613 219.832 143.892C219.942 144.171 219.931 144.485 219.799 144.754L213.891 156.852C213.736 157.17 213.434 157.389 213.084 157.439C213.034 157.447 212.983 157.45 212.933 157.45Z'
        fill='#17D48F'
      />
      <path
        d='M143.255 64.1039H131.223V60.1419C131.223 53.501 136.614 48.1094 143.255 48.1094L143.255 64.1039Z'
        fill='#2F2E41'
      />
      <path
        d='M140.585 56.7075L142.654 66.0127L130.531 63.9997L135.471 55.1992L140.585 56.7075Z'
        fill='#FFB6B6'
      />
      <path
        opacity='0.1'
        d='M140.585 56.7075L142.654 66.0127L130.531 63.9997L135.471 55.1992L140.585 56.7075Z'
        fill='black'
      />
      <path d='M140.587 76.5859H128.965V93.7879H140.587V76.5859Z' fill='#FFB6B6' />
      <path
        d='M148.138 107.852L145.581 121.567C145.581 121.567 147.209 131.097 142.327 140.861L139.073 150.857L134.191 150.159C134.191 150.159 137.213 142.72 137.213 139.466C137.213 136.212 137.911 123.659 137.911 123.659L137.562 109.335L148.138 107.852Z'
        fill='#FFB6B6'
      />
      <path
        d='M133.498 149.926C133.498 149.926 139.077 147.834 139.541 150.856L139.309 152.251C139.309 152.251 141.401 158.527 138.379 158.992C135.357 159.457 132.568 160.154 132.336 158.76C132.103 157.365 132.107 155.624 132.686 154.402C133.265 153.18 133.498 149.926 133.498 149.926Z'
        fill='#2F2E41'
      />
      <path
        d='M130.542 105.725L129.184 119.61C129.184 119.61 124.971 128.312 126.944 139.048L127.29 149.554L132.173 150.242C132.173 150.242 131.339 142.256 132.244 139.13C133.149 136.004 135.97 123.753 135.97 123.753L140.289 110.091L130.542 105.725Z'
        fill='#FFB6B6'
      />
      <path
        d='M132.911 150.212C132.911 150.212 128.134 146.651 126.847 149.424L126.683 150.829C126.683 150.829 122.994 157.77 125.767 159.057C128.541 160.344 130.96 160.295 131.571 159.02C132.182 157.745 132.663 156.072 132.446 154.737C132.229 153.403 132.911 150.212 132.911 150.212Z'
        fill='#2F2E41'
      />
      <path
        d='M145.119 85.7676L155.114 106.921C155.114 106.921 135.007 117.033 123.152 117.033L129.039 85.7676L145.119 85.7676Z'
        fill='#2F2E41'
      />
      <path
        d='M136.169 61.0098L141.98 61.9397L141.515 76.5846L148.024 89.6023L139.656 93.7866C139.656 93.7866 133.147 81.4662 131.985 73.7951C130.823 66.1239 131.52 60.5449 131.52 60.5449L136.169 61.0098Z'
        fill='#E6E6E6'
      />
      <path
        d='M131.753 61.4747L125.942 62.4045L126.406 77.0494L125.012 90.5321L128.266 94.2514C128.266 94.2514 134.775 81.9311 135.937 74.2599C137.099 66.5888 136.402 61.0098 136.402 61.0098L131.753 61.4747Z'
        fill='#E6E6E6'
      />
      <path
        d='M140.702 62.2901L141.981 61.9414C141.981 61.9414 146.514 61.3603 147.211 63.22C147.908 65.0796 147.676 76.0052 147.676 76.0052C147.676 76.0052 148.875 89.4146 144.208 89.5675C139.54 89.7203 140.47 78.5623 140.47 78.5623L140.702 62.2901Z'
        fill='#E6E6E6'
      />
      <path
        d='M138.555 59.2611C141.878 59.2611 144.571 56.5675 144.571 53.2448C144.571 49.9221 141.878 47.2285 138.555 47.2285C135.233 47.2285 132.539 49.9221 132.539 53.2448C132.539 56.5675 135.233 59.2611 138.555 59.2611Z'
        fill='#FFB6B6'
      />
      <path
        d='M136.674 61.5247C137.965 61.1972 139.428 62.5757 139.943 64.6044C140.16 65.412 140.18 66.2597 140.002 67.0767L140.556 69.3828L140.597 69.3807L144.971 79.69C145.988 82.087 145.881 84.8389 144.584 87.0966C143.812 88.4396 142.799 89.3425 141.61 88.4208C139.636 86.8915 138.347 78.1347 137.758 73.1045L136.397 67.9914C135.851 67.358 135.464 66.603 135.27 65.7895C134.755 63.7616 135.384 61.8521 136.674 61.5247Z'
        fill='#FFB6B6'
      />
      <path
        d='M131.057 62.2901L129.779 61.9414C129.779 61.9414 125.246 61.3603 124.548 63.22C123.851 65.0796 124.084 76.0052 124.084 76.0052C124.084 76.0052 122.884 89.4146 127.552 89.5675C132.219 89.7203 131.289 78.5623 131.289 78.5623L131.057 62.2901Z'
        fill='#E6E6E6'
      />
      <path
        d='M135.083 61.5247C133.792 61.1972 132.329 62.5757 131.814 64.6044C131.597 65.412 131.577 66.2597 131.755 67.0767L131.201 69.3828L131.16 69.3807L126.786 79.69C125.768 82.087 125.876 84.8389 127.173 87.0966C127.945 88.4396 128.958 89.3425 130.147 88.4208C132.121 86.8915 133.41 78.1347 133.999 73.1045L135.36 67.9914C135.906 67.358 136.293 66.603 136.487 65.7895C137.002 63.7616 136.373 61.8521 135.083 61.5247Z'
        fill='#FFB6B6'
      />
      <path
        d='M127.041 58.1121C127.347 57.1816 127.951 56.367 128.222 55.4255C128.581 54.1754 129.345 56.9487 129.419 55.6502C129.561 53.1823 133.327 46.8339 135.478 45.6145C137.628 44.3951 138.863 44.7627 141.053 45.908C141.053 45.908 142.117 45.9756 143.441 46.6292C144.764 47.2828 145.956 48.3296 146.471 49.7134C146.879 50.8102 146.834 52.0147 146.97 53.177C147.35 56.4151 149.165 59.4948 148.776 62.7318C148.654 63.7417 148.27 64.786 147.445 65.38C146.619 65.974 145.285 65.9094 144.741 65.0496C144.556 64.7562 144.477 64.4081 144.318 64.0995C144.16 63.7909 143.872 63.5033 143.525 63.5129C143.004 63.5273 142.738 64.2271 142.944 64.7066C143.15 65.1861 143.633 65.4756 144.087 65.7332C143.006 66.2193 141.665 66.0679 140.72 65.353C139.775 64.638 139.265 63.3891 139.439 62.2169C139.684 60.565 142.09 60.9069 142.374 59.2612C142.58 58.0631 143.305 56.0586 143.108 54.859C142.91 53.6595 141.962 55.1119 142.961 54.4188C141.862 54.9485 139.512 51.3248 138.362 50.9189C137.211 50.5131 136.236 49.6342 135.713 48.5322C134.493 50.3008 133.42 49.8206 132.983 51.9243C132.546 54.0279 132.95 58.8073 134.749 59.9818C134.029 60.4731 133.455 61.1756 133.117 61.9791C132.926 62.4343 132.802 62.9324 132.484 63.3105C131.878 64.0325 130.757 64.0764 129.865 63.7728C128.757 63.3964 127.811 62.5696 127.289 61.5231C126.767 60.4766 126.676 59.223 127.041 58.1121Z'
        fill='#2F2E41'
      />
    </g>
    <defs>
      <clipPath id='clip0_389_18960'>
        <rect width='256' height='160' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Icon
