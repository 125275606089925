import { Brand } from 'src/models/brands'
import { AccordionSummary, Checkbox, IconButton, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Text16pxRany500 } from 'src/components/Typography'

interface Props {
  brand: Brand
  onToggle: (id: string) => void
  onExpandClick: (panelId: string) => void
  isChecked: boolean
  isExpanded: boolean
}

const LocationListItem: React.FC<Props> = ({
  brand,
  onToggle,
  onExpandClick,
  isChecked,
  isExpanded
}) => {
  const preventDefault = (event: any) => {
    event.stopPropagation()
  }

  return (
    <AccordionSummary
      aria-controls={`panel${brand.id}d-content`}
      id={`panel${brand.id}d-header`}
      onClick={preventDefault}
      sx={{
        borderBottom: 'none', // Remove border on the summary part
        minHeight: 56, // Ensure consistent height
        '&.Mui-expanded': {
          minHeight: 56
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(180deg)'
        },
        '& .MuiAccordionSummary-content': {
          alignItems: 'center', // Align the summary content vertically

          '&.Mui-expanded': {
            margin: '12px 0'
          }
        }
      }}
    >
      <Checkbox
        checked={isChecked}
        onChange={() => onToggle(brand.id)}
        sx={{ padding: '0px 12px 0px 0px' }} // Adjust padding for alignment, if necessary
      />
      <Text16pxRany500
        style={{ flex: 1, textAlign: 'left', display: 'flex', alignItems: 'center' }}
      >
        {brand.name}
      </Text16pxRany500>
      <IconButton onClick={() => onExpandClick(`panel${brand.id}`)} size='small'>
        <ExpandMoreIcon />
      </IconButton>
    </AccordionSummary>
  )
}

export default LocationListItem
