import { parsePhoneNumber } from 'libphonenumber-js'
import { CountryOTPRequestMethodsList, OTPMethods } from 'src/models/auth'

export default function getAllowedOTPMethods(
  list: CountryOTPRequestMethodsList[],
  phoneNumber: string
): OTPMethods[] {
  if (list?.length) {
    const parsedPhoneNumber = parsePhoneNumber('+' + phoneNumber)
    const allowedOTPMethods = list.find(
      item => item.countryCode.toLowerCase() === parsedPhoneNumber?.country?.toLowerCase()
    )
    return allowedOTPMethods?.methods!
  }
  return ['whatsapp']
}
