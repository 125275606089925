import React, { useEffect } from 'react'
import Form from './Form'
import ContainerPageWithBack from 'src/components/Layouts/ContainerPageWithBack'

import { useDispatch } from 'react-redux'
import { Container } from '@mui/material'
import ActionButtons from 'src/components/Layouts/ActionsButtons'
import useBrandsStore from './useBrandsStore'

const CreatePromotion: React.FC = () => {
  const { draft, errors, initDraft, updateDraft, handleInputChange, createBrand } = useBrandsStore()

  useEffect(() => initDraft(), [])

  return (
    <ContainerPageWithBack title={'Create new brand'}>
      <Container sx={{ mt: 5 }} maxWidth='sm'>
        <Form
          draft={draft}
          errors={errors}
          updateDraft={updateDraft}
          handleInputChange={handleInputChange}
        />
        <ActionButtons hrefLink={'/brands'} primaryOnCLick={createBrand} />
      </Container>
    </ContainerPageWithBack>
  )
}

export default CreatePromotion
