import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Box
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { SecondaryButton } from 'src/components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import format from 'date-fns/format'

import subscriptionsStore from 'src/store/subscriptions'
import SubscriptionStatus from './SubscriptionStatus'
// Define the subscription data type

// Subscription data example

const StyledTable = styled(Table)(({ theme }) => ({
  '& thead th': {
    fontWeight: theme.typography.fontWeightBold,
    borderBottom: 'none'
  },
  '& tbody td': {
    borderBottom: 'none'
  },
  '& tbody tr:last-child td': {
    paddingBottom: theme.spacing(2)
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#F7F9F8'
  // other styles...
}))

const NoBorderTableCell = styled(TableCell)({
  borderBottom: 'none'
})
//@ts-ignore
const FirstTableCell = styled(TableCell)(({ theme }) => ({
  borderTopLeftRadius: 16,
  borderBottomLeftRadius: 16,
  borderBottom: 'none'
}))

const LastTableCell = styled(TableCell)(({ theme }) => ({
  borderTopRightRadius: 16,
  borderBottomRightRadius: 16,
  borderBottom: 'none'
}))

const SubscriptionPanel: React.FC = () => {
  const { t } = useTranslation('translation')
  const subscriptions = useSelector(subscriptionsStore.selectors.subscriptions)

  return (
    <TableContainer component={Paper} elevation={0} square>
      <StyledTable>
        <TableHead>
          <TableRow>
            <NoBorderTableCell>Type</NoBorderTableCell>
            <NoBorderTableCell align='center'>The Next Payment</NoBorderTableCell>
            <NoBorderTableCell align='center'>Price</NoBorderTableCell>
            <NoBorderTableCell align='center'>Status</NoBorderTableCell>
            <NoBorderTableCell align='center'></NoBorderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions.map(subscription => {
            const { currentPeriodEndDate, cancelAt, plan, status, currentPeriodStartDate } =
              subscription
            const strings = plan?.i18n?.[i18next?.language] || plan?.i18n?.['en'] || {}

            return (
              <>
                <StyledTableRow key={subscription.id}>
                  <FirstTableCell
                    component='th'
                    scope='row'
                    style={{ fontWeight: 'bold', fontSize: '1em' }}
                  >
                    {
                      //@ts-ignore
                      subscription.id === 'hub_subscription_annual_2023'
                        ? 'Annual Plan'
                        : '3 Months Plan'
                    }
                    {' (1 locations)'}
                  </FirstTableCell>
                  <NoBorderTableCell align='center' style={{ fontSize: '1em' }}>
                    {format(new Date(currentPeriodEndDate), 'MMMM d, yyyy')}
                  </NoBorderTableCell>
                  <NoBorderTableCell style={{ fontSize: '1em' }} align='center'>
                    ${subscription.plan.price}
                  </NoBorderTableCell>
                  <NoBorderTableCell align='center'>
                    <SubscriptionStatus status={subscription.status}>
                      {subscription.status}
                    </SubscriptionStatus>
                  </NoBorderTableCell>
                  <LastTableCell align='center'>
                    {!cancelAt && (
                      <SecondaryButton
                        sx={{
                          color: '#E42121',
                          borderColor: '#E42121'
                        }}
                        color={'error'}
                        variant='outlined'
                      >
                        Cancel subscription
                      </SecondaryButton>
                    )}
                  </LastTableCell>
                </StyledTableRow>
                <Box mt={2} />
              </>
            )
          })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  )
}

export default SubscriptionPanel
