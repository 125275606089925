import FilesDropzone from 'src/components/FilesDropzone'
import { InputLabelWrapper } from 'src/components/Base/InputWithLabel'

const PhotoUploadComponent = () => {
  return (
    <InputLabelWrapper label='Upload your photos to location gallery (optional)'>
      <FilesDropzone uploadType={'location-image'} />
    </InputLabelWrapper>
  )
}

export default PhotoUploadComponent
