import { DayOfTheWeek, DiscountSchedule } from 'src/models/promotions'

export const daysOfWeek: DayOfTheWeek[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
]

export const dayAbbreviations = {
  sunday: 'Sun',
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat'
}

export type TimePeriod = {
  id: number
  startsAt: string
  endsAt: string
  selectedDays: DayOfTheWeek[]
}

function timeToDecimal(timeStr: string): number {
  const [hours, minutes] = timeStr.split(':').map(Number)
  return hours + minutes / 60
}

export function transformScheduleData(data: TimePeriod[]): DiscountSchedule[] {
  const transformedData: DiscountSchedule[] = []

  data.forEach(entry => {
    entry.selectedDays.forEach(day => {
      transformedData.push({
        dayOfTheWeek: day,
        startTime: timeToDecimal(entry.startsAt),
        endTime: timeToDecimal(entry.endsAt)
      })
    })
  })

  return transformedData
}

function decimalToTime(decimal: number): string {
  const hours = Math.floor(decimal)
  const minutes = Math.round((decimal - hours) * 60)
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}

export function reverseTransformData1(data: DiscountSchedule[]): TimePeriod[] {
  const scheduleData: TimePeriod[] = data.map(entry => ({
    endsAt: decimalToTime(entry.endTime),
    id: Math.floor(Math.random() * Date.now()), // Example ID generation
    selectedDays: [entry.dayOfTheWeek],
    startsAt: decimalToTime(entry.startTime)
  }))

  return scheduleData
}

export function reverseTransformData(data: DiscountSchedule[]): TimePeriod[] {
  const groupedByTime = data.reduce(
    (acc: Record<string, DiscountSchedule[]>, entry: DiscountSchedule) => {
      const key = `${entry.startTime}-${entry.endTime}`
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(entry)
      return acc
    },
    {}
  )

  const scheduleData: TimePeriod[] = Object.entries(groupedByTime).map(([key, value]) => {
    const [startTime, endTime] = key.split('-').map(Number)
    return {
      endsAt: decimalToTime(endTime),
      id: Math.floor(Math.random() * Date.now()), // Example ID generation, consider a more robust approach for production
      selectedDays: value.map(entry => entry.dayOfTheWeek),
      startsAt: decimalToTime(startTime)
    }
  })

  return scheduleData
}
