import {
  CountryOTPRequestMethodsList,
  OTPMethods,
  Provider,
  ProviderAdmin,
  ProviderId,
  Session,
  User,
  SubscriptionCountry
} from 'src/models/auth'
import { MediaUploads } from 'src/models/uploads'
import { signInData } from './selectors'

export enum ActiveOperation {
  sendPhoneVerification = 1,
  signIn = 2,
  saveProfile = 3,
  getCountryOTPRequestMethodsList = 4,
  updateCurrentProvider = 5,
  createProvider = 6,
  signUp = 7
}

export interface SignInData {
  countryCode?: string
  phoneNumber?: string
  phoneVerificationId?: string
  nextPhoneVerificationRequestAvailableAt?: string
  sendLimitReached?: boolean
  otp?: string
  fullName?: string
  referralCode?: string
}

export interface UpdateSignInDataParams extends SignInData {}

export interface ProviderUpdate {
  id?: ProviderId
  name: string
  subscriptionCountry?: SubscriptionCountry
}

export interface UpdateCurrentProviderData {
  provider: ProviderUpdate
}

export interface UpdateCurrentProviderPayload {
  currentProvider: Provider | null
  providerAdmins: ProviderAdmin[] | null
}

export interface ProviderCreate {
  name: string
  posSystemType?: 'foodics' | 'other'
  posSystemOther?: string
  subscriptionCountry?: SubscriptionCountry
}

export interface CreateProviderData {
  provider: ProviderCreate
}

export interface CreateProviderPayload {
  currentProvider: Provider | null
}

export interface SelectProviderPayload {
  provider: Provider | null
}

export interface StateAuth {
  session: Session | null
  user: User | null

  providerAdmins: ProviderAdmin[] | null
  currentProvider: Provider | null

  countriesOTPRequestMethodsList: CountryOTPRequestMethodsList[] | null
  allowedOTPMethods: OTPMethods[] | null

  signInData: SignInData
  editProfileData: EditProfileData

  error: Error | undefined
  activeOperation: ActiveOperation | undefined
  redirectURL?: string
  signUpData: SignUpData
  validationErrors: SignUpValidationErrors
}

export interface SendPhoneVerificationParams {
  operation: 'signIn' | 'editProfile' | 'signUp'
  messenger?: OTPMethods
  eventId?: string
  recaptcha?: string
}

export interface AuthChangePayload {
  session: Session | null
  user: User | null
  providerAdmins: ProviderAdmin[] | null
  currentProvider: Provider | null
  signInData?: SignInData | null
  isRestored?: boolean
}

export interface EditProfileData extends SignInData {
  username?: string
  firstName?: string
  lastName?: string
  referralCode?: string
  email?: string
  bio?: string
}

export interface LogoutPayload {
  isForcedByServer?: boolean
}

export type AllowedOTPMethodsChangePayload = OTPMethods[] | null

// Sign Up
export interface SignUpData extends SignInData {
  fullName?: string
  email?: string
  website?: string
  phoneNumber?: string

  brandName?: string
  countryCode?: string
  images?: MediaUploads
  city?: string
  contactPhoneNumber?: string
  contactEmail?: string
  websiteUrl?: string

  ownerId?: string
  providerName?: string
  subscriptionCountry?: SubscriptionCountry
  
  messenger?: OTPMethods
  posSystemType?: 'foodics' | 'other'
  posSystemOther?: string
}

export type SignUpValidationErrors = {
  [K in keyof SignUpData]?: string
}
