import React, { useState, useCallback } from 'react'
import { FC } from 'react'
import clsx from 'clsx'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@mui/styles'
import UploadIcon from 'src/assets/svg/Upload' // Ensure this path is correct
import { useDispatch, useSelector } from 'react-redux'
import mediaUploads from 'src/store/mediaUploads'
import { MediaFile, UploadType } from 'src/models/uploads'
import { MediaUpload } from 'src/store/mediaUploads/types'
import { Text14pxRany } from 'src/components/Typography'
import { Box } from '@mui/material'
import IconButtonWrapper from 'src/components/Base/IconButton'
import TrashIcon from 'src/assets/svg/Trash' // Import MUI Delete icon
import mediaUploadsStore from 'src/store/mediaUploads'

interface FilesDropzoneProps {
  uploadType: UploadType
  className?: string
  showDropzone?: boolean
  showTitle?: boolean
  isSubmitting?: boolean
  progress?: number
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(3)
  },
  mediaListContainer: {
    justifyContent: 'center',
    position: 'relative',
    width: '100%',

    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginRight: 8, // Add some space between the thumbnails
    marginBottom: 8
  },
  dropZone: {
    border: `2px dashed #D1D9D6`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F7F9F8',
    alignItems: 'center',
    padding: theme.spacing(6),
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  icon: {},
  text: {
    color: 'black'
  },
  browseButton: {
    color: '#04BC79'
  },
  previewImage: {
    // Add styles to resemble the provided image
    minHeight: 160,
    objectFit: 'cover',
    width: 160, // This will make the image responsive
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
    // Any other styles you need to add based on your screenshot
  },
  iconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 10
  },
  deleteIcon: {
    color: '#ffffff',
    margin: 4,
    padding: 5,
    backgroundColor: 'white', // Semi-transparent black background
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Smaller shadow for the icon, adjust as needed
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)' // Darken on hover
    }
  }
}))

const FilesDropzone: FC<FilesDropzoneProps> = ({
  className,
  showDropzone,
  showTitle,
  isSubmitting,
  progress,
  uploadType,
  ...rest
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [filePreview, setFilePreview] = useState<string>('')
  const mediaList = useSelector(mediaUploadsStore.selectors.mediaUploads)

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]
      if (file) {
        setFilePreview(URL.createObjectURL(file)) // Set file preview

        const data = [
          {
            uid: Date.now(),
            order: 1,
            file: file,
            uploadType,
            state: 'queued'
          }
        ] as MediaUpload[]
        dispatch(mediaUploads.actions.addMediaUploads(data))
      }
    },
    [dispatch, uploadType]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: 'image/jpeg, image/png'
  })

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {filePreview || mediaList.length ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Box className={classes.mediaListContainer}>
            <img
              src={
                filePreview ||
                (mediaList?.[0] as MediaFile).url ||
                URL.createObjectURL((mediaList?.[0] as MediaUpload).file)
              }
              alt='Preview'
              className={classes.previewImage}
            />
            <div className={classes.iconContainer}>
              <IconButtonWrapper
                bordercolor='#E52121'
                onClick={() => {
                  setFilePreview('')
                  dispatch(mediaUploads.actions.clearDraft())
                }}
                size='small'
                className={classes.deleteIcon}
              >
                <TrashIcon size={15} />
              </IconButtonWrapper>
            </div>
          </Box>
        </Box>
      ) : (
        <div {...getRootProps()} className={classes.dropZone}>
          <input {...getInputProps()} />
          <UploadIcon className={classes.icon} />
          <Text14pxRany mt={3} className={classes.text}>
            Drag and drop a photo or <span className={classes.browseButton}>Browse</span>
          </Text14pxRany>
        </div>
      )}
    </div>
  )
}

export default FilesDropzone
