import actionCreatorFactory from 'typescript-fsa'

import { FoodicsAuthCallbackPayload, FoodicsUser } from 'src/models/foodics'
import { BrandId } from 'src/models/brands'

// Actions
const actionCreator = actionCreatorFactory('foodics')

export const fetchFoodicsUserInfo = actionCreator.async<BrandId | undefined, FoodicsUser, Error>(
  'FETCH_FOODICS_USER_INFO'
)
export const fetchFoodicsAuthCallback = actionCreator.async<
  FoodicsAuthCallbackPayload,
  undefined,
  Error
>('FOODICS_AUTH_CALLBACK')
export const dismissError = actionCreator<void>('DISMISS_ERROR')
