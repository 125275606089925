import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container } from '@mui/material'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import subscriptionsStore from 'src/store/subscriptions'
import { PageSubtitle, PageTitle, Text16pxRany500 } from 'src/components/Typography'

import SubscriptionPanel from './SubscriptionPanel'
import { PrimaryButton } from 'src/components/index'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'

const App: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions' })
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const error = useSelector(subscriptionsStore.selectors.error)
  const subscriptionStatus = useSelector(subscriptionsStore.selectors.subscriptionStatus)

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
        onExited: () => dispatch(subscriptionsStore.actions.dismissError())
      })
    }
  }, [error])

  useEffect(() => {
    dispatch(subscriptionsStore.actions.fetchStatus.started())
    dispatch(subscriptionsStore.actions.fetchSubscriptions.started())
  }, [])

  const { locationsCountUsed, locationsCountAvailable } = subscriptionStatus || {}

  return (
    <Container maxWidth={false}>
      <PageTitle mt={4}>Profile settings</PageTitle>
      <PageSubtitle mt={5}>Current active subscription plans</PageSubtitle>
      <Text16pxRany500 mt={2}>
        {t('list_available_locations', {
          locationsCountUsed,
          locationsCountAvailable
        })}
      </Text16pxRany500>
      <Box
        mt={3}
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            flexDirection: 'column',
            display: 'flex',
            width: '70%'
          }}
        >
          <SubscriptionPanel />
          <Box
            component={Link}
            to={'/plans'}
            display={'flex'}
            style={{ textDecoration: 'none' }}
            justifyContent={'flex-end'}
            mt={3}
          >
            <PrimaryButton variant='contained' color='primary' startIcon={<AddIcon />}>
              New subscription
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default App
