import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container } from '@mui/material'

import InputWithLabel, { InputLabelWrapper } from 'src/components/Base/InputWithLabel'
import PhoneNumberInput from 'src/components/PhoneNumberInput'
import { Dropdown, PrimaryButton, SecondaryButton } from 'src/components/index'
import Brands from './Brands'
import { AdminProfileUpsert } from 'src/models/admins'

interface Props {
  draft: AdminProfileUpsert
  hidePhoneNumber?: boolean
  onSave: () => void
  updateProfile: (
    key: keyof AdminProfileUpsert,
    value: AdminProfileUpsert[keyof AdminProfileUpsert]
  ) => void
}

const Form = ({ draft, hidePhoneNumber, updateProfile, onSave }: Props) => {
  const setPhoneNumber = (value: string) => updateProfile('phoneNumber', value)

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    updateProfile(name as any, value)
  }

  const setCheckedBrands = (value: any) => {
    updateProfile('brandIds', value)
  }

  const setCheckedLocations = (value: any) => {
    updateProfile('locationIds', value)
  }

  return (
    <>
      <InputWithLabel
        name={'name'}
        label='User name'
        value={draft.name}
        onChange={handleInputChange}
        fullWidth
        placeholder='Enter your username'
      />
      {!hidePhoneNumber ? (
        <InputLabelWrapper label='User phone number'>
          <PhoneNumberInput
            disabled={hidePhoneNumber}
            defaultValue={draft.phoneNumber}
            onChangePhone={setPhoneNumber}
          />
        </InputLabelWrapper>
      ) : null}

      <Brands
        checkedBrands={draft.brandIds || []}
        setCheckedBrands={setCheckedBrands}
        checkedLocations={draft.locationIds || []}
        setCheckedLocations={setCheckedLocations}
      />

      <Box
        gap={2}
        mt={6}
        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 30, p: 0 }}
      >
        <SecondaryButton href='/users' variant='outlined' fullWidth>
          Back
        </SecondaryButton>
        <PrimaryButton variant='contained' onClick={onSave} fullWidth color='primary'>
          Continue
        </PrimaryButton>
      </Box>
    </>
  )
}

export default Form
