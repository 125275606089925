// GoogleMapWrapper.tsx
import React from 'react'
import GoogleMapReact, { Props } from 'google-map-react'
import customStyles from './customStyles'

interface GoogleMapWrapperProps extends Props {
  children?: React.ReactNode
}

const GoogleMapWrapper: React.FC<GoogleMapWrapperProps> = props => {
  const defaultProps = {
    center: {
      lat: 34.0522, // Example latitude for Los Angeles
      lng: -118.2437 // Example longitude for Los Angeles
    },
    zoom: 11
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyBABOnefpq2-1RrMIM_CX7BpMZ0xu6tyHY' }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      yesIWantToUseGoogleMapApiInternals
      {...props}
      options={{
        styles: customStyles
      }}
    >
      {props.children}
    </GoogleMapReact>
  )
}

export default GoogleMapWrapper
