import React from 'react'
import { Typography, Box, Avatar } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import Tags from './components/Tags'

import { CustomCard, Label, Category, Subtitle } from 'src/components/List/Components'
import LocationLabel from 'src/components/List/LocationLabel'
import Container from './components/Container'
import ActionButtons from './components/ActionButtons'

import { ProviderLocation } from 'src/models/providerLocations'
import { calculateResponsiveFontSize } from 'src/theme/index'
import PhotosComponent from './components/Photos'
import Contacts from './components/Contacts'

import { Text12pxPoppins, Text14pxRanyBold } from '../Typography'
import { Promotion, PromotionType } from 'src/models/promotions'
import Status from 'src/pages/AdminPreview/components/Status'
import { StatusLabel } from '..'

interface Props {
  item: Promotion
}
const LocationComponent = ({ item }: Props) => {
  const imgWidth = '30vh' // This value can be dynamically calculated if needed
  const imgHeight = '51vh'
  const ratio = 516 / 238

  const location = item.locations?.[0]

  const discount =
    item.type === PromotionType.percentageDiscount
      ? `${item.discountPercentage}% Discount`
      : `${item.fixedDiscount} ${item.currency || ''}`

  return (
    <Container>
      <Box sx={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '24px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={item?.image?.url || location?.image?.url}
            alt='Logo'
            sx={{
              width: `${calculateResponsiveFontSize(3)}rem`,
              height: `${calculateResponsiveFontSize(3)}rem`,
              marginRight: 2
            }}
          />
          <Box>
            <Label variant='subtitle1'>{item?.title}</Label>
            <Subtitle variant={'subtitle2'} mt={1}>
              {location?.name}
            </Subtitle>
            <Category
              label={item.customOfferText || discount}
              sx={{ mt: 1.5, backgroundColor: '#5ABA1A' }}
            />
          </Box>
        </Box>

        <LocationLabel address={location?.address!} fontSize={14} />
      </Box>

      <ActionButtons />

      <Box sx={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: '32px' }}>
        <Text14pxRanyBold
          mt={1}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '3',
            fontSize: '14px',
            WebkitBoxOrient: 'vertical'
          }}
        >
          {item.text}
        </Text14pxRanyBold>
        <Text12pxPoppins mt={2}>The discount is available any day and at any time.</Text12pxPoppins>
        <Text14pxRanyBold mt={2} mb={0}>
          Contacts
        </Text14pxRanyBold>
        <Contacts data={location!} />
        <PhotosComponent images={item?.images || location?.images!} />
        <Tags tags={location?.tags!} />
      </Box>
    </Container>
  )
}

export default LocationComponent
