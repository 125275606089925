import React from 'react'
import Box from '@mui/material/Box'

import LocalOfferIcon from '@mui/icons-material/LocalOffer'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import { Text14pxRanyBold } from 'src/components/Typography'
import { ProviderLocation } from 'src/models/providerLocations'
import { Brand } from 'src/models/brands'

const StyledList = styled(List)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'transparent' // Set background to transparent
}))

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0, 0),
  alignItems: 'center', // Align items to the center vertically
  justifyContent: 'flex-start' // Align items to the start horizontally
}))

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 'auto',
  marginRight: '16px'
})

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontWeight: 'bold',
    color: '#95A19C'
  },
  '& .MuiListItemText-secondary': {
    color: '#95A19C'
  }
}))

interface Props {
  brands: Brand[]
}
export default function LocationList({ brands }: Props) {
  if (!brands?.length) {
    return null
  }
  return (
    <Box sx={{ width: '100%', bgcolor: 'transparent' }}>
      {/* Adjust the Box to transparent background */}
      <Text14pxRanyBold mt={1} gutterBottom>
        Brands
      </Text14pxRanyBold>
      <StyledList>
        {brands.map((location, index) => (
          <StyledListItem key={index}>
            <StyledListItemIcon>
              <LocalOfferIcon htmlColor='#95A19C' />
            </StyledListItemIcon>
            <StyledListItemText primary={location.name} />
          </StyledListItem>
        ))}
      </StyledList>
    </Box>
  )
}
