import React, { useEffect, useState } from 'react'
import { Button, Typography, TextField, Box, Container } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import subscriptionsStore from 'src/store/subscriptions'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { PrimaryButton, TextInput } from 'src/components/index'

const PromoCodeComponent = (): JSX.Element => {
  const subscriptionPromoCode = useSelector(subscriptionsStore.selectors.subscriptionPromoCode)
  const [promoCode, setPromoCode] = useState<string>(subscriptionPromoCode || '')
  const [showPromoView, setShowPromoView] = useState<boolean>(true)

  const [searchParams] = useSearchParams()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const dispatch = useDispatch()

  useEffect(() => {
    if (searchParams.get('promoCode')?.length) {
      setPromoCode(searchParams.get('promoCode')!)
      setShowPromoView(true)
    }
  }, [searchParams])

  const togglePromoView = () => {
    setShowPromoView(!showPromoView)
  }

  const handlePromoCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(event.target.value)
  }

  const applyPromoCode = () => {
    dispatch(subscriptionsStore.actions.fetchPlans.started(promoCode))
  }

  return (
    <div>
      {!showPromoView ? (
        <Typography
          variant='body1'
          textAlign={'center'}
          onClick={togglePromoView}
          color={'primary'}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          Click here to enter a promotional code to receive a discount on your subscription
        </Typography>
      ) : null}

      {showPromoView && (
        <Container
          style={{
            display: 'flex',
            padding: 0,
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            mt={4}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <TextInput
              placeholder='Do you have a promo code?'
              fullWidth
              value={promoCode}
              onChange={handlePromoCodeChange}
              style={{ flex: 1, marginRight: '16px', background: 'white' }}
            />
            <PrimaryButton
              variant='contained'
              color='primary'
              //     disabled={!promoCode}
              onClick={applyPromoCode}
              style={{ flex: 1, margin: 0, paddingTop: '16px', paddingBottom: '16px' }}
            >
              Apply Promo Code
            </PrimaryButton>
          </Box>
        </Container>
      )}
    </div>
  )
}

export default PromoCodeComponent
