import { ProviderLocation } from 'src/models/providerLocations' // Adjust the import path as necessary

/**
 * Calculates the center for a collection of items.
 * The zoom level calculation is very basic and might need manual adjustment or
 * dynamic calculation based on the map's display size and capabilities.
 * @param {ProviderLocation[]} items - The items with location data.
 * @returns {Object} The calculated center and a basic zoom level.
 */
export const calculateCenterAndZoom = (
  items: ProviderLocation[]
): { center: { lat: number; lng: number }; zoom: number } => {
  if (items.length === 0) {
    return { center: { lat: 0, lng: 0 }, zoom: 10 } // Default center and zoom if no items
  }

  let latMin = Infinity,
    latMax = -Infinity,
    lngMin = Infinity,
    lngMax = -Infinity

  items.forEach(item => {
    const { latitude, longitude } = item.location!
    latMin = Math.min(latMin, latitude)
    latMax = Math.max(latMax, latitude)
    lngMin = Math.min(lngMin, longitude)
    lngMax = Math.max(lngMax, longitude)
  })

  const center = {
    lat: (latMin + latMax) / 2,
    lng: (lngMin + lngMax) / 2
  }

  // Basic zoom level calculation - This needs to be adjusted according to your map's specifications
  // This is a placeholder calculation and likely won't give accurate results for all map types and sizes
  const latDiff = latMax - latMin
  const lngDiff = lngMax - lngMin
  const maxDiff = Math.max(latDiff, lngDiff)
  let zoom = calculateZoomLevel(maxDiff) // Implement this function based on your map's specifics

  return { center, zoom }
}

/**
 * Placeholder function to calculate zoom level based on the maximum latitude or longitude difference.
 * Implement this based on your specific map component's requirements.
 * @param {number} maxDiff - Maximum difference in latitude or longitude.
 * @returns {number} Calculated zoom level.
 */
function calculateZoomLevel(maxDiff: number): number {
  // This is a very basic placeholder. You should replace this with a more accurate calculation.
  if (maxDiff > 20) return 4
  if (maxDiff > 10) return 5
  if (maxDiff > 5) return 6
  if (maxDiff > 2) return 7
  if (maxDiff > 1) return 8
  if (maxDiff > 0.5) return 9
  return 10 // Adjust as necessary
}
