import React from 'react'
import { Box, Typography, ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import { Text14pxRanyBold } from 'src/components/Typography'
import { File } from 'src/models/uploads'

interface PhotosComponentProps {
  images: File[] | null
}

const PhotosComponent: React.FC<PhotosComponentProps> = ({ images }) => {
  if (!images?.length) {
    return null
  }
  const morePhotosCount = images?.length > 3 ? images?.length - 3 : 0

  return (
    <Box sx={{ py: 2 }}>
      <Text14pxRanyBold mt={1}>Photos</Text14pxRanyBold>
      <ImageList sx={{ flexWrap: 'nowrap', transform: 'translateZ(0)' }} cols={4}>
        {images.slice(0, 3).map((image, index) => (
          <ImageListItem key={index} sx={{ minWidth: '22%', height: 250 }}>
            <img
              src={image.url}
              alt={`Photo ${index + 1}`}
              loading='lazy'
              style={{
                borderRadius: 8,
                width: '100%',
                height: '100%',
                minHeight: 80,
                objectFit: 'cover'
              }}
            />
          </ImageListItem>
        ))}
        {morePhotosCount > 0 && (
          <ImageListItem key='more-photos' sx={{ minWidth: '22%', height: 250 }}>
            <img
              src={images[3].url} // Assuming images[3] is the "more photos" image
              alt={`+${morePhotosCount}`}
              loading='lazy'
              style={{ borderRadius: 8, width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <ImageListItemBar
              title={`+${morePhotosCount}`}
              sx={{
                background: 'rgba(0, 0, 0, 0.5)',
                borderRadius: '8px',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0
              }}
            />
          </ImageListItem>
        )}
      </ImageList>
    </Box>
  )
}

export default PhotosComponent
