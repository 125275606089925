import React from 'react'
import { useSelector } from 'react-redux'
import { Dropdown, PhoneNumberInput } from 'src/components'
import InputWithLabel, { InputLabelWrapper } from 'src/components/Base/InputWithLabel'
import brandsStore from 'src/store/brands'
import useLocationStore from './useLocationsStore'

const LocationForm = () => {
  const { errros, draft, updateDraft, handleInputChange, handleSelectChange } = useLocationStore()
  const brandsOptions = useSelector(brandsStore.selectors.brandsOptions)

  const onChangePhone = (value: string) => updateDraft('contactPhoneNumber', value)

  return (
    <form noValidate autoComplete='off'>
      <InputWithLabel
        placeholder='Enter your location name'
        id='location-name'
        name={'name'}
        error={!!errros?.name}
        helperText={errros?.name}
        value={draft?.name}
        onChange={handleInputChange}
        label='Location name'
        fullWidth
      />
      <InputLabelWrapper
        error={!!errros?.brandId}
        helperText={errros?.brandId || ''}
        label={'Brand'}
      >
        <Dropdown
          labelId='brand-label'
          id='brand'
          name='brandId'
          fullWidth
          placeholder='Select your brand'
          value={draft?.brandId}
          options={brandsOptions || []}
          onChange={handleSelectChange}
          label='Brand*'
        />
      </InputLabelWrapper>
      <InputWithLabel
        id='location-email'
        label='Location email'
        placeholder='Enter your business email'
        fullWidth
        name={'contactEmail'}
        onChange={handleInputChange}
        value={draft?.contactEmail}
        error={!!errros?.contactEmail}
        helperText={
          errros?.contactEmail || 'Email will be shown in the promotion details inside of the app'
        }
      />
      <InputLabelWrapper
        id='location-phone-number'
        label='Location phone number'
        error={!!errros?.contactPhoneNumber}
        helperText={errros?.contactPhoneNumber || 'This will be shown in promotion of the app'}
      >
        <PhoneNumberInput defaultValue={draft?.contactPhoneNumber} onChangePhone={onChangePhone} />
      </InputLabelWrapper>
    </form>
  )
}

export default LocationForm
